import { URL_EMPLOYEE } from "../config/AppConstant";
import http from "../utils/http-common";

class EmployeeDataService {
  getAll(params) {
    return http.get(URL_EMPLOYEE + "/list", { params });
  }

  get(id) {
    return http.get(`${URL_EMPLOYEE}/${id}`);
  }

  create(data) {
    return http.post(URL_EMPLOYEE, data);
  }

  update(data) {
    return http.put(URL_EMPLOYEE, data);
  }

  delete(id) {
    return http.delete(`${URL_EMPLOYEE}/${id}`);
  }

  changePassword(params) {
    return http.put(`${URL_EMPLOYEE}/change-password`, params);
  }
  assignAddressesToEmployee(employeeId, params) {
    return http.post(`${URL_EMPLOYEE}/${employeeId}/assign-addresses`, params);
  }
  sendAppUrlToEmployee(id) {
    return http.post(`/management/email/application-link/${id}`);
  }
  isEmployeeConnected(id) {
    return http.get(`${URL_EMPLOYEE}/${id}/is_connected`);
  }
}

export default new EmployeeDataService();
