import React, { useEffect, useState } from "react";

import { Col, FormGroup, Label, Row } from "reactstrap";

import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import AppModal from "../../components/Modal";
import { retrieveAddresses } from "../../redux/actions/addressActions";
import { showMessage, validateInput } from "../../utils/app-utils";
import { MESSAGE_ERROR } from "../../config/AppConstant";
import {
  createCustomerAccount,
  updateCustomerAccount,
} from "../../redux/actions/customerAccountAction";

const UpdateCustomerAccount = (props) => {
  const { t } = useTranslation();
  const { addresses } = useSelector((state) => state);
  const [openModal, setOpenModal] = useState(false);
  const [selectedAddressIds, setSelectedAddressIds] = useState([]);
  const [selectedAddresses, setSelectedAddresses] = useState([]);
  const [accountInfo, setAccountInfo] = useState({
    publicId: "",
    customerId: "",
    email: "",
    name: "",
    password: "",
    isCreate: false,
    isOpenModel: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveAddresses());
  }, [dispatch]);

  useEffect(() => {
    props.customerAccountInfo && setAccountInfo(props.customerAccountInfo);
    props.customerAccountInfo?.isOpenModel && setOpenModal(true);
    if (props.customerAccountInfo?.addressList) {
      const selectedAddressOptions =
        props.customerAccountInfo?.addressList?.map((address) =>
          getAddressOption(address)
        );
      setSelectedAddresses(selectedAddressOptions);
      let selectedAddressIds = [];
      props.customerAccountInfo?.addressList?.map((address) =>
        selectedAddressIds.push(address?.publicId)
      );
      setSelectedAddressIds(selectedAddressIds);
    } else {
      setSelectedAddresses([]);
      setSelectedAddressIds([]);
    }
  }, [props.customerAccountInfo]);

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    setAccountInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddressDropDownChange = (addresses) => {
    let selectedAddressIds = [];
    if (addresses?.length > 0) {
      for (const address of addresses) {
        const value = address?.value?.split("|");
        selectedAddressIds.push(value[0]);
      }
    }
    setSelectedAddressIds(selectedAddressIds);
    setSelectedAddresses(addresses);
  };

  const handleCancel = (publicId) => {
    setOpenModal(false);
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    let payload = {
      publicId: accountInfo.publicId,
      email: accountInfo.email,
      name: accountInfo.name,
      password: accountInfo.password,
      addressIds: selectedAddressIds,
    };
    const values = [payload.name, payload.email];

    accountInfo.isCreate && values.push(payload.password);

    if (validateInput(values)) {
      console.log(values);
      if (payload.publicId) {
        delete payload.password;
        dispatch(updateCustomerAccount(payload));
      } else {
        dispatch(createCustomerAccount(accountInfo.customerId, payload));
      }
    } else {
      showMessage({
        type: MESSAGE_ERROR,
        message: t("missing_information"),
      });
    }
    setOpenModal(false);
  };

  const getAddressOption = (option) => {
    return {
      label: option.objectName + " (" + option.objectNumber + ")",
      value: option.publicId,
    };
  };

  const customerAddresses = addresses?.data?.filter(
    (address) =>
      address?.agreement?.customer?.publicId === accountInfo.customerId
  );

  const addressOptions = customerAddresses?.map((address) =>
    getAddressOption(address)
  );

  return (
    <AppModal
      openModal={openModal}
      setOpenModal={setOpenModal}
      header={
        accountInfo.publicId
          ? t("update_customer_account")
          : t("add_customer_account")
      }
      formId={"add-customer-account"}
      handleCancel={handleCancel}
    >
      <AvForm id="add-customer-account" onSubmit={handleOnSubmit}>
        <Row>
          <Col>
            <AvGroup>
              <Label for="name">{t("name")}</Label>
              <AvInput
                required
                type="text"
                name="name"
                id="name"
                placeholder={t("name")}
                value={accountInfo.name}
                onChange={handleInputChange}
              />
              <AvFeedback>{t("missing_name")}</AvFeedback>
            </AvGroup>
            <AvGroup>
              <Label for="email">{t("email")}</Label>
              <AvInput
                required
                type="email"
                name="email"
                id="email"
                placeholder={t("email")}
                value={accountInfo.email}
                onChange={handleInputChange}
              />
              <AvFeedback>{t("missing_email")}</AvFeedback>
            </AvGroup>
            {!accountInfo.publicId && (
              <AvGroup>
                <Label for="password">{t("password")}</Label>
                <AvInput
                  required
                  type="password"
                  name="password"
                  id="password"
                  placeholder={t("password")}
                  value={accountInfo.password}
                  onChange={handleInputChange}
                />
                <AvFeedback>{t("missing_password")}</AvFeedback>
              </AvGroup>
            )}
            <FormGroup>
              <Label for="addressId">{t("address")}</Label>
              <ReactSelect
                className="react-select-container"
                classNamePrefix="react-select"
                options={addressOptions}
                value={selectedAddresses}
                placeholder={t("all_addresses")}
                isSearchable
                id="addressId"
                onChange={handleAddressDropDownChange}
                isMulti
              />
            </FormGroup>
          </Col>
        </Row>
      </AvForm>
    </AppModal>
  );
};

export default UpdateCustomerAccount;
