import { t } from "i18next";
import AddressDataService from "../../services/addressService";
import {
  ADDRESS_IN_PROCESSING,
  ASSIGN_CLEANERS_TO_ADDRESS,
  CREATE_ADDRESS,
  DELETE_ADDRESS,
  MESSAGE_SUCCESS,
  RETRIEVE_ADDRESS,
  RETRIEVE_ADDRESSES,
  RETRIEVE_ADDRESSES_FOR_QUALITY_RATING_REPORT,
  RETRIEVE_ADDRESS_CLEANERS,
  UPDATE_ADDRESS,
  UPDATE_ADDRESS_STATUS,
} from "../constants";

export const retrieveAddresses = (params) => async (dispatch) => {
  try {
    const res = await AddressDataService.getAll(params);
    dispatch({
      type: RETRIEVE_ADDRESSES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const retrieveAgreementAddresses =
  (publicId, params) => async (dispatch) => {
    try {
      const res = await AddressDataService.getAgreementAddress(
        publicId,
        params
      );
      dispatch({
        type: RETRIEVE_ADDRESSES,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const retrieveAgreementAddressesForQualityCheckReport =
  (publicId, params) => async (dispatch) => {
    try {
      const res = await AddressDataService.getAgreementAddress(
        publicId,
        params
      );
      dispatch({
        type: RETRIEVE_ADDRESSES_FOR_QUALITY_RATING_REPORT,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const getAddress = (publicId) => async (dispatch) => {
  try {
    const res = await AddressDataService.get(publicId);
    dispatch({
      type: RETRIEVE_ADDRESS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAddress = (publicId) => async (dispatch) => {
  try {
    await AddressDataService.delete(publicId);
    dispatch({
      type: DELETE_ADDRESS,
      payload: { publicId },
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateAddressStatus = (publicId, status) => async (dispatch) => {
  try {
    dispatch({
      type: ADDRESS_IN_PROCESSING,
      payload: true,
    });
    await AddressDataService.updateAddressStatus(publicId, status);
    dispatch({
      type: UPDATE_ADDRESS_STATUS,
      payload: { publicId },
    });
    dispatch({
      type: MESSAGE_SUCCESS,
      message: t("address_status_updated"),
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ADDRESS_IN_PROCESSING,
      payload: false,
    });
  }
};

export const editAddress = (address, customerRep) => async (dispatch) => {
  try {
    let body = address;
    body.customerRep = {};
    body.customerRep = customerRep;
    dispatch({
      type: ADDRESS_IN_PROCESSING,
      payload: true,
    });
    const res = await AddressDataService.update(address);
    dispatch({
      type: UPDATE_ADDRESS,
      payload: res.data,
    });
    dispatch({
      type: MESSAGE_SUCCESS,
      message: t("address_updated"),
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ADDRESS_IN_PROCESSING,
      payload: false,
    });
  }
};

export const createAddress = (address, customerRep) => async (dispatch) => {
  try {
    let body = address;
    body.customerRep = {};
    body.customerRep = customerRep;
    dispatch({
      type: ADDRESS_IN_PROCESSING,
      payload: true,
    });
    const res = await AddressDataService.create(body);
    dispatch({
      type: CREATE_ADDRESS,
      payload: res.data,
    });
    dispatch({
      type: MESSAGE_SUCCESS,
      message: t("address_created"),
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ADDRESS_IN_PROCESSING,
      payload: false,
    });
  }
};

export const uploadAdressDocument = (document) => async (dispatch) => {
  try {
    const formData = new FormData();
    document.image_file && formData.append("file", document.image_file);
    const res = await AddressDataService.uploadAddressDocument(
      document.addressId,
      formData
    );
    dispatch({
      type: MESSAGE_SUCCESS,
      message: t("doc_upload"),
    });
    dispatch({
      type: UPDATE_ADDRESS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getAddressDocument = (publicId) => async (dispatch) => {
  try {
    const res = await AddressDataService.getAddressDocument(publicId);
    const type = res.headers["content-type"];
    const blob = new Blob([res.data], { type: type, encoding: "UTF-8" });
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
  } catch (err) {
    console.log(err);
  }
};

export const updateAddressSchedule =
  (publicId, addressSchedule) => async (dispatch) => {
    try {
      dispatch({
        type: ADDRESS_IN_PROCESSING,
        payload: true,
      });
      const res = await AddressDataService.updateAddressSchedule(
        publicId,
        addressSchedule
      );
      dispatch({
        type: UPDATE_ADDRESS,
        payload: res.data,
      });
      dispatch({
        type: MESSAGE_SUCCESS,
        message: t("schedule_updated"),
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: ADDRESS_IN_PROCESSING,
        payload: false,
      });
    }
  };

export const assignCleanersToAddress =
  (addressId, params) => async (dispatch) => {
    try {
      const res = await AddressDataService.assignCleanersToAddress(
        addressId,
        params
      );
      dispatch({
        type: ASSIGN_CLEANERS_TO_ADDRESS,
        payload: res.data,
      });
      dispatch({
        type: MESSAGE_SUCCESS,
        message: t("cleaner_assignment_success"),
      });
    } catch (err) {
      console.log(err);
    }
  };

export const retrieveAddressCleaners = (publicId) => async (dispatch) => {
  try {
    const res = await AddressDataService.getCleanersAssignedToAddress(publicId);
    dispatch({
      type: RETRIEVE_ADDRESS_CLEANERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
