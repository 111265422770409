import React, { forwardRef, useEffect } from "react";
import { Link } from "react-router-dom";

import { Badge, Card, CardBody } from "reactstrap";

import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import EmptyData from "../../components/EmptyData";
import { SIZE_PER_PAGE, STATUS, STATUS_COLORS } from "../../config/AppConstant";
import { retrieveTeamLeadQuestionnaires } from "../../redux/actions/egenQuestionnairesActions";

export const TeamLeadEgenQuestionnaireList = forwardRef((props, ref) => {
  const { teamLeadEgenQuestionnaires, isLoading } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();
  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: SIZE_PER_PAGE,
      type: props.type,
    };
    dispatch(retrieveTeamLeadQuestionnaires(props.categoryId, params));
  }, [dispatch, props.categoryId, props.type]);

  const onQuestionnaireClickHandler = (publicId) => {
    let questionnaire = {
      publicId: "",
      name: "",
      active: true,
    };
    if (publicId) {
      questionnaire = teamLeadEgenQuestionnaires?.data?.find(
        (obj) => obj.publicId === publicId
      );
    }
    questionnaire.categoryId = props.categoryId;
    questionnaire.type = props.type;
    ref?.current?.setEgenQuestionnaire(questionnaire);
  };

  const actionFormatter = (cell, row, rowIndex) => {
    return (
      <div className="table-action text-center">
        <Link
          to={"#"}
          onClick={() => onQuestionnaireClickHandler(row?.publicId)}
        >
          <FontAwesomeIcon
            icon={faPen}
            fixedWidth
            className="align-middle mr-1"
          />
        </Link>
      </div>
    );
  };

  const handleTableChange = (type, newState) => {
    const { page, sizePerPage, filters, sortField, sortOrder, status } =
      newState;
    const params = {
      pageNo: page ? page - 1 : "",
      pageSize: sizePerPage ? sizePerPage : SIZE_PER_PAGE,
      sortBy: sortField ? sortField : "",
      sortDir: sortOrder ? sortOrder : "",
      status: status ? status : "",
      type: props.type,
    };
    for (const dataField in filters) {
      const { filterVal } = filters[dataField];
      params[dataField] = filterVal;
    }
    dispatch(retrieveTeamLeadQuestionnaires(props.categoryId, params));
  };

  const headerFormatter = (
    column,
    colIndex,
    { sortElement, filterElement }
  ) => {
    return (
      <div>
        <div>
          {column.text}
          {sortElement}
        </div>
        <div style={{ display: "grid", marginTop: "10px" }}>
          {filterElement}
        </div>
      </div>
    );
  };

  const tableColumns = [
    {
      dataField: "publicId",
      text: "",
      hidden: true,
    },
    {
      dataField: "name",
      text: t("name"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "active",
      text: t("status"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
      filter: selectFilter({
        key: t("active"),
        placeholder: t("select"),
        options: [
          {
            value: true,
            label: t("active"),
          },
          {
            value: false,
            label: t("in_active"),
          },
        ],
      }),
      formatter: (cell, row, rowIndex) => {
        return (
          <Badge style={{ backgroundColor: STATUS_COLORS[row?.active] }}>
            {STATUS[row?.active]}
          </Badge>
        );
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "actions",
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      formatter: actionFormatter,
      headerFormatter: headerFormatter,
    },
  ];

  return (
    <Card>
      <CardBody className={isLoading ? "text-center" : ""}>
        <BootstrapTable
          condensed
          keyField="publicId"
          data={teamLeadEgenQuestionnaires?.data}
          remote={true}
          columns={tableColumns}
          bootstrap4
          striped
          hover
          bordered={false}
          filter={filterFactory()}
          pagination={paginationFactory({
            sizePerPage: teamLeadEgenQuestionnaires?.sizePerPage,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true,
            totalSize: teamLeadEgenQuestionnaires?.totalSize,
          })}
          onTableChange={handleTableChange}
          loading={isLoading}
          noDataIndication={() => {
            return <EmptyData isLoading={isLoading} />;
          }}
        ></BootstrapTable>
      </CardBody>
    </Card>
  );
});
