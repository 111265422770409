import {
  CLEAR_CLEANER_EGEN_CONTROL_QUALITY_CHECKS,
  RETRIEVE_CLEANER_EGEN_CONTROL_QUALITY_CHECKS,
} from "../constants";

const initialState = {
  data: [],
  page: 0,
  sizePerPage: 0,
  totalSize: 0,
  isLoading: true,
};
export default function reducer(state = initialState, actions) {
  const { type, payload } = actions;
  switch (type) {
    case RETRIEVE_CLEANER_EGEN_CONTROL_QUALITY_CHECKS:
      return {
        ...state,
        data: payload.content,
        page: payload.pageNo,
        sizePerPage: payload.pageSize,
        totalSize: payload.totalElements,
        isLoading: false,
      };
    case CLEAR_CLEANER_EGEN_CONTROL_QUALITY_CHECKS:
      return {
        ...state,
        data: [],
        page: 0,
        sizePerPage: 0,
        totalSize: 0,
        isLoading: true,
      };
    default:
      return state;
  }
}
