import { URL_SCHEDULE } from "../config/AppConstant";
import http from "../utils/http-common";

class ScheduleDataService {
  getAll() {
    return http.get(URL_SCHEDULE + "/list");
  }

  get(id) {
    return http.get(`${URL_SCHEDULE}/${id}`);
  }

  getUserSchedule(publicId) {
    return http.get(`${URL_SCHEDULE}/user/${publicId}`);
  }

  create(data) {
    return http.post(URL_SCHEDULE, data);
  }

  createFollowUp(parentId, data) {
    return http.post(`${URL_SCHEDULE}/${parentId}/followup`, data);
  }

  update(data) {
    return http.put(`${URL_SCHEDULE}`, data);
  }

  delete(id) {
    return http.delete(`${URL_SCHEDULE}/${id}`);
  }

  exportSchedules(params) {
    return http.get(`${URL_SCHEDULE}/export/`, {
      params,
      responseType: "arraybuffer",
      "Content-Type": "blob",
    });
  }
}

export default new ScheduleDataService();
