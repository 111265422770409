import React from "react";

import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

import EmptyData from "../../components/EmptyData";

import { t } from "i18next";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Tippy from "@tippyjs/react";

const YearlyTable = (props) => {
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="btn btn-primary" onClick={handleClick}>
          {t("export")}
        </button>
      </div>
    );
  };
  const { isLoading } = props;
  // const handleTableChange = (type, newState) => {
  //   const { page, sizePerPage, filters, sortField, sortOrder } = newState;
  //   const params = {
  //     pageNo: page ? page - 1 : "",
  //     pageSize: sizePerPage ? sizePerPage : "",
  //     sortBy: sortField ? sortField : "",
  //     sortDir: sortOrder ? sortOrder : "",
  //   };
  //   for (const dataField in filters) {
  //     const { filterVal } = filters[dataField];
  //     params[dataField] = filterVal;
  //   }
  //   props.handleTableChange(params);
  // };

  // const headerFormatter = (
  //   column,
  //   colIndex,
  //   { sortElement, filterElement }
  // ) => {
  //   return (
  //     <div>
  //       <div>
  //         {column.text}
  //         {sortElement}
  //       </div>
  //       <div style={{ display: "grid", marginTop: "10px" }}>
  //         {filterElement}
  //       </div>
  //     </div>
  //   );
  // };

  const tableColumns = [
    {
      dataField: props.isTeamLeadData ? "name" : "objectNumber",
      text: props.isTeamLeadData ? t("team_lead") : t("object_number"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      // sort: true,
      // filter: textFilter({
      //   placeholder: t("search"),
      // }),
      // headerFormatter: headerFormatter,
    },
    {
      dataField: "Jan",
      text: "Jan",
      align: "right",
      // sort: true,
      // filter: textFilter({
      //   placeholder: t("search"),
      // }),
      // headerFormatter: headerFormatter,
    },
    {
      dataField: "Feb",
      text: "Feb",
      align: "right",
      // sort: true,
      // filter: textFilter({
      //   placeholder: t("search"),
      // }),
      // headerFormatter: headerFormatter,
    },
    {
      dataField: "Mar",
      text: t("mar"),
      align: "right",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      // sort: true,
      // filter: textFilter({
      //   placeholder: t("search"),
      // }),
      // headerFormatter: headerFormatter,
    },
    {
      dataField: "Apr",
      text: "Apr",
      align: "right",
      // sort: true,
      // filter: textFilter({
      //   placeholder: t("search"),
      // }),
      // headerFormatter: headerFormatter,
    },
    {
      dataField: "May",
      text: t("may"),
      align: "right",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      // sort: true,
      // filter: textFilter({
      //   placeholder: t("search"),
      // }),
      // headerFormatter: headerFormatter,
    },
    {
      dataField: "Jun",
      text: "Jun",
      align: "right",
      // sort: true,
      // filter: textFilter({
      //   placeholder: t("search"),
      // }),
      // headerFormatter: headerFormatter,
    },
    {
      dataField: "Jul",
      text: "Jul",
      align: "right",
      // sort: true,
      // filter: textFilter({
      //   placeholder: t("search"),
      // }),
      // headerFormatter: headerFormatter,
    },
    {
      dataField: "Aug",
      text: "Aug",
      align: "right",
      // sort: true,
      // filter: textFilter({
      //   placeholder: t("search"),
      // }),
      // headerFormatter: headerFormatter,
    },
    {
      dataField: "Sep",
      text: "Sep",
      align: "right",
      // sort: true,
      // filter: textFilter({
      //   placeholder: t("search"),
      // }),
      // headerFormatter: headerFormatter,
    },
    {
      dataField: "Oct",
      text: t("oct"),
      align: "right",
      // sort: true,
      // filter: textFilter({
      //   placeholder: t("search"),
      // }),
      // headerFormatter: headerFormatter,
    },
    {
      dataField: "Nov",
      text: "Nov",
      align: "right",
      // sort: true,
      // filter: textFilter({
      //   placeholder: t("search"),
      // }),
      // headerFormatter: headerFormatter,
    },
    {
      dataField: "Dec",
      text: "Dec",
      align: "right",
      // sort: true,
      // filter: textFilter({
      //   placeholder: t("search"),
      // }),
      // headerFormatter: headerFormatter,
    },
    {
      dataField: "yearlyAverage",
      text: "Total",
      align: "right",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      // sort: true,
      // filter: textFilter({
      //   placeholder: t("search"),
      // }),
      // headerFormatter: headerFormatter,
    },
  ];

  !props.isTeamLeadData &&
    tableColumns.splice(1, 0, {
      dataField: "objectName",
      text: t("object_name"),
      headerStyle: (colum, colIndex) => {
        return {
          width: "10%",
        };
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <Tippy
            content={<span className="calenderTooltip">{row?.objectName}</span>}
          >
            <div
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {row?.objectName}
            </div>
          </Tippy>
        );
      },
    });

  let totalAvg = props.data?.totalAverage;
  totalAvg = totalAvg?.split(",")[0];
  const reportTotalAvg = props.data?.totalAverage;
  const reportTitle = props.title;
  return (
    <Card className="border">
      <ToolkitProvider
        keyField="name"
        data={props.data ? props.data.objectRatingList : []}
        columns={tableColumns}
        exportCSV
      >
        {(props) => (
          <div>
            <CardHeader>
              <div className="float-right pull-right">
                <MyExportCSV {...props.csvProps} />
              </div>
              <CardTitle tag="h5" className="mb-0">
                {reportTitle}
              </CardTitle>
            </CardHeader>
            <CardBody className={isLoading ? "text-center" : ""}>
              <BootstrapTable
                {...props.baseProps}
                classes="yearlyReportTable"
                remote={true}
                bootstrap4
                striped
                hover
                condensed
                bordered={true}
                // filter={filterFactory()}
                // pagination={paginationFactory({
                //   sizePerPage: props.sizePerPage,
                //   hideSizePerPage: true,
                //   hidePageListOnlyOnePage: true,
                //   totalSize: props.totalSize,
                // })}
                // onTableChange={handleTableChange}
                loading={true}
                noDataIndication={() => {
                  return <EmptyData isLoading={isLoading} />;
                }}
              ></BootstrapTable>
              <div className="mt-3 text-center yearlyTable">
                <div style={{ display: "inline-flex" }}>
                  {t("overall_total") + " "}
                  <div
                    className={
                      totalAvg && totalAvg < 5 ? "ml-1 text-danger" : "ml-1"
                    }
                  >
                    {reportTotalAvg}
                  </div>
                </div>
              </div>
            </CardBody>
          </div>
        )}
      </ToolkitProvider>
    </Card>
  );
};

export default YearlyTable;
