import { toastr } from "react-redux-toastr";
import moment from "moment";
import { MESSAGE_ERROR } from "../config/AppConstant";
import { t } from "i18next";

export const showMessage = ({
  title = "",
  message = "",
  type = "success",
  timeOut = 3000,
  showCloseButton = true,
  progressBar = true,
  position = "top-right",
}) => {
  const options = {
    timeOut: parseInt(timeOut),
    showCloseButton: showCloseButton,
    progressBar: progressBar,
    position: position,
  };

  const toastrInstance =
    type === "info"
      ? toastr.info
      : type === "warning"
      ? toastr.warning
      : type === "error"
      ? toastr.error
      : toastr.success;

  toastrInstance(title, message, options);
};

export const validateInput = (values) => {
  const allFieldsFilled = values.every((field) => {
    const value = field ? `${field}`.trim() : "";
    return value !== "" && value !== "0";
  });
  return allFieldsFilled;
};

export const camelCase = (value, delim = ".") => {
  const list = value.split(delim);
  return list.reduce(
    (res, cur) => res + cur.charAt(0).toUpperCase() + cur.slice(1)
  );
};

export const base64ToArrayBuffer = (base64) => {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export const getAddressOption = (option) => {
  return {
    label:
      option.objectName +
      " (" +
      option.objectNumber +
      ")" +
      " (" +
      option.agreement.agreementNumber +
      ")",
    value: option.publicId,
  };
};

export const getOption = (option) => {
  return {
    label: option.name,
    value:
      option.publicId +
      "|" +
      option.name +
      "|" +
      option.email +
      "|" +
      option.phoneNumber,
  };
};

export const getEmployeeOption = (option) => {
  return {
    label:
      (option.employeeNumber ? option.employeeNumber : "N/A") +
      " - " +
      option.name,
    value:
      option.publicId +
      "|" +
      option.name +
      "|" +
      option.username +
      "|" +
      option.email +
      "|" +
      option.phoneNumber,
  };
};

export const getStatusMapping = () => {
  return {
    ALL: "all",
    INPROGRESS: "in_progress",
    COMPLETED: "completed",
    NOTSTARTED: "not_started",
    PENDINGAPPROVAL: "pending_approval",
    REJECTED: "rejected",
    CANCELLED: "cancelled",
  };
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const convertToISOString = (date) => {
  let x = new Date().getTimezoneOffset() * 60000;
  let localISOString = new Date(date - x).toISOString();
  return localISOString;
};

export const valid = (current) => {
  const yesterday = moment().subtract(1, "day");
  return current.isAfter(yesterday);
};

export const checkDateValidation = (startDate, endDate) => {
  // check the dates
  if (new Date(endDate) < new Date(startDate)) {
    showMessage({
      type: MESSAGE_ERROR,
      message: t("end_date_greater_then_start_date"),
    });
    return false;
  } else {
    return true;
  }
};

export const checkStartDateValidation = (startDate) => {
  // check the start dates
  if (new Date(startDate) < new Date()) {
    showMessage({
      type: MESSAGE_ERROR,
      message: t("start_date_greater_then_current_time"),
    });
    return false;
  } else {
    return true;
  }
};
