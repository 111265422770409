import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap";

import DateTime from "react-datetime";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import { AvForm } from "availity-reactstrap-validation";
import moment from "moment";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavbarDropdowns, NavbarToggle } from "../../components/Navbar";
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  MESSAGE_ERROR,
  QUALITY_CHECK_STATUS_COLORS,
  QUALITY_CHECK_STATUS_COMPLETED,
  QUALITY_CHECK_STATUS_IN_PROGRESS,
  TIME_FORMAT,
} from "../../config/AppConstant";
import {
  editEgenControlQualityCheckPerformedDate,
  getEgenControlQualityCheck,
  getEgenControlQualityCheckReport,
} from "../../redux/actions/egenControlActions";
import { MESSAGE_SUCCESS } from "../../redux/constants";
import { PATH_EGEN_QUALITY_CHECK } from "../../routes/constants";
import { showMessage, validateInput } from "../../utils/app-utils";
import { retrieveEmployees } from "../../redux/actions/employeeActions";

const UpdateEgenQualityCheck = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { egenControlQualityChecks, employees, auth, messages } = useSelector(
    (state) => state
  );
  const qualityCheckId = props.match.params.id;
  const [qualityCheck, setQualityCheck] = useState(null);
  const { message } = messages;

  const isMounted = useRef(false);

  const colorArray = ["#193c58", "#808180", "#ed9839"];

  const answerArray = ["notSelected", "Ja", "Nej", "Ej aktuellt"];

  let color = "#193c58";

  useEffect(() => {
    if (isMounted.current) {
      if (message.type === "success") {
        showMessage({
          type: MESSAGE_SUCCESS,
          message: message.detail,
        });
        props.history.push(PATH_EGEN_QUALITY_CHECK);
      }
    } else {
      isMounted.current = true;
    }
  }, [messages, message, props.history]);
  useEffect(() => {
    const params = {
      status: true,
    };
    if (qualityCheckId) {
      dispatch(getEgenControlQualityCheck(qualityCheckId));
      dispatch(retrieveEmployees(params, true));
    }
  }, [qualityCheckId, dispatch]);

  useEffect(() => {
    if (qualityCheckId && egenControlQualityChecks.detail.length > 0) {
      setQualityCheck(
        egenControlQualityChecks.detail.find(
          ({ publicId }) => publicId === qualityCheckId
        )
      );
    }
  }, [qualityCheckId, egenControlQualityChecks]);

  const onQualityCheckSelectReportHandler = (publicId) => {
    if (publicId) {
      const qualityCheck = egenControlQualityChecks.detail.find(
        (obj) => obj.publicId === publicId
      );
      if (qualityCheck) {
        dispatch(getEgenControlQualityCheckReport(publicId));
      }
    }
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    let payload = {
      publicId: qualityCheck?.publicId,
      performedDate: qualityCheck?.performedDate,
    };
    const values = [date];

    if (validateInput(values)) {
      dispatch(editEgenControlQualityCheckPerformedDate(payload));
    } else {
      showMessage({
        type: MESSAGE_ERROR,
        message: t("missing_information"),
      });
    }
  };

  const actualDate = DateTime.moment.utc(qualityCheck?.schedule?.start);
  const date = qualityCheck?.performedDate
    ? DateTime.moment.utc(qualityCheck?.performedDate)
    : actualDate;

  const dateTimeLocale =
    localStorage.getItem("i18nextLng") === "SE" ? "sv" : "en-gb";

  const qualityCheckQuestionnaires = qualityCheck?.questionnaireResponses;

  const qualityCheckQuestionnaireKeys =
    qualityCheckQuestionnaires && Object.keys(qualityCheckQuestionnaires);

  const egenControlPerformer = employees?.allData?.find(
    (employee) => employee?.publicId === qualityCheck?.employeeId
  );

  return (
    <Container fluid>
      <Header>
        <div className="effektToggle">
          <NavbarToggle />
          <HeaderTitle>{t("egen_control")}</HeaderTitle>
        </div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">{t("dashboard")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={PATH_EGEN_QUALITY_CHECK}>{t("egen_control")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("report")}</BreadcrumbItem>
        </Breadcrumb>
        <NavbarDropdowns />
      </Header>

      <Row>
        <Col>
          <ErrorBoundary
            fallbackRender={() => {}}
            onError={() => {
              showMessage({
                type: MESSAGE_ERROR,
                message: t("qualityChecks_fallback_msg"),
              });
            }}
          >
            <Card>
              <CardBody>
                <Row className="m-sm-3 m-md-3">
                  <Col md={3}>
                    <h6>{t("date")}:</h6>
                    {date.format(DATE_TIME_FORMAT)}
                  </Col>
                  {qualityCheck?.status === QUALITY_CHECK_STATUS_COMPLETED && (
                    <Col md={3} className="text-center">
                      <h6 className="mb-0">{t("signature")}:</h6>
                      <div>
                        {qualityCheck?.signature ? (
                          <img
                            className="ml-5"
                            src={
                              "data:image/png;base64," + qualityCheck?.signature
                            }
                            width="auto"
                            height="85"
                            alt={qualityCheck?.name}
                          />
                        ) : (
                          "N/A"
                        )}
                      </div>
                    </Col>
                  )}
                  {qualityCheck?.reportingId && (
                    <Col md={3} className="text-center">
                      <h6 className="mb-0">{t("number")}:</h6>
                      <div>
                        {qualityCheck?.reportingId
                          ? qualityCheck?.reportingId
                          : "N/A"}
                      </div>
                    </Col>
                  )}
                  <Col md={3} className="text-md-right">
                    <div>
                      <Badge
                        style={{
                          backgroundColor:
                            QUALITY_CHECK_STATUS_COLORS[qualityCheck?.status],
                        }}
                      >
                        {qualityCheck?.status}
                      </Badge>
                    </div>
                  </Col>
                </Row>
                <h4>{t("address")}</h4>
                <Table striped>
                  <thead>
                    <tr>
                      <th width="30%">{t("object_name")}</th>
                      <th width="30%">{t("object_number")}</th>
                      <th>{t("street_address")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td width="30%">{qualityCheck?.address?.objectName}</td>
                      <td width="30%">{qualityCheck?.address?.objectNumber}</td>
                      <td>
                        {qualityCheck?.address?.streetAddress +
                          ", " +
                          qualityCheck?.address?.city +
                          ", " +
                          qualityCheck?.address?.postalCode}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <h4>{t("customer")}</h4>
                <Table striped>
                  <thead>
                    <tr>
                      <th width="30%">{t("name")}</th>
                      <th width="30%">{t("organization_number")}</th>
                      <th>{t("phone_number")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td width="30%">
                        {qualityCheck?.address?.agreement?.customer?.name}
                      </td>
                      <td>
                        {
                          qualityCheck?.address?.agreement?.customer
                            .organizationNumber
                        }
                      </td>
                      <td>
                        {
                          qualityCheck?.address?.agreement?.customer
                            ?.phoneNumber
                        }
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <h4>{t("performed_by")}</h4>
                <Table striped>
                  <thead>
                    <tr>
                      <th width="40%">{t("name")}</th>
                      <th>{t("phone_number")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td width="40%">{egenControlPerformer?.name}</td>
                      <td>{egenControlPerformer?.phoneNumber}</td>
                    </tr>
                  </tbody>
                </Table>

                <hr className="my-4" />
                <Row>
                  <Col className="text-center">
                    <h5>{t("egen_questionnaires")}</h5>
                  </Col>
                </Row>
                {qualityCheckQuestionnaireKeys?.map(
                  (qualityCheckQuestionnaireKey, index) => {
                    color = colorArray[index % 3];
                    const qualityCheckCategoryQuestionnaire =
                      qualityCheckQuestionnaires[qualityCheckQuestionnaireKey];
                    return (
                      <div className="mt-3">
                        <Row style={{ backgroundColor: color }}>
                          <Col className="text-center">
                            <div style={{ color: "white" }}>
                              {" "}
                              {qualityCheckQuestionnaireKey}
                            </div>
                          </Col>
                        </Row>
                        {qualityCheckCategoryQuestionnaire?.map(
                          (qualityCheckQuestionnaire) => {
                            const selectedAnswer =
                              answerArray[qualityCheckQuestionnaire?.rating];
                            return (
                              <Row
                                className="mt-3"
                                key={qualityCheckQuestionnaire?.publicId}
                              >
                                <Col md={6}>
                                  <div className="ml-1">
                                    {qualityCheckQuestionnaire.name}
                                  </div>
                                </Col>
                                <Col md={6} className="text-center">
                                  <div style={{ display: "inline-flex" }}>
                                    <div
                                      className="egenControlAnswer"
                                      style={{
                                        backgroundColor:
                                          selectedAnswer === "Ja"
                                            ? color
                                            : "#cbcccb",
                                      }}
                                    >
                                      <span class="font-weight-bold">Ja</span>
                                    </div>
                                    <div
                                      className="ml-3 egenControlAnswer"
                                      style={{
                                        backgroundColor:
                                          selectedAnswer === "Nej"
                                            ? color
                                            : "#cbcccb",
                                      }}
                                    >
                                      <span class="font-weight-bold">Nej</span>
                                    </div>
                                    <div
                                      className="ml-3 egenControlAnswer"
                                      style={{
                                        width: "70px",
                                        backgroundColor:
                                          selectedAnswer === "Ej aktuellt"
                                            ? color
                                            : "#cbcccb",
                                      }}
                                    >
                                      <span class="font-weight-bold">
                                        Ej aktuellt
                                      </span>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            );
                          }
                        )}
                      </div>
                    );
                  }
                )}
                <hr className="my-4" />

                <Row>
                  <Col>
                    <h6>{t("suggestion_for_improvements")}:</h6>
                    <p className="wrapWhiteSpace">
                      {qualityCheck?.measures || "N/A"}
                    </p>
                  </Col>
                </Row>
                <hr className="my-4" />
                <Row>
                  {qualityCheck?.images?.map((image, i) => {
                    return (
                      <Col key={i} md={4}>
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={image.split("|")[1]}
                            alt={image.split("|")[0]}
                          />
                          <CardHeader>
                            <CardTitle tag="h5" className="mb-0">
                              {i + 1}
                            </CardTitle>
                          </CardHeader>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>

                {qualityCheck?.status === QUALITY_CHECK_STATUS_COMPLETED && (
                  <div className="text-center">
                    <Button
                      onClick={() =>
                        onQualityCheckSelectReportHandler(
                          qualityCheck?.publicId
                        )
                      }
                      color="primary"
                      disabled={egenControlQualityChecks.isLoadingReport}
                    >
                      {egenControlQualityChecks.isLoadingReport ? (
                        <Spinner />
                      ) : (
                        t("print_report")
                      )}
                    </Button>
                  </div>
                )}
              </CardBody>
            </Card>
          </ErrorBoundary>
        </Col>
        {auth.isAdmin &&
          qualityCheck?.status === QUALITY_CHECK_STATUS_IN_PROGRESS && (
            <Col className="col-md-3 col-xxl-3">
              <ErrorBoundary
                fallbackRender={() => {}}
                onError={() => {
                  showMessage({
                    type: MESSAGE_ERROR,
                    message: t("qualityChecks_fallback_msg"),
                  });
                }}
              >
                <Card>
                  <CardHeader>
                    <CardTitle tag="h5" className="mb-0">
                      {t("performed_date")}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="align-self-center w-100">
                      <Table className="mb-0">
                        <tbody>
                          <tr>
                            <td>{t("actual_date")}</td>
                            <td className="text-right">
                              {actualDate.format(DATE_TIME_FORMAT)}
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <AvForm onValidSubmit={handleOnSubmit}>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label for="start">{t("date")}</Label>
                            <div className="align-self-center w-100">
                              <DateTime
                                inputProps={{
                                  readOnly: true,
                                  placeholder: t("date"),
                                }}
                                timeConstraints={{
                                  hours: { min: 6, max: 20 },
                                  minutes: { min: 0, max: 59 },
                                  seconds: { min: 0, max: 59 },
                                }}
                                initialViewDate={moment
                                  .utc()
                                  .set({ hour: 6, minute: 0 })}
                                className="effectDatePicker"
                                name="date"
                                dateFormat={DATE_FORMAT}
                                timeFormat={TIME_FORMAT}
                                defaultValue={date}
                                value={date}
                                locale={dateTimeLocale}
                                closeOnSelect
                                onChange={(date) =>
                                  setQualityCheck((prevState) => ({
                                    ...prevState,
                                    performedDate: date.toISOString(),
                                  }))
                                }
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Button color="primary">{t("update")}</Button>
                    </AvForm>
                  </CardBody>
                </Card>
              </ErrorBoundary>
            </Col>
          )}
      </Row>
    </Container>
  );
};

export default UpdateEgenQualityCheck;
