import { URL_ADDRESS } from "../config/AppConstant";
import http from "../utils/http-common";

class AddressDataService {
  getAll(params) {
    return http.get(URL_ADDRESS + "/list", { params });
  }

  get(id) {
    return http.get(`${URL_ADDRESS}/${id}`);
  }

  getAgreementAddress(publicId, params) {
    return http.get(`${URL_ADDRESS}/agreement/${publicId}`, { params });
  }

  create(data) {
    return http.post(URL_ADDRESS, data);
  }

  update(data) {
    return http.put(`${URL_ADDRESS}`, data);
  }

  delete(id) {
    return http.delete(`${URL_ADDRESS}/${id}`);
  }

  updateAddressStatus(id, status) {
    return http.put(`${URL_ADDRESS}/${id}/active/${status}`);
  }

  uploadAddressDocument(publicId, formData) {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return http.post(
      `${URL_ADDRESS}/upload-document/${publicId}`,
      formData,
      config
    );
  }

  getAddressDocument(publicId) {
    return http.get(`${URL_ADDRESS}/get-document/${publicId}`, {
      responseType: "arraybuffer",
    });
  }

  updateAddressSchedule(publicId, data) {
    return http.put(`${URL_ADDRESS}/schedule/${publicId}`, data);
  }

  assignCleanersToAddress(addressId, data) {
    return http.put(`${URL_ADDRESS}/${addressId}/egenkontroll/employee`, data);
  }

  getCleanersAssignedToAddress(addressId) {
    return http.get(`${URL_ADDRESS}/${addressId}/egenkontroll/employee`);
  }
}

export default new AddressDataService();
