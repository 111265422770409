import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

import { faKey, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import EmptyData from "../../components/EmptyData";
import { SIZE_PER_PAGE } from "../../config/AppConstant";
import { retrieveCustomerAccounts } from "../../redux/actions/customerAccountAction";
import UpdateCustomerAccount from "./UpdateCustomerAccount";
import { X } from "react-feather";

const CustomerAccountsList = (props) => {
  const { t } = useTranslation();
  const { customerAccounts } = useSelector((state) => state);
  const [customerAccountInfo, setCustomerAccountInfo] = useState(null);
  const { isLoading } = customerAccounts;
  const dispatch = useDispatch();

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: SIZE_PER_PAGE,
      status: "ALL",
    };
    dispatch(retrieveCustomerAccounts(props?.customerId, params));
  }, [dispatch, props?.customerId]);

  const onChangePasswordSelectHandler = (publicId) => {
    let customerAccount = {
      publicId: "",
      name: "",
      email: "",
      password: "",
      addressList: [],
    };
    if (publicId) {
      customerAccount = customerAccounts.data.find(
        (obj) => obj.publicId === publicId
      );
    }
    props.setChangePassword(true);
    props.setShowCustomerAccounts(false);
    props.setCustomerAccount(customerAccount);
  };

  const onCustomerAccountsSelectHandler = (publicId) => {
    let customerAccountData = customerAccounts.data.find(
      (obj) => obj.publicId === publicId
    );
    let customerAccountInfo = {
      publicId: customerAccountData?.publicId,
      customerId: props?.customerId,
      email: customerAccountData?.email,
      name: customerAccountData?.name,
      addressList: customerAccountData?.addresses,
      isCreate: false,
      isOpenModel: true,
    };
    setCustomerAccountInfo(customerAccountInfo);
  };

  const actionFormatter = (cell, row, rowIndex) => {
    return (
      <div className="table-action text-center">
        <Link
          to={"#"}
          onClick={() => onCustomerAccountsSelectHandler(row?.publicId)}
        >
          <FontAwesomeIcon
            icon={faPen}
            fixedWidth
            className="align-middle mr-1"
          />
        </Link>
        <Link
          to={"#"}
          onClick={() => onChangePasswordSelectHandler(row?.publicId)}
        >
          <FontAwesomeIcon
            icon={faKey}
            fixedWidth
            className="align-middle mr-1"
          />
        </Link>
      </div>
    );
  };

  const handleTableChange = (type, newState) => {
    const { page, sizePerPage, filters, sortField, sortOrder, status } =
      newState;
    const params = {
      pageNo: page ? page - 1 : "",
      pageSize: sizePerPage ? sizePerPage : "",
      sortBy: sortField ? sortField : "",
      sortDir: sortOrder ? sortOrder : "",
      status: status ? status : "ALL",
    };

    for (const dataField in filters) {
      const { filterVal } = filters[dataField];
      params[dataField] = filterVal;
    }
    dispatch(retrieveCustomerAccounts(props?.customerId, params));
  };

  const headerFormatter = (
    column,
    colIndex,
    { sortElement, filterElement }
  ) => {
    return (
      <div>
        <div>
          {column.text}
          {sortElement}
        </div>
        <div style={{ display: "grid", marginTop: "10px" }}>
          {filterElement}
        </div>
      </div>
    );
  };

  const tableColumns = [
    {
      dataField: "publicId",
      text: "",
      hidden: true,
    },
    {
      dataField: "email",
      text: t("email"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "actions",
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      formatter: actionFormatter,
    },
  ];

  return (
    <Card>
      <CardHeader>
        <div className="card-actions float-right">
          <span className="cursor-pointer mr-1">
            <X onClick={() => props.setShowCustomerAccounts(false)} />
          </span>{" "}
        </div>
        <CardTitle tag="h5" className="mb-0">
          {t("customer_accounts") + " (" + props.customerName + ")"}
        </CardTitle>
      </CardHeader>
      <CardBody className={isLoading ? "text-center" : ""}>
        <UpdateCustomerAccount customerAccountInfo={customerAccountInfo} />
        <BootstrapTable
          condensed
          keyField="publicId"
          data={customerAccounts.data}
          remote={true}
          columns={tableColumns}
          striped
          hover
          bootstrap4
          bordered={false}
          filter={filterFactory()}
          pagination={paginationFactory({
            sizePerPage: customerAccounts.sizePerPage,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true,
            totalSize: customerAccounts.totalSize,
          })}
          onTableChange={handleTableChange}
          loading={customerAccounts.isLoading}
          noDataIndication={() => {
            return <EmptyData isLoading={customerAccounts.isLoading} />;
          }}
        ></BootstrapTable>
      </CardBody>
    </Card>
  );
};

export default CustomerAccountsList;
