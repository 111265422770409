import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";

import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  UncontrolledDropdown,
} from "reactstrap";

import {
  faArrowAltCircleRight,
  faCog,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { userLogOut } from "../redux/actions/authActions";
import FlagIcon from "./FlagIcon";
import { t } from "i18next";
import { PATH_CHANGE_PASSWORD } from "../routes/constants";
import { Link } from "react-router-dom";

export const NavbarToggle = () => {
  const dispatch = useDispatch();
  return (
    <span
      className="sidebar-toggle d-flex mr-2"
      onClick={() => {
        dispatch(toggleSidebar());
      }}
    >
      <i className="hamburger align-self-center" />
    </span>
  );
};

export const NavbarDropdowns = (props) => {
  const dispatch = useDispatch();
  const onClickHandler = () => {
    dispatch(userLogOut());
  };
  const navBarClass = props.isDashboard
    ? "effekt-dashboard-navbar navbar-theme navbar navbar-expand"
    : "effekt-navbar navbar-theme navbar navbar-expand";
  return (
    <div className={navBarClass}>
      <FlagIcon />
      <Collapse navbar>
        <Nav navbar>
          <UncontrolledDropdown nav inNavbar className="ml-lg-1">
            <DropdownToggle nav caret>
              <FontAwesomeIcon icon={faCog} className="align-middle" />
            </DropdownToggle>
            <DropdownMenu right>
              <Link to={PATH_CHANGE_PASSWORD}>
                <DropdownItem>
                  <FontAwesomeIcon
                    icon={faKey}
                    fixedWidth
                    className="mr-2 align-middle"
                  />
                  {t("change_password")}
                </DropdownItem>
              </Link>
              <DropdownItem divider />
              <DropdownItem onClick={onClickHandler}>
                <FontAwesomeIcon
                  icon={faArrowAltCircleRight}
                  fixedWidth
                  className="mr-2 align-middle"
                />
                Sign out
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </div>
  );
};

const NavbarComponent = () => {
  const { sidebar } = useSelector((state) => state);
  return (
    <Navbar expand className="navbar-theme">
      {!sidebar.isOnRight ? (
        <React.Fragment>
          <NavbarToggle />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <NavbarToggle />
        </React.Fragment>
      )}
    </Navbar>
  );
};

export default NavbarComponent;
