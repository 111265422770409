import {
  CREATE_CUSTOMER_ACCOUNT,
  RETRIEVE_CUSTOMER_ACCOUNT,
  RETRIEVE_CUSTOMER_ACCOUNTS,
  UPDATE_CUSTOMER_ACCOUNT,
} from "../constants";

const initialState = {
  data: [],
  accountData: [],
  page: 0,
  sizePerPage: 0,
  totalSize: 0,
  isLoading: true,
};

export default function reducer(state = initialState, actions) {
  const { type, payload } = actions;
  switch (type) {
    case CREATE_CUSTOMER_ACCOUNT:
      return {
        ...state,
        data: [...state.data, payload],
        isLoading: false,
      };
    case RETRIEVE_CUSTOMER_ACCOUNTS:
      return {
        ...state,
        data: payload.content,
        page: payload.pageNo,
        sizePerPage: payload.pageSize,
        totalSize: payload.totalElements,
        isLoading: false,
      };
    case RETRIEVE_CUSTOMER_ACCOUNT:
      return {
        ...state,
        accountData: [payload],
        isLoading: false,
      };
    case UPDATE_CUSTOMER_ACCOUNT:
      return {
        ...state,
        data: state.data.map((customerAccount) => {
          if (customerAccount.publicId === payload.publicId) {
            return {
              ...customerAccount,
              ...payload,
            };
          } else {
            return customerAccount;
          }
        }),
        isLoading: false,
      };
    default:
      return state;
  }
}
