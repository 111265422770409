import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import {
  faEye,
  faKey,
  faPen,
  faPlus,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ErrorBoundary } from "react-error-boundary";
import { MinusCircle, PlusCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import defaultLogo from "../../assets/img/avatars/default-logo.jpg";
import ChangePassword from "../../components/ChangePassword";
import EmptyData from "../../components/EmptyData";
import { NavbarDropdowns, NavbarToggle } from "../../components/Navbar";
import {
  MESSAGE_ERROR,
  SIZE_PER_PAGE,
  STATUS,
  STATUS_COLORS,
} from "../../config/AppConstant";
import {
  changeCustomerPassword,
  createCustomer,
  // deleteCustomer,
  editCustomer,
  retrieveCustomers,
} from "../../redux/actions/customerActions";
import { MESSAGE_SUCCESS } from "../../redux/constants";
import { base64ToArrayBuffer, showMessage } from "../../utils/app-utils";
import { AgreementList } from "../agreement/AgreementList";
import UpdateCustomer from "./UpdateCustomer";
import UpdateCustomerAccount from "./UpdateCustomerAccount";
import CustomerAccountsList from "./CustomerAccountsList";
import { changeCustomerAccountPassword } from "../../redux/actions/customerAccountAction";

const CustomersList = (props) => {
  const { t } = useTranslation();
  const { customers } = useSelector((state) => state);
  const [customerAccountInfo, setCustomerAccountInfo] = useState(null);
  const { isLoading } = customers;
  const dispatch = useDispatch();

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: SIZE_PER_PAGE,
      status: "ALL",
    };
    dispatch(retrieveCustomers(params));
  }, [dispatch]);

  // const removeCustomer = (publicId) => {
  //   dispatch(deleteCustomer(publicId));
  //   props.setCustomer(null);
  // };

  const onCustomerSelectHandler = (publicId) => {
    let customer = {
      publicId: "",
      username: "",
      email: "",
      name: "",
      password: "",
      phoneNumber: "",
      duration: 60,
      active: true,
    };
    if (publicId) {
      customer = customers.data.find((obj) => obj.publicId === publicId);
    }
    props.setChangePassword(false);
    props.setCustomer(customer);
    props.setShowCustomerAccounts(false);
  };

  const showCustomerAccountsHandler = (publicId) => {
    props.setChangePassword(false);
    props.setShowCustomerAccounts(true);
    let customer = {};
    if (publicId) {
      customer = customers.data.find((obj) => obj.publicId === publicId);
    }
    props.setCustomerId(customer?.publicId);
    props.setCustomerName(customer?.name);
  };

  const onChangePasswordSelectHandler = (publicId) => {
    let customer = {
      publicId: "",
      username: "",
      email: "",
      name: "",
      password: "",
      phoneNumber: "",
      duration: 60,
    };
    if (publicId) {
      customer = customers.data.find((obj) => obj.publicId === publicId);
    }
    props.setChangePassword(true);
    props.setShowCustomerAccounts(false);
    props.setCustomer(customer);
  };

  const onAddCustomerAccountHandler = (publicId) => {
    let customerAccountInfo = {
      publicId: "",
      customerId: publicId,
      email: "",
      name: "",
      password: "",
      isCreate: true,
      isOpenModel: true,
    };
    setCustomerAccountInfo(customerAccountInfo);
  };

  const actionFormatter = (cell, row, rowIndex) => {
    return (
      <div className="table-action text-center">
        <Link to={"#"} onClick={() => onCustomerSelectHandler(row?.publicId)}>
          <FontAwesomeIcon
            icon={faPen}
            fixedWidth
            className="align-middle mr-1"
          />
        </Link>
        <Link
          to={"#"}
          onClick={() => onChangePasswordSelectHandler(row?.publicId)}
        >
          <FontAwesomeIcon
            icon={faKey}
            fixedWidth
            className="align-middle mr-1"
          />
        </Link>
        <Link
          to={"#"}
          onClick={() => onAddCustomerAccountHandler(row?.publicId)}
        >
          <FontAwesomeIcon
            icon={faPlus}
            fixedWidth
            className="align-middle mr-1"
          />
        </Link>
        <Link
          to={"#"}
          onClick={() => showCustomerAccountsHandler(row?.publicId)}
        >
          <FontAwesomeIcon
            icon={faEye}
            fixedWidth
            className="align-middle mr-1"
          />
        </Link>
      </div>
    );
  };

  const logoFormatter = (cell, row, rowIndex) => {
    let image_as_base64 = null;
    if (row?.logo) {
      const imageArray = base64ToArrayBuffer(row.logo);
      const blob = new Blob([imageArray], {
        type: "image/jpeg",
      });
      image_as_base64 = URL.createObjectURL(blob);
    }
    return (
      <div style={{ display: "flex" }}>
        <img
          alt="Logo"
          src={image_as_base64 || defaultLogo}
          className="rounded-circle img-responsive ml-2"
          width="34"
          height="34"
        />
        <div className="ml-2" style={{ alignSelf: "center" }}>
          {row?.customerNumber ?? "N/A"}
        </div>
      </div>
    );
  };

  const handleTableChange = (type, newState) => {
    const { page, sizePerPage, filters, sortField, sortOrder, status } =
      newState;
    const params = {
      pageNo: page ? page - 1 : "",
      pageSize: sizePerPage ? sizePerPage : "",
      sortBy: sortField ? sortField : "",
      sortDir: sortOrder ? sortOrder : "",
      status: status ? status : "ALL",
    };

    for (const dataField in filters) {
      const { filterVal } = filters[dataField];
      params[dataField] = filterVal;
    }
    dispatch(retrieveCustomers(params));
  };

  const headerFormatter = (
    column,
    colIndex,
    { sortElement, filterElement }
  ) => {
    return (
      <div>
        <div>
          {column.text}
          {sortElement}
        </div>
        <div style={{ display: "grid", marginTop: "10px" }}>
          {filterElement}
        </div>
      </div>
    );
  };

  const tableColumns = [
    {
      dataField: "publicId",
      text: "",
      hidden: true,
    },
    {
      dataField: "customerNumber",
      text: t("customer_number"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      formatter: logoFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "logo",
      text: "",
      hidden: true,
    },
    {
      dataField: "name",
      text: t("customer_name"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerFormatter: headerFormatter,
    },
    {
      dataField: "phoneNumber",
      text: t("phone_number"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "organizationNumber",
      text: t("organization_number"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "status",
      text: t("status"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      filter: selectFilter({
        key: t("active"),
        placeholder: t("select"),
        options: [
          {
            value: true,
            label: t("active"),
          },
          {
            value: false,
            label: t("in_active"),
          },
        ],
      }),
      formatter: (cell, row, rowIndex) => {
        return (
          <Badge style={{ backgroundColor: STATUS_COLORS[row?.active] }}>
            {STATUS[row?.active]}
          </Badge>
        );
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "actions",
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      formatter: actionFormatter,
    },
  ];

  const expandRow = {
    renderer: (row) => (
      <div>
        <AgreementList customerId={row.publicId} />
      </div>
    ),
    showExpandColumn: true,
    onlyOneExpanding: true,
    parentClassName: "table-expand-row",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => null,
    expandColumnRenderer: ({ expanded }) =>
      expanded ? (
        <MinusCircle width={16} height={16} />
      ) : (
        <PlusCircle width={16} height={16} />
      ),
  };

  return (
    <Card>
      <CardHeader>
        <div className="card-actions float-right">
          <Button
            color="primary"
            className="mr-1 mb-1"
            onClick={() => onCustomerSelectHandler(null)}
          >
            <FontAwesomeIcon icon={faUserPlus} /> {t("add")}
          </Button>
        </div>
        <CardTitle tag="h5" className="mb-0">
          {t("customers")}
        </CardTitle>
      </CardHeader>
      <CardBody className={isLoading ? "text-center" : ""}>
        <UpdateCustomerAccount customerAccountInfo={customerAccountInfo} />
        <BootstrapTable
          condensed
          keyField="publicId"
          data={customers.data}
          remote={true}
          columns={tableColumns}
          expandRow={expandRow}
          striped
          hover
          bootstrap4
          bordered={false}
          filter={filterFactory()}
          pagination={paginationFactory({
            sizePerPage: customers.sizePerPage,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true,
            totalSize: customers.totalSize,
          })}
          onTableChange={handleTableChange}
          loading={customers.isLoading}
          noDataIndication={() => {
            return <EmptyData isLoading={customers.isLoading} />;
          }}
        ></BootstrapTable>
      </CardBody>
    </Card>
  );
};

const Customers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [customer, setCustomer] = useState(null);
  const [showCustomerAccounts, setShowCustomerAccounts] = useState(false);
  const [customerAccount, setCustomerAccount] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [changePassword, setChangePassword] = useState(false);
  const { messages } = useSelector((state) => state);
  const { message } = messages;
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      if (message.type === "success") {
        showMessage({
          type: MESSAGE_SUCCESS,
          message: message.detail,
        });
        setCustomer(null);
        setChangePassword(false);
      }
    } else {
      isMounted.current = true;
    }
  }, [messages, message]);

  const handleOnSubmit = (customer) => {
    if (customer.publicId) {
      dispatch(editCustomer(customer));
    } else {
      dispatch(createCustomer(customer));
    }
  };

  const handlePasswordChange = (data) => {
    if (data.isCustomerAccount) {
      delete data.isCustomerAccount;
      dispatch(changeCustomerAccountPassword(data?.publicId, data));
    } else {
      delete data.isCustomerAccount;
      dispatch(changeCustomerPassword(data));
    }
  };

  return (
    <Container fluid>
      <Header>
        <div className="effektToggle">
          <NavbarToggle />
          <HeaderTitle>{t("customers")}</HeaderTitle>
        </div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">{t("dashboard")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("customers")}</BreadcrumbItem>
        </Breadcrumb>
        <NavbarDropdowns />
      </Header>
      <Row>
        <Col>
          <ErrorBoundary
            fallbackRender={() => {}}
            onError={() => {
              showMessage({
                type: MESSAGE_ERROR,
                message: t("customers_fallback_msg"),
              });
            }}
          >
            <CustomersList
              setCustomer={setCustomer}
              setChangePassword={setChangePassword}
              setCustomerId={setCustomerId}
              setCustomerName={setCustomerName}
              setShowCustomerAccounts={setShowCustomerAccounts}
            />
          </ErrorBoundary>
        </Col>
        {showCustomerAccounts && (
          <Col className="col-md-3 col-xxl-3">
            <ErrorBoundary
              fallbackRender={() => {}}
              onError={() => {
                showMessage({
                  type: MESSAGE_ERROR,
                  message: t("customer_fallback_msg"),
                });
              }}
            >
              <CustomerAccountsList
                setShowCustomerAccounts={setShowCustomerAccounts}
                setCustomerAccount={setCustomerAccount}
                customerId={customerId}
                customerName={customerName}
                setChangePassword={setChangePassword}
              />
            </ErrorBoundary>
          </Col>
        )}
        {!changePassword && customer && (
          <Col className="col-md-3 col-xxl-3">
            <ErrorBoundary
              fallbackRender={() => {}}
              onError={() => {
                showMessage({
                  type: MESSAGE_ERROR,
                  message: t("customer_fallback_msg"),
                });
              }}
            >
              <UpdateCustomer
                customer={customer}
                setCustomer={setCustomer}
                setChangePassword={setChangePassword}
                handleOnSubmit={handleOnSubmit}
              />
            </ErrorBoundary>
          </Col>
        )}
        {changePassword && (
          <Col className="col-md-3 col-xxl-3">
            <ErrorBoundary
              fallbackRender={() => {}}
              onError={() => {
                showMessage({
                  type: MESSAGE_ERROR,
                  message: t("customer_fallback_msg"),
                });
              }}
            >
              <ChangePassword
                user={customerAccount ? customerAccount : customer}
                setUser={customerAccount ? setCustomerAccount : setCustomer}
                isCustomerAccount={customerAccount ? true : false}
                setChangePassword={setChangePassword}
                handlePasswordChange={handlePasswordChange}
              />
            </ErrorBoundary>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Customers;
