import React, { useEffect, useRef, useState } from "react";

import { Col, FormGroup, Input, Label, Row } from "reactstrap";

import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import AppModal from "../../components/Modal";
import { MESSAGE_ERROR } from "../../config/AppConstant";
import {
  assignCleanersToAddress,
  retrieveAddressCleaners,
} from "../../redux/actions/addressActions";
import { retrieveCleaners } from "../../redux/actions/employeeActions";
import {
  getEmployeeOption,
  showMessage,
  validateInput,
} from "../../utils/app-utils";

const AssignCleaner = (props) => {
  const { t } = useTranslation();
  const { employees, addresses } = useSelector((state) => state);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCleanerIds, setSelectedCleanerIds] = useState([]);
  const [selectedCleaners, setSelectedCleaners] = useState([]);
  const [performerPublicId, setPerformerPublicId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [addressInfo, setAddressInfo] = useState({
    publicId: "",
    isCreate: false,
    isOpenModel: false,
  });
  const dispatch = useDispatch();
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      props.address && setAddressInfo(props.address);
      props.address?.isOpenModel && setOpenModal(true);
      const params = {
        status: true,
        type: "CLEANER",
      };
      dispatch(retrieveCleaners(params, true));
      dispatch(retrieveAddressCleaners(props.address?.publicId));
    } else {
      isMounted.current = true;
    }
  }, [dispatch, props.address]);

  useEffect(() => {
    addresses?.assignedCleanersData?.employeeId &&
      setPerformerPublicId(addresses?.assignedCleanersData?.employeeId);

    if (addresses?.assignedCleanersData?.employeeList) {
      const selectedCleanerOptions =
        addresses?.assignedCleanersData?.employeeList?.map((cleaner) =>
          getEmployeeOption(cleaner)
        );
      setSelectedCleaners(selectedCleanerOptions);
      let selectedCleanerIds = [];
      addresses?.assignedCleanersData?.employeeList?.map((cleaner) =>
        selectedCleanerIds.push(cleaner?.publicId)
      );
      setSelectedCleanerIds(selectedCleanerIds);
      setIsEdit(true);
    } else {
      setSelectedCleaners([]);
      setSelectedCleanerIds([]);
      setPerformerPublicId("");
      setIsEdit(false);
    }
  }, [addresses?.assignedCleanersData]);

  const handleCleanerDropDownChange = (cleaners) => {
    let selectedCleanerIds = [];
    let isPerformerIdExists = false;
    if (cleaners?.length > 0) {
      for (const cleaner of cleaners) {
        const value = cleaner?.value?.split("|");
        selectedCleanerIds.push(value[0]);
      }
      for (const cleaner of cleaners) {
        const value = cleaner?.value?.split("|");
        if (value[0] === performerPublicId) {
          isPerformerIdExists = true;
          break;
        }
      }
    }
    !isPerformerIdExists && setPerformerPublicId("");
    setSelectedCleanerIds(selectedCleanerIds);
    setSelectedCleaners(cleaners);
  };

  const handleCancel = (publicId) => {
    setOpenModal(false);
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    let payload = {
      employeeId: performerPublicId,
      employeeList: selectedCleanerIds,
    };
    const values = [payload.employeeId, payload.employeeList];
    if (isEdit || validateInput(values)) {
      if (!payload.employeeId && payload.employeeList?.length > 0) {
        showMessage({
          type: MESSAGE_ERROR,
          message: t("missing_performer"),
        });
      } else {
        dispatch(assignCleanersToAddress(addressInfo.publicId, payload));
        setOpenModal(false);
      }
    } else {
      let errorMsg = "missing_information";
      if (payload.employeeList?.length === 0) errorMsg = "missing_cleaner";
      else if (!payload.employeeId) errorMsg = "missing_performer";

      showMessage({
        type: MESSAGE_ERROR,
        message: t(errorMsg),
      });
    }
  };

  const cleanerOptions = employees?.allCleaners?.map((employee) =>
    getEmployeeOption(employee)
  );

  return (
    <AppModal
      openModal={openModal}
      setOpenModal={setOpenModal}
      header={t("assign_cleaners")}
      formId={"assign-cleaners"}
      handleCancel={handleCancel}
    >
      <AvForm id="assign-cleaners" onSubmit={handleOnSubmit}>
        <Row>
          <Col>
            <FormGroup>
              <Label for="cleanerId">{t("select_cleaners")}</Label>
              <ReactSelect
                className="react-select-container"
                classNamePrefix="react-select"
                options={cleanerOptions}
                value={selectedCleaners}
                placeholder={t("select")}
                isSearchable
                id="cleanerId"
                onChange={handleCleanerDropDownChange}
                isMulti
              />
            </FormGroup>
            <FormGroup>
              <Label for="performer">{t("select_performer")}</Label>
            </FormGroup>
            <div
              style={{
                display: "block",
                overflowY: "auto",
                height: "130px",
              }}
            >
              {selectedCleaners &&
                selectedCleaners?.map((selectedCleaner) => {
                  return (
                    <FormGroup check className="mb-2">
                      <Label check>
                        <Input
                          type="radio"
                          name="radio1"
                          value={selectedCleaner?.value}
                          checked={
                            selectedCleaner?.value?.split("|")[0] ===
                            performerPublicId
                          }
                          onChange={(event) =>
                            setPerformerPublicId(
                              event.target.value?.split("|")[0]
                            )
                          }
                        />{" "}
                        {selectedCleaner?.label}
                      </Label>
                    </FormGroup>
                  );
                })}
            </div>
          </Col>
        </Row>
      </AvForm>
    </AppModal>
  );
};

export default AssignCleaner;
