import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";

import { MESSAGE_ERROR, MESSAGE_SUCCESS } from "../../config/AppConstant";

import { showMessage } from "../../utils/app-utils";

import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import { ErrorBoundary } from "react-error-boundary";
import { MinusCircle, PlusCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import EmptyData from "../../components/EmptyData";
import {
  createTeamLeadQuestionnaire,
  editTeamLeadQuestionnaire,
  retrieveTeamLeadQuestionnairesCategories,
} from "../../redux/actions/egenQuestionnairesActions";
import { TeamLeadEgenQuestionnaireList } from "./TeamLeadEgenQuestionnaireList";
import UpdateEgenQuestionnaire from "./UpdateEgenQuestionnaire";

export const EgenCategoryList = (props) => {
  const { teamLeadEgenQuestionnaires } = useSelector((state) => state);
  const [categoryId, setCategoryId] = useState([]);
  const buttonRef = useRef({
    setEgenQuestionnaire: (egenQuestionnaire) =>
      props.setEgenQuestionnaire(egenQuestionnaire),
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    // const params = {
    //   pageNo: 0,
    //   pageSize: SIZE_PER_PAGE,
    // };
    dispatch(retrieveTeamLeadQuestionnairesCategories(/*params*/));
  }, [dispatch]);

  const onCategorySelectHandler = (publicId) => {
    let egenQuestionnaire = {
      publicId: "",
      name: "",
      active: true,
      type: "TEAM_LEAD",
      categoryId: publicId,
    };
    setCategoryId([publicId]);
    props.setEgenQuestionnaire(egenQuestionnaire);
  };

  const actionFormatter = (cell, row, rowIndex) => {
    return (
      <div className="table-action text-center">
        <Link to={"#"} onClick={() => onCategorySelectHandler(row?.publicId)}>
          <FontAwesomeIcon
            icon={faPlus}
            fixedWidth
            className="align-middle mr-1"
          />
        </Link>
      </div>
    );
  };

  const handleTableChange = (type, newState) => {
    const { /*page, sizePerPage, */ filters, sortField, sortOrder, status } =
      newState;
    const params = {
      /*pageNo: page ? page - 1 : "",
      pageSize: sizePerPage ? sizePerPage : SIZE_PER_PAGE,*/
      sortBy: sortField ? sortField : "",
      sortDir: sortOrder ? sortOrder : "",
      status: status ? status : "",
    };
    for (const dataField in filters) {
      const { filterVal } = filters[dataField];
      params[dataField] = filterVal;
    }
    dispatch(retrieveTeamLeadQuestionnairesCategories(params));
  };

  const headerFormatter = (
    column,
    colIndex,
    { sortElement, filterElement }
  ) => {
    return (
      <div>
        <div>
          {column.text}
          {sortElement}
        </div>
        <div style={{ display: "grid", marginTop: "10px" }}>
          {filterElement}
        </div>
      </div>
    );
  };

  const tableColumns = [
    {
      dataField: "publicId",
      text: "",
      hidden: true,
    },
    {
      dataField: "name",
      text: t("category"),
      headerStyle: (colum, colIndex) => {
        return { width: "100%" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "actions",
      text: "",
      formatter: actionFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      headerFormatter: headerFormatter,
    },
  ];

  const expandRow = {
    renderer: (row) => (
      <div>
        <TeamLeadEgenQuestionnaireList
          ref={buttonRef}
          type="TEAM_LEAD"
          categoryId={row?.publicId}
        />
      </div>
    ),
    onExpand: (row, isExpand, rowIndex, e) => {
      isExpand ? setCategoryId([row?.publicId]) : setCategoryId([]);
    },
    expanded: categoryId,
    showExpandColumn: true,
    onlyOneExpanding: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => null,
    expandColumnRenderer: ({ expanded }) =>
      expanded ? (
        <MinusCircle width={16} height={16} />
      ) : (
        <PlusCircle width={16} height={16} />
      ),
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          {t("egen_questionnaires")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <BootstrapTable
          condensed
          keyField="publicId"
          data={teamLeadEgenQuestionnaires.category}
          remote={true}
          columns={tableColumns}
          expandRow={expandRow}
          bootstrap4
          striped
          hover
          bordered={false}
          filter={filterFactory()}
          // pagination={paginationFactory({
          //   sizePerPage: teamLeadEgenQuestionnaires.sizePerPage,
          //   hideSizePerPage: true,
          //   hidePageListOnlyOnePage: true,
          //   totalSize: teamLeadEgenQuestionnaires.totalSize,
          // })}
          onTableChange={handleTableChange}
          loading={teamLeadEgenQuestionnaires.isLoading}
          noDataIndication={() => {
            return (
              <EmptyData isLoading={teamLeadEgenQuestionnaires.isLoading} />
            );
          }}
        ></BootstrapTable>
      </CardBody>
    </Card>
  );
};
const TeamLeadEgenQuestionnaires = (props) => {
  const { teamLeadEgenQuestionnaires, messages } = useSelector(
    (state) => state
  );
  const [egenQuestionnaire, setEgenQuestionnaire] = useState(null);
  const { message } = messages;
  const { t } = useTranslation();
  const isMounted = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMounted.current) {
      if (message.type === "TEAM_LEAD" && message.detail) {
        showMessage({
          type: MESSAGE_SUCCESS,
          message: message.detail,
        });
        setEgenQuestionnaire(null);
      }
    } else {
      isMounted.current = true;
    }
  }, [messages, message, teamLeadEgenQuestionnaires.isCleaner]);

  const handleOnSubmit = (egenQuestionnaire) => {
    if (egenQuestionnaire.publicId) {
      dispatch(
        editTeamLeadQuestionnaire(egenQuestionnaire.publicId, egenQuestionnaire)
      );
    } else {
      dispatch(createTeamLeadQuestionnaire(egenQuestionnaire));
    }
  };
  return (
    <Row>
      <Col>
        {" "}
        <ErrorBoundary
          fallbackRender={() => {}}
          onError={() => {
            showMessage({
              type: MESSAGE_ERROR,
              message: t("questionnaires_fallback_msg"),
            });
          }}
        >
          <EgenCategoryList
            setEgenQuestionnaire={setEgenQuestionnaire}
            {...props}
          />
        </ErrorBoundary>
      </Col>
      {egenQuestionnaire && (
        <Col className="col-md-3 col-xxl-3">
          <ErrorBoundary
            fallbackRender={() => {}}
            onError={() => {
              showMessage({
                type: MESSAGE_ERROR,
                message: t("questionnaire_fallback_msg"),
              });
            }}
          >
            <UpdateEgenQuestionnaire
              egenQuestionnaire={egenQuestionnaire}
              setEgenQuestionnaire={setEgenQuestionnaire}
              handleOnSubmit={handleOnSubmit}
            />
          </ErrorBoundary>
        </Col>
      )}
    </Row>
  );
};

export default TeamLeadEgenQuestionnaires;
