import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import { Button, Card, CardBody, Label, Spinner } from "reactstrap";

import avatar from "../../assets/img/brands/Effektlogo.jpg";
import { userLogin } from "../../redux/actions/authActions";
import {
  PATH_DASHBOARD,
  PATH_QUALITY_CHECK,
  PATH_RESET_PASSWORD,
  ROLE_ADMIN,
} from "../../routes/constants";
import { showMessage, validateInput } from "../../utils/app-utils";

const SignIn = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { auth } = useSelector((state) => state);

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  if (auth.isLoggedIn) {
    return (
      <Redirect
        to={
          auth.roles?.includes(ROLE_ADMIN) ? PATH_DASHBOARD : PATH_QUALITY_CHECK
        }
      />
    );
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { email, password } = user;

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const values = [email, password];

    if (validateInput(values)) {
      const user = {
        email,
        password,
      };
      dispatch(userLogin(user));
    } else {
      showMessage({
        type: "error",
        message: t("missing_information"),
      });
    }
  };

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h2>{t("welcome")}</h2>
        <p className="lead">{t("sign_in")}</p>
      </div>
      <Card>
        <CardBody>
          <div className="m-sm-4">
            <div className="text-center">
              <img
                src={avatar}
                alt="Linda Miller"
                className="img-fluid rounded-circle"
                width="132"
                height="132"
              />
            </div>
            <AvForm onValidSubmit={handleOnSubmit}>
              <AvGroup>
                <Label for="email">{t("email")}</Label>
                <AvInput
                  required
                  bsSize="lg"
                  type="email"
                  name="email"
                  id="email"
                  placeholder={t("email")}
                  value={email}
                  onChange={handleInputChange}
                />
                <AvFeedback>{t("missing_email")}</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label>{t("password")}</Label>
                <AvInput
                  required
                  bsSize="lg"
                  type="password"
                  name="password"
                  placeholder={t("password")}
                  onChange={handleInputChange}
                />
                <AvFeedback>{t("missing_password")}</AvFeedback>
              </AvGroup>
              <small>
                <Link to={PATH_RESET_PASSWORD}>{t("forgot_password")}</Link>
              </small>
              <div className="text-center mt-3">
                <Button
                  color="primary"
                  size="lg"
                  type="submit"
                  disabled={auth.isLoading}
                >
                  {auth.isLoading ? <Spinner /> : t("sign_in")}
                </Button>
              </div>
            </AvForm>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SignIn;
