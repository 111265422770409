import React from "react";

import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

import { t } from "i18next";
import { AlertCircle, CheckCircle, Clock, FileText } from "react-feather";

const Statistics = (props) => {
  const {
    thisYearCompleted,
    thisYearPending,
    thisMonthCompleted,
    thisMonthPending,
    currentYear,
    currentMonth,
  } = props;
  return (
    <div className="w-100">
      <Row>
        <Col md={6} lg={6} xl={6}>
          <Card className="statistic">
            <CardBody>
              <Row>
                <Col md={8} lg={8} xl={8}>
                  <CardTitle tag="h5">
                    {t("current_month") +
                      " " +
                      currentMonth +
                      " " +
                      t("pending_stats")}
                  </CardTitle>
                </Col>
                <Col md={2} lg={2} xl={2}>
                  <div className="avatar ml-2">
                    <div className="avatar-title rounded-circle bg-primary-dark">
                      <AlertCircle className="feather align-middle" />
                    </div>
                  </div>
                </Col>
              </Row>
              <h1 className="display-5 mt-1 mb-3">{thisMonthPending}</h1>
            </CardBody>
          </Card>
          <Card className="statistic">
            <CardBody>
              <Row>
                <Col md={8} lg={8} xl={8}>
                  <CardTitle tag="h5">
                    {t("current_month") +
                      " " +
                      currentMonth +
                      " " +
                      t("done_stats")}
                  </CardTitle>
                </Col>
                <Col md={2} lg={2} xl={2}>
                  <div className="avatar ml-2">
                    <div className="avatar-title rounded-circle bg-primary-dark">
                      <FileText className="feather align-middle" />
                    </div>
                  </div>
                </Col>
              </Row>
              <h1 className="display-5 mt-1 mb-3">{thisMonthCompleted}</h1>
            </CardBody>
          </Card>
        </Col>
        <Col md={6} lg={6} xl={6}>
          <Card className="statistic">
            <CardBody>
              <Row>
                <Col md={8} lg={8} xl={8}>
                  <CardTitle tag="h5">
                    {t("current_year") +
                      " " +
                      currentYear +
                      " " +
                      t("pending_stats")}
                  </CardTitle>
                </Col>
                <Col md={2} lg={2} xl={2}>
                  <div className="avatar ml-2">
                    <div className="avatar-title rounded-circle bg-primary-dark">
                      <CheckCircle className="feather align-middle" />
                    </div>
                  </div>
                </Col>
              </Row>
              <h1 className="display-5 mt-1 mb-3">{thisYearPending}</h1>
            </CardBody>
          </Card>
          <Card className="statistic">
            <CardBody>
              <Row>
                <Col md={8} lg={8} xl={8}>
                  <CardTitle tag="h5">
                    {t("current_year") +
                      " " +
                      currentYear +
                      " " +
                      t("done_stats")}
                  </CardTitle>
                </Col>
                <Col md={2} lg={2} xl={2}>
                  <div className="avatar ml-2">
                    <div className="avatar-title rounded-circle bg-primary-dark">
                      <Clock className="feather align-middle" />
                    </div>
                  </div>
                </Col>
              </Row>
              <h1 className="display-5 mt-1 mb-3">{thisYearCompleted}</h1>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Statistics;
