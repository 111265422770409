import { URL_QUALITY_CHECK } from "../config/AppConstant";
import http from "../utils/http-common";

class QualityCheckDataService {
  getAll(params) {
    return http.get(URL_QUALITY_CHECK + "/list", { params });
  }

  get(id) {
    return http.get(`${URL_QUALITY_CHECK}/${id}`);
  }

  getFollowUp(parentId, params) {
    return http.get(`${URL_QUALITY_CHECK}/${parentId}/followup`, { params });
  }

  getReport(id) {
    return http.get(`${URL_QUALITY_CHECK}/report/${id}`, {
      responseType: "blob",
    });
  }

  create(data) {
    return http.post(URL_QUALITY_CHECK, data);
  }

  update(data) {
    return http.put(`${URL_QUALITY_CHECK}`, data);
  }

  updatePerformedDate(data) {
    return http.put(`${URL_QUALITY_CHECK}/update-date`, data);
  }

  delete(id) {
    return http.delete(`${URL_QUALITY_CHECK}/${id}`);
  }
}

export default new QualityCheckDataService();
