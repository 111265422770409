import { t } from "i18next";
import CustomerDataService from "../../services/customerService";
import {
  CREATE_CUSTOMER_ACCOUNT,
  MESSAGE_SUCCESS,
  RETRIEVE_CUSTOMER_ACCOUNT,
  RETRIEVE_CUSTOMER_ACCOUNTS,
  UPDATE_CUSTOMER_ACCOUNT,
} from "../constants";
export const retrieveCustomerAccounts =
  (customerId, params) => async (dispatch) => {
    try {
      const res = await CustomerDataService.getAllCustomerAccounts(
        customerId,
        params
      );
      dispatch({
        type: RETRIEVE_CUSTOMER_ACCOUNTS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const retrieveCustomerAccount = (publicId) => async (dispatch) => {
  try {
    const res = await CustomerDataService.getCustomerAccount(publicId);
    dispatch({
      type: RETRIEVE_CUSTOMER_ACCOUNT,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const createCustomerAccount =
  (customerId, params) => async (dispatch) => {
    try {
      const res = await CustomerDataService.createCustomerAccount(
        customerId,
        params
      );
      dispatch({
        type: CREATE_CUSTOMER_ACCOUNT,
        payload: res.data,
      });
      dispatch({
        type: MESSAGE_SUCCESS,
        message: t("customer_account_created"),
      });
    } catch (err) {
      console.log(err);
    }
  };

export const updateCustomerAccount = (params) => async (dispatch) => {
  try {
    const res = await CustomerDataService.updateCustomerAccount(params);
    dispatch({
      type: UPDATE_CUSTOMER_ACCOUNT,
      payload: res.data,
    });
    dispatch({
      type: MESSAGE_SUCCESS,
      message: t("customer_account_updated"),
    });
  } catch (err) {
    console.log(err);
  }
};

export const changeCustomerAccountPassword =
  (publicId, account) => async (dispatch) => {
    try {
      await CustomerDataService.changeCustomerAccountPassword(
        publicId,
        account
      );
      dispatch({
        type: MESSAGE_SUCCESS,
        message: t("customer_account_updated"),
      });
    } catch (err) {
      console.log(err);
    }
  };
