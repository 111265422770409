import { ROLE_ADMIN, ROLE_CUSTOMER, ROLE_EMPLOYEE } from "../../routes/constants";
import {
  SIGN_IN_FAIL,
  SIGN_IN_IN_PROGRESS,
  SIGN_IN_SUCCESS,
} from "../constants";

const token = JSON.parse(localStorage.getItem("token"));
const roles = JSON.parse(localStorage.getItem("roles"));

const initialState = token
  ? {
      isLoggedIn: true,
      token,
      roles: roles,
      isLoading: false,
      isCustomer: roles?.includes(ROLE_CUSTOMER),
      isAdmin: roles?.includes(ROLE_ADMIN),
      isEmployee: roles?.includes(ROLE_EMPLOYEE),
    }
  : {
      isLoggedIn: false,
      token: null,
      roles: null,
      isLoading: false,
      isCustomer: false,
      isAdmin: false,
    };

export default function reducer(state = initialState, actions) {
  const { type, payload } = actions;
  switch (type) {
    case SIGN_IN_IN_PROGRESS:
      return {
        ...state,
        isLoading: payload,
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        token: payload.accessToken,
        roles: payload.roles,
        isCustomer: payload.roles?.includes(ROLE_CUSTOMER),
        isAdmin: payload.roles?.includes(ROLE_ADMIN),
        isEmployee: payload.roles?.includes(ROLE_EMPLOYEE),
        isLoading: false,
      };
    case SIGN_IN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        token: null,
        roles: null,
        isCustomer: false,
        isAdmin: false,
        isLoading: false,
      };

    default:
      return state;
  }
}
