import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import EmptyData from "../../components/EmptyData";
import {
  DATE_TIME_FORMAT,
  MESSAGE_ERROR,
  QUALITY_CHECK_STATUS_COLORS,
  QUALITY_CHECK_STATUS_NOT_STARTED,
  SIZE_PER_PAGE,
} from "../../config/AppConstant";
import {
  exportEgenControl,
  retrieveTeamLeadEgenControlQualityChecks,
} from "../../redux/actions/egenControlActions";
import { retrieveEmployees } from "../../redux/actions/employeeActions";
import { PATH_EGEN_QUALITY_CHECK } from "../../routes/constants";
import {
  getEmployeeOption,
  getStatusMapping,
  showMessage,
} from "../../utils/app-utils";

const TeamLeadEgenControlList = (props) => {
  const { t } = useTranslation();
  const {
    employees,
    teamLeadEgenControlQualityChecks,
    egenControlQualityChecks,
    auth,
  } = useSelector((state) => state);
  const [status, setStatus] = useState("all");
  const [tableFilters, setTableFilters] = useState([]);
  const [selectedTeamLead, setSelectedTeamLead] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const params = {
      status: true,
      type: "TEAM_LEAD",
    };
    auth.isAdmin && dispatch(retrieveEmployees(params, true));
  }, [auth.isAdmin, dispatch]);

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: SIZE_PER_PAGE,
      status: status,
      month: props?.selectedMonth,
      employeeId: selectedTeamLead?.value,
      type: "TEAM_LEAD",
    };
    for (const dataField in tableFilters) {
      const { filterVal } = tableFilters[dataField];
      params[dataField] = filterVal;
    }
    dispatch(retrieveTeamLeadEgenControlQualityChecks(params));
  }, [dispatch, props?.selectedMonth, selectedTeamLead, status, tableFilters]);

  const statusOptions = [
    { value: "all", label: t("all") },
    { value: "completed", label: t("completed") },
    { value: "inprogress", label: t("in_progress") },
    { value: "NOTSTARTED", label: t("not_started") },
  ];

  const employeeOptions = employees?.allData?.map((employee) =>
    getEmployeeOption(employee)
  );

  const handleTypeDropDownChange = (type) => {
    if (type) {
      setStatus(type?.value);
    }
  };

  const handleTeamLeadDropDownChange = (teamLead) => {
    if (teamLead) {
      const value = teamLead?.value?.split("|");
      setSelectedTeamLead({
        label: teamLead?.label,
        value: value[0],
      });
    } else {
      setSelectedTeamLead(null);
    }
  };

  const onExportBtnClick = (event) => {
    const params = {
      status: status,
      month: props?.selectedMonth,
      employeeId: selectedTeamLead?.value,
      type: "TEAM_LEAD",
    };
    for (const dataField in tableFilters) {
      const { filterVal } = tableFilters[dataField];
      params[dataField] = filterVal;
    }
    dispatch(exportEgenControl(params, selectedTeamLead?.label));
  };

  const viewReportFormatter = (cell, row, rowIndex) => {
    return row?.status === QUALITY_CHECK_STATUS_NOT_STARTED ? (
      ""
    ) : (
      <div className="table-action ml-4">
        <Link to={PATH_EGEN_QUALITY_CHECK + "/" + row?.publicId}>
          <FontAwesomeIcon
            icon={faEye}
            fixedWidth
            className="align-middle mr-1"
          />
        </Link>
      </div>
    );
  };

  const handleTableChange = (type, newState) => {
    const { page, sizePerPage, filters, sortField, sortOrder } = newState;
    const params = {
      pageNo: page ? page - 1 : "",
      pageSize: sizePerPage ? sizePerPage : "",
      sortBy: sortField ? sortField : "",
      sortDir: sortOrder ? sortOrder : "",
      status: status,
      month: props?.selectedMonth,
      employeeId: selectedTeamLead?.value,
      type: "TEAM_LEAD",
    };

    for (const dataField in filters) {
      const { filterVal } = filters[dataField];
      params[dataField] = filterVal;
    }
    setTableFilters(filters);
    dispatch(retrieveTeamLeadEgenControlQualityChecks(params));
  };

  const headerFormatter = (
    column,
    colIndex,
    { sortElement, filterElement }
  ) => {
    return (
      <div>
        <div className={column.dataField === "actions" && "text-center"}>
          {column.text}
          {sortElement}
        </div>
        <div style={{ display: "grid", marginTop: "10px" }}>
          {filterElement}
        </div>
      </div>
    );
  };

  const tableColumns = [
    {
      dataField: "publicId",
      text: "",
      hidden: true,
    },
    {
      dataField: "parentId",
      text: "",
      hidden: true,
    },
    {
      dataField: "reportingId",
      text: t("number"),
      sort: status !== QUALITY_CHECK_STATUS_NOT_STARTED,
      filter:
        status !== QUALITY_CHECK_STATUS_NOT_STARTED
          ? textFilter({
              placeholder: t("search"),
            })
          : "",
      headerStyle: (colum, colIndex) => {
        return { verticalAlign: "top" };
      },
      formatter: (cell, row, rowIndex) => {
        return <div>{row?.reportingId || "N/A"}</div>;
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "customerName",
      text: t("customer_name"),
      sort: auth.isAdmin,
      hidden: auth.isCustomer,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerFormatter: headerFormatter,
    },
    {
      dataField: "objectNumber",
      text: t("object_number"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "objectName",
      text: t("object_name"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerFormatter: headerFormatter,
    },
    {
      dataField: "addressInfo",
      text: t("address"),
      sort: auth.isAdmin,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerFormatter: headerFormatter,
      headerStyle: (colum, colIndex) => {
        return { verticalAlign: "top" };
      },
    },
    {
      dataField: "performedDate",
      text: t("performed_date"),
      sort: auth.isAdmin && status !== QUALITY_CHECK_STATUS_NOT_STARTED,
      // filter:
      //   auth.isAdmin &&
      //   textFilter({
      //     placeholder: t("search"),
      //   }),

      headerStyle: (colum, colIndex) => {
        return { width: "13%", verticalAlign: "top" };
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <div>
            {row?.status === QUALITY_CHECK_STATUS_NOT_STARTED
              ? "N/A"
              : moment(row?.performedDate).format(DATE_TIME_FORMAT)}
          </div>
        );
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "status",
      text: t("status"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%", verticalAlign: "top" };
      },
      sort: auth.isAdmin && status !== QUALITY_CHECK_STATUS_NOT_STARTED,
      formatter: (cell, row, rowIndex, t) => {
        return (
          <Badge
            style={{
              backgroundColor: QUALITY_CHECK_STATUS_COLORS[row?.status],
            }}
          >
            {t(getStatusMapping()[row?.status])}
          </Badge>
        );
      },
      formatExtraData: t,
      headerFormatter: headerFormatter,
    },
    {
      dataField: "viewReport",
      text: t("view_report"),
      formatter: viewReportFormatter,
      headerFormatter: headerFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "10%", verticalAlign: "top" };
      },
    },
  ];

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          {t("team_lead_egen_control")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        {auth.isAdmin && (
          <Row>
            <Col md={3} lg={3} xl={3}>
              <FormGroup>
                <Label for="filter_by_type">{t("filter_by_type")}</Label>
                <ReactSelect
                  key={t("all")}
                  className="calenderSelect react-select-container"
                  classNamePrefix="react-select"
                  value={{
                    value: status,
                    label: t(getStatusMapping()[status.toUpperCase()]),
                  }}
                  options={statusOptions}
                  placeholder={t("select")}
                  onChange={handleTypeDropDownChange}
                />
              </FormGroup>
            </Col>
            <Col md={3} lg={3} xl={3}>
              <FormGroup>
                <Label for="filter_by_team_lead">
                  {t("filter_by_team_lead")}
                </Label>
                <ReactSelect
                  className="calenderSelect react-select-container"
                  classNamePrefix="react-select"
                  placeholder={t("select")}
                  isClearable
                  options={employeeOptions}
                  onChange={handleTeamLeadDropDownChange}
                  value={selectedTeamLead ? selectedTeamLead : ""}
                />
              </FormGroup>
            </Col>
            {teamLeadEgenControlQualityChecks.data?.length > 0 && (
              <Col md={6} lg={6} xl={6}>
                <FormGroup>
                  <div className="mt-5" style={{ float: "right" }}>
                    <Button
                      color="primary"
                      disabled={egenControlQualityChecks.isLoadingReport}
                      onClick={onExportBtnClick}
                    >
                      {egenControlQualityChecks.isLoadingReport ? (
                        <Spinner />
                      ) : (
                        t("export")
                      )}
                    </Button>
                  </div>
                </FormGroup>
              </Col>
            )}
          </Row>
        )}
        <BootstrapTable
          classes={"mt-2"}
          condensed
          keyField="publicId"
          data={teamLeadEgenControlQualityChecks.data}
          remote={true}
          columns={tableColumns}
          striped
          hover
          bootstrap4
          bordered={false}
          filter={filterFactory()}
          pagination={paginationFactory({
            sizePerPage: teamLeadEgenControlQualityChecks.sizePerPage,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true,
            totalSize: teamLeadEgenControlQualityChecks.totalSize,
          })}
          onTableChange={handleTableChange}
          loading={teamLeadEgenControlQualityChecks.isLoading}
          noDataIndication={() => {
            return (
              <EmptyData
                isLoading={teamLeadEgenControlQualityChecks.isLoading}
              />
            );
          }}
        ></BootstrapTable>
      </CardBody>
    </Card>
  );
};

const TeamLeadEgenControl = (props) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col>
        <ErrorBoundary
          fallbackRender={() => {}}
          onError={() => {
            showMessage({
              type: MESSAGE_ERROR,
              message: t("egen_team_lead_fallback_msg"),
            });
          }}
        >
          <TeamLeadEgenControlList selectedMonth={props?.selectedMonth} />
        </ErrorBoundary>
      </Col>
    </Row>
  );
};

export default TeamLeadEgenControl;
