import { t } from "i18next";
import {
  CREATE_UN_BOOKABLE_DATE,
  DELETE_UN_BOOKABLE_DATE,
  MESSAGE_SUCCESS,
  RETRIEVE_UN_BOOKABLE_DATE,
  RETRIEVE_UN_BOOKABLE_DATES,
  UPDATE_UN_BOOKABLE_DATE,
} from "../constants";
import UnBookableDatesDataService from "../../services/unBookableDatesService";

export const retrieveUnBookableDates = (params) => async (dispatch) => {
  try {
    const res = await UnBookableDatesDataService.getAll(params);
    dispatch({
      type: RETRIEVE_UN_BOOKABLE_DATES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const createUnBookableDate = (unBookableDate) => async (dispatch) => {
  try {
    const res = await UnBookableDatesDataService.create(unBookableDate);
    dispatch({
      type: CREATE_UN_BOOKABLE_DATE,
      payload: res.data,
    });
    dispatch({
      type: MESSAGE_SUCCESS,
      message: t("un_bookable_date_created"),
    });
  } catch (err) {
    console.log(err);
  }
};

export const getUnBookableDate = (publicId) => async (dispatch) => {
  try {
    const res = await UnBookableDatesDataService.get(publicId);
    dispatch({
      type: RETRIEVE_UN_BOOKABLE_DATE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteUnBookableDate = (publicId) => async (dispatch) => {
  try {
    await UnBookableDatesDataService.delete(publicId);

    dispatch({
      type: DELETE_UN_BOOKABLE_DATE,
      payload: { publicId },
    });
  } catch (err) {
    console.log(err);
  }
};

export const editUnBookableDate = (unBookableDate) => async (dispatch) => {
  try {
    const res = await UnBookableDatesDataService.update(unBookableDate);

    dispatch({
      type: UPDATE_UN_BOOKABLE_DATE,
      payload: res.data,
    });
    dispatch({
      type: MESSAGE_SUCCESS,
      message: t("un_bookable_date_updated"),
    });
  } catch (err) {
    console.log(err);
  }
};
