import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ErrorBoundary } from "react-error-boundary";
import { MinusCircle, PlusCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import EmptyData from "../../components/EmptyData";
import { NavbarDropdowns, NavbarToggle } from "../../components/Navbar";
import {
  DATE_TIME_FORMAT,
  MESSAGE_ERROR,
  MESSAGE_SUCCESS,
  QUALITY_CHECK_STATUS_CANCELLED,
  QUALITY_CHECK_STATUS_COLORS,
  QUALITY_CHECK_STATUS_COMPLETED,
  QUALITY_CHECK_STATUS_IN_PROGRESS,
  SIZE_PER_PAGE,
} from "../../config/AppConstant";
import { retrieveQualityChecks } from "../../redux/actions/qualityCheckActions";
import {
  createFollowUpSchedule,
  editSchedule,
} from "../../redux/actions/scheduleActions";
import { PATH_QUALITY_CHECK } from "../../routes/constants";
import { getStatusMapping, showMessage } from "../../utils/app-utils";
import { FollowUpQualityCheckList } from "./FollowUpQualityCheckList";
import CreateQualityCheck from "./UpdateFollowUpQualityCheck";

const QualityCheckList = (props) => {
  const { t } = useTranslation();
  const { qualityChecks, auth } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: SIZE_PER_PAGE,
    };
    dispatch(retrieveQualityChecks(params));
  }, [dispatch]);

  const onQualityCheckCreateHandler = (publicId) => {
    let qualityCheck = {
      employeePublicId: "",
      addressId: "",
      title: "",
      start: "",
      end: "",
      publicId: "",
      parentId: publicId,
      isCreate: true,
    };
    props.setQualityCheck(qualityCheck);
  };

  const viewReportFormatter = (cell, row, rowIndex) => {
    return (
      <div className="table-action ml-4">
        <Link to={PATH_QUALITY_CHECK + "/" + row?.publicId}>
          <FontAwesomeIcon
            icon={faEye}
            fixedWidth
            className="align-middle mr-1"
          />
        </Link>
      </div>
    );
  };

  const actionFormatter = (cell, row, rowIndex) => {
    return (
      <div className="table-action text-center">
        {row?.status !== "CANCELLED" && (
          <Link
            to={"#"}
            onClick={() => onQualityCheckCreateHandler(row?.publicId)}
          >
            <FontAwesomeIcon
              icon={faPlus}
              fixedWidth
              className="align-middle mr-1"
            />
          </Link>
        )}
      </div>
    );
  };

  const handleTableChange = (type, newState) => {
    const { page, sizePerPage, filters, sortField, sortOrder } = newState;
    const params = {
      pageNo: page ? page - 1 : "",
      pageSize: sizePerPage ? sizePerPage : "",
      sortBy: sortField ? sortField : "",
      sortDir: sortOrder ? sortOrder : "",
    };

    for (const dataField in filters) {
      const { filterVal } = filters[dataField];
      params[dataField] = filterVal;
    }
    dispatch(retrieveQualityChecks(params));
  };

  const headerFormatter = (
    column,
    colIndex,
    { sortElement, filterElement }
  ) => {
    return (
      <div>
        <div className={column.dataField === "actions" && "text-center"}>
          {column.text}
          {sortElement}
        </div>
        <div style={{ display: "grid", marginTop: "10px" }}>
          {filterElement}
        </div>
      </div>
    );
  };

  const tableColumns = [
    {
      dataField: "publicId",
      text: "",
      hidden: true,
    },
    {
      dataField: "parentId",
      text: "",
      hidden: true,
    },
    {
      dataField: "reportingId",
      text: t("number"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerFormatter: headerFormatter,
    },
    {
      dataField: "customerName",
      text: t("customer_name"),
      sort: auth.isAdmin,
      hidden: auth.isCustomer,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "objectNumber",
      text: t("object_number"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "objectName",
      text: t("object_name"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerFormatter: headerFormatter,
    },
    {
      dataField: "addressInfo",
      text: t("address"),
      sort: auth.isAdmin,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerFormatter: headerFormatter,
      headerStyle: (colum, colIndex) => {
        return { verticalAlign: "top" };
      },
    },
    {
      dataField: "dateTime",
      text: t("performed_date"),
      sort: auth.isAdmin,
      // filter:
      //   auth.isAdmin &&
      //   textFilter({
      //     placeholder: t("search"),
      //   }),

      headerStyle: (colum, colIndex) => {
        return { width: "10%", verticalAlign: "top" };
      },
      formatter: (cell, row, rowIndex) => {
        return <div>{moment(row?.dateTime).format(DATE_TIME_FORMAT)}</div>;
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "status",
      text: t("status"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%", verticalAlign: "top" };
      },
      sort: auth.isAdmin,
      filter:
        auth.isAdmin &&
        selectFilter({
          key: t("completed"),
          placeholder: t("select"),
          options: [
            {
              value: QUALITY_CHECK_STATUS_COMPLETED,
              label: t("completed"),
            },
            {
              value: QUALITY_CHECK_STATUS_IN_PROGRESS,
              label: t("in_progress"),
            },
            {
              value: QUALITY_CHECK_STATUS_CANCELLED,
              label: t("cancelled"),
            },
          ],
        }),
      formatter: (cell, row, rowIndex, t) => {
        return (
          <Badge
            style={{
              backgroundColor: QUALITY_CHECK_STATUS_COLORS[row?.status],
            }}
          >
            {t(getStatusMapping()[row?.status])}
          </Badge>
        );
      },
      formatExtraData: t,
      headerFormatter: headerFormatter,
    },
    {
      dataField: "viewReport",
      text: t("view_report"),
      formatter: viewReportFormatter,
      headerFormatter: headerFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "10%", verticalAlign: "top" };
      },
    },
  ];

  auth.isAdmin &&
    tableColumns.push({
      dataField: "actions",
      text: t("create_follow_up"),
      formatter: actionFormatter,
      headerFormatter: headerFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "10%", verticalAlign: "top" };
      },
    });

  const expandRow = {
    renderer: (row) => (
      <div>
        <FollowUpQualityCheckList
          qualityCheckId={row.publicId}
          setQualityCheck={props.setQualityCheck}
        />
      </div>
    ),
    showExpandColumn: true,
    onlyOneExpanding: true,
    parentClassName: "table-expand-row",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => null,
    expandColumnRenderer: ({ expanded }) =>
      expanded ? (
        <MinusCircle width={16} height={16} />
      ) : (
        <PlusCircle width={16} height={16} />
      ),
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          {t("quality_checks")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <BootstrapTable
          condensed
          keyField="publicId"
          data={qualityChecks.data}
          remote={true}
          columns={tableColumns}
          expandRow={expandRow}
          striped
          hover
          bootstrap4
          bordered={false}
          filter={filterFactory()}
          pagination={paginationFactory({
            sizePerPage: qualityChecks.sizePerPage,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true,
            totalSize: qualityChecks.totalSize,
          })}
          onTableChange={handleTableChange}
          loading={qualityChecks.isLoading}
          noDataIndication={() => {
            return <EmptyData isLoading={qualityChecks.isLoading} />;
          }}
        ></BootstrapTable>
      </CardBody>
    </Card>
  );
};

const QualityChecks = () => {
  const { t } = useTranslation();
  const { messages } = useSelector((state) => state);
  const [qualityCheck, setQualityCheck] = useState(null);
  const dispatch = useDispatch();
  const { message } = messages;
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      if (message.type === "success") {
        showMessage({
          type: MESSAGE_SUCCESS,
          message: message.detail,
        });
        setQualityCheck(null);
      }
    } else {
      isMounted.current = true;
    }
  }, [messages, message]);

  const handleOnSubmit = (parentId, qualityCheck) => {
    if (qualityCheck.publicId) {
      dispatch(editSchedule(qualityCheck, true));
    } else {
      dispatch(createFollowUpSchedule(parentId, qualityCheck));
    }
  };

  return (
    <Container fluid>
      <Header>
        <div className="effektToggle">
          <NavbarToggle />
          <HeaderTitle>{t("quality_checks")}</HeaderTitle>
        </div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">{t("dashboard")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("quality_checks")}</BreadcrumbItem>
        </Breadcrumb>
        <NavbarDropdowns />
      </Header>

      <Row>
        <Col>
          <ErrorBoundary
            fallbackRender={() => {}}
            onError={() => {
              showMessage({
                type: MESSAGE_ERROR,
                message: t("qualityChecks_fallback_msg"),
              });
            }}
          >
            <QualityCheckList setQualityCheck={setQualityCheck} />
          </ErrorBoundary>
        </Col>
        {qualityCheck && (
          <Col className="col-md-3 col-xxl-3">
            <ErrorBoundary
              fallbackRender={() => {}}
              onError={() => {
                showMessage({
                  type: MESSAGE_ERROR,
                  message: t("customer_fallback_msg"),
                });
              }}
            >
              <CreateQualityCheck
                qualityCheck={qualityCheck}
                setQualityCheck={setQualityCheck}
                handleOnSubmit={handleOnSubmit}
              />
            </ErrorBoundary>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default QualityChecks;
