import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import ConfirmationModal from "../../components/ConfirmationModal";
import { MESSAGE_ERROR } from "../../config/AppConstant";
import { retrieveCustomerAgreements } from "../../redux/actions/agreementActions";
import {
  retrieveCleaners,
  retrieveEmployees,
} from "../../redux/actions/employeeActions";
import { retrieveRepresentatives } from "../../redux/actions/representativeActions";
import { getOption, showMessage, validateInput } from "../../utils/app-utils";
import ScheduleCategory from "./ScheduleCategory";
import ScheduleList from "./ScheduleList";

const AddressForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { address, setAddress, custRep, setCustRep, isProcessing, customers } =
    props;

  const { agreements, representatives, employees } = useSelector(
    (state) => state
  );

  const options = customers?.map((customer) => getOption(customer));

  const {
    publicId,
    objectName,
    objectNumber,
    city,
    postalCode,
    streetAddress,
    agreementId,
    agreementNumber,
    companyRepId,
    companyRepName,
    egenkontrollApproverId,
    egenkontrollApproverName,
    teamLeadId,
    teamLeadName,
    agreement,
    teamLead,
    companyRep,
    egenkontrollApprover,
  } = address;

  useEffect(() => {
    if (selectedCustomer && selectedCustomer !== "clear") {
      dispatch(retrieveCustomerAgreements(selectedCustomer?.value));
    }
  }, [selectedCustomer, dispatch]);

  useEffect(() => {
    const params = {
      status: true,
      type: "TEAM_LEAD",
    };
    dispatch(retrieveEmployees(params, true));
    const cleanersParams = {
      status: true,
      type: "CLEANER",
    };
    dispatch(retrieveCleaners(cleanersParams, true));
  }, [dispatch]);

  useEffect(() => {
    setSelectedAgreement({
      label: agreementNumber || agreement?.agreementNumber || "",
      value: agreementId || agreement?.publicId || "",
    });
  }, [
    agreement?.agreementNumber,
    agreement?.publicId,
    agreementId,
    agreementNumber,
    t,
  ]);

  useEffect(() => {
    if (agreement?.customer) {
      let customer = getOption(agreement?.customer);
      const value = customer?.value?.split("|");
      setSelectedCustomer({
        label: customer?.label,
        value: value[0],
      });
    }
  }, [agreement?.customer, t]);

  useEffect(() => {
    let pid = agreement?.publicId || agreementId;
    if (pid) {
      const customerId = agreements.data.find(
        ({ publicId }) => publicId === pid
      )?.customer?.publicId;
      if (customerId) {
        dispatch(retrieveRepresentatives(customerId));
      }
    }
  }, [agreement?.publicId, agreementId, agreements.data, dispatch]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAgreementDropDownChange = (event) => {
    if (event) {
      let { value, label } = event;
      const agreement = agreements.data.find(
        ({ publicId }) => publicId === value
      );
      const employee = agreement?.customer?.teamLead;
      setAddress((prevState) => ({
        ...prevState,
        agreement: agreement,
        teamLead: publicId ? teamLead : employee,
        companyRep: publicId ? companyRep : employee,
        teamLeadId: publicId ? teamLead.publicId : employee?.publicId,
        teamLeadName: publicId ? teamLead.name : employee?.name,
        companyRepId: publicId ? companyRep.publicId : employee?.publicId,
        companyRepName: publicId ? companyRep.name : employee?.name,
        egenkontrollApprover: publicId ? egenkontrollApprover : employee,
        egenkontrollApproverId: publicId
          ? egenkontrollApprover.publicId
          : employee?.publicId,
        egenkontrollApproverName: publicId
          ? egenkontrollApprover.name
          : employee?.name,
        agreementId: value,
        agreementNumber: label,
      }));
    } else {
      setSelectedAgreement(null);
    }
  };

  const handleTeamLeadDropDownChange = (event) => {
    let { value, label } = event;
    setAddress((prevState) => ({
      ...prevState,
      teamLeadId: value,
      teamLeadName: label,
    }));
  };

  const handleCompanyRepDropDownChange = (event) => {
    let { value, label } = event;
    setAddress((prevState) => ({
      ...prevState,
      companyRepId: value,
      companyRepName: label,
    }));
  };

  const handleEgenkontrollApproverDropDownChange = (event) => {
    let { value, label } = event;
    setAddress((prevState) => ({
      ...prevState,
      egenkontrollApproverId: value,
      egenkontrollApproverName: label,
    }));
  };

  const handleCustomerRepDropDownChange = (event) => {
    if (event.length > 0) {
      let { value } = event[0];
      value = value?.split("|");
      event.label = value[1];
      setCustRep((prevState) => ({
        ...prevState,
        publicId: value[0],
        name: value[1],
        username: value[2],
        email: value[3],
        phoneNumber: value[4],
        designation: value[5],
      }));
    }
  };

  const handleCustomerRepNameChange = (inputValue) => {
    setCustRep((prevState) => ({
      ...prevState,
      publicId: "",
      name: inputValue,
      username: "",
      email: "",
      phoneNumber: "",
      designation: "",
    }));
  };

  const handleCustomerRepInputChange = (event) => {
    const { name, value } = event.target;
    setCustRep((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const values = [city, postalCode, streetAddress];
    if (validateInput(values)) {
      const address = {
        publicId,
        city,
        postalCode,
        streetAddress,
        agreementId: agreementId || agreement?.publicId,
        teamLeadId: teamLeadId || teamLead?.publicId,
        companyRepId: companyRepId || companyRep?.publicId,
        egenkontrollApproverId:
          egenkontrollApproverId || egenkontrollApprover?.publicId,
        objectName,
        objectNumber,
      };
      props.handleOnSubmit(address);
    } else {
      showMessage({
        type: MESSAGE_ERROR,
        message: t("missing_information"),
      });
    }
  };

  const handleAddressStatus = (event) => {
    event.preventDefault();
    openConfirmationModal && setOpenConfirmationModal(false);
    const addressDetail = {
      publicId,
      activate: false,
    };
    props.handleAddressStatus(addressDetail);
  };

  const employeeOptions = employees?.allData?.map((employee) => {
    return {
      label:
        (employee.employeeNumber ? employee.employeeNumber : "N/A") +
        " - " +
        employee.name,
      value: employee.publicId,
    };
  });

  const allEmployeeData =
    employees?.allCleaners?.length > 0 && employees?.allData?.length > 0
      ? [...employees?.allData, ...employees?.allCleaners]
      : [];

  const allEmployeeOptions = allEmployeeData?.map((employee) => {
    return {
      label:
        (employee.employeeNumber ? employee.employeeNumber : "N/A") +
        " - " +
        employee.name,
      value: employee.publicId,
    };
  });

  const agreementOptions =
    selectedAgreement === "clear"
      ? []
      : agreements?.data?.map((agreement) => {
          return {
            label: agreement.agreementNumber + " - " + agreement.description,
            value: agreement.publicId,
          };
        });

  const customerRepOptions = representatives?.data?.map((representative) => {
    return {
      label: representative.name + " (" + representative.email + ")",
      value:
        representative.publicId +
        "|" +
        representative.name +
        "|" +
        representative.username +
        "|" +
        representative.email +
        "|" +
        representative.phoneNumber +
        "|" +
        representative.designation,
    };
  });

  const handleCustomerDropDownChange = (customer) => {
    if (customer) {
      const value = customer?.value?.split("|");
      setSelectedCustomer({
        label: customer?.label,
        value: value[0],
      });
      setSelectedAgreement(null);
    } else {
      setSelectedCustomer(null);
      setSelectedAgreement("clear");
    }
  };

  return (
    <Row>
      <Col md={publicId ? 7 : 12}>
        <AvForm id="address-form" onValidSubmit={handleOnSubmit}>
          <Card style={{ height: "272.8px" }}>
            <CardBody>
              <ConfirmationModal
                openConfirmationModal={openConfirmationModal}
                setOpenConfirmationModal={setOpenConfirmationModal}
                header={t("deactivate_address_confirmation_header")}
                message={t("deactivate_address_confirmation_message")}
                handleOnSubmit={handleAddressStatus}
              />
              <h5>{t("address_information")}</h5>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label for="customerId">{t("customer_name")}</Label>
                    <ReactSelect
                      className="calenderSelect react-select-container"
                      classNamePrefix="react-select"
                      options={options}
                      isDisabled={publicId}
                      onChange={handleCustomerDropDownChange}
                      value={selectedCustomer ? selectedCustomer : ""}
                      placeholder={t("select_customer")}
                      isClearable
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for="agreementId">{t("agreement_number")}</Label>
                    <ReactSelect
                      className="addressAgreement react-select-container"
                      classNamePrefix="react-select"
                      options={agreementOptions}
                      value={selectedAgreement ? selectedAgreement : ""}
                      id="agreementId"
                      onChange={handleAgreementDropDownChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <AvGroup>
                    <Label for="objectNumber">{t("object_number")}</Label>
                    <AvInput
                      required
                      type="text"
                      name="objectNumber"
                      id="objectNumber"
                      placeholder={t("object_number")}
                      value={objectNumber || ""}
                      onChange={handleInputChange}
                    />
                  </AvGroup>
                </Col>
                <Col md={4}>
                  <AvGroup>
                    <Label for="objectName">{t("object_name")}</Label>
                    <AvInput
                      required
                      type="text"
                      name="objectName"
                      id="objectName"
                      placeholder={t("object_name")}
                      value={objectName || ""}
                      onChange={handleInputChange}
                    />
                  </AvGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <AvGroup>
                    <Label for="streetAddress">{t("street_address")}</Label>
                    <AvInput
                      required
                      type="text"
                      name="streetAddress"
                      id="streetAddress"
                      placeholder={t("street_address")}
                      value={streetAddress || ""}
                      onChange={handleInputChange}
                    />
                  </AvGroup>
                </Col>
                <Col md={3}>
                  <AvGroup>
                    <Label for="city">{t("city")}</Label>
                    <AvInput
                      required
                      type="text"
                      name="city"
                      id="city"
                      placeholder={t("city")}
                      value={city || ""}
                      onChange={handleInputChange}
                    />
                  </AvGroup>
                </Col>
                <Col md={3}>
                  <AvGroup>
                    <Label for="postalCode">{t("post_code")}</Label>
                    <AvInput
                      minLength={6}
                      required
                      type="text"
                      name="postalCode"
                      id="postalCode"
                      placeholder={t("post_code")}
                      value={postalCode || ""}
                      mask="999 99"
                      maskChar=""
                      onChange={handleInputChange}
                      tag={[Input, InputMask]}
                    />
                    <AvFeedback>{t("zip_code_format")}</AvFeedback>
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  {publicId && (
                    <Button
                      style={{ float: "right" }}
                      color="primary"
                      onClick={() => {
                        setOpenConfirmationModal(true);
                      }}
                    >
                      {t("deactivate_address_confirmation_header")}
                    </Button>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label for="teamLead">{t("team_lead")}</Label>
                    <ReactSelect
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={employeeOptions}
                      value={{
                        label: teamLeadName || teamLead?.name || t("select"),
                        value: teamLeadId || teamLead?.publicId || "",
                      }}
                      isSearchable
                      label={teamLeadId || ""}
                      id="teamLead"
                      onChange={handleTeamLeadDropDownChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="egenkontrollApprover">
                      {t("approver_of_egen_control")}
                    </Label>
                    <ReactSelect
                      className="egenkontrollApprover react-select-container"
                      classNamePrefix="react-select"
                      options={allEmployeeOptions}
                      value={{
                        label:
                          egenkontrollApproverName ||
                          egenkontrollApprover?.name ||
                          t("select"),
                        value:
                          egenkontrollApproverId ||
                          egenkontrollApprover?.publicId ||
                          "",
                      }}
                      isSearchable
                      label={egenkontrollApproverName || ""}
                      id="egenkontrollApprover"
                      onChange={handleEgenkontrollApproverDropDownChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="compRepName">
                      {t("company_representative")}
                    </Label>
                    <ReactSelect
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={employeeOptions}
                      value={{
                        label:
                          companyRepName || companyRep?.name || t("select"),
                        value: companyRepId || companyRep?.publicId || "",
                      }}
                      isSearchable
                      label={companyRepName || ""}
                      id="compRepName"
                      onChange={handleCompanyRepDropDownChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <hr />

              <h5>{t("customer_representative")}</h5>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="custRepName">{t("name")}</Label>
                    <Typeahead
                      clearButton={custRep.name ? true : false}
                      id="customerRepName"
                      labelKey="label"
                      options={customerRepOptions}
                      placeholder={t("choose_representative")}
                      onChange={handleCustomerRepDropDownChange}
                      onInputChange={handleCustomerRepNameChange}
                      defaultSelected={[custRep.name || ""]}
                      selected={[custRep.name || ""]}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="email">{t("email")}</Label>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      placeholder={t("email")}
                      value={custRep.email || ""}
                      onChange={handleCustomerRepInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="phoneNumber">{t("phone_number")}</Label>
                    <Input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      placeholder={t("phone_number")}
                      value={custRep.phoneNumber || ""}
                      onChange={handleCustomerRepInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="designation">{t("designation")}</Label>
                    <Input
                      type="text"
                      name="designation"
                      id="designation"
                      placeholder={t("designation")}
                      value={custRep.designation || ""}
                      onChange={handleCustomerRepInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Button color="primary" type="submit">
                {publicId ? t("update") : t("create")}
              </Button>
            </CardBody>
          </Card>
        </AvForm>
      </Col>
      {publicId && (
        <Col md={5}>
          <ScheduleCategory address={address} isProcessing={isProcessing} />
          {address?.scheduleCategory?.publicId && (
            <ScheduleList address={address} />
          )}
        </Col>
      )}
    </Row>
  );
};

export default AddressForm;
