import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Row, Spinner } from "reactstrap";
import {
  MESSAGE_ERROR,
  MESSAGE_SUCCESS,
  QUALITY_CHECK_STATUS_APPROVED,
  QUALITY_CHECK_STATUS_REJECTED,
} from "../../config/AppConstant";
import {
  approveOrRejectQualityCheck,
  isValidUrl,
} from "../../redux/actions/egenControlActions";
import { showMessage, validateInput } from "../../utils/app-utils";
import PageInvalidUrl from "../auth/PageInvalidUrl";
import EgenControlActionSuccessPage from "./EgenControlActionSuccessPage";

const EgenControlAction = () => {
  const [reason, setReason] = useState("");
  const [submitButton, setSubmitButton] = useState("");
  const { egenControlQualityChecks, messages } = useSelector((state) => state);
  const [successMsg, setSuccessMsg] = useState("");
  const dispatch = useDispatch();
  const { id } = useParams();
  const { egenId } = useParams();
  const { message } = messages;
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      if (message.type === "success") {
        showMessage({
          type: MESSAGE_SUCCESS,
          message: message.detail,
        });
        setSuccessMsg(message.detail);
      }
    } else {
      isMounted.current = true;
    }
  }, [messages, message]);

  useEffect(() => {
    if (id) {
      dispatch(isValidUrl(id));
    }
  }, [id, dispatch]);
  const handleReasonChange = (event) => {
    const { value } = event.target;
    setReason(value);
  };
  const handleOnSubmit = (event) => {
    event.preventDefault();
    let payload = {
      publicId: egenId,
      status:
        submitButton === "approve"
          ? QUALITY_CHECK_STATUS_APPROVED
          : QUALITY_CHECK_STATUS_REJECTED,
      comments: reason,
    };
    const values = [payload.comments];

    if (submitButton === "approve") {
      dispatch(approveOrRejectQualityCheck(id, payload));
    } else if (submitButton === "decline") {
      if (validateInput(values)) {
        dispatch(approveOrRejectQualityCheck(id, payload));
      } else {
        showMessage({
          type: MESSAGE_ERROR,
          message: "Vänligen lägg till en anledning",
        });
      }
    }
  };
  return (
    <div className="text-center">
      {egenControlQualityChecks.isLoading ? (
        <Spinner />
      ) : !egenControlQualityChecks.isValid ? (
        <PageInvalidUrl />
      ) : successMsg ? (
        <EgenControlActionSuccessPage successMsg={successMsg} />
      ) : (
        <div>
          <p className="h1">Godkänn eller avvisa egenkontroll</p>
          <Form id="egen-action" onSubmit={handleOnSubmit}>
            <Row className="mt-5">
              <Col>
                <FormGroup>
                  <Input
                    type="textarea"
                    name="reason"
                    id="reason"
                    placeholder={"Ange en anledning"}
                    style={{ minHeight: "150px" }}
                    onChange={handleReasonChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            {egenControlQualityChecks.isLoadingReport && <Spinner />}
            <Row className="mt-3">
              <Col>
                <Button
                  type="submit"
                  name="btn"
                  color="primary"
                  disabled={egenControlQualityChecks.isLoadingReport}
                  onClick={() => setSubmitButton("approve")}
                >
                  {"Godkänna"}
                </Button>

                <Button
                  className="ml-5"
                  type="submit"
                  name="btn"
                  color="danger"
                  disabled={egenControlQualityChecks.isLoadingReport}
                  onClick={() => setSubmitButton("decline")}
                >
                  {"Avvisa"}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
};

export default EgenControlAction;
