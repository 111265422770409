import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const AppModal = (props) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={props.openModal}
      toggle={() => props.setOpenModal(!props.openModal)}
      centered
    >
      <ModalHeader toggle={() => props.setOpenModal(!props.openModal)}>
        {props.header}
      </ModalHeader>
      <ModalBody className="m-3">{props.children}</ModalBody>
      <ModalFooter>
        {props.publicId && props.handleCancel && (
          <Button
            color="cancel"
            onClick={() => props.handleCancel(props.publicId)}
          >
            {t("cancel_schedule")}
          </Button>
        )}
        <Button color="primary" form={props.formId}>
          {t("save_changes")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

AppModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

export default AppModal;
