import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { LOCATION_CHANGE } from "../redux/constants";

const ScrollToTop = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch({ type: LOCATION_CHANGE });
  }, [dispatch, props.location.pathname])
  return props.children;
}

export default withRouter(ScrollToTop);
