import { t } from "i18next";
import { MESSAGE_ERROR } from "../../config/AppConstant";
import AuthService from "../../services/authService";
import { showMessage } from "../../utils/app-utils";
import {
  MESSAGE_SUCCESS,
  SIGN_IN_FAIL,
  SIGN_IN_IN_PROGRESS,
  SIGN_IN_SUCCESS,
} from "../constants";

export const userLogin = (user) => async (dispatch) => {
  try {
    dispatch({
      type: SIGN_IN_IN_PROGRESS,
      payload: true,
    });
    const response = await AuthService.login(user);
    dispatch({
      type: SIGN_IN_SUCCESS,
      payload: response,
    });

    showMessage({
      type: "success",
      message: t("sign_in_success"),
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: SIGN_IN_FAIL,
    });

    showMessage({
      type: MESSAGE_ERROR,
      message: t("sign_in_fail"),
    });
  }
};

export const changePassword = (user) => async (dispatch) => {
  try {
    const response = await AuthService.changePassword(user);
    dispatch({
      type: MESSAGE_SUCCESS,
      message: t(response.data),
    });
  } catch (error) {
    console.log(error);
  }
};

export const resetPassword = (email) => async (dispatch) => {
  try {
    const response = await AuthService.resetPassword(email);
    showMessage({
      type: MESSAGE_SUCCESS,
      message: t(response.data),
    });
  } catch (error) {
    console.log(error);
  }
};

export const userLogOut = () => async (dispatch) => {
  try {
    AuthService.logout();
    dispatch({
      type: SIGN_IN_FAIL,
      payload: null,
    });

    showMessage({
      type: "success",
      message: t("sign_out_success"),
    });
  } catch (error) {
    console.log(error);
  }
};

export const unAuthorizedUser = () => async (dispatch) => {
  try {
    AuthService.logout();
    dispatch({
      type: SIGN_IN_FAIL,
      payload: null,
    });
  } catch (error) {
    console.log(error);
  }
};
