import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import {
  faFilePdf,
  faPen,
  faPlus,
  faUpload,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import EmptyData from "../../components/EmptyData";
import {
  getAddressDocument,
  retrieveAddresses,
  retrieveAgreementAddresses,
  updateAddressStatus,
} from "../../redux/actions/addressActions";
import {
  PATH_ADDRESS,
  PATH_ADDRESSES,
  PATH_ADDRESS_CREATE,
  PATH_DASHBOARD,
} from "../../routes/constants";

import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ErrorBoundary } from "react-error-boundary";
import {
  MESSAGE_ERROR,
  MESSAGE_SUCCESS,
  SIZE_PER_PAGE,
  STATUS,
  STATUS_COLORS,
} from "../../config/AppConstant";
import { showMessage } from "../../utils/app-utils";
import { NavbarDropdowns, NavbarToggle } from "../../components/Navbar";
import UpdateDocument from "./UpdateDocument";
import { faToggleOn } from "@fortawesome/free-solid-svg-icons";
import AssignCleaner from "./AssignCleaner";

export const AddressesList = (props) => {
  const { t } = useTranslation();
  const { addresses } = useSelector((state) => state);
  const [addressInfo, setAddressInfo] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: SIZE_PER_PAGE,
      status: "ALL",
    };
    if (props.agreementId) {
      dispatch(retrieveAgreementAddresses(props.agreementId, params));
    } else dispatch(retrieveAddresses(params));
  }, [dispatch, props.agreementId, props.addressStatus]);

  // const removeAddress = (publicId) => {
  //   dispatch(deleteAddress(publicId));
  // };

  const onDocumentSelectHandler = (publicId) => {
    let document = {
      publicId: "",
      document: "",
    };
    if (publicId) {
      document = addresses.data.find((obj) => obj.publicId === publicId);
    }
    props.setDocument(document);
  };

  const onDocumentDownload = (publicId) => {
    if (publicId) {
      dispatch(getAddressDocument(publicId));
    }
  };

  const activateAddress = (publicId) => {
    dispatch(updateAddressStatus(publicId, true));
  };

  const onAddressAssignmentHandler = (publicId) => {
    let addressInfo = {
      publicId: publicId,
      isCreate: true,
      isOpenModel: true,
    };
    setAddressInfo(addressInfo);
  };

  const actionFormatter = (cell, row, rowIndex) => {
    if (props.agreementId) {
      return "";
    } else {
      return !row.active ? (
        <div className="table-action text-center">
          {" "}
          <Link to={"#"} onClick={() => activateAddress(row?.publicId)}>
            <FontAwesomeIcon
              icon={faToggleOn}
              fixedWidth
              className="align-middle mr-1"
            />
          </Link>
        </div>
      ) : (
        <div className="table-action text-center">
          <Link to={PATH_ADDRESS + "/" + row?.publicId}>
            <FontAwesomeIcon
              icon={faPen}
              fixedWidth
              className="align-middle mr-1"
            />
          </Link>
          <Link to={"#"} onClick={() => onDocumentSelectHandler(row?.publicId)}>
            <FontAwesomeIcon
              icon={faUpload}
              fixedWidth
              className="align-middle mr-1"
            />
          </Link>
          {row?.document && (
            <Link to={"#"} onClick={() => onDocumentDownload(row?.publicId)}>
              <FontAwesomeIcon
                icon={faFilePdf}
                fixedWidth
                className="align-middle mr-1"
              />
            </Link>
          )}
          <Link
            to={"#"}
            onClick={() => onAddressAssignmentHandler(row?.publicId)}
          >
            <FontAwesomeIcon
              icon={faUserPlus}
              fixedWidth
              className="align-middle mr-1"
            />
          </Link>
        </div>
      );
    }
  };
  const handleTableChange = (type, newState) => {
    const { page, sizePerPage, filters, sortField, sortOrder, status } =
      newState;
    const params = {
      pageNo: page ? page - 1 : "",
      pageSize: sizePerPage ? sizePerPage : "",
      sortBy: sortField ? sortField : "",
      sortDir: sortOrder ? sortOrder : "",
      status: status ? status : "ALL",
    };
    for (const dataField in filters) {
      const { filterVal } = filters[dataField];
      const fieldItems = dataField.split(".");
      params[fieldItems[fieldItems.length - 1]] = filterVal;
    }
    if (props.agreementId) {
      dispatch(retrieveAgreementAddresses(props.agreementId, params));
    } else dispatch(retrieveAddresses(params));
  };

  const headerFormatter = (
    column,
    colIndex,
    { sortElement, filterElement }
  ) => {
    return (
      <div>
        <div>
          {column.text}
          {sortElement}
        </div>
        <div style={{ display: "grid", marginTop: "10px" }}>
          {filterElement}
        </div>
      </div>
    );
  };

  const tableColumns = [
    {
      dataField: "agreement.customer.name",
      text: t("customer_name"),
      sort: true,
      hidden: props.agreementId,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "agreement.agreementNumber",
      text: t("agreement_number"),
      sort: true,
      hidden: props.agreementId,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "objectNumber",
      text: t("object_number"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "objectName",
      text: t("object_name"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "streetAddress",
      text: t("street_address"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "postalCode",
      text: t("post_code"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "city",
      text: t("city"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "status",
      text: t("status"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      filter: selectFilter({
        key: t("active"),
        placeholder: t("select"),
        options: [
          {
            value: true,
            label: t("active"),
          },
          {
            value: false,
            label: t("in_active"),
          },
        ],
      }),
      formatter: (cell, row, rowIndex) => {
        return (
          <Badge style={{ backgroundColor: STATUS_COLORS[row?.active] }}>
            {STATUS[row?.active]}
          </Badge>
        );
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "actions",
      text: "",
      hidden: props.agreementId,
      formatter: actionFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "5rem" };
      },
      headerFormatter: headerFormatter,
    },
  ];

  return (
    <Card>
      <CardHeader>
        {props.agreementId ? (
          ""
        ) : (
          <div className="card-actions float-right">
            <Link to={PATH_ADDRESS_CREATE}>
              <Button color="primary" className="mr-1 mb-1">
                <FontAwesomeIcon icon={faPlus} /> {t("add")}
              </Button>
            </Link>
          </div>
        )}
        <CardTitle tag="h5" className="mb-0">
          {t("addresses")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <AssignCleaner address={addressInfo} />
        <BootstrapTable
          condensed
          keyField="publicId"
          data={addresses.data}
          remote={true}
          columns={tableColumns}
          bootstrap4
          striped
          hover
          bordered={false}
          filter={filterFactory()}
          pagination={paginationFactory({
            sizePerPage: addresses.sizePerPage,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true,
            totalSize: addresses.totalSize,
          })}
          onTableChange={handleTableChange}
          loading={addresses.isLoading}
          noDataIndication={() => {
            return (
              <EmptyData
                publicId={props.agreementId}
                isLoading={addresses.isLoading}
              />
            );
          }}
        ></BootstrapTable>
      </CardBody>
    </Card>
  );
};

const Addresses = (props) => {
  const { t } = useTranslation();
  const [document, setDocument] = useState(null);
  const [addressStatus, setAddressStatus] = useState(false);
  const { messages } = useSelector((state) => state);
  const { message } = messages;
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      if (message.type === "success") {
        showMessage({
          type: MESSAGE_SUCCESS,
          message: message.detail,
        });
        if (message.detail === t("address_status_updated")) {
          setAddressStatus(true);
        } else {
          setDocument(null);
        }
      }
    } else {
      isMounted.current = true;
    }
  }, [messages, message, t, props.history]);
  return (
    <Container fluid>
      <Header>
        <div className="effektToggle">
          <NavbarToggle />
          <HeaderTitle>{t("addresses")}</HeaderTitle>
        </div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={PATH_DASHBOARD}>{t("dashboard")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("addresses")}</BreadcrumbItem>
        </Breadcrumb>
        <NavbarDropdowns />
      </Header>

      <Row>
        <Col>
          <ErrorBoundary
            fallbackRender={() => {}}
            onError={() => {
              showMessage({
                type: MESSAGE_ERROR,
                message: t("addresses_fallback_msg"),
              });
            }}
          >
            <AddressesList
              addressStatus={addressStatus}
              setDocument={setDocument}
            />
          </ErrorBoundary>
        </Col>
        {document && (
          <Col className="col-md-3 col-xxl-3">
            <ErrorBoundary
              fallbackRender={() => {}}
              onError={() => {
                showMessage({
                  type: MESSAGE_ERROR,
                  message: t("document_fallback_msg"),
                });
              }}
            >
              <UpdateDocument document={document} setDocument={setDocument} />
            </ErrorBoundary>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Addresses;
