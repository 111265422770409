import { URL_AGREEMENT } from "../config/AppConstant";
import http from "../utils/http-common";

class AgreementDataService {
  getAll(params) {
    return http.get(`${URL_AGREEMENT}/list`, { params });
  }

  get(id) {
    return http.get(`${URL_AGREEMENT}/${id}`);
  }

  create(data) {
    return http.post(URL_AGREEMENT, data);
  }

  update(data) {
    return http.put(URL_AGREEMENT, data);
  }

  delete(id) {
    return http.delete(`${URL_AGREEMENT}/${id}`);
  }

  getCustomerAgreements(publicId, params) {
    return http.get(`${URL_AGREEMENT}/customer/${publicId}`, { params });
  }
}

export default new AgreementDataService();
