import { URL_CUSTOMER, URL_REPRESENTATIVE } from "../config/AppConstant";
import http from "../utils/http-common";

class RepresentativeDataService {
  get(id) {
    return http.get(`${URL_REPRESENTATIVE}/${id}`);
  }

  create(data) {
    return http.post(URL_REPRESENTATIVE, data);
  }

  update(data) {
    return http.put(URL_REPRESENTATIVE, data);
  }

  delete(id) {
    return http.delete(`${URL_REPRESENTATIVE}/${id}`);
  }

  getAll(id) {
    return http.get(`${URL_CUSTOMER}${URL_REPRESENTATIVE}/${id}`);
  }
}

export default new RepresentativeDataService();
