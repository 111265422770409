export const APP_URL = "https://clean-control.effektab.se/cleaning/api";

// Customers Url
export const URL_CUSTOMER = "/customer";

// Agreement Url
export const URL_AGREEMENT = "/agreement";

// Addresses Url
export const URL_ADDRESS = "/address";

// Dashboard Url
export const URL_DASHBOARD = "/dashboard";

// Employees Url
export const URL_EMPLOYEE = "/employee";

//Questionnaires Url
export const URL_QUESTIONNAIRE = "/questionnaire";

//Egen Questionnaires Url
export const URL_EGEN_QUESTIONNAIRE = "/egenkontroll/questionnaire";

//Egen Control Url
export const URL_EGEN_CONTROL = "/egenkontroll";

//Un Bookable Date Url
export const URL_UN_BOOKABLE_DATE = "/unbookable";

//Schedule Url
export const URL_SCHEDULE = "/schedule";

//Quality Check Url
export const URL_QUALITY_CHECK = "/quality-check";

// Auth Url
export const URL_SIGN_IN = "/auth/login";

// Change Password Url
export const URL_CHANGE_PASSWORD = "/auth/change-password";
export const URL_RESET_PASSWORD = "/auth/reset-password";

// Representatives Url
export const URL_REPRESENTATIVE = "/representative";

// Schedule time
export const SCHEDULE_DURATION = 60;
export const SCHEDULE_DAY_MAX_EVENTS = 3;
export const DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "HH:mm";
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";
export const DISPLAY_TIME_ZONE = "local";
export const DATA_TIME_ZONE = "utc";

// Status
export const STATUS_ACTIVE = "ACTIVE";
export const STATUS_IN_ACTIVE = "INACTIVE";
export const STATUS = {
  [true]: STATUS_ACTIVE,
  [false]: STATUS_IN_ACTIVE,
};

// Quality Check status coloring
export const STATUS_COLOR_ACTIVE = "darkgreen";
export const STATUS_COLOR_IN_ACTIVE = "darkred";
export const STATUS_COLORS = {
  [true]: STATUS_COLOR_ACTIVE,
  [false]: STATUS_COLOR_IN_ACTIVE,
};

// Messages type
export const MESSAGE_SUCCESS = "success";
export const MESSAGE_INFO = "info";
export const MESSAGE_WARNING = "warning";
export const MESSAGE_ERROR = "error";

// Quality Check status
export const QUALITY_CHECK_STATUS_PENDING = "PENDING";
export const QUALITY_CHECK_STATUS_IN_PROGRESS = "INPROGRESS";
export const QUALITY_CHECK_STATUS_COMPLETED = "COMPLETED";
export const QUALITY_CHECK_STATUS_CANCELLED = "CANCELLED";
export const QUALITY_CHECK_STATUS_NOT_STARTED = "NOTSTARTED";
export const QUALITY_CHECK_STATUS_PENDING_APPROVAL = "PENDINGAPPROVAL";
export const QUALITY_CHECK_STATUS_REJECTED = "REJECTED";
export const QUALITY_CHECK_STATUS_APPROVED = "APPROVED";

// Patterns
export const PATTERN_PHONE_NUMBER = "^[0-9- ]+$";
export const PATTERN_ZIP_CODE = "[0-9]{5}";

// Pagination
export const SIZE_PER_PAGE = 10;

// Languages
export const DEFAULT_LANGUAGE = "SE";
export const LANGUAGE_SE = "SE";
export const LANGUAGE_US = "US";
export const SUPPORTED_LANGUAGES = [LANGUAGE_US, LANGUAGE_SE];

// Quality Check status coloring
export const STATUS_COLOR_COMPLETED = "darkgreen";
export const STATUS_COLOR_PENDING = "#153d77";
export const STATUS_COLOR_IN_PROGRESS = "darkgoldenrod";
export const STATUS_COLOR_CANCELLED = "darkred";
export const STATUS_COLOR_NOT_STARTED = "#45adad";
export const STATUS_COLOR_REJECTED = "#607D8B";
export const STATUS_COLOR_PENDING_APPROVAL = "#9E9E9E";
export const QUALITY_CHECK_STATUS_COLORS = {
  [QUALITY_CHECK_STATUS_PENDING]: STATUS_COLOR_PENDING,
  [QUALITY_CHECK_STATUS_IN_PROGRESS]: STATUS_COLOR_IN_PROGRESS,
  [QUALITY_CHECK_STATUS_COMPLETED]: STATUS_COLOR_COMPLETED,
  [QUALITY_CHECK_STATUS_CANCELLED]: STATUS_COLOR_CANCELLED,
  [QUALITY_CHECK_STATUS_NOT_STARTED]: STATUS_COLOR_NOT_STARTED,
  [QUALITY_CHECK_STATUS_PENDING_APPROVAL]: STATUS_COLOR_PENDING_APPROVAL,
  [QUALITY_CHECK_STATUS_REJECTED]: STATUS_COLOR_REJECTED,
};
