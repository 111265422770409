import React from "react";

import { Col, Container, Row } from "reactstrap";

import rightBot from "../assets/img/brands/right-bot.png";
import topLeft from "../assets/img/brands/top-left.png";
import Main from "../components/Main";

const Auth = ({ children }) => (
  <React.Fragment>
    <Main className="h-100 w-100">
      <img src={topLeft} alt="" className="absolute-top w-25" />
      <img src={rightBot} alt="" className="absolute-bot-right w-25" />
      <Container className="h-100">
        <Row className="h-100">
          <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
            <div className="d-table-cell align-middle">{children}</div>
          </Col>
        </Row>
      </Container>
    </Main>
  </React.Fragment>
);

export default Auth;
