import { URL_DASHBOARD } from "../config/AppConstant";
import http from "../utils/http-common";

class DashboardDataService {
  get() {
    return http.get(URL_DASHBOARD);
  }
  getReport1() {
    return http.get(URL_DASHBOARD + "/report1");
  }
  getReport2(params) {
    return http.get(URL_DASHBOARD + "/report2", { params });
  }
  getQualityCheckRatingReport(params) {
    return http.get(URL_DASHBOARD + "/report/rating", { params });
  }
  getObjectQualityCheckRatingReport(params) {
    return http.get(URL_DASHBOARD + "/report/object/quality/rating", {
      params,
    });
  }
  getQuestionQualityCheckRatingReport(params) {
    return http.get(URL_DASHBOARD + "/report/object/question/rating", {
      params,
    });
  }
  getTeamLeadQualityCheckRatingReport(params) {
    return http.get(URL_DASHBOARD + "/report/lead/quality/rating", {
      params,
    });
  }
  getTeamLeadQuestionQualityCheckRatingReport(params) {
    return http.get(URL_DASHBOARD + "/report/lead/question/rating", {
      params,
    });
  }
}

export default new DashboardDataService();
