import { URL_QUESTIONNAIRE } from "../config/AppConstant";
import http from "../utils/http-common";

class QuestionnairesDataService {
  getAll(params) {
    return http.get(URL_QUESTIONNAIRE, { params });
  }

  get(id) {
    return http.get(`${URL_QUESTIONNAIRE}/${id}`);
  }

  create(data) {
    return http.post(URL_QUESTIONNAIRE, data);
  }

  update(data) {
    return http.put(`${URL_QUESTIONNAIRE}`, data);
  }

  delete(id) {
    return http.delete(`${URL_QUESTIONNAIRE}/${id}`);
  }
}

export default new QuestionnairesDataService();
