import React, { useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { NavbarDropdowns, NavbarToggle } from "../../components/Navbar";
import { MESSAGE_ERROR, MESSAGE_SUCCESS } from "../../config/AppConstant";
import {
  createAddress,
  editAddress,
  getAddress,
  updateAddressStatus,
} from "../../redux/actions/addressActions";
import { retrieveCustomers } from "../../redux/actions/customerActions";
import { PATH_ADDRESSES, PATH_DASHBOARD } from "../../routes/constants";
import { showMessage } from "../../utils/app-utils";
import AddressForm from "./AddressForm";

const initialAddress = {
  publicId: "",
  city: "",
  postalCode: "",
  streetAddress: "",
};
const initialCustRep = {
  publicId: "",
  name: "",
  username: "",
  email: "",
  phoneNumber: "",
};

const UpdateAddress = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { customers, addresses, messages } = useSelector((state) => state);
  const addressId = props.match.params.id;
  const { message } = messages;

  useEffect(() => {
    dispatch(retrieveCustomers());
  }, [dispatch]);

  const isMounted = useRef(false);

  const [address, setAddress] = useState(initialAddress);

  const [custRep, setCustRep] = useState(initialCustRep);

  useEffect(() => {
    if (isMounted.current) {
      if (message.type === "success") {
        showMessage({
          type: MESSAGE_SUCCESS,
          message: message.detail,
        });
        if (message.detail !== t("schedule_updated")) {
          props.history.push(PATH_ADDRESSES);
          setAddress(initialAddress);
          setCustRep(initialCustRep);
        }
      }
    } else {
      isMounted.current = true;
    }
  }, [messages, message, props.history]);

  useEffect(() => {
    if (addressId) {
      dispatch(getAddress(addressId));
    }
  }, [addressId, dispatch]);

  useEffect(() => {
    if (addressId && addresses.data.length > 0) {
      const address = addresses.data.find(
        ({ publicId }) => publicId === addressId
      );
      setAddress(address);
      address?.customerRep && setCustRep(address?.customerRep);
    }
  }, [addressId, addresses]);

  const handleOnSubmit = (address) => {
    if (!addressId || addressId === "create") {
      dispatch(createAddress(address, custRep));
    } else {
      dispatch(editAddress(address, custRep));
    }
  };

  const handleAddressStatus = (address) => {
    dispatch(updateAddressStatus(address?.publicId, address?.activate));
  };

  return (
    <Container fluid>
      <Header>
        <div className="effektToggle">
          <NavbarToggle />
          <HeaderTitle>{t("addresses")}</HeaderTitle>
        </div>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={PATH_DASHBOARD}>{t("dashboard")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={PATH_ADDRESSES}>{t("addresses")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("create")}</BreadcrumbItem>
        </Breadcrumb>
        <NavbarDropdowns />
      </Header>

      <Row>
        <Col>
          <ErrorBoundary
            fallbackRender={() => {}}
            onError={() => {
              showMessage({
                type: MESSAGE_ERROR,
                message: t("address_fallback_msg"),
              });
            }}
          >
            {address && (
              <AddressForm
                address={address}
                customers={customers.data}
                setAddress={setAddress}
                custRep={custRep}
                setCustRep={setCustRep}
                handleOnSubmit={handleOnSubmit}
                handleAddressStatus={handleAddressStatus}
                addressId={addressId}
                isProcessing={addresses.isProcessing}
                {...props}
              />
            )}
          </ErrorBoundary>
        </Col>
      </Row>
    </Container>
  );
};

export default UpdateAddress;
