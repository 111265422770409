import QualityCheckDataService from "../../services/qualityCheckService";
import { RETRIEVE_FOLLOW_UP_QUALITY_CHECKS } from "../constants";

export const retrieveFollowUpQualityChecks =
  (parentId, params) => async (dispatch) => {
    try {
      const res = await QualityCheckDataService.getFollowUp(parentId, params);
      dispatch({
        type: RETRIEVE_FOLLOW_UP_QUALITY_CHECKS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
