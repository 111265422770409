import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { MESSAGE_ERROR } from "../../config/AppConstant";
import { retrieveCustomers } from "../../redux/actions/customerActions";
import { getOption, showMessage, validateInput } from "../../utils/app-utils";

const UpdateAgreement = (props) => {
  const { customers } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [agreement, setAgreement] = useState({
    publicId: "",
    customerId: "",
    customerName: "",
    agreementNumber: "",
    description: "",
    duration: 60,
  });

  useEffect(() => {
    dispatch(retrieveCustomers());
  }, [dispatch]);

  useEffect(() => {
    let selectedCust = "";
    if (props.agreement?.customerId) {
      selectedCust =
        customers?.data &&
        customers.data.find(
          ({ publicId }) => publicId === props.agreement.customerId
        );
    }
    setAgreement((prevState) => ({
      ...prevState,
      publicId: props.agreement?.publicId,
      customerId: selectedCust ? selectedCust?.publicId : "",
      customerName: selectedCust ? selectedCust?.name : "",
      agreementNumber: props.agreement?.agreementNumber,
      description: props.agreement?.description,
      duration: props.agreement?.duration,
    }));
  }, [customers.data, props.agreement]);

  const {
    publicId,
    customerName,
    customerId,
    agreementNumber,
    description,
    duration,
  } = agreement;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAgreement((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCustomerRepDropDownChange = (event) => {
    let { value } = event;
    value = value?.split("|");
    setAgreement((prevState) => ({
      ...prevState,
      customerId: value[0],
      customerName: value[1],
    }));
  };

  const handleDurationDropDownChange = (event) => {
    let { value } = event;
    setAgreement((prevState) => ({
      ...prevState,
      duration: value,
    }));
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const values = [customerId, agreementNumber, description, duration];

    if (validateInput(values)) {
      const agreement = {
        publicId,
        customerId,
        agreementNumber,
        description,
        duration,
      };
      props.handleOnSubmit(agreement);
    } else {
      showMessage({
        type: MESSAGE_ERROR,
        message: t("missing_information"),
      });
    }
  };

  const customerOptions = customers?.data?.map((employee) =>
    getOption(employee)
  );

  const durationOptions = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 15, value: 15 },
    { label: 20, value: 20 },
    { label: 25, value: 25 },
    { label: 30, value: 30 },
    { label: 35, value: 35 },
    { label: 40, value: 40 },
    { label: 45, value: 45 },
    { label: 50, value: 50 },
    { label: 55, value: 55 },
    { label: "60 ( 1 " + t("hrs") + " )", value: 60 },
    { label: "90 ( 1.5 " + t("hrs") + " )", value: 90 },
    { label: "120 ( 2 " + t("hrs") + " )", value: 120 },
  ];
  return (
    <Card>
      <CardHeader>
        <div className="card-actions float-right">
          <span className="cursor-pointer mr-1">
            <X onClick={() => props.setAgreement(null)} />
          </span>{" "}
        </div>
        <CardTitle tag="h5" className="mb-0">
          {t("agreement")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <AvForm onValidSubmit={handleOnSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <Label for="name">{t("select_customer")}</Label>
                <ReactSelect
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={customerOptions}
                  value={{
                    label: customerName || t("select"),
                    value: customerName || "",
                  }}
                  isSearchable
                  id="custName"
                  onChange={handleCustomerRepDropDownChange}
                />
              </FormGroup>
              <AvGroup>
                <Label for="agreementNumber">{t("agreement_number")}</Label>
                <AvInput
                  required
                  type="text"
                  name="agreementNumber"
                  id="agreementNumber"
                  value={agreementNumber}
                  placeholder={t("agreement_number")}
                  onChange={handleInputChange}
                />
                <AvFeedback>{t("missing_agreement_number")}</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="description">{t("agreement_description")}</Label>
                <AvInput
                  required
                  type="textarea"
                  rows="2"
                  name="description"
                  id="description"
                  placeholder={t("description")}
                  value={description}
                  onChange={handleInputChange}
                />
                <AvFeedback>{t("missing_description")}</AvFeedback>
              </AvGroup>
              <FormGroup>
                <Label for="duration">{t("gap_between_schedule")}</Label>
                <ReactSelect
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={durationOptions}
                  value={{
                    label: duration || t("select"),
                    value: duration || "",
                  }}
                  isSearchable
                  id="duration"
                  onChange={handleDurationDropDownChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Button color="primary">
            {publicId ? t("update") : t("create")}
          </Button>
        </AvForm>
      </CardBody>
    </Card>
  );
};

export default UpdateAgreement;
