import {
  CREATE_CUSTOMER,
  DELETE_ALL_CUSTOMERS,
  DELETE_CUSTOMER,
  RETRIEVE_CUSTOMERS,
  RETRIEVE_CUSTOMER,
  UPDATE_CUSTOMER,
  RETRIEVE_CUSTOMER_TEAM_LEADS,
  RETRIEVE_CUSTOMER_LOGO,
  RETRIEVE_TEAM_LEAD_CUSTOMERS,
} from "../constants";

const initialState = {
  data: [],
  teamLeadData: [],
  teamLeadCustomers: [],
  logo: "",
  page: 0,
  sizePerPage: 0,
  totalSize: 0,
  isLoading: true,
};

export default function reducer(state = initialState, actions) {
  const { type, payload } = actions;
  switch (type) {
    case CREATE_CUSTOMER:
      return {
        ...state,
        data: [...state.data, payload],
        isLoading: false,
      };
    case RETRIEVE_CUSTOMERS:
      return {
        ...state,
        data: payload.content,
        page: payload.pageNo,
        sizePerPage: payload.pageSize,
        totalSize: payload.totalElements,
        isLoading: false,
      };
    case RETRIEVE_CUSTOMER:
      return {
        ...state,
        data: [payload],
        isLoading: false,
      };
    case RETRIEVE_CUSTOMER_LOGO:
      return {
        ...state,
        logo: payload.logo,
        isLoading: false,
      };
    case UPDATE_CUSTOMER:
      return {
        ...state,
        data: state.data.map((customer) => {
          if (customer.publicId === payload.publicId) {
            return {
              ...customer,
              ...payload,
            };
          } else {
            return customer;
          }
        }),
        isLoading: false,
      };
    case DELETE_CUSTOMER:
      return {
        ...state,
        data: state.data.filter(
          ({ publicId }) => publicId !== payload.publicId
        ),
        isLoading: false,
      };
    case RETRIEVE_CUSTOMER_TEAM_LEADS:
      return {
        ...state,
        teamLeadData: payload,
        isLoading: false,
      };
    case RETRIEVE_TEAM_LEAD_CUSTOMERS:
      return {
        ...state,
        teamLeadCustomers: payload,
        isLoading: false,
      };
    case DELETE_ALL_CUSTOMERS:
      return [];
    default:
      return state;
  }
}
