import async from "../components/Async";

import {
  faAddressBook,
  faCalendarAlt,
  faCalendarCheck,
  faChartPie,
  faCog,
  faHome,
  faQuestion,
  faSignInAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

// Auth
import Addresses from "../pages/address/AddressList";
import UpdateAddress from "../pages/address/UpdateAddress";
import Agreements from "../pages/agreement/AgreementList";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";
import ResetPassword from "../pages/auth/ResetPassword";
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import Customers from "../pages/customer/CustomerList";
import Employees from "../pages/employee/EmployeeList";
import Questionnaires from "../pages/questionnaires/QuestionnaireList";
import Scheduler from "../pages/schedule/Scheduler";
import Report from "../pages/reports/Report";
import {
  ADDRESSES,
  AGREEMENTS,
  AUTH,
  CALENDER,
  CHARTS,
  CUSTOMERS,
  DASHBOARD,
  EGEN_QUESTIONNAIRES,
  EMPLOYEES,
  PAGE_404,
  PAGE_500,
  PATH_404,
  PATH_500,
  PATH_ADDRESSES,
  PATH_ADDRESS_CREATE,
  PATH_ADDRESS_EDIT,
  PATH_AGREEMENTS,
  PATH_AUTH,
  PATH_CHANGE_PASSWORD,
  PATH_CHARTS,
  PATH_CUSTOMERS,
  PATH_DASHBOARD,
  PATH_EGEN_QUESTIONNAIRES,
  PATH_EMPLOYEES,
  PATH_LANDING,
  PATH_QUALITY_CHECK,
  PATH_QUALITY_CHECK_VIEW,
  PATH_EGEN_QUALITY_CHECK,
  PATH_QUESTIONNAIRES,
  PATH_REPORTS,
  PATH_RESET_PASSWORD,
  PATH_SCHEDULER,
  PATH_SETTINGS,
  PATH_SIGN_IN,
  PATH_SIGN_UP,
  PATH_UN_BOOKABLE_DATE,
  QUALITY_CHECKS,
  QUESTIONNAIRES,
  REPORTS,
  RESET_PASSWORD,
  SETTINGS,
  SIGN_IN,
  SIGN_UP,
  UN_BOOKABLE_DATE,
  EGEN_QUALITY_CHECKS,
  PATH_EGEN_QUALITY_CHECK_VIEW,
  PATH_EGEN_CONTROL_APPROVE_REJECT,
  EGEN_CONTROL_ACTION,
} from "./constants";
import QualityChecks from "../pages/quality/QualityCheckList";
import EgenControl from "../pages/egen/EgenControlTabs";
import UnBookableDates from "../pages/unBookableDate/UnBookableDateList";
import ChangePassword from "../pages/change-password/ChangePassword";
import UpdateQualityCheck from "../pages/quality/UpdateQualityCheck";
import UpdateEgenQualityCheck from "../pages/egen/UpdateEgenControl";
import EgenQuestionnaires from "../pages/egen-questionnaires/EgenQuestionnaireTabs";
import EgenControlAction from "../pages/egen/EgenControlAction";

// Pages
const Settings = async(() => import("../pages/pages/Settings"));

// Dashboards
const Default = async(() => import("../pages/dashboards/Default"));

// Charts
const Chartjs = async(() => import("../pages/charts/Chartjs"));

// Routes
const dashboardRoutes = {
  path: PATH_DASHBOARD,
  name: DASHBOARD,
  header: "Main",
  icon: faHome,
  component: Default,
  children: null,
};

const chartRoutes = {
  path: PATH_CHARTS,
  name: CHARTS,
  icon: faChartPie,
  component: Chartjs,
  children: null,
};

const schedularRoutes = {
  path: PATH_SCHEDULER,
  name: CALENDER,
  icon: faCalendarAlt,
  component: Scheduler,
  children: null,
};

const reportRoutes = {
  path: PATH_REPORTS,
  name: REPORTS,
  icon: faChartPie,
  component: Report,
  children: null,
};

const landingRoutes = {
  path: PATH_LANDING,
  name: SIGN_IN,
  component: SignIn,
  icon: faSignInAlt,
};

const egenRoutes = {
  path: PATH_EGEN_CONTROL_APPROVE_REJECT,
  name: EGEN_CONTROL_ACTION,
  component: EgenControlAction,
  icon: faSignInAlt,
};

const settingsRoute = {
  path: PATH_SETTINGS,
  name: SETTINGS,
  component: Settings,
  icon: faCog,
};

const changePasswordRoute = {
  path: PATH_CHANGE_PASSWORD,
  name: SETTINGS,
  component: ChangePassword,
  icon: faCog,
};

const customersRoute = {
  path: PATH_CUSTOMERS,
  name: CUSTOMERS,
  component: Customers,
  icon: faUser,
  children: null,
};

const agreementsRoute = {
  path: PATH_AGREEMENTS,
  name: AGREEMENTS,
  component: Agreements,
  icon: faUser,
};

const addressesRoute = {
  path: PATH_ADDRESSES,
  name: ADDRESSES,
  component: Addresses,
  icon: faAddressBook,
  children: [
    {
      path: PATH_ADDRESS_CREATE,
      name: ADDRESSES,
      component: UpdateAddress,
    },
    {
      path: PATH_ADDRESS_EDIT,
      name: ADDRESSES,
      component: UpdateAddress,
    },
  ],
};

const qualityChecksRoute = {
  path: PATH_QUALITY_CHECK,
  name: QUALITY_CHECKS,
  component: QualityChecks,
  icon: faCalendarCheck,
  children: [
    {
      path: PATH_QUALITY_CHECK_VIEW,
      name: QUALITY_CHECKS,
      component: UpdateQualityCheck,
    },
  ],
};

const egenQualityChecksRoute = {
  path: PATH_EGEN_QUALITY_CHECK,
  name: EGEN_QUALITY_CHECKS,
  component: EgenControl,
  icon: faCalendarCheck,
  children: [
    {
      path: PATH_EGEN_QUALITY_CHECK_VIEW,
      name: EGEN_QUALITY_CHECKS,
      component: UpdateEgenQualityCheck,
    },
  ],
};

const employeesRoute = {
  path: PATH_EMPLOYEES,
  name: EMPLOYEES,
  component: Employees,
  icon: faUser,
};

const unBookableDateRoute = {
  path: PATH_UN_BOOKABLE_DATE,
  name: UN_BOOKABLE_DATE,
  component: UnBookableDates,
  icon: faQuestion,
};

const questionnairesRoute = {
  path: PATH_QUESTIONNAIRES,
  name: QUESTIONNAIRES,
  component: Questionnaires,
  icon: faQuestion,
};

const egenQuestionnairesRoute = {
  path: PATH_EGEN_QUESTIONNAIRES,
  name: EGEN_QUESTIONNAIRES,
  component: EgenQuestionnaires,
  icon: faQuestion,
};

const authRoutes = {
  path: PATH_AUTH,
  name: AUTH,
  icon: faSignInAlt,
  children: [
    {
      path: PATH_SIGN_IN,
      name: SIGN_IN,
      component: SignIn,
    },
    {
      path: PATH_SIGN_UP,
      name: SIGN_UP,
      component: SignUp,
    },
    {
      path: PATH_RESET_PASSWORD,
      name: RESET_PASSWORD,
      component: ResetPassword,
    },
    {
      path: PATH_404,
      name: PAGE_404,
      component: Page404,
    },
    {
      path: PATH_500,
      name: PAGE_500,
      component: Page500,
    },
  ],
};
// Customer Dashboard routes
export const customerRoutes = [
  qualityChecksRoute,
  changePasswordRoute,
  egenQualityChecksRoute,
];
// Customer Dashboard routes
export const adminRoutes = [
  dashboardRoutes,
  reportRoutes,
  customersRoute,
  agreementsRoute,
  addressesRoute,
  qualityChecksRoute,
  egenQualityChecksRoute,
  employeesRoute,
  unBookableDateRoute,
  questionnairesRoute,
  egenQuestionnairesRoute,
  chartRoutes,
  schedularRoutes,
  settingsRoute,
  changePasswordRoute,
];

// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  reportRoutes,
  customersRoute,
  agreementsRoute,
  addressesRoute,
  qualityChecksRoute,
  egenQualityChecksRoute,
  employeesRoute,
  unBookableDateRoute,
  questionnairesRoute,
  egenQuestionnairesRoute,
  chartRoutes,
  schedularRoutes,
  settingsRoute,
];

// Auth specific routes
export const auth = [authRoutes];

// Sign-In specific route
export const landing = [landingRoutes];

// Egen Control Action specific route
export const egenControlActionRoutes = [egenRoutes];

export default dashboard;
