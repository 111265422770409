import { t } from "i18next";
import EmployeeDataService from "../../services/employeeService";
import {
  ASSIGN_ADDRESSES_TO_EMPLOYEE,
  CREATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  MESSAGE_SUCCESS,
  RETRIEVE_CLEANERS,
  RETRIEVE_EMPLOYEE,
  RETRIEVE_EMPLOYEES,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_CONNECTED_FLAG,
  UPDATE_EMPLOYEE_PENDING_FLAG,
} from "../constants";

export const retrieveEmployees = (params, fetchAll) => async (dispatch) => {
  try {
    const res = await EmployeeDataService.getAll(params);
    dispatch({
      type: RETRIEVE_EMPLOYEES,
      payload: res.data,
      fetchAll: fetchAll,
    });
  } catch (err) {
    console.log(err);
  }
};

export const retrieveCleaners = (params, fetchAll) => async (dispatch) => {
  try {
    const res = await EmployeeDataService.getAll(params);
    dispatch({
      type: RETRIEVE_CLEANERS,
      payload: res.data,
      fetchAll: fetchAll,
    });
  } catch (err) {
    console.log(err);
  }
};

export const createEmployee = (employee) => async (dispatch) => {
  try {
    const res = await EmployeeDataService.create(employee);
    dispatch({
      type: CREATE_EMPLOYEE,
      payload: res.data,
    });
    dispatch({
      type: MESSAGE_SUCCESS,
      message: t("employee_created"),
    });
  } catch (err) {
    console.log(err);
  }
};

export const getEmployee = (id) => async (dispatch) => {
  try {
    const res = await EmployeeDataService.get(id);
    dispatch({
      type: RETRIEVE_EMPLOYEE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteEmployee = (publicId) => async (dispatch) => {
  try {
    await EmployeeDataService.delete(publicId);

    dispatch({
      type: DELETE_EMPLOYEE,
      payload: { publicId },
    });
  } catch (err) {
    console.log(err);
  }
};

export const changeEmployeePassword = (employee) => async (dispatch) => {
  try {
    await EmployeeDataService.changePassword(employee);
    dispatch({
      type: MESSAGE_SUCCESS,
      message: t("employee_updated"),
    });
  } catch (err) {
    console.log(err);
  }
};

export const editEmployee = (employee) => async (dispatch) => {
  try {
    const res = await EmployeeDataService.update(employee);

    dispatch({
      type: UPDATE_EMPLOYEE,
      payload: res.data,
    });
    dispatch({
      type: MESSAGE_SUCCESS,
      message: t("employee_updated"),
    });
  } catch (err) {
    console.log(err);
  }
};

export const assignAddressesToEmployee =
  (employeeId, params) => async (dispatch) => {
    try {
      const res = await EmployeeDataService.assignAddressesToEmployee(
        employeeId,
        params
      );
      dispatch({
        type: ASSIGN_ADDRESSES_TO_EMPLOYEE,
        payload: res.data,
      });
      dispatch({
        type: MESSAGE_SUCCESS,
        message: t("address_assignment_success"),
      });
    } catch (err) {
      console.log(err);
    }
  };

export const sendAppUrlToEmployee = (id) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_EMPLOYEE_PENDING_FLAG,
      payload: true,
    });
    await EmployeeDataService.sendAppUrlToEmployee(id);
    dispatch({
      type: MESSAGE_SUCCESS,
      message: t("app_url_sent_sucessfully"),
    });
    dispatch({
      type: UPDATE_EMPLOYEE_PENDING_FLAG,
      payload: false,
    });
  } catch (err) {
    console.log(err);
  }
};

export const isEmployeeConnected = (id) => async (dispatch) => {
  try {
    const res = await EmployeeDataService.isEmployeeConnected(id);
    dispatch({
      type: UPDATE_EMPLOYEE_CONNECTED_FLAG,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
