import appReducer from "../reducers/index";
import { configureStore } from "@reduxjs/toolkit";
import { LOCATION_CHANGE, SIGN_IN_FAIL } from "../constants";

const initialState = {};

const rootReducer = (state, action) => {
  if (action.type === SIGN_IN_FAIL) {
    return appReducer(undefined, action);
  }
   else if (action.type === LOCATION_CHANGE) {
    return appReducer({
      auth: state.auth
    }, action);
  }

  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
});

export default store;
