import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import InputMask from "react-input-mask";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CustomInput,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { PATTERN_PHONE_NUMBER } from "../../config/AppConstant";
import { retrieveEmployees } from "../../redux/actions/employeeActions";
import { getEmployeeOption, validateInput } from "../../utils/app-utils";
import { base64ToArrayBuffer } from "../../utils/app-utils";
import defaultLogo from "../../assets/img/avatars/default-logo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "../../components/ConfirmationModal";

const UpdateCustomer = (props) => {
  const dispatch = useDispatch();
  const { employees, messages } = useSelector((state) => state);
  const { message } = messages;
  const inputRef = useRef(null);

  useEffect(() => {
    const params = {
      status: true,
      type: "TEAM_LEAD",
    };
    dispatch(retrieveEmployees(params, true));
  }, [dispatch]);

  const [customer, setCustomer] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    password: "",
    teamLeadId: "",
    duration: 60,
    customerNumber: "",
    active: true,
  });

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [logo, setLogo] = useState({
    image_file: null,
    image_preview: "",
  });

  useEffect(() => {
    props.customer && setCustomer(props.customer);
    if (props.customer?.logo) {
      const imageArray = base64ToArrayBuffer(props.customer?.logo);
      const blob = new Blob([imageArray], {
        type: "image/jpeg",
      });
      const image_as_base64 = URL.createObjectURL(blob);
      setLogo({
        image_file: blob,
        image_preview: image_as_base64,
      });
    } else {
      setLogo({
        image_file: null,
        image_preview: "",
      });
    }
  }, [props.customer]);

  const {
    publicId,
    name,
    email,
    password,
    phoneNumber,
    teamLeadId,
    duration,
    teamLead,
    organizationNumber,
    customerNumber,
    active,
  } = customer;

  const { image_file, image_preview } = logo;

  const employee = employees?.allData?.find(
    (e) => e.publicId === (teamLeadId || teamLead?.publicId)
  );

  const readOnly = props.readOnly;

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    setCustomer((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckChange = (event) => {
    const { name, checked } = event.target;
    setCustomer((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleDurationDropDownChange = (event) => {
    let { value } = event;
    setCustomer((prevState) => ({
      ...prevState,
      duration: value,
    }));
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const values = [
      name,
      email,
      phoneNumber,
      organizationNumber,
      customerNumber,
      teamLeadId || teamLead?.publicId,
      duration || 1,
    ];

    if (validateInput(values)) {
      if (!active && !openConfirmationModal) {
        setOpenConfirmationModal(true);
      } else {
        openConfirmationModal && setOpenConfirmationModal(false);
        const customer = {
          publicId,
          name,
          phoneNumber,
          username: email,
          password: password,
          email,
          teamLeadId: teamLeadId || teamLead?.publicId,
          duration: duration || 1,
          organizationNumber,
          customerNumber,
          image_file,
          active,
        };
        props.handleOnSubmit(customer);
      }
    }
  };

  const employeeOptions = employees?.allData?.map((employee) =>
    getEmployeeOption(employee)
  );

  const handleDropDownChange = (event) => {
    let { value } = event;
    value = value?.split("|");
    event.label = value[1];
    setCustomer((prevState) => ({
      ...prevState,
      teamLeadId: value[0],
    }));
  };

  const handleImagePreview = (event) => {
    let image_as_base64 = URL.createObjectURL(event.target.files[0]);
    let image_as_files = event.target.files[0];
    setLogo({
      image_preview: image_as_base64,
      image_file: image_as_files,
    });
  };

  const handleBtnClick = () => {
    inputRef.current.click();
  };

  const durationOptions = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 15, value: 15 },
    { label: 20, value: 20 },
    { label: 25, value: 25 },
    { label: 30, value: 30 },
    { label: 35, value: 35 },
    { label: 40, value: 40 },
    { label: 45, value: 45 },
    { label: 50, value: 50 },
    { label: 55, value: 55 },
    { label: "60 ( 1 " + t("hrs") + " )", value: 60 },
    { label: "90 ( 1.5 " + t("hrs") + " )", value: 90 },
    { label: "120 ( 2 " + t("hrs") + " )", value: 120 },
    { label: "150 ( 2.5 " + t("hrs") + " )", value: 150 },
    { label: "180 ( 3 " + t("hrs") + " )", value: 180 },
    { label: "210 ( 3.5 " + t("hrs") + " )", value: 210 },
    { label: "240 ( 4 " + t("hrs") + " )", value: 240 },
    { label: "270 ( 4.5 " + t("hrs") + " )", value: 270 },
    { label: "300 ( 5 " + t("hrs") + " )", value: 300 },
    { label: "330 ( 5.5 " + t("hrs") + " )", value: 330 },
    { label: "360 ( 6 " + t("hrs") + " )", value: 360 },
    { label: "390 ( 6.5 " + t("hrs") + " )", value: 390 },
    { label: "420 ( 7 " + t("hrs") + " )", value: 420 },
    { label: "450 ( 7.5 " + t("hrs") + " )", value: 450 },
    { label: "480 ( 8 " + t("hrs") + " )", value: 480 },
  ];

  return (
    <Card>
      <CardHeader>
        <div className="card-actions float-right">
          <span className="cursor-pointer mr-1">
            <X onClick={() => props.setCustomer(null)} />
          </span>{" "}
        </div>
        <CardTitle tag="h5" className="mb-0">
          {t("customer")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <ConfirmationModal
          openConfirmationModal={openConfirmationModal}
          setOpenConfirmationModal={setOpenConfirmationModal}
          header={t("deactivate_customer_confirmation_header")}
          message={t("deactivate_customer_confirmation_message")}
          handleOnSubmit={handleOnSubmit}
        />
        <AvForm onValidSubmit={handleOnSubmit}>
          <FormGroup>
            <div className="text-center">
              <img
                alt="Logo"
                src={image_preview || defaultLogo}
                className="rounded-circle img-responsive mt-2"
                width="128"
                height="128"
              />
              <div className="mt-2">
                <input
                  style={{ display: "none" }}
                  ref={inputRef}
                  type="file"
                  onChange={handleImagePreview}
                />
                <Button color="primary" onClick={handleBtnClick}>
                  <FontAwesomeIcon icon={faUpload} />{" "}
                  {publicId ? t("edit_logo") : t("upload_logo")}
                </Button>
              </div>
              <small>{t("customer_logo_text")}</small>
            </div>
          </FormGroup>
          <AvGroup>
            <Label for="customerNumber">{t("customer_number")}</Label>
            <AvInput
              required
              type="text"
              name="customerNumber"
              id="customerNumber"
              placeholder={t("customer_number")}
              value={customerNumber || ""}
              mask="99999"
              maskChar=""
              onChange={handleInputChange}
              tag={[Input, InputMask]}
            />
            <AvFeedback>{t("customer_number_format")}</AvFeedback>
          </AvGroup>
          <AvGroup>
            <Label for="name">{t("name")}</Label>
            <AvInput
              required
              type="text"
              name="name"
              id="name"
              placeholder={t("name")}
              value={name}
              readOnly={readOnly}
              onChange={handleInputChange}
            />
            <AvFeedback>{t("missing_name")}</AvFeedback>
          </AvGroup>
          <AvGroup>
            <Label for="email">{t("email")}</Label>
            <AvInput
              required
              type="email"
              name="email"
              id="email"
              placeholder={t("email")}
              value={email}
              readOnly={readOnly}
              onChange={handleInputChange}
            />
            <AvFeedback>{t("missing_email")}</AvFeedback>
          </AvGroup>
          {!publicId && (
            <AvGroup>
              <Label for="password">{t("password")}</Label>
              <AvInput
                required
                type="password"
                name="password"
                id="password"
                placeholder={t("password")}
                value={password}
                readOnly={readOnly}
                onChange={handleInputChange}
              />
              <AvFeedback>{t("missing_password")}</AvFeedback>
            </AvGroup>
          )}
          <AvGroup>
            <Label for="phoneNumber">{t("phone_number")}</Label>
            <AvInput
              required
              name="phoneNumber"
              id="phoneNumber"
              value={phoneNumber}
              readOnly={readOnly}
              placeholder={t("phone_number")}
              type="tel"
              validate={{ tel: { pattern: PATTERN_PHONE_NUMBER } }}
              onChange={handleInputChange}
            />
            <AvFeedback>{t("missing_phone_number")}</AvFeedback>
          </AvGroup>
          <AvGroup>
            <Label for="organizationNumber">{t("organization_number")}</Label>
            <AvInput
              required
              type="text"
              name="organizationNumber"
              id="organizationNumber"
              value={organizationNumber || ""}
              readOnly={readOnly}
              placeholder={t("organization_number")}
              onChange={handleInputChange}
            />
            <AvFeedback>{t("missing_organization_number")}</AvFeedback>
          </AvGroup>
          <FormGroup>
            <Label for="duration">{t("duration")}</Label>
            <ReactSelect
              className="react-select-container"
              classNamePrefix="react-select"
              options={durationOptions}
              value={{
                label: duration || t("select"),
                value: duration || "",
              }}
              isSearchable
              id="duration"
              onChange={handleDurationDropDownChange}
              menuPlacement="top"
            />
          </FormGroup>
          <FormGroup>
            <Label for="teamLeadId">{t("team_lead")}</Label>
            <ReactSelect
              className="react-select-container"
              classNamePrefix="react-select"
              options={employeeOptions}
              isSearchable
              value={{
                label: employee?.name || t("select"),
                value: employee?.name || "",
              }}
              label={teamLeadId || ""}
              id="teamLeadId"
              onChange={handleDropDownChange}
              menuPlacement="top"
            />
          </FormGroup>
          {publicId && (
            <FormGroup>
              <CustomInput
                type="checkbox"
                name="active"
                id="checkbox"
                value={active}
                checked={active}
                label={t("active")}
                onChange={handleCheckChange}
              />
            </FormGroup>
          )}
          <Button color="primary">
            {publicId ? t("update") : t("create")}
          </Button>
        </AvForm>
      </CardBody>
    </Card>
  );
};

export default UpdateCustomer;
