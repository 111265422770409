import {
  CUSTOMER_QUALITY_CHECK_REPORT_IN_PROCESSING,
  CUSTOMER_QUESTION_REPORT_IN_PROCESSING,
  DASHBOARD_REPORT1_IN_PROCESSING,
  DASHBOARD_REPORT2_IN_PROCESSING,
  RETRIEVE_DASHBOARD,
  RETRIEVE_DASHBOARD_REPORT1,
  RETRIEVE_DASHBOARD_REPORT2,
  RETRIEVE_OBJECT_QUALITY_CHECK_RATING_REPORT,
  RETRIEVE_QUALITY_CHECK_RATING_REPORT,
  RETRIEVE_QUESTION_QUALITY_CHECK_RATING_REPORT,
  RETRIEVE_TEAM_LEAD_QUALITY_CHECK_RATING_REPORT,
  RETRIEVE_TEAM_LEAD_QUESTION_QUALITY_CHECK_RATING_REPORT,
  TEAM_LEAD_QUALITY_CHECK_REPORT_IN_PROCESSING,
  TEAM_LEAD_QUESTION_REPORT_IN_PROCESSING,
} from "../constants";

const initialState = {
  data: [],
  report1: null,
  report2: [],
  qualityCheckRating: [],
  isLoading: true,
  isReport1Loading: true,
  isReport2Loading: true,
  objectQualityCheckRating: null,
  questionQualityCheckRating: null,
  isCustomerQualityCheckProcessing: true,
  isCustomerQuestionCheckProcessing: true,
  teamLeadQualityCheckRating: null,
  teamLeadQuestionQualityCheckRating: null,
  isTeamLeadQualityCheckProcessing: true,
  isTeamLeadQuestionCheckProcessing: true,
};

export default function reducer(state = initialState, actions) {
  const { type, payload } = actions;
  switch (type) {
    case RETRIEVE_DASHBOARD:
      return {
        ...state,
        data: [payload],
        isLoading: false,
      };
    case RETRIEVE_DASHBOARD_REPORT1:
      return {
        ...state,
        report1: payload,
        isLoading: false,
      };
    case RETRIEVE_DASHBOARD_REPORT2:
      return {
        ...state,
        report2: payload,
        isLoading: false,
      };
    case RETRIEVE_QUALITY_CHECK_RATING_REPORT:
      return {
        ...state,
        qualityCheckRating: payload,
        isLoading: false,
      };
    case RETRIEVE_OBJECT_QUALITY_CHECK_RATING_REPORT:
      return {
        ...state,
        objectQualityCheckRating: payload,
        isCustomerQualityCheckProcessing: false,
      };
    case RETRIEVE_QUESTION_QUALITY_CHECK_RATING_REPORT:
      return {
        ...state,
        questionQualityCheckRating: payload,
        isCustomerQuestionCheckProcessing: false,
      };
    case CUSTOMER_QUALITY_CHECK_REPORT_IN_PROCESSING:
      return {
        ...state,
        isCustomerQualityCheckProcessing: payload,
      };
    case CUSTOMER_QUESTION_REPORT_IN_PROCESSING:
      return {
        ...state,
        isCustomerQuestionCheckProcessing: payload,
      };
    case RETRIEVE_TEAM_LEAD_QUALITY_CHECK_RATING_REPORT:
      return {
        ...state,
        teamLeadQualityCheckRating: payload,
        isTeamLeadQualityCheckProcessing: false,
      };
    case RETRIEVE_TEAM_LEAD_QUESTION_QUALITY_CHECK_RATING_REPORT:
      return {
        ...state,
        teamLeadQuestionQualityCheckRating: payload,
        isTeamLeadQuestionCheckProcessing: false,
      };
    case TEAM_LEAD_QUALITY_CHECK_REPORT_IN_PROCESSING:
      return {
        ...state,
        isTeamLeadQualityCheckProcessing: payload,
      };
    case TEAM_LEAD_QUESTION_REPORT_IN_PROCESSING:
      return {
        ...state,
        isTeamLeadQuestionCheckProcessing: payload,
      };
    case DASHBOARD_REPORT1_IN_PROCESSING:
      return {
        ...state,
        isReport1Loading: payload,
      };
    case DASHBOARD_REPORT2_IN_PROCESSING:
      return {
        ...state,
        isReport2Loading: payload,
      };
    default:
      return state;
  }
}
