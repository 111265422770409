import classnames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Nav,
  NavItem,
  NavLink,
  Col,
  FormGroup,
  Label,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { NavbarDropdowns, NavbarToggle } from "../../components/Navbar";
import { PATH_DASHBOARD } from "../../routes/constants";
import CleanerEgenControl from "./CleanerEgenControlList";
import TeamLeadEgenControl from "./TeamLeadEgenControlList";
import DateTime from "react-datetime";
import moment from "moment";

const EgenControlTabs = (props) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("1");
  const [date, setDate] = useState(new Date());

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const dateTimeLocale =
    localStorage.getItem("i18nextLng") === "SE" ? "sv" : "en-gb";

  return (
    <Container fluid>
      <Header>
        <div className="effektToggle">
          <NavbarToggle />
          <HeaderTitle>{t("egen_control")}</HeaderTitle>
        </div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={PATH_DASHBOARD}>{t("dashboard")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("egen_control")}</BreadcrumbItem>
        </Breadcrumb>
        <NavbarDropdowns />
      </Header>
      <Row>
        <Col md={2} lg={2} xl={2}>
          <FormGroup>
            <Label for="month_picker">{t("month_picker")}</Label>
            <DateTime
              showMonthYearPicker
              value={date}
              name="monthPicker"
              className="effectDatePicker"
              dateFormat="MMM yyyy"
              timeFormat={false}
              locale={dateTimeLocale}
              closeOnSelect
              onChange={(date) => {
                setDate(date.format("YYYY-MM"));
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <div className={"tab "}>
        <Nav style={{ backgroundColor: "#f8f9fa" }} tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              {t("team_lead")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              {t("cleaner")}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <TeamLeadEgenControl
              selectedMonth={moment(date).format("YYYY-MM")}
            />
          </TabPane>
          <TabPane tabId="2">
            <CleanerEgenControl
              selectedMonth={moment(date).format("YYYY-MM")}
            />
          </TabPane>
        </TabContent>
      </div>
    </Container>
  );
};
export default EgenControlTabs;
