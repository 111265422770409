import React from "react";
import Chart from "react-apexcharts";
import { connect } from "react-redux";

import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { t } from "i18next";

const ColumnChart = (props) => {
  const { theme, data } = props;
  const options = {
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "75%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: props.categories,
    },
    yaxis: {
      title: {
        text: t("completed_quality_checks_text"),
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    colors: [
      "#33b2df",
      "#546E7A",
      "#d4526e",
      "#13d8aa",
      "#A5978B",
      "#2b908f",
      "#f9a3a4",
      "#90ee7e",
      "#f48024",
      "#69d2e7",
    ],
  };

  return (
    <Card className={props.border ? "border" : ""}>
      <CardHeader>
        <CardTitle tag="h5"> {props.title}</CardTitle>
      </CardHeader>
      <CardBody>
        <div className="chart">
          <Chart
            key={t("completed_quality_checks_text")}
            options={options}
            series={data}
            type="bar"
            height="300"
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default connect((store) => ({
  theme: store.theme.currentTheme,
}))(ColumnChart);
