import {
  ASSIGN_ADDRESSES_TO_EMPLOYEE,
  CREATE_EMPLOYEE,
  DELETE_ALL_EMPLOYEES,
  DELETE_EMPLOYEE,
  RETRIEVE_CLEANERS,
  RETRIEVE_EMPLOYEE,
  RETRIEVE_EMPLOYEES,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_CONNECTED_FLAG,
  UPDATE_EMPLOYEE_PENDING_FLAG,
} from "../constants";

const initialState = {
  data: [],
  allData: [],
  allCleaners: [],
  cleaners: [],
  page: 0,
  sizePerPage: 0,
  totalSize: 0,
  isLoading: true,
  isPending: false,
  isConnected: false,
};

export default function reducer(state = initialState, actions) {
  const { type, payload } = actions;
  switch (type) {
    case CREATE_EMPLOYEE:
      return {
        ...state,
        data: [...state.data, payload],
        isLoading: false,
      };
    case RETRIEVE_EMPLOYEES:
      if (actions.fetchAll) {
        return {
          ...state,
          allData: payload.content,
          isLoading: false,
        };
      } else {
        return {
          ...state,
          data: payload.content,
          page: payload.pageNo,
          sizePerPage: payload.pageSize,
          totalSize: payload.totalElements,
          isLoading: false,
        };
      }
    case RETRIEVE_CLEANERS:
      if (actions.fetchAll) {
        return {
          ...state,
          allCleaners: payload.content,
          isLoading: false,
        };
      } else {
        return {
          ...state,
          cleaners: payload.content,
          page: payload.pageNo,
          sizePerPage: payload.pageSize,
          totalSize: payload.totalElements,
          isLoading: false,
        };
      }
    case RETRIEVE_EMPLOYEE:
      return {
        ...state,
        data: [payload],
        isLoading: false,
      };
    case UPDATE_EMPLOYEE:
      return {
        ...state,
        data: state.data.map((employee) => {
          if (employee.publicId === payload.publicId) {
            return {
              ...employee,
              ...payload,
            };
          } else {
            return employee;
          }
        }),
        isLoading: false,
      };

    case DELETE_EMPLOYEE:
      return {
        ...state,
        data: state.data.filter(
          ({ publicId }) => publicId !== payload.publicId
        ),
        isLoading: false,
      };
    case ASSIGN_ADDRESSES_TO_EMPLOYEE:
      return {
        ...state,
        data: state.data.map((employee) => {
          if (employee.publicId === payload.publicId) {
            return {
              ...employee,
              ...payload,
            };
          } else {
            return employee;
          }
        }),
        isLoading: false,
      };
    case UPDATE_EMPLOYEE_PENDING_FLAG:
      return {
        ...state,
        isPending: payload,
      };
    case UPDATE_EMPLOYEE_CONNECTED_FLAG:
      return {
        ...state,
        isConnected: payload,
      };
    case DELETE_ALL_EMPLOYEES:
      return [];
    default:
      return state;
  }
}
