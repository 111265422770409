import classnames from "classnames";
import React, { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { MESSAGE_ERROR } from "../../config/AppConstant";
import { showMessage } from "../../utils/app-utils";
import CustomerQualityAndQuestionRating from "./CustomerQualityAndQuestionRating";
import EmployeeQCR from "./EmployeeQCR";
import QualityRating from "./QualityRating";
import TeamleadQualityAndQuestionRating from "./TeamleadQualityAndQuestionRating";

const ReportTabs = (props) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div className={"tab "}>
      <Nav style={{ backgroundColor: "#f8f9fa" }} tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            {t("chart_view")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            {t("tabular_view")}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <ErrorBoundary
            fallbackRender={() => {}}
            onError={() => {
              showMessage({
                type: MESSAGE_ERROR,
                message: t("employee_qcr_fallback_msg"),
              });
            }}
          >
            <EmployeeQCR customers={props.customers} />
          </ErrorBoundary>
          <ErrorBoundary
            fallbackRender={() => {}}
            onError={() => {
              showMessage({
                type: MESSAGE_ERROR,
                message: t("quality_rating_fallback_msg"),
              });
            }}
          >
            <QualityRating customers={props.customers} />
          </ErrorBoundary>
        </TabPane>
        <TabPane tabId="2">
          <ErrorBoundary
            fallbackRender={() => {}}
            onError={() => {
              showMessage({
                type: MESSAGE_ERROR,
                message: t(
                  "team_lead_quality_and_question_rating_fallback_msg"
                ),
              });
            }}
          >
            <TeamleadQualityAndQuestionRating />
          </ErrorBoundary>
          <ErrorBoundary
            fallbackRender={() => {}}
            onError={() => {
              showMessage({
                type: MESSAGE_ERROR,
                message: t("quality_and_question_rating_fallback_msg"),
              });
            }}
          >
            <CustomerQualityAndQuestionRating customers={props.customers} />
          </ErrorBoundary>
        </TabPane>
      </TabContent>
    </div>
  );
};
export default ReportTabs;
