import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { NavbarDropdowns, NavbarToggle } from "../../components/Navbar";
import { MESSAGE_ERROR } from "../../config/AppConstant";
import { changePassword } from "../../redux/actions/authActions";
import { MESSAGE_SUCCESS } from "../../redux/constants";
import { showMessage, validateInput } from "../../utils/app-utils";


const initialState = {
  oldPassword: "",
  password: "",
  confirmPassword: "",
}
const ChangePassword = (props) => {
  const dispatch = useDispatch();
  const { messages } = useSelector((state) => state);
  const { message } = messages;
  const isMounted = useRef(false);
  const [customer, setCustomer] = useState(initialState);

  useEffect(() => {
    if (isMounted.current) {
      if (message.type === "success") {
        showMessage({
          type: MESSAGE_SUCCESS,
          message: message.detail,
        });
      }
    } else {
      isMounted.current = true;
    }
  }, [messages, message]);
  const { oldPassword, password, confirmPassword } = customer;

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const values = [oldPassword, password, confirmPassword];

    if (validateInput(values)) {
      if (password !== confirmPassword) {
        showMessage({
          type: MESSAGE_ERROR,
          message: t("password_not_match"),
        });
      } else {
        const customer = {
          oldPassword,
          password,
        };
        dispatch(changePassword(customer));
      }
    }
  };

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    setCustomer((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Container fluid>
      <Header>
        <div className="effektToggle">
          <NavbarToggle />
          <HeaderTitle>{t("change_password")}</HeaderTitle>
        </div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">{t("dashboard")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("change_password")}</BreadcrumbItem>
        </Breadcrumb>
        <NavbarDropdowns />
      </Header>
      <Row>
        <Col>
          <ErrorBoundary
            fallbackRender={() => {}}
            onError={() => {
              showMessage({
                type: MESSAGE_ERROR,
                message: t("change_password_fallback_msg"),
              });
            }}
          >
            <Row>
              <Col md={4}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h5" className="mb-0">
                      {t("change_password")}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <AvForm onValidSubmit={handleOnSubmit}>
                      <AvGroup>
                        <Label for="oldPassword">{t("old_password")}</Label>
                        <AvInput
                          required
                          type="password"
                          name="oldPassword"
                          id="oldPassword"
                          placeholder={t("old_password")}
                          value={oldPassword || ""}
                          onChange={handleInputChange}
                        />
                        <AvFeedback>{t("missing_password")}</AvFeedback>
                      </AvGroup>
                      <AvGroup>
                        <Label for="password">{t("password")}</Label>
                        <AvInput
                          required
                          type="password"
                          name="password"
                          id="password"
                          placeholder={t("password")}
                          value={password || ""}
                          onChange={handleInputChange}
                        />
                        <AvFeedback>{t("missing_password")}</AvFeedback>
                      </AvGroup>
                      <AvGroup>
                        <Label for="confirmPassword">
                          {t("confirm_password")}
                        </Label>
                        <AvInput
                          required
                          type="password"
                          name="confirmPassword"
                          id="confirmPassword"
                          placeholder={t("confirm_password")}
                          value={confirmPassword || ""}
                          onChange={handleInputChange}
                        />
                        <AvFeedback>{t("missing_password")}</AvFeedback>
                      </AvGroup>
                      <Button color="primary">{t("update")}</Button>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ErrorBoundary>
        </Col>
      </Row>
    </Container>
  );
};

export default ChangePassword;
