import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import "@fullcalendar/react/dist/vdom";
import "@fortawesome/fontawesome-free/css/all.css";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import enLocale from "@fullcalendar/core/locales/en-gb";
import svLocale from "@fullcalendar/core/locales/sv";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import chroma from "chroma-js";
import moment from "moment";
import DateTime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import AppModal from "../../components/Modal";
import {
  DATA_TIME_ZONE,
  DATE_FORMAT,
  LANGUAGE_US,
  MESSAGE_ERROR,
  MESSAGE_SUCCESS,
  QUALITY_CHECK_STATUS_CANCELLED,
  QUALITY_CHECK_STATUS_COMPLETED,
  QUALITY_CHECK_STATUS_IN_PROGRESS,
  QUALITY_CHECK_STATUS_PENDING,
  SCHEDULE_DAY_MAX_EVENTS,
  SCHEDULE_DURATION,
  STATUS_COLOR_CANCELLED,
  STATUS_COLOR_COMPLETED,
  STATUS_COLOR_IN_PROGRESS,
  STATUS_COLOR_PENDING,
  TIME_FORMAT,
} from "../../config/AppConstant";
import { retrieveEmployees } from "../../redux/actions/employeeActions";
import {
  createSchedule,
  editSchedule,
  exportSchedules,
  retrieveSchedules,
} from "../../redux/actions/scheduleActions";
import { PATH_DASHBOARD, PATH_QUALITY_CHECK } from "../../routes/constants";
import {
  getEmployeeOption,
  getOption,
  showMessage,
  valid,
  validateInput,
} from "../../utils/app-utils";

import Tippy from "@tippyjs/react";
import { t } from "i18next";
import { retrieveAgreementAddresses } from "../../redux/actions/addressActions";
import { retrieveCustomerAgreements } from "../../redux/actions/agreementActions";
import { retrieveCustomers } from "../../redux/actions/customerActions";

import "tippy.js/dist/tippy.css";
import CancelledPopUp from "../../components/CancelledPopUp";
import EmptyData from "../../components/EmptyData";
import { NavbarDropdowns, NavbarToggle } from "../../components/Navbar";
import { retrieveUnBookableDates } from "../../redux/actions/unBookableDatesActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faRedo } from "@fortawesome/free-solid-svg-icons";
const initialEventState = {
  title: "",
};
const Scheduler = () => {
  const dispatch = useDispatch();
  const {
    unBookableDates,
    schedules,
    employees,
    addresses,
    customers,
    agreements,
    sidebar,
  } = useSelector((state) => state);

  const { messages } = useSelector((state) => state);
  const { message } = messages;
  const isMounted = useRef(false);
  const agreementSelectRef = useRef();
  const addressSelectRef = useRef();
  useEffect(() => {
    if (isMounted.current) {
      if (message.type === "success") {
        showMessage({
          type: MESSAGE_SUCCESS,
          message: message.detail,
        });
      }
    } else {
      isMounted.current = true;
    }
  }, [messages, message]);

  useEffect(() => {
    const params = {
      status: true,
      type: "TEAM_LEAD",
    };
    dispatch(retrieveSchedules());
    dispatch(retrieveEmployees(params, true));
    dispatch(retrieveCustomers());
    dispatch(retrieveUnBookableDates());
  }, [dispatch]);

  const isLoading =
    schedules.isLoading || employees.isLoading || unBookableDates.isLoading;

  const { isProcessing } = schedules;

  const [event, setEvent] = useState(initialEventState);

  const [openModal, setOpenModal] = useState(false);

  const [openCancelPopUP, setOpenCancelPopUP] = useState(false);

  const [reason, setReason] = useState("");

  const [isExport, setIsExport] = useState(false);

  const [exportStartDate, setExportStartDate] = useState(null);
  const [exportEndDate, setExportEndDate] = useState(null);
  // const getSelectedUser = (data) => {
  //   return [...new Set(data.map((t) => t.employee.publicId))];
  // };

  const [selectedUser, setSelectedUser] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState([]);

  // useEffect(() => {
  //   setSelectedUser(getSelectedUser(schedules.data));
  // }, [schedules.data]);

  useEffect(() => {
    if (event?.customerPublicId) {
      dispatch(retrieveCustomerAgreements(event?.customerPublicId));
    }
  }, [event?.customerPublicId, dispatch]);

  useEffect(() => {
    if (event?.agreementId) {
      dispatch(retrieveAgreementAddresses(event?.agreementId));
    }
  }, [dispatch, event?.agreementId]);

  const options = employees?.allData?.map((employee) =>
    getEmployeeOption(employee)
  );
  const customerOptions = customers?.data?.map((customer) =>
    getOption(customer)
  );

  const getAddressOption = (option) => {
    return {
      label:
        option.objectName +
        " (" +
        option.objectNumber +
        ")" +
        " (" +
        option.agreement.agreementNumber +
        ")",
      value: option.publicId,
    };
  };
  const addressOptions = addresses?.data?.map((address) =>
    getAddressOption(address)
  );

  const agreementOptions = agreements?.data?.map((agreement) => {
    return {
      label: agreement.agreementNumber + " - " + agreement.description,
      value: agreement.publicId,
    };
  });

  const handleEventAllow = (dropInfo, draggedEvent) => {
    const isPending =
      draggedEvent.extendedProps?.status === QUALITY_CHECK_STATUS_PENDING
        ? true
        : false;
    return isPending;
  };

  const handleEventChange = (e) => {
    const isPending =
      e.event.extendedProps?.status === QUALITY_CHECK_STATUS_PENDING
        ? true
        : false;
    if (isPending) {
      const start = moment.utc(e.event.startStr);
      if (start.isBefore(moment())) {
        showMessage({
          type: MESSAGE_ERROR,
          message: t("schedule_previous_time"),
        });
        e.revert();
        return;
      }
      const eventObj = {
        ...e.event.extendedProps,
        title: e.event.title.split("(")[0],
        start: start.toISOString(),
        addressId: e.event.extendedProps.address.publicId,
        end: start.add(SCHEDULE_DURATION, "minutes").toISOString(),
        employeePublicId: e.event.extendedProps.employee.publicId,
      };
      dispatch(editSchedule(eventObj));
    }
  };
  const handleEventClick = (clickInfo) => {
    const openNotAllowed =
      clickInfo.event.extendedProps?.status ===
        QUALITY_CHECK_STATUS_CANCELLED ||
      clickInfo.event.extendedProps?.status === QUALITY_CHECK_STATUS_COMPLETED;
    if (!openNotAllowed) {
      setEvent(() => {
        const extendedProps = clickInfo.event.extendedProps;
        const employee = extendedProps.employee;
        const customer = extendedProps.address.agreement.customer;
        const address = extendedProps.address;
        const start = moment.utc(clickInfo.event.startStr).toISOString();
        const addressOption = address ? getAddressOption(address) : "";
        return {
          ...extendedProps,
          title: clickInfo.event.title.split("(")[0],
          start,
          customerPublicId: extendedProps.address.agreement.customer.publicId,
          agreementId: extendedProps.address.agreement.publicId,
          agreementNumber: extendedProps.address.agreement.agreementNumber,
          addressId: extendedProps.address.publicId,
          addressName: addressOption?.label,
          addressOption: addressOption,
          customer: customer ? getOption(customer) : "",
          employee: employee ? getOption(employee) : "",
        };
      });
      setOpenModal(!openModal);
    }
  };

  const dayRender = (date) => {
    const d = unBookableDates.data.find((d) =>
      date.date.toISOString().includes(d.date)
    );
    if (d) {
      date.el.classList.add("fc-daygrid-red-day");
    }
  };

  const handleDateSelect = (selectInfo) => {
    const start = moment.utc(selectInfo.startStr);
    if (start.isBefore(moment().startOf("day"))) {
      showMessage({
        type: MESSAGE_ERROR,
        message: t("schedule_add_previous_time"),
      });
      return;
    }
    setEvent(() => {
      return {
        ...initialEventState,
        start: moment.utc(selectInfo.startStr).add(6, "hours").toISOString(),
      };
    });
    setOpenModal(!openModal);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEvent((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleReasonChange = (event) => {
    const { value } = event.target;
    setReason(value);
  };

  const handleUserDropDownChange = (event) => {
    let { value } = event;
    value = value?.split("|");
    event.label = value[1];
    setEvent((prevState) => ({
      ...prevState,
      employeePublicId: value[0],
    }));
  };

  const handleCustomerDropDownChange = (event) => {
    //agreementSelectRef?.current?.select?.clearValue();
    let { value } = event;
    value = value?.split("|");
    event.label = value[1];
    setEvent((prevState) => ({
      ...prevState,
      customerPublicId: value[0],
      agreementId: "",
      agreementNumber: "",
      addressId: "",
      addressName: "",
    }));
  };

  const handleAgreementDropDownChange = (event) => {
    let { value, label } = event;
    setEvent((prevState) => ({
      ...prevState,
      agreementId: value,
      agreementNumber: label,
      addressId: "",
      addressName: "",
    }));
  };

  const handleAddressDropDownChange = (event) => {
    let { value, label } = event;
    const address = addresses.data.find(({ publicId }) => publicId === value);
    setEvent((prevState) => ({
      ...prevState,
      addressId: address.publicId,
      addressName: label,
    }));
  };

  const handleEmployeeDropDownChange = (employees) => {
    let selectedEmp = [];
    if (employees?.length > 0) {
      for (const employee of employees) {
        const value = employee?.value?.split("|");
        selectedEmp.push(value[0]);
      }
    }
    setSelectedUser(selectedEmp);
  };

  const handleStatusDropDownChange = (statuses) => {
    let selectedStatus = [];
    if (statuses?.length > 0) {
      for (const status of statuses) {
        const value = status?.value;
        selectedStatus.push(value);
      }
    }
    setSelectedStatus(selectedStatus);
  };

  const handleOnSubmit = (e) => {
    const isCancelled = e.currentTarget?.id === "cancel-event" ? true : false;
    e.preventDefault();
    const start = moment.utc(event.start).add(event.startTime);
    let eventObj = {
      publicId: event.publicId,
      employeePublicId: event.employeePublicId,
      addressId: event.addressId,
      title: event.title,
      start: start.toISOString(),
      end: start.add(SCHEDULE_DURATION, "minutes").toISOString(),
    };
    if (isCancelled) {
      eventObj.status = QUALITY_CHECK_STATUS_CANCELLED;
      eventObj.reason = reason;
    }
    if (!eventObj.employeePublicId && event.employee) {
      let { value } = event.employee;
      value = value?.split("|");
      eventObj.employeePublicId = value[0];
    }
    const values = [
      eventObj.title,
      eventObj.start,
      eventObj.end,
      eventObj.employeePublicId,
      eventObj.addressId,
    ];

    if (validateInput(values)) {
      if (event.publicId) {
        dispatch(editSchedule(eventObj, true));
      } else {
        dispatch(createSchedule(eventObj));
      }
      setEvent(initialEventState);
      setOpenModal(false);
      setOpenCancelPopUP(false);
    } else {
      showMessage({
        type: MESSAGE_ERROR,
        message: t("missing_information"),
      });
    }
  };

  const handleCancel = (publicId) => {
    setOpenModal(false);
    setOpenCancelPopUP(true);
  };

  let events = schedules.data;
  if (selectedUser?.length > 0) {
    events = events.filter((event) =>
      selectedUser.includes(event.employee.publicId)
    );
  }

  if (selectedStatus?.length > 0) {
    events = events.filter((event) => selectedStatus.includes(event.status));
  }

  const colourOptions = [
    {
      value: QUALITY_CHECK_STATUS_PENDING,
      label: t("pending"),
      color: STATUS_COLOR_PENDING,
    },
    {
      value: QUALITY_CHECK_STATUS_IN_PROGRESS,
      label: t("in_progress"),
      color: STATUS_COLOR_IN_PROGRESS,
    },
    {
      value: QUALITY_CHECK_STATUS_COMPLETED,
      label: t("completed"),
      color: STATUS_COLOR_COMPLETED,
    },
    {
      value: QUALITY_CHECK_STATUS_CANCELLED,
      label: t("cancelled"),
      color: STATUS_COLOR_CANCELLED,
    },
  ];

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.color,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ":hover": {
        backgroundColor: data.color,
        color: "white",
      },
    }),
  };

  const eventContent = (info) => {
    return (
      <Tippy
        content={
          <span className="calenderTooltip">
            {renderTooltipContent(info.event.extendedProps)}
          </span>
        }
      >
        {renderInnerContent(info)}
      </Tippy>
    );
  };

  const renderInnerContent = (innerProps) => {
    let empName = innerProps?.event?.extendedProps?.employee?.name;
    let matches = empName?.match(/\b(\w)/g);
    let empAcronym = matches?.join("");
    let eventClass = "fc-h-pending-event";
    if (innerProps.event.extendedProps.status === "CANCELLED") {
      eventClass = "fc-h-cancelled-event";
    } else if (innerProps.event.extendedProps.status === "COMPLETED") {
      eventClass = "fc-h-completed-event";
    } else if (innerProps.event.extendedProps.status === "INPROGRESS") {
      eventClass = "fc-h-inprogress-event";
    }
    eventClass = "fc-event-main-frame " + eventClass;

    return (
      <div className={eventClass}>
        {innerProps.timeText && (
          <div
            className="fc-event-time"
            style={{
              minWidth: "30px",
            }}
          >
            {innerProps.timeText}
          </div>
        )}
        <div className="fc-event-title-container">
          <div
            className="fc-event-title fc-sticky"
            style={{
              maxWidth: "100px",
              textOverflow: "ellipsis",
            }}
          >
            {innerProps.event.title || <Fragment>&nbsp;</Fragment>}
          </div>
          <div
            style={{
              float: "right",
            }}
          >
            {innerProps.event.extendedProps?.qualityCheck?.parentId && (
              <FontAwesomeIcon
                icon={faRedo}
                fixedWidth
                className="align-middle mr-1"
              />
            )}
            <span className="dot mr-1">{empAcronym}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderTooltipContent = (content) => {
    return (
      <div>
        <span>{t("employee") + " : " + content?.employee?.name}</span>
        <hr />
        <span>
          {t("customer") + " : " + content?.address?.agreement?.customer?.name}
        </span>
        <hr />
        <span>
          {t("agreement_number") +
            " : " +
            content?.address?.agreement?.agreementNumber}
        </span>
        <span>
          {t("object_number") + " : " + content?.address?.objectNumber}
        </span>
        <span>{t("object_name") + " : " + content?.address?.objectName} </span>
        <hr />
        <span>
          {t("street_address") + " : " + content?.address?.streetAddress}
        </span>
        <span>{t("city") + " : " + content?.address?.city}</span>
        <span>{t("post_code") + " : " + content?.address?.postalCode} </span>
        {content?.status === QUALITY_CHECK_STATUS_CANCELLED ? (
          <>
            <hr />
            <span>{t("cancel_reason") + " : " + t(content?.reason)} </span>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const customButtons = isExport
    ? {
        scheduleExportBtn: {
          text: isProcessing ? <Spinner /> : t("export"),
          click: function () {
            const params = {
              startDate: exportStartDate,
              endDate: exportEndDate,
            };
            dispatch(exportSchedules(params));
          },
        },
      }
    : {};

  let headerToolBarRightContent = isExport
    ? "scheduleExportBtn,listMonth,dayGridMonth,timeGridWeek,timeGridDay"
    : "listMonth,dayGridMonth,timeGridWeek,timeGridDay";

  const dateTimeLocale =
    localStorage.getItem("i18nextLng") === "SE" ? "sv" : "en-gb";

  return (
    <Container fluid>
      <Header>
        <div className="effektToggle">
          <NavbarToggle />
          <HeaderTitle>{t("scheduler")}</HeaderTitle>
        </div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={PATH_DASHBOARD}>{t("dashboard")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("scheduler")}</BreadcrumbItem>
        </Breadcrumb>
        <NavbarDropdowns />
      </Header>
      <Card>
        <CardHeader>
          <CardTitle tag="h5" className="mb-0">
            {t("filters")}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div c></div>
          <Row>
            <Col md={4} lg={4} xl={4}>
              <div>
                <Label>{t("employees")}</Label>
                <ReactSelect
                  className="calenderSelect react-select-container"
                  classNamePrefix="react-select"
                  options={options}
                  onChange={handleEmployeeDropDownChange}
                  placeholder={t("select")}
                  isMulti
                />
              </div>
            </Col>
            <Col md={4} lg={4} xl={4}>
              <div>
                <Label>{t("schedule_status")}</Label>
                <ReactSelect
                  className="calenderSelect react-select-container"
                  classNamePrefix="react-select"
                  options={colourOptions}
                  onChange={handleStatusDropDownChange}
                  placeholder={t("select")}
                  isMulti
                  styles={colourStyles}
                />
              </div>
            </Col>
            <Col md={4} lg={4} xl={4}>
              <div className="colorIndicatorContainer">
                <div>
                  <div className="colorIndicator">
                    <span style={{ backgroundColor: STATUS_COLOR_COMPLETED }} />
                    <div>{t("completed")}</div>
                  </div>
                  <div className="colorIndicator">
                    <span style={{ backgroundColor: STATUS_COLOR_PENDING }} />
                    <div>{t("pending")}</div>
                  </div>
                </div>
                <div>
                  <div className="colorIndicator">
                    <span
                      style={{ backgroundColor: STATUS_COLOR_IN_PROGRESS }}
                    />
                    <div>{t("in_progress")}</div>
                  </div>
                  <div className="colorIndicator">
                    <span style={{ backgroundColor: STATUS_COLOR_CANCELLED }} />
                    <div>{t("cancelled")}</div>
                  </div>
                </div>
                <div className="colorIndicator">
                  <FontAwesomeIcon
                    icon={faRedo}
                    fixedWidth
                    className="align-middle ml-4 mr-1 mt-1"
                  />
                  <div>{t("follow_up")}</div>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="calender">
        <Row>
          <Col md={12} lg={12} xl={12}>
            <div className="flex-grow-0 py-3 px-4 border-top">
              <CancelledPopUp
                openCancelPopUP={openCancelPopUP}
                setOpenCancelPopUP={setOpenCancelPopUP}
                header={t("cancel_schedule")}
                formId={"cancel-event"}
                publicId={event.publicId}
              >
                <Form id="cancel-event" onSubmit={handleOnSubmit}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="title">{t("reason")}</Label>
                        <Input
                          type="textarea"
                          name="reason"
                          id="reason"
                          placeholder={t("reason_detail")}
                          onChange={handleReasonChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CancelledPopUp>
              <AppModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                header={event.publicId ? t("edit_schedule") : t("add_schedule")}
                formId={"add-event"}
                publicId={event.publicId}
                handleCancel={handleCancel}
              >
                <Form id="add-event" onSubmit={handleOnSubmit}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="title">{t("title")}</Label>
                        <Input
                          type="text"
                          name="title"
                          id="title"
                          placeholder={t("title")}
                          value={event.title || ""}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="customerId">{t("customer")}</Label>
                        <ReactSelect
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={customerOptions}
                          label={event.customer}
                          defaultValue={event.customer}
                          isSearchable
                          id="customerId"
                          onChange={handleCustomerDropDownChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="agreementId">{t("agreement_number")}</Label>
                        <ReactSelect
                          className="react-select-container"
                          ref={agreementSelectRef}
                          classNamePrefix="react-select"
                          options={agreementOptions}
                          isSearchable
                          label={event?.agreementNumber}
                          value={
                            event?.agreementNumber
                              ? {
                                  label: event?.agreementNumber,
                                  value: event?.agreementId,
                                }
                              : ""
                          }
                          id="agreementId"
                          onChange={handleAgreementDropDownChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="addressId">{t("address")}</Label>
                        <ReactSelect
                          className="react-select-container"
                          ref={addressSelectRef}
                          classNamePrefix="react-select"
                          options={addressOptions}
                          isSearchable
                          label={event.addressOption}
                          value={
                            event?.addressId
                              ? {
                                  label: event?.addressName,
                                  value: event?.addressId,
                                }
                              : ""
                          }
                          id="addressId"
                          onChange={handleAddressDropDownChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="employeePublicId">{t("employee")}</Label>
                        <ReactSelect
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={options}
                          label={event.employee}
                          defaultValue={event.employee}
                          isSearchable
                          id="employeePublicId"
                          onChange={handleUserDropDownChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="start">{t("start_date")}</Label>
                        <div className="align-self-center w-100">
                          <DateTime
                            inputProps={{
                              readOnly: true,
                            }}
                            className="effectDatePicker"
                            name="start"
                            timeConstraints={{
                              hours: { min: 6, max: 20 },
                              minutes: { min: 0, max: 59 },
                              seconds: { min: 0, max: 59 },
                            }}
                            initialViewDate={moment
                              .utc()
                              .set({ hour: 6, minute: 0 })}
                            dateFormat={DATE_FORMAT}
                            timeFormat={TIME_FORMAT}
                            dataTimezone={DATA_TIME_ZONE}
                            displayTimezone={DATA_TIME_ZONE}
                            // initialValue={DateTime.moment.utc(event.start)}
                            value={DateTime.moment.utc(event.start)}
                            locale={dateTimeLocale}
                            isValidDate={valid}
                            closeOnSelect
                            onChange={(date) => {
                              if (typeof date != "string") {
                                setEvent((prevState) => ({
                                  ...prevState,
                                  start: date.toISOString(),
                                }));
                              }
                            }}
                          />
                        </div>
                      </FormGroup>
                      {event?.qualityCheck?.parentId && (
                        <FormGroup>
                          <Label for="parent_quality_check_number">
                            {t("parent_quality_check_number") +
                              " " +
                              event?.qualityCheck?.reportingId}
                          </Label>
                          <Link
                            class="ml-1"
                            to={
                              PATH_QUALITY_CHECK +
                              "/" +
                              event?.qualityCheck?.parentId
                            }
                          >
                            <FontAwesomeIcon
                              icon={faLink}
                              fixedWidth
                              className="align-middle mr-1"
                            />
                          </Link>
                        </FormGroup>
                      )}
                    </Col>
                  </Row>
                </Form>
              </AppModal>
              {isLoading ? (
                <EmptyData isLoading={isLoading} />
              ) : (
                <FullCalendar
                  plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin,
                    bootstrapPlugin,
                    listPlugin,
                  ]}
                  timeZone={DATA_TIME_ZONE}
                  eventTimeFormat={{
                    hour: "numeric",
                    minute: "numeric",
                    hour12: false,
                  }}
                  slotLabelFormat={[
                    {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: false,
                    },
                  ]}
                  customButtons={customButtons}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: headerToolBarRightContent,
                  }}
                  datesSet={(info) => {
                    if (info?.view?.type === "listMonth") {
                      let startDate =
                        info.start && info.start.toISOString().split("T")[0];
                      let endDate =
                        info.end && info.end.toISOString().split("T")[0];
                      setIsExport(true);
                      setExportStartDate(startDate);
                      setExportEndDate(endDate);
                    } else setIsExport(false);
                  }}
                  themeSystem="bootstrap"
                  initialView="dayGridMonth"
                  weekNumbers={true}
                  editable={true}
                  selectable={true}
                  selectMirror={true}
                  dayMaxEvents={SCHEDULE_DAY_MAX_EVENTS}
                  eventContent={eventContent}
                  events={events}
                  eventClick={handleEventClick}
                  eventChange={handleEventChange}
                  eventAllow={handleEventAllow}
                  select={handleDateSelect}
                  height="80vh"
                  firstDay={1}
                  locale={
                    sidebar.language === LANGUAGE_US ? enLocale : svLocale
                  }
                  dayCellDidMount={dayRender}
                  eventDisplay="block"
                  eventDataTransform={(data) => {
                    return {
                      ...data,
                      title: data.title,
                    };
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default Scheduler;
