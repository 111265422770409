import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { Breadcrumb, BreadcrumbItem, Container } from "reactstrap";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fullcalendar/react/dist/vdom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { PATH_DASHBOARD } from "../../routes/constants";

import { t } from "i18next";
import { retrieveCustomers } from "../../redux/actions/customerActions";

import "tippy.js/dist/tippy.css";
import { NavbarDropdowns, NavbarToggle } from "../../components/Navbar";
import ReportTabs from "./ReportTabs";

const Report = () => {
  const dispatch = useDispatch();
  const { customers } = useSelector((state) => state);

  useEffect(() => {
    dispatch(retrieveCustomers());
  }, [dispatch]);

  return (
    <Container fluid>
      <Header>
        <div className="effektToggle">
          <NavbarToggle />
          <HeaderTitle>{t("reports")}</HeaderTitle>
        </div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={PATH_DASHBOARD}>{t("dashboard")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("reports")}</BreadcrumbItem>
        </Breadcrumb>
        <NavbarDropdowns />
      </Header>
      <ReportTabs customers={customers?.data} />
    </Container>
  );
};

export default Report;
