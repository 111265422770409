import { t } from "i18next";
import {
  CREATE_QUESTIONNAIRE,
  DELETE_QUESTIONNAIRE,
  MESSAGE_SUCCESS,
  RETRIEVE_QUESTIONNAIRE,
  RETRIEVE_QUESTIONNAIRES,
  UPDATE_QUESTIONNAIRE,
} from "../constants";
import QuestionnairesDataService from "../../services/questionnairesService";

export const retrieveQuestionnaires = (params) => async (dispatch) => {
  try {
    const res = await QuestionnairesDataService.getAll(params);
    dispatch({
      type: RETRIEVE_QUESTIONNAIRES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const createQuestionnaire = (questionnaire) => async (dispatch) => {
  try {
    const res = await QuestionnairesDataService.create(questionnaire);
    dispatch({
      type: CREATE_QUESTIONNAIRE,
      payload: res.data,
    });
    dispatch({
      type: MESSAGE_SUCCESS,
      message: t("questionnaire_created"),
    });
  } catch (err) {
    console.log(err);
  }
};

export const getQuestionnaire = (publicId) => async (dispatch) => {
  try {
    const res = await QuestionnairesDataService.get(publicId);
    dispatch({
      type: RETRIEVE_QUESTIONNAIRE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteQuestionnaire = (publicId) => async (dispatch) => {
  try {
    await QuestionnairesDataService.delete(publicId);

    dispatch({
      type: DELETE_QUESTIONNAIRE,
      payload: { publicId },
    });
  } catch (err) {
    console.log(err);
  }
};

export const editQuestionnaire = (questionnaire) => async (dispatch) => {
  try {
    const res = await QuestionnairesDataService.update(questionnaire);

    dispatch({
      type: UPDATE_QUESTIONNAIRE,
      payload: res.data,
    });
    dispatch({
      type: MESSAGE_SUCCESS,
      message: t("questionnaire_updated"),
    });
  } catch (err) {
    console.log(err);
  }
};
