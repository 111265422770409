import { t } from "i18next";
import ScheduleDataService from "../../services/scheduleService";
import {
  CREATE_SCHEDULE,
  DATA_PROCESSING,
  DELETE_SCHEDULE,
  MESSAGE_SUCCESS,
  RETRIEVE_SCHEDULE,
  RETRIEVE_SCHEDULES,
  UPDATE_SCHEDULE,
} from "../constants";

export const retrieveSchedules = () => async (dispatch) => {
  try {
    const res = await ScheduleDataService.getAll();
    dispatch({
      type: RETRIEVE_SCHEDULES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const retrieveUserSchedules = (publicId) => async (dispatch) => {
  try {
    const res = await ScheduleDataService.getUserSchedule(publicId);
    dispatch({
      type: RETRIEVE_SCHEDULES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getSchedule = (publicId) => async (dispatch) => {
  try {
    const res = await ScheduleDataService.get(publicId);
    dispatch({
      type: RETRIEVE_SCHEDULE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteSchedule = (publicId) => async (dispatch) => {
  try {
    await ScheduleDataService.delete(publicId);

    dispatch({
      type: DELETE_SCHEDULE,
      payload: { publicId },
    });
  } catch (err) {
    console.log(err);
  }
};

export const editSchedule = (schedule, isShowMessage) => async (dispatch) => {
  try {
    const res = await ScheduleDataService.update(schedule);
    dispatch({
      type: UPDATE_SCHEDULE,
      payload: res.data,
    });
    if (isShowMessage)
      dispatch({
        type: MESSAGE_SUCCESS,
        message: t("schedule_updated"),
      });
  } catch (err) {
    console.log(err);
  }
};

export const createSchedule = (schedule) => async (dispatch) => {
  try {
    const res = await ScheduleDataService.create(schedule);
    dispatch({
      type: CREATE_SCHEDULE,
      payload: res.data,
    });
    dispatch({
      type: MESSAGE_SUCCESS,
      message: t("schedule_created"),
    });
  } catch (err) {
    console.log(err);
  }
};

export const createFollowUpSchedule =
  (parentId, followUpSchedule) => async (dispatch) => {
    try {
      const res = await ScheduleDataService.createFollowUp(
        parentId,
        followUpSchedule
      );
      dispatch({
        type: CREATE_SCHEDULE,
        payload: res.data,
      });
      dispatch({
        type: MESSAGE_SUCCESS,
        message: t("follow_up_quality_check_created"),
      });
    } catch (err) {
      console.log(err);
    }
  };

export const exportSchedules = (params) => async (dispatch) => {
  try {
    dispatch({
      type: DATA_PROCESSING,
      payload: true,
    });
    const res = await ScheduleDataService.exportSchedules(params);
    dispatch({
      type: DATA_PROCESSING,
      payload: false,
    });
    const outputFilename = `Schedules_${Date.now()}.xls`;
    const url = URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", outputFilename);
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    console.log(err);
  }
};
