import React, { useEffect, useRef, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fullcalendar/react/dist/vdom";
import DateTime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";

import { useTranslation } from "react-i18next";
import "tippy.js/dist/tippy.css";
import {
  QUALITY_CHECK_STATUS_CANCELLED,
  QUALITY_CHECK_STATUS_COMPLETED,
  QUALITY_CHECK_STATUS_IN_PROGRESS,
  QUALITY_CHECK_STATUS_PENDING,
} from "../../config/AppConstant";
import { retrieveAgreementAddressesForQualityCheckReport } from "../../redux/actions/addressActions";
import { retrieveCustomerAgreementsForQualityCheckReport } from "../../redux/actions/agreementActions";
import { getDashboardReport2 } from "../../redux/actions/dashboardActions";
import { getAddressOption, getOption } from "../../utils/app-utils";
import Column from "../dashboards/Default/Column";

const EmployeeQCR = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dashboard, addresses, agreements } = useSelector((state) => state);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const date = new Date();
  const curentYear = date?.getUTCFullYear();
  const startDateValue = curentYear + "-01-01";
  const endDateValue = curentYear + "-12-31";
  const [startDate, setStartDate] = useState(startDateValue);
  const [endDate, setEndDate] = useState(endDateValue);
  const [status, setStatus] = useState(QUALITY_CHECK_STATUS_COMPLETED);

  useEffect(() => {
    const params = {
      customerPublicId:
        selectedCustomer === "clear" ? undefined : selectedCustomer,
      agreementPublicId: selectedAgreement?.value,
      addressPublicId: selectedAddress?.value,
      startDate: startDate,
      endDate: endDate,
      status: status,
    };
    if (
      status ||
      startDate ||
      endDate ||
      (selectedCustomer && selectedCustomer !== "clear") ||
      (selectedAgreement && selectedAgreement !== "clear") ||
      (selectedAddress && selectedAddress !== "clear")
    )
      dispatch(getDashboardReport2(params));
  }, [
    dispatch,
    endDate,
    selectedAddress,
    selectedAgreement,
    selectedCustomer,
    status,
    startDate,
  ]);

  useEffect(() => {
    if (selectedCustomer && selectedCustomer !== "clear")
      dispatch(
        retrieveCustomerAgreementsForQualityCheckReport(selectedCustomer)
      );
  }, [selectedCustomer, dispatch]);

  useEffect(() => {
    if (selectedAgreement && selectedAgreement !== "clear")
      dispatch(
        retrieveAgreementAddressesForQualityCheckReport(
          selectedAgreement?.value
        )
      );
  }, [dispatch, selectedAgreement]);

  const options = props.customers?.map((customer) => getOption(customer));

  const agreementOptions =
    selectedAgreement === "clear"
      ? []
      : agreements?.qualityRatingData?.map((agreement) => {
          return {
            label: agreement.agreementNumber,
            value: agreement.publicId,
          };
        });

  const addressOptions =
    selectedAddress === "clear"
      ? []
      : addresses?.qualityRatingData?.map((address) =>
          getAddressOption(address)
        );

  const handleCustomerDropDownChange = (customer) => {
    if (customer) {
      const value = customer?.value?.split("|");
      setSelectedCustomer(value[0]);
      setSelectedAgreement(null);
      setSelectedAddress("clear");
    } else {
      setSelectedCustomer("clear");
      setSelectedAgreement("clear");
      setSelectedAddress("clear");
    }
  };

  const handleAgreementDropDownChange = (agreement) => {
    if (agreement) {
      setSelectedAgreement(agreement);
      setSelectedAddress(null);
    } else {
      setSelectedAgreement(null);
      setSelectedAddress("clear");
    }
  };

  const handleAddressDropDownChange = (address) => {
    if (address) {
      setSelectedAddress(address);
    } else {
      setSelectedAddress(null);
    }
  };

  let columnChartData = [];
  let categories = [
    "Jan",
    "Feb",
    t("mar"),
    "Apr",
    t("may"),
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    t("oct"),
    "Nov",
    "Dec",
  ];
  if (dashboard?.report2) {
    for (const empDetail of dashboard?.report2) {
      let columnChartDataObj = { name: "", data: [] };
      columnChartDataObj.name = empDetail.employeeName;
      for (const monthlyDetail of empDetail?.monthlyResponses) {
        columnChartDataObj.data.push(monthlyDetail.count);
      }
      columnChartData.push(columnChartDataObj);
    }
  }

  const statusOptions = [
    { value: QUALITY_CHECK_STATUS_COMPLETED, label: t("completed") },
    { value: QUALITY_CHECK_STATUS_PENDING, label: t("pending") },
    { value: QUALITY_CHECK_STATUS_IN_PROGRESS, label: t("in_progress") },
    { value: QUALITY_CHECK_STATUS_CANCELLED, label: t("cancelled") },
  ];

  const handleStatusDropDownChange = (status) => {
    if (status) {
      setStatus(status?.value);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          {t("quality_checks_reports")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={3} lg={3} xl={3}>
            <FormGroup>
              <Label for="customerId">{t("customer_name")}</Label>
              <ReactSelect
                className="calenderSelect react-select-container"
                classNamePrefix="react-select"
                options={options}
                onChange={handleCustomerDropDownChange}
                placeholder={t("select")}
                isClearable
              />
            </FormGroup>
          </Col>
          <Col md={2} lg={2} xl={2}>
            <FormGroup>
              <Label for="agreement_number">{t("agreement_number")}</Label>
              <ReactSelect
                className="calenderSelect react-select-container"
                classNamePrefix="react-select"
                options={agreementOptions}
                onChange={handleAgreementDropDownChange}
                placeholder={t("select")}
                value={selectedAgreement ? selectedAgreement : ""}
                isClearable
              />
            </FormGroup>
          </Col>
          <Col md={3} lg={3} xl={3}>
            <FormGroup>
              <Label for="address">{t("address")}</Label>
              <ReactSelect
                className="calenderSelect react-select-container"
                classNamePrefix="react-select"
                options={addressOptions}
                onChange={handleAddressDropDownChange}
                placeholder={t("select")}
                value={selectedAddress ? selectedAddress : ""}
                isClearable
              />
            </FormGroup>
          </Col>
          <Col md={2} lg={2} xl={2}>
            <FormGroup>
              <Label for="status">{t("status")}</Label>
              <ReactSelect
                key={t("completed")}
                className="calenderSelect react-select-container"
                classNamePrefix="react-select"
                options={statusOptions}
                defaultValue={{
                  value: QUALITY_CHECK_STATUS_COMPLETED,
                  label: t("completed"),
                }}
                onChange={handleStatusDropDownChange}
                placeholder={t("select")}
              />
            </FormGroup>
          </Col>
          <Col md={2} lg={2} xl={2}>
            <FormGroup>
              <Label for="year_picker">{t("year_picker")}</Label>
              <DateTime
                inputProps={{
                  readOnly: true,
                  placeholder: t("select"),
                }}
                name="yearPicker"
                className="effectDatePicker"
                initialValue={new Date()}
                dateFormat={"YYYY"}
                timeFormat={false}
                closeOnSelect
                onChange={(date) => {
                  const selectedYear = date.year();
                  const startDate = selectedYear + "-01-01";
                  const endDate = selectedYear + "-12-31";
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12} xl={12} className="mt-3">
            {dashboard.isReport2Loading ? (
              <div className="text-center">
                <Spinner />
              </div>
            ) : (
              <Column
                data={columnChartData}
                title=""
                border={true}
                categories={Array.from(categories)}
              />
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default EmployeeQCR;
