import {
  CREATE_REPRESENTATIVE,
  DELETE_ALL_REPRESENTATIVES,
  DELETE_REPRESENTATIVE,
  RETRIEVE_REPRESENTATIVES,
  RETRIEVE_REPRESENTATIVE,
  UPDATE_REPRESENTATIVE,
} from "../constants";

const initialState = {
  data: [],
  isLoading: true,
};

export default function reducer(state = initialState, actions) {
  const { type, payload } = actions;
  switch (type) {
    case CREATE_REPRESENTATIVE:
      return {
        ...state,
        data: [...state.data, payload],
        isLoading: false,
      };
    case RETRIEVE_REPRESENTATIVES:
      return {
        ...state,
        data: payload,
        isLoading: false,
      };
    case RETRIEVE_REPRESENTATIVE:
      return {
        ...state,
        data: [payload],
        isLoading: false,
      };
    case UPDATE_REPRESENTATIVE:
      return {
        ...state,
        data: state.data.map((REPRESENTATIVE) => {
          if (REPRESENTATIVE.publicId === payload.publicId) {
            return {
              ...REPRESENTATIVE,
              ...payload,
            };
          } else {
            return REPRESENTATIVE;
          }
        }),
        isLoading: false,
      };

    case DELETE_REPRESENTATIVE:
      return {
        ...state,
        data: state.data.filter(
          ({ publicId }) => publicId !== payload.publicId
        ),
        isLoading: false,
      };

    case DELETE_ALL_REPRESENTATIVES:
      return [];
    default:
      return state;
  }
}
