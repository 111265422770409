import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import DateTime from "react-datetime";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import EmptyData from "../../components/EmptyData";
import { NavbarDropdowns, NavbarToggle } from "../../components/Navbar";
import {
  MESSAGE_ERROR,
  MESSAGE_SUCCESS,
  SIZE_PER_PAGE,
} from "../../config/AppConstant";
import {
  createUnBookableDate,
  deleteUnBookableDate,
  editUnBookableDate,
  retrieveUnBookableDates,
} from "../../redux/actions/unBookableDatesActions";
import { showMessage } from "../../utils/app-utils";
import UpdateUnBookableDate from "./UpdateUnBookableDate";

const UnBookableDateList = (props) => {
  const { t } = useTranslation();
  const { unBookableDates } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: SIZE_PER_PAGE,
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(retrieveUnBookableDates(params));
  }, [dispatch, endDate, startDate]);

  const onUnBookableDateSelectHandler = (publicId) => {
    let unBookableDate = {
      publicId: "",
      date: "",
      active: false,
    };
    if (publicId) {
      unBookableDate = unBookableDates.data.find(
        (obj) => obj.publicId === publicId
      );
    }
    props.setUnBookableDate(unBookableDate);
  };

  const removeUnBookableDate = (publicId) => {
    dispatch(deleteUnBookableDate(publicId));
    props.setUnBookableDate(null);
  };

  const actionFormatter = (cell, row, rowIndex) => {
    return (
      <div className="table-action text-center">
        <Link
          to={"#"}
          onClick={() => onUnBookableDateSelectHandler(row?.publicId)}
        >
          <FontAwesomeIcon
            icon={faPen}
            fixedWidth
            className="align-middle mr-1"
          />
        </Link>
        <Link to={"#"} onClick={() => removeUnBookableDate(row?.publicId)}>
          <FontAwesomeIcon icon={faTrash} fixedWidth className="align-middle" />
        </Link>
      </div>
    );
  };

  const dateFormatter = (cell, row, rowIndex) => {
    return (
      <div>
        {row?.date} ({moment(row?.date, "YYYY-MM-DD").fromNow()})
      </div>
    );
  };

  const handleTableChange = (type, newState) => {
    const { page, sizePerPage, filters, sortField, sortOrder } = newState;
    const params = {
      pageNo: page ? page - 1 : "",
      pageSize: sizePerPage ? sizePerPage : "",
      sortBy: sortField ? sortField : "",
      sortDir: sortOrder ? sortOrder : "",
    };

    for (const dataField in filters) {
      const { filterVal } = filters[dataField];
      params[dataField] = filterVal;
    }
    dispatch(retrieveUnBookableDates(params));
  };

  const headerFormatter = (
    column,
    colIndex,
    { sortElement, filterElement }
  ) => {
    return (
      <div>
        <div>
          {column.text}
          {sortElement}
        </div>
        <div style={{ display: "grid", marginTop: "10px" }}>
          {filterElement}
        </div>
      </div>
    );
  };

  const tableColumns = [
    {
      dataField: "publicId",
      text: "",
      hidden: true,
    },
    {
      dataField: "date",
      text: t("date"),
      sort: true,
      formatter: dateFormatter,
      headerFormatter: headerFormatter,
    },
    {
      dataField: "description",
      text: t("description"),
      sort: false,
      headerFormatter: headerFormatter,
    },
    {
      dataField: "actions",
      text: "",
      formatter: actionFormatter,
      headerFormatter: headerFormatter,
    },
  ];

  return (
    <Card>
      <CardHeader>
        <div className="card-actions float-right">
          <Button
            color="primary"
            className="mr-1 mb-1"
            onClick={() => onUnBookableDateSelectHandler(null)}
          >
            <FontAwesomeIcon icon={faPlus} /> {t("add")}
          </Button>
        </div>
        <CardTitle tag="h5" className="mb-0">
          {t("un_bookable_dates")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={3} lg={3} xl={3}>
            <FormGroup>
              <Label for="year_picker">{t("year_picker")}</Label>
              <DateTime
                inputProps={{
                  readOnly: true,
                  placeholder: t("select"),
                }}
                className="effectDatePicker"
                name="yearPicker"
                dateFormat={"YYYY"}
                timeFormat={false}
                closeOnSelect
                onChange={(date) => {
                  const selectedYear = date.year();
                  const startDate = selectedYear + "-01-01";
                  const endDate = selectedYear + "-12-31";
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <BootstrapTable
          condensed
          keyField="publicId"
          data={unBookableDates.data}
          remote={true}
          columns={tableColumns}
          striped
          hover
          bootstrap4
          bordered={false}
          filter={filterFactory()}
          pagination={paginationFactory({
            sizePerPage: unBookableDates.sizePerPage,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true,
            totalSize: unBookableDates.totalSize,
          })}
          onTableChange={handleTableChange}
          loading={unBookableDates.isLoading}
          noDataIndication={() => {
            return <EmptyData isLoading={unBookableDates.isLoading} />;
          }}
        ></BootstrapTable>
      </CardBody>
    </Card>
  );
};

const UnBookableDates = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [unBookableDate, setUnBookableDate] = useState(null);
  const { messages } = useSelector((state) => state);
  const { message } = messages;
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      if (message.type === "success") {
        showMessage({
          type: MESSAGE_SUCCESS,
          message: message.detail,
        });
        setUnBookableDate(null);
      }
    } else {
      isMounted.current = true;
    }
  }, [messages, message]);

  const handleOnSubmit = (unBookableDate) => {
    if (unBookableDate.publicId) {
      dispatch(editUnBookableDate(unBookableDate));
    } else {
      dispatch(createUnBookableDate(unBookableDate));
    }
  };

  return (
    <Container fluid>
      <Header>
        <div className="effektToggle">
          <NavbarToggle />
          <HeaderTitle>{t("un_bookable_dates")}</HeaderTitle>
        </div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">{t("dashboard")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("un_bookable_dates")}</BreadcrumbItem>
        </Breadcrumb>
        <NavbarDropdowns />
      </Header>

      <Row>
        <Col>
          <ErrorBoundary
            fallbackRender={() => {}}
            onError={() => {
              showMessage({
                type: MESSAGE_ERROR,
                message: t("unBookableDates_fallback_msg"),
              });
            }}
          >
            <UnBookableDateList setUnBookableDate={setUnBookableDate} />
          </ErrorBoundary>
        </Col>
        {unBookableDate && (
          <Col className="col-md-3 col-xxl-3">
            <ErrorBoundary
              fallbackRender={() => {}}
              onError={() => {
                showMessage({
                  type: MESSAGE_ERROR,
                  message: t("customer_fallback_msg"),
                });
              }}
            >
              <UpdateUnBookableDate
                unBookableDate={unBookableDate}
                setUnBookableDate={setUnBookableDate}
                handleOnSubmit={handleOnSubmit}
              />
            </ErrorBoundary>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default UnBookableDates;
