import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
} from "reactstrap";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fullcalendar/react/dist/vdom";
import DateTime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";

import { useTranslation } from "react-i18next";
import "tippy.js/dist/tippy.css";
import {
  getObjectQualityCheckRatingReport,
  getQuestionQualityCheckRatingReport,
} from "../../redux/actions/dashboardActions";
import { getOption } from "../../utils/app-utils";
import YearlyTable from "./YearlyTable";

const CustomerQualityAndQuestionRating = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dashboard } = useSelector((state) => state);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [reportType, setReportType] = useState("all");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // useEffect(() => {
  //   dispatch(getObjectQualityCheckRatingReport());
  // }, [dispatch]);

  useEffect(() => {
    const params = {
      customerPublicId: selectedCustomer,
      startDate: startDate,
      endDate: endDate,
    };
    if (selectedCustomer && selectedCustomer !== "clear") {
      if (reportType === "all") {
        dispatch(getObjectQualityCheckRatingReport(params));
        dispatch(getQuestionQualityCheckRatingReport(params));
      } else if (reportType === "quality")
        dispatch(getObjectQualityCheckRatingReport(params));
      else dispatch(getQuestionQualityCheckRatingReport(params));
    }
  }, [dispatch, endDate, reportType, selectedCustomer, startDate]);

  const options = props.customers?.map((customer) => getOption(customer));

  const reportOptions = [
    { value: "all", label: t("all") },
    { value: "quality", label: t("quality_control_rating") },
    { value: "question", label: t("question_rating") },
  ];

  const handleCustomerDropDownChange = (customer) => {
    if (customer) {
      const value = customer?.value?.split("|");
      setSelectedCustomer(value[0]);
    }
  };

  const handleReportTypeDropDownChange = (reportType) => {
    if (reportType) {
      setReportType(reportType?.value);
    }
  };

  let qualityCheckRatingReportData = null;
  let questionRatingReportData = null;
  let qualityCheckRatingReportTitle = "";
  let questionRatingReportTitle = "";
  let columnSpan = 6;
  if (reportType === "all") {
    qualityCheckRatingReportData = dashboard?.objectQualityCheckRating;
    questionRatingReportData = dashboard?.questionQualityCheckRating;
    qualityCheckRatingReportTitle = t("customer_quality_checks_rating");
    questionRatingReportTitle = t("customer_question_rating");
  } else if (reportType === "quality") {
    qualityCheckRatingReportData = dashboard?.objectQualityCheckRating;
    qualityCheckRatingReportTitle = t("customer_quality_checks_rating");
    columnSpan = 12;
  } else {
    questionRatingReportData = dashboard?.questionQualityCheckRating;

    questionRatingReportTitle = t("customer_question_rating");
    columnSpan = 12;
  }
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          {t("object_quality_and_question_rating_report")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={3} lg={3} xl={3}>
            <FormGroup>
              <Label for="select_customer">{t("select_customer")}</Label>
              <ReactSelect
                className="calenderSelect react-select-container"
                classNamePrefix="react-select"
                options={options}
                onChange={handleCustomerDropDownChange}
                placeholder={t("select")}
              />
            </FormGroup>
          </Col>
          <Col md={3} lg={3} xl={3}>
            <FormGroup>
              <Label for="select_report_type">{t("select_report_type")}</Label>
              <ReactSelect
                key={t("quality_control_rating")}
                className="calenderSelect react-select-container"
                classNamePrefix="react-select"
                options={reportOptions}
                defaultValue={{
                  value: "all",
                  label: t("all"),
                }}
                onChange={handleReportTypeDropDownChange}
                placeholder={t("select")}
              />
            </FormGroup>
          </Col>
          <Col md={3} lg={3} xl={3}>
            <FormGroup>
              <Label for="year_picker">{t("year_picker")}</Label>
              <DateTime
                inputProps={{
                  readOnly: true,
                  placeholder: t("select"),
                }}
                className="effectDatePicker"
                name="yearPicker"
                dateFormat={"YYYY"}
                timeFormat={false}
                closeOnSelect
                onChange={(date) => {
                  const selectedYear = date.year();
                  const startDate = selectedYear + "-01-01";
                  const endDate = selectedYear + "-12-31";
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12} xl={12} className="mt-3">
            {selectedCustomer ? (
              <Row>
                {qualityCheckRatingReportData && (
                  <Col md={columnSpan}>
                    <YearlyTable
                      data={qualityCheckRatingReportData}
                      title={qualityCheckRatingReportTitle}
                      handleTableChange={""}
                      isLoading={dashboard?.isCustomerQualityCheckProcessing}
                    />
                  </Col>
                )}
                {questionRatingReportData && (
                  <Col md={columnSpan}>
                    <YearlyTable
                      data={questionRatingReportData}
                      title={questionRatingReportTitle}
                      handleTableChange={""}
                      isLoading={dashboard?.isCustomerQuestionCheckProcessing}
                    />
                  </Col>
                )}
              </Row>
            ) : (
              <div className="text-center mt-4">
                {t("select_customer_to_view_report")}
              </div>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default CustomerQualityAndQuestionRating;
