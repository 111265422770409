import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvForm } from "availity-reactstrap-validation";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { X } from "react-feather";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormGroup,
} from "reactstrap";
import defaultLogo from "../../assets/img/avatars/default-logo.jpg";
import { uploadAdressDocument } from "../../redux/actions/addressActions";
import { base64ToArrayBuffer } from "../../utils/app-utils";

const UpdateDocument = (props) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [addressId, setAddressId] = useState(null);

  const [logo, setLogo] = useState({
    image_file: null,
    image_preview: "",
  });

  useEffect(() => {
    props.document?.publicId && setAddressId(props.document?.publicId);
    if (props.document?.logo) {
      const imageArray = base64ToArrayBuffer(props.document?.logo);
      const blob = new Blob([imageArray], {
        type: "image/jpeg",
      });
      const image_as_base64 = URL.createObjectURL(blob);
      setLogo({
        image_file: blob,
        image_preview: image_as_base64,
      });
    } else {
      setLogo({
        image_file: null,
        image_preview: "",
      });
    }
  }, [props]);

  const { image_file, image_preview } = logo;

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const document = {
      addressId,
      image_file,
    };
    if (addressId) {
      dispatch(uploadAdressDocument(document));
    }
  };

  const handleImagePreview = (event) => {
    let image_as_base64 = URL.createObjectURL(event.target.files[0]);
    let image_as_files = event.target.files[0];
    setLogo({
      image_preview: image_as_base64,
      image_file: image_as_files,
    });
  };

  return (
    <Card>
      <CardHeader>
        <div className="card-actions float-right">
          <span className="cursor-pointer mr-1">
            <X onClick={() => props.setDocument(null)} />
          </span>{" "}
        </div>
        <CardTitle tag="h5" className="mb-0">
          {t("upload_document")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <AvForm onValidSubmit={handleOnSubmit}>
          <FormGroup>
            <input
              ref={inputRef}
              type="file"
              onChange={handleImagePreview}
              accept="application/pdf"
            />
          </FormGroup>
          <Button color="primary">
            <FontAwesomeIcon icon={faUpload} /> {t("upload")}
          </Button>
        </AvForm>
      </CardBody>
    </Card>
  );
};

export default UpdateDocument;
