import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CancelledPopUp = (props) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={props.openCancelPopUP}
      toggle={() => props.setOpenCancelPopUP(!props.openCancelPopUP)}
      centered
    >
      <ModalHeader
        toggle={() => props.setOpenCancelPopUP(!props.openCancelPopUP)}
      >
        {props.header}
      </ModalHeader>
      <ModalBody className="m-3">{props.children}</ModalBody>
      <ModalFooter>
        <Button color="cancel" form={props.formId}>
          {t("cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

CancelledPopUp.propTypes = {
  openCancelPopUP: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  setOpenCancelPopUP: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

export default CancelledPopUp;
