import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ConfirmationModal = (props) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={props.openConfirmationModal}
      toggle={() =>
        props.setOpenConfirmationModal(!props.openConfirmationModal)
      }
      centered
      size="md"
    >
      <ModalHeader
        toggle={() =>
          props.setOpenConfirmationModal(!props.openConfirmationModal)
        }
      >
        {props.header}
      </ModalHeader>
      <ModalBody className="m-3">{props.message}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={props.handleOnSubmit}>
          {t("yes")}
        </Button>
        <Button
          color="secondary"
          onClick={() =>
            props.setOpenConfirmationModal(!props.openConfirmationModal)
          }
        >
          {t("no")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  openConfirmationModal: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  setOpenConfirmationModal: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  handleOnSubmit: PropTypes.func.isRequired,
};

export default ConfirmationModal;
