import {
  CREATE_FOLLOW_UP_QUALITY_CHECK,
  RETRIEVE_FOLLOW_UP_QUALITY_CHECKS,
} from "../constants";

const initialState = {
  data: [],
  page: 0,
  sizePerPage: 0,
  totalSize: 0,
  isLoading: true,
};

export default function reducer(state = initialState, actions) {
  const { type, payload } = actions;
  switch (type) {
    case CREATE_FOLLOW_UP_QUALITY_CHECK:
      return {
        ...state,
        data: [...state.data, { ...payload }],
        isLoading: false,
      };
    case RETRIEVE_FOLLOW_UP_QUALITY_CHECKS:
      return {
        ...state,
        data: payload.content,
        page: payload.pageNo,
        sizePerPage: payload.pageSize,
        totalSize: payload.totalElements,
        isLoading: false,
      };
    default:
      return state;
  }
}
