import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import DateTime from "react-datetime";
import { X } from "react-feather";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { DATE_FORMAT, MESSAGE_ERROR } from "../../config/AppConstant";
import { showMessage, validateInput } from "../../utils/app-utils";

const UpdateUnBookableDate = (props) => {
  const [unBookableDate, setUnBookableDate] = useState({
    publicId: "",
    date: "",
    description: "",
  });

  useEffect(() => {
    props.unBookableDate && setUnBookableDate(props.unBookableDate);
  }, [props.unBookableDate]);

  const { publicId, date, description } = unBookableDate;

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const values = [date, description];

    if (validateInput(values)) {
      const unBookableDate = {
        publicId,
        date,
        description,
      };
      props.handleOnSubmit(unBookableDate);
    } else {
      showMessage({
        type: MESSAGE_ERROR,
        message: t("missing_information"),
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUnBookableDate((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const dateTimeLocale =
    localStorage.getItem("i18nextLng") === "SE" ? "sv" : "en-gb";

  return (
    <Card>
      <CardHeader>
        <div className="card-actions float-right">
          <span className="cursor-pointer mr-1">
            <X onClick={() => props.setUnBookableDate(null)} />
          </span>{" "}
        </div>
        <CardTitle tag="h5" className="mb-0">
          {t("un_bookable_date")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <AvForm onValidSubmit={handleOnSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <Label for="start">{t("start_date")}</Label>
                <div className="align-self-center w-100">
                  <DateTime
                    inputProps={{
                      readOnly: true,
                      placeholder: t("date"),
                    }}
                    className="effectDatePicker"
                    name="date"
                    dateFormat={DATE_FORMAT}
                    locale={dateTimeLocale}
                    timeFormat={false}
                    defaultValue={DateTime.moment.utc()}
                    value={date && DateTime.moment.utc(date)}
                    utc={true}
                    onChange={(date) =>
                      setUnBookableDate((prevState) => ({
                        ...prevState,
                        date: date.toISOString(),
                      }))
                    }
                    closeOnSelect
                  />
                </div>
              </FormGroup>
              <AvGroup>
                <Label for="description">{t("description")}</Label>
                <AvInput
                  required
                  type="textarea"
                  rows="2"
                  name="description"
                  id="description"
                  placeholder={t("description")}
                  value={description}
                  onChange={handleInputChange}
                />
                <AvFeedback>{t("missing_description")}</AvFeedback>
              </AvGroup>
            </Col>
          </Row>

          <Button color="primary">
            {publicId ? t("update") : t("create")}
          </Button>
        </AvForm>
      </CardBody>
    </Card>
  );
};

export default UpdateUnBookableDate;
