import { URL_UN_BOOKABLE_DATE } from "../config/AppConstant";
import http from "../utils/http-common";

class UnBookableDateDataService {
  getAll(params) {
    return http.get(URL_UN_BOOKABLE_DATE, { params });
  }

  get(id) {
    return http.get(`${URL_UN_BOOKABLE_DATE}/${id}`);
  }

  create(data) {
    return http.post(URL_UN_BOOKABLE_DATE, data);
  }

  update(data) {
    return http.put(`${URL_UN_BOOKABLE_DATE}`, data);
  }

  delete(id) {
    return http.delete(`${URL_UN_BOOKABLE_DATE}/${id}`);
  }
}

export default new UnBookableDateDataService();
