import {
  CREATE_UN_BOOKABLE_DATE,
  DELETE_ALL_UN_BOOKABLE_DATES,
  DELETE_UN_BOOKABLE_DATE,
  RETRIEVE_UN_BOOKABLE_DATES,
  RETRIEVE_UN_BOOKABLE_DATE,
  UPDATE_UN_BOOKABLE_DATE,
} from "../constants";

const initialState = {
  data: [],
  page: 0,
  sizePerPage: 0,
  totalSize: 0,
  isLoading: true,
};

export default function reducer(state = initialState, actions) {
  const { type, payload } = actions;
  switch (type) {
    case CREATE_UN_BOOKABLE_DATE:
      return {
        ...state,
        data: [...state.data, payload],
        isLoading: false,
      };
    case RETRIEVE_UN_BOOKABLE_DATES:
      return {
        ...state,
        data: payload.content,
        page: payload.pageNo,
        sizePerPage: payload.pageSize,
        totalSize: payload.totalElements,
        isLoading: false,
      };
    case RETRIEVE_UN_BOOKABLE_DATE:
      return {
        ...state,
        data: [payload],
        isLoading: false,
      };
    case UPDATE_UN_BOOKABLE_DATE:
      return {
        ...state,
        data: state.data.map((unBookableDate) => {
          if (unBookableDate.publicId === payload.publicId) {
            return {
              ...unBookableDate,
              ...payload,
            };
          } else {
            return unBookableDate;
          }
        }),
        isLoading: false,
      };

    case DELETE_UN_BOOKABLE_DATE:
      return {
        ...state,
        data: state.data.filter(({ publicId }) => publicId !== payload.publicId),
        isLoading: false,
      };

    case DELETE_ALL_UN_BOOKABLE_DATES:
      return [];
    default:
      return state;
  }
}
