import {
  CREATE_QUALITY_CHECK,
  DELETE_ALL_QUALITY_CHECKS,
  DELETE_QUALITY_CHECK,
  RETRIEVE_QUALITY_CHECK,
  RETRIEVE_QUALITY_CHECKS,
  RETRIEVE_QUALITY_CHECK_REPORT,
  UPDATE_QUALITY_CHECK,
} from "../constants";

const initialState = {
  data: [],
  detail: [],
  page: 0,
  sizePerPage: 0,
  totalSize: 0,
  isLoading: true,
  isLoadingReport: false,
};

export default function reducer(state = initialState, actions) {
  const { type, payload } = actions;
  switch (type) {
    case RETRIEVE_QUALITY_CHECK_REPORT:
      return {
        ...state,
        isLoadingReport: payload,
      };
    case CREATE_QUALITY_CHECK:
      return {
        ...state,
        data: [...state.data, { ...payload }],
        isLoading: false,
      };
    case RETRIEVE_QUALITY_CHECKS:
      return {
        ...state,
        data: payload.content,
        page: payload.pageNo,
        sizePerPage: payload.pageSize,
        totalSize: payload.totalElements,
        isLoading: false,
      };
    case RETRIEVE_QUALITY_CHECK:
      return {
        ...state,
        detail: [payload],
        isLoading: false,
      };
    case UPDATE_QUALITY_CHECK:
      return {
        ...state,
        data: state.data.map((qualityCheck) => {
          if (qualityCheck.publicId === payload.publicId) {
            return {
              ...qualityCheck,
              ...payload,
            };
          } else {
            return qualityCheck;
          }
        }),
        isLoading: false,
      };

    case DELETE_QUALITY_CHECK:
      return {
        ...state,
        data: state.data.filter(
          ({ publicId }) => publicId !== payload.publicId
        ),
        isLoading: false,
      };

    case DELETE_ALL_QUALITY_CHECKS:
      return [];
    default:
      return state;
  }
}
