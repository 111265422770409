import DashboardDataService from "../../services/dashboardService";
import {
  CUSTOMER_QUALITY_CHECK_REPORT_IN_PROCESSING,
  CUSTOMER_QUESTION_REPORT_IN_PROCESSING,
  DASHBOARD_REPORT1_IN_PROCESSING,
  DASHBOARD_REPORT2_IN_PROCESSING,
  RETRIEVE_DASHBOARD,
  RETRIEVE_DASHBOARD_REPORT1,
  RETRIEVE_DASHBOARD_REPORT2,
  RETRIEVE_OBJECT_QUALITY_CHECK_RATING_REPORT,
  RETRIEVE_QUALITY_CHECK_RATING_REPORT,
  RETRIEVE_QUESTION_QUALITY_CHECK_RATING_REPORT,
  RETRIEVE_TEAM_LEAD_QUALITY_CHECK_RATING_REPORT,
  RETRIEVE_TEAM_LEAD_QUESTION_QUALITY_CHECK_RATING_REPORT,
  TEAM_LEAD_QUALITY_CHECK_REPORT_IN_PROCESSING,
  TEAM_LEAD_QUESTION_REPORT_IN_PROCESSING,
} from "../constants";

export const getDashboard = (publicId) => async (dispatch) => {
  try {
    const res = await DashboardDataService.get(publicId);
    dispatch({
      type: RETRIEVE_DASHBOARD,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getDashboardReport1 = () => async (dispatch) => {
  try {
    const res = await DashboardDataService.getReport1();
    dispatch({
      type: RETRIEVE_DASHBOARD_REPORT1,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getDashboardReport2 = (params) => async (dispatch) => {
  try {
    dispatch({
      type: DASHBOARD_REPORT2_IN_PROCESSING,
      payload: true,
    });
    const res = await DashboardDataService.getReport2(params);
    dispatch({
      type: DASHBOARD_REPORT2_IN_PROCESSING,
      payload: false,
    });
    dispatch({
      type: RETRIEVE_DASHBOARD_REPORT2,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getQualityCheckRatingReport = (params) => async (dispatch) => {
  try {
    dispatch({
      type: DASHBOARD_REPORT1_IN_PROCESSING,
      payload: true,
    });
    const res = await DashboardDataService.getQualityCheckRatingReport(params);
    dispatch({
      type: DASHBOARD_REPORT1_IN_PROCESSING,
      payload: false,
    });
    dispatch({
      type: RETRIEVE_QUALITY_CHECK_RATING_REPORT,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getObjectQualityCheckRatingReport =
  (params) => async (dispatch) => {
    try {
      dispatch({
        type: CUSTOMER_QUALITY_CHECK_REPORT_IN_PROCESSING,
        payload: true,
      });
      const res = await DashboardDataService.getObjectQualityCheckRatingReport(
        params
      );
      dispatch({
        type: RETRIEVE_OBJECT_QUALITY_CHECK_RATING_REPORT,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const getQuestionQualityCheckRatingReport =
  (params) => async (dispatch) => {
    try {
      dispatch({
        type: CUSTOMER_QUESTION_REPORT_IN_PROCESSING,
        payload: true,
      });
      const res =
        await DashboardDataService.getQuestionQualityCheckRatingReport(params);
      dispatch({
        type: RETRIEVE_QUESTION_QUALITY_CHECK_RATING_REPORT,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const getTeamLeadQualityCheckRatingReport =
  (params) => async (dispatch) => {
    try {
      dispatch({
        type: TEAM_LEAD_QUALITY_CHECK_REPORT_IN_PROCESSING,
        payload: true,
      });
      const res =
        await DashboardDataService.getTeamLeadQualityCheckRatingReport(params);
      dispatch({
        type: RETRIEVE_TEAM_LEAD_QUALITY_CHECK_RATING_REPORT,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const getTeamLeadQuestionQualityCheckRatingReport =
  (params) => async (dispatch) => {
    try {
      dispatch({
        type: TEAM_LEAD_QUESTION_REPORT_IN_PROCESSING,
        payload: true,
      });
      const res =
        await DashboardDataService.getTeamLeadQuestionQualityCheckRatingReport(
          params
        );
      dispatch({
        type: RETRIEVE_TEAM_LEAD_QUESTION_QUALITY_CHECK_RATING_REPORT,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
