import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fullcalendar/react/dist/vdom";
import DateTime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";

import "tippy.js/dist/tippy.css";
import { retrieveAgreementAddresses } from "../../redux/actions/addressActions";
import { retrieveCustomerAgreements } from "../../redux/actions/agreementActions";
import { getQualityCheckRatingReport } from "../../redux/actions/dashboardActions";
import {
  capitalizeFirstLetter,
  getAddressOption,
  getEmployeeOption,
  getOption,
} from "../../utils/app-utils";
import ColumnWithLabel from "../dashboards/Default/ColumnWithLabel";
import {
  retrieveCustomerTeamLeads,
  retrieveTeamLeadCustomers,
} from "../../redux/actions/customerActions";
import { retrieveEmployees } from "../../redux/actions/employeeActions";

const QualityRating = (props) => {
  const { customers } = useSelector((state) => state);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dashboard, addresses, agreements, employees } = useSelector(
    (state) => state
  );
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedTeamLead, setSelectedTeamLead] = useState(null);
  const [disableDropDown, setDisableDropDown] = useState(false);
  const [showTeamLeadCustomers, setShowTeamLeadCustomers] = useState(false);
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const date = new Date();
  const curentYear = date?.getUTCFullYear();
  const startDateValue = curentYear + "-01-01";
  const endDateValue = curentYear + "-12-31";
  const [startDate, setStartDate] = useState(startDateValue);
  const [endDate, setEndDate] = useState(endDateValue);

  useEffect(() => {
    const params = {
      status: true,
      type: "TEAM_LEAD",
    };
    dispatch(retrieveEmployees(params, true));
  }, [dispatch]);

  useEffect(() => {
    const params = {
      customerPublicId:
        selectedCustomer === "clear" ? undefined : selectedCustomer,
      agreementPublicId: selectedAgreement?.value,
      addressPublicId: selectedAddress?.value,
      teamLeadPublicId:
        selectedTeamLead === "clear" ? undefined : selectedTeamLead?.value,
      startDate: startDate,
      endDate: endDate,
    };
    if (
      startDate ||
      endDate ||
      (selectedCustomer && selectedCustomer !== "clear") ||
      (selectedAgreement && selectedAgreement !== "clear") ||
      (selectedAddress && selectedAddress !== "clear") ||
      (selectedTeamLead && selectedTeamLead !== "clear")
    )
      dispatch(getQualityCheckRatingReport(params));
  }, [
    dispatch,
    endDate,
    selectedAddress,
    selectedAgreement,
    selectedCustomer,
    selectedTeamLead,
    startDate,
  ]);

  useEffect(() => {
    if (selectedCustomer && selectedCustomer !== "clear")
      dispatch(retrieveCustomerTeamLeads(selectedCustomer));
  }, [selectedCustomer, dispatch]);

  useEffect(() => {
    if (
      showTeamLeadCustomers &&
      selectedTeamLead &&
      selectedTeamLead !== "clear"
    )
      dispatch(retrieveTeamLeadCustomers(selectedTeamLead?.value));
  }, [dispatch, selectedTeamLead, showTeamLeadCustomers]);

  useEffect(() => {
    if (
      selectedTeamLead &&
      selectedTeamLead !== "clear" &&
      selectedCustomer &&
      selectedCustomer !== "clear"
    ) {
      const params = {
        teamLeadPublicId: selectedTeamLead?.value,
      };

      dispatch(retrieveCustomerAgreements(selectedCustomer, params));
    }
  }, [selectedCustomer, dispatch, selectedTeamLead]);

  useEffect(() => {
    if (selectedAgreement && selectedAgreement !== "clear")
      dispatch(retrieveAgreementAddresses(selectedAgreement?.value));
  }, [dispatch, selectedAgreement]);

  const options =
    showTeamLeadCustomers && selectedTeamLead && selectedTeamLead !== "clear"
      ? customers.teamLeadCustomers.map((customer) => getOption(customer))
      : props.customers?.map((customer) => getOption(customer));

  const teamLeadOptions =
    selectedTeamLead === "clear"
      ? employees?.allData?.map((teamLead) => getEmployeeOption(teamLead))
      : selectedCustomer && selectedCustomer !== "clear"
      ? customers.teamLeadData.map((teamLead) => getEmployeeOption(teamLead))
      : employees?.allData?.map((teamLead) => getEmployeeOption(teamLead));

  const agreementOptions =
    selectedAgreement === "clear"
      ? []
      : agreements?.data?.map((agreement) => {
          return {
            label: agreement.agreementNumber,
            value: agreement.publicId,
          };
        });

  const addressOptions =
    selectedAddress === "clear"
      ? []
      : addresses?.data?.map((address) => getAddressOption(address));

  const handleCustomerDropDownChange = (customer) => {
    if (customer) {
      const value = customer?.value?.split("|");
      setSelectedCustomer(value[0]);
      if (selectedTeamLead?.value) {
        setDisableDropDown(true);
        setSelectedAgreement(null);
      } else {
        setSelectedTeamLead(null);
        setSelectedAgreement("clear");
      }

      setSelectedAddress("clear");
    } else {
      setSelectedCustomer("clear");
      setSelectedTeamLead("clear");
      setSelectedAgreement("clear");
      setSelectedAddress("clear");
      setDisableDropDown(false);
    }
  };

  const handleTeamLeadDropDownChange = (teamLead) => {
    if (teamLead) {
      const value = teamLead?.value?.split("|");
      setSelectedTeamLead({
        label: teamLead?.label,
        value: value[0],
      });
      setSelectedAddress("clear");
      if (selectedCustomer === null || selectedCustomer === "clear") {
        setShowTeamLeadCustomers(true);
        setSelectedAgreement("clear");
      } else {
        setSelectedAgreement(null);
      }
    } else {
      setSelectedTeamLead(null);
      setSelectedAgreement("clear");
      setSelectedAddress("clear");
      setShowTeamLeadCustomers(false);
    }
  };

  const handleAgreementDropDownChange = (agreement) => {
    if (agreement) {
      setSelectedAgreement(agreement);
      setSelectedAddress(null);
    } else {
      setSelectedAgreement(null);
      setSelectedAddress("clear");
    }
  };

  const handleAddressDropDownChange = (address) => {
    if (address) {
      setSelectedAddress(address);
    } else {
      setSelectedAddress(null);
    }
  };

  let columnLabelChartSeries = [];
  if (dashboard?.qualityCheckRating) {
    let columnLabelChartData = {
      name: t("quality_checks_rating"),
      data: dashboard?.qualityCheckRating?.map((rat) => {
        return {
          x: capitalizeFirstLetter(t(rat["x"].toLowerCase())),
          y: t(rat["y"]),
        };
      }),
    };
    columnLabelChartSeries.push(columnLabelChartData);
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          {t("quality_checks_rating")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={3} lg={3} xl={3}>
            <FormGroup>
              <Label for="filter_by_customer">{t("filter_by_customer")}</Label>
              <ReactSelect
                className="calenderSelect react-select-container"
                classNamePrefix="react-select"
                options={options}
                onChange={handleCustomerDropDownChange}
                placeholder={t("select")}
                isClearable
              />
            </FormGroup>
          </Col>
          <Col md={2} lg={2} xl={2}>
            <FormGroup>
              <Label for="filter_by_team_lead">
                {t("filter_by_team_lead")}
              </Label>
              <ReactSelect
                className="calenderSelect react-select-container"
                classNamePrefix="react-select"
                options={teamLeadOptions}
                onChange={handleTeamLeadDropDownChange}
                placeholder={t("select")}
                value={selectedTeamLead ? selectedTeamLead : ""}
                isDisabled={disableDropDown}
                isClearable
              />
            </FormGroup>
          </Col>
          <Col md={2} lg={2} xl={2}>
            <FormGroup>
              <Label for="filter_by_agreement">
                {t("filter_by_agreement")}
              </Label>
              <ReactSelect
                className="calenderSelect react-select-container"
                classNamePrefix="react-select"
                options={agreementOptions}
                onChange={handleAgreementDropDownChange}
                placeholder={t("select")}
                value={selectedAgreement ? selectedAgreement : ""}
                isClearable
              />
            </FormGroup>
          </Col>
          <Col md={3} lg={3} xl={3}>
            <FormGroup>
              <Label for="filter_by_address">{t("filter_by_address")}</Label>
              <ReactSelect
                className="calenderSelect react-select-container"
                classNamePrefix="react-select"
                options={addressOptions}
                onChange={handleAddressDropDownChange}
                placeholder={t("select")}
                value={selectedAddress ? selectedAddress : ""}
                isClearable
              />
            </FormGroup>
          </Col>
          <Col md={2} lg={2} xl={2}>
            <FormGroup>
              <Label for="year_picker">{t("year_picker")}</Label>
              <DateTime
                inputProps={{
                  readOnly: true,
                  placeholder: t("select"),
                }}
                initialValue={new Date()}
                className="effectDatePicker"
                name="yearPicker"
                dateFormat={"YYYY"}
                timeFormat={false}
                closeOnSelect
                onChange={(date) => {
                  const selectedYear = date.year();
                  const startDate = selectedYear + "-01-01";
                  const endDate = selectedYear + "-12-31";
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12} xl={12} className="mt-3">
            {dashboard.isReport1Loading ? (
              <div className="text-center">
                <Spinner />
              </div>
            ) : (
              <ColumnWithLabel
                data={columnLabelChartSeries}
                title=""
                border={true}
                height={300}
              />
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default QualityRating;
