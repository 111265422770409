import { URL_EGEN_QUESTIONNAIRE } from "../config/AppConstant";
import http from "../utils/http-common";

class EgenQuestionnairesDataService {
  getCategories(params) {
    return http.get(`${URL_EGEN_QUESTIONNAIRE}/category`, { params });
  }

  createCategory(data) {
    return http.post(URL_EGEN_QUESTIONNAIRE, data);
  }

  getAll(categoryId, params) {
    return http.get(`${URL_EGEN_QUESTIONNAIRE}/list/${categoryId}`, { params });
  }

  get(id) {
    return http.get(`${URL_EGEN_QUESTIONNAIRE}/${id}`);
  }

  create(data) {
    return http.post(URL_EGEN_QUESTIONNAIRE, data);
  }

  update(id, data) {
    return http.put(`${URL_EGEN_QUESTIONNAIRE}/${id}`, data);
  }

  delete(id) {
    return http.delete(`${URL_EGEN_QUESTIONNAIRE}/${id}`);
  }
}

export default new EgenQuestionnairesDataService();
