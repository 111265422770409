import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  CustomInput,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { MESSAGE_ERROR } from "../../config/AppConstant";
import { showMessage, validateInput } from "../../utils/app-utils";

const UpdateQuestionnaire = (props) => {
  const [questionnaire, setQuestionnaire] = useState({
    publicId: "",
    name: "",
    active: false,
  });

  useEffect(() => {
    props.questionnaire && setQuestionnaire(props.questionnaire);
  }, [props.questionnaire]);

  const { publicId, name, active } = questionnaire;

  const readOnly = props.readOnly;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setQuestionnaire((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckChange = (event) => {
    const { name, checked } = event.target;
    setQuestionnaire((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const values = [name];

    if (validateInput(values)) {
      const questionnaire = {
        publicId,
        name,
        active,
      };
      props.handleOnSubmit(questionnaire);
    } else {
      showMessage({
        type: MESSAGE_ERROR,
        message: t("missing_information"),
      });
    }
  };

  return (
    <Card>
      <CardHeader>
        <div className="card-actions float-right">
          <span className="cursor-pointer mr-1">
            <X onClick={() => props.setQuestionnaire(null)} />
          </span>{" "}
        </div>
        <CardTitle tag="h5" className="mb-0">
          {t("questionnaire")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <AvForm onValidSubmit={handleOnSubmit}>
          <Row>
            <Col>
              <AvGroup>
                <Label for="name">{t("question")}</Label>
                <AvInput
                  required
                  type="textarea"
                  rows="2"
                  name="name"
                  id="name"
                  placeholder={t("question_information")}
                  value={name}
                  readOnly={readOnly}
                  onChange={handleInputChange}
                />
                <AvFeedback>{t("missing_question")}</AvFeedback>
              </AvGroup>
              <FormGroup>
                <CustomInput
                  type="checkbox"
                  name="active"
                  id="checkbox"
                  value={active}
                  checked={active}
                  label={t("active")}
                  onChange={handleCheckChange}
                />
              </FormGroup>
            </Col>
          </Row>

          <Button color="primary">
            {publicId ? t("update") : t("create")}
          </Button>
        </AvForm>
      </CardBody>
    </Card>
  );
};

export default UpdateQuestionnaire;
