import { t } from "i18next";
import QualityCheckDataService from "../../services/qualityCheckService";
import {
  CREATE_QUALITY_CHECK,
  DELETE_QUALITY_CHECK,
  MESSAGE_FAILURE,
  MESSAGE_SUCCESS,
  RETRIEVE_QUALITY_CHECK,
  RETRIEVE_QUALITY_CHECKS,
  RETRIEVE_QUALITY_CHECK_REPORT,
  UPDATE_QUALITY_CHECK,
} from "../constants";

export const retrieveQualityChecks = (params) => async (dispatch) => {
  try {
    const res = await QualityCheckDataService.getAll(params);
    dispatch({
      type: RETRIEVE_QUALITY_CHECKS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getQualityCheck = (publicId) => async (dispatch) => {
  try {
    const res = await QualityCheckDataService.get(publicId);
    dispatch({
      type: RETRIEVE_QUALITY_CHECK,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getQualityCheckReport = (publicId) => async (dispatch) => {
  try {
    dispatch({
      type: RETRIEVE_QUALITY_CHECK_REPORT,
      payload: true,
    });
    const res = await QualityCheckDataService.getReport(publicId);
    dispatch({
      type: RETRIEVE_QUALITY_CHECK_REPORT,
      payload: false,
    });
    const type = res.headers["content-type"];
    const blob = new Blob([res.data], { type: type, encoding: "UTF-8" });
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
  } catch (err) {
    console.log(err);
  }
};

export const deleteQualityCheck = (publicId) => async (dispatch) => {
  try {
    await QualityCheckDataService.delete(publicId);

    dispatch({
      type: DELETE_QUALITY_CHECK,
      payload: { publicId },
    });
  } catch (err) {
    console.log(err);
  }
};

export const editQualityCheck = (qualityCheck) => async (dispatch) => {
  try {
    const res = await QualityCheckDataService.update(qualityCheck);

    dispatch({
      type: UPDATE_QUALITY_CHECK,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const editQualityCheckPerformedDate =
  (qualityCheck) => async (dispatch) => {
    try {
      const res = await QualityCheckDataService.updatePerformedDate(
        qualityCheck
      );

      dispatch({
        type: UPDATE_QUALITY_CHECK,
        payload: res.data,
      });
      dispatch({
        type: MESSAGE_SUCCESS,
        message: t("performed_date_updated"),
      });
    } catch (err) {
      console.log(err);
    }
  };

export const createQualityCheck = (qualityCheck) => async (dispatch) => {
  try {
    const res = await QualityCheckDataService.create(qualityCheck);
    dispatch({
      type: CREATE_QUALITY_CHECK,
      payload: res.data,
    });
    dispatch({
      type: MESSAGE_SUCCESS,
      message: "success",
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: MESSAGE_FAILURE,
      message: "failure",
    });
  }
};
