import {
  faAddressBook,
  faChartPie,
  faCog,
  faFileContract,
  faHome,
  faQuestion,
  faUsers,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import Addresses from "../../pages/address/AddressList";
import Agreements from "../../pages/agreement/AgreementList";
import Chartjs from "../../pages/charts/Chartjs";
import Customers from "../../pages/customer/CustomerList";
import Default from "../../pages/dashboards/Default";
import Clients from "../../pages/pages/Clients";
import Settings from "../../pages/pages/Settings";
import QualityChecks from "../../pages/quality/QualityCheckList";
import EgenControl from "../../pages/egen/EgenControlTabs";
import Report from "../../pages/reports/Report";
import {
  ADDRESSES,
  AGREEMENTS,
  CHARTS,
  CUSTOMERS,
  DASHBOARD,
  EGEN_QUESTIONNAIRES,
  EMPLOYEES,
  PATH_ADDRESSES,
  PATH_AGREEMENTS,
  PATH_CHARTS,
  PATH_CUSTOMERS,
  PATH_DASHBOARD,
  PATH_EGEN_QUESTIONNAIRES,
  PATH_EMPLOYEES,
  PATH_QUALITY_CHECK,
  PATH_EGEN_QUALITY_CHECK,
  PATH_QUESTIONNAIRES,
  PATH_REPORTS,
  PATH_SCHEDULER,
  PATH_SETTINGS,
  PATH_UN_BOOKABLE_DATE,
  QUALITY_CHECKS,
  QUESTIONNAIRES,
  REPORTS,
  SCHEDULER,
  SETTINGS,
  UN_BOOKABLE_DATE,
  EGEN_QUALITY_CHECKS,
} from "../constants";

const dashboardRoutes = {
  path: PATH_DASHBOARD,
  name: DASHBOARD,
  header: "Main",
  icon: faHome,
  component: Default,
  children: null,
};

const customersRoute = {
  path: PATH_CUSTOMERS,
  name: CUSTOMERS,
  component: Customers,
  icon: faUsers,
};

const agreementsRoute = {
  path: PATH_AGREEMENTS,
  name: AGREEMENTS,
  component: Agreements,
  icon: faFileContract,
};

const addressesRoute = {
  path: PATH_ADDRESSES,
  name: ADDRESSES,
  component: Addresses,
  icon: faAddressBook,
};

const qualityChecksRoute = {
  path: PATH_QUALITY_CHECK,
  name: QUALITY_CHECKS,
  component: QualityChecks,
  icon: faCalendarCheck,
};

const egenQualityChecksRoute = {
  path: PATH_EGEN_QUALITY_CHECK,
  name: EGEN_QUALITY_CHECKS,
  component: EgenControl,
  icon: faCalendarCheck,
};
const employeesRoute = {
  path: PATH_EMPLOYEES,
  name: EMPLOYEES,
  component: Clients,
  icon: faUsers,
};

const unBookableDateRoute = {
  path: PATH_UN_BOOKABLE_DATE,
  name: UN_BOOKABLE_DATE,
  component: Clients,
  icon: faCalendarDay,
};

const questionnairesRoute = {
  path: PATH_QUESTIONNAIRES,
  name: QUESTIONNAIRES,
  component: Clients,
  icon: faQuestion,
};

const egenQuestionnairesRoute = {
  path: PATH_EGEN_QUESTIONNAIRES,
  name: EGEN_QUESTIONNAIRES,
  component: Clients,
  icon: faQuestion,
};

const chartRoutes = {
  path: PATH_CHARTS,
  name: CHARTS,
  icon: faChartPie,
  component: Chartjs,
};

const schedularRoutes = {
  path: PATH_SCHEDULER,
  name: SCHEDULER,
  icon: faCalendarAlt,
  component: SCHEDULER,
  children: null,
};

const settingsRoute = {
  path: PATH_SETTINGS,
  name: SETTINGS,
  component: Settings,
  icon: faCog,
};

const reportRoutes = {
  path: PATH_REPORTS,
  name: REPORTS,
  icon: faChartPie,
  component: Report,
  children: null,
};

export const customerRoutes = [qualityChecksRoute, egenQualityChecksRoute];
export const adminRoutes = [
  dashboardRoutes,
  reportRoutes,
  customersRoute,
  agreementsRoute,
  addressesRoute,
  qualityChecksRoute,
  egenQualityChecksRoute,
  employeesRoute,
  unBookableDateRoute,
  questionnairesRoute,
  egenQuestionnairesRoute,
  // chartRoutes,
  schedularRoutes,
  // settingsRoute,
];

const allRoutes = [
  dashboardRoutes,
  reportRoutes,
  customersRoute,
  agreementsRoute,
  addressesRoute,
  qualityChecksRoute,
  egenQualityChecksRoute,
  employeesRoute,
  unBookableDateRoute,
  questionnairesRoute,
  egenQuestionnairesRoute,
  // chartRoutes,
  schedularRoutes,
  // settingsRoute,
];

export default allRoutes;
