// Path for all routes
export const PATH_DASHBOARD = "/dashboard";
export const PATH_CHARTS = "/charts";
export const PATH_CALENDER = "/calendar";
export const PATH_SCHEDULER = "/scheduler";
export const PATH_REPORTS = "/reports";
export const PATH_QUALITY_CHECK = "/quality-check";
export const PATH_QUALITY_CHECK_VIEW = "/quality-check/:id";
export const PATH_EGEN_QUALITY_CHECK = "/egen-quality-check";
export const PATH_EGEN_QUALITY_CHECK_VIEW = "/egen-quality-check/:id";
export const PATH_LANDING = "/";
export const PATH_SETTINGS = "/settings";
export const PATH_CHANGE_PASSWORD = "/change-password";
export const PATH_CUSTOMERS = "/customers";
export const PATH_AGREEMENTS = "/agreements";
export const PATH_AGREEMENT_ADDRESSES = "/agreements/:id/addresses";
export const PATH_AGREEMENT_ADDRESS_CREATE = "/agreements/:id/address/create";
export const PATH_AGREEMENT_ADDRESS_EDIT = "/agreements/:id/address/:addressId";
export const PATH_ADDRESSES = "/addresses";
export const PATH_ADDRESS = "/addresses";
export const PATH_ADDRESS_CREATE = "/addresses/create";
export const PATH_ADDRESS_EDIT = "/addresses/:id";
export const PATH_EMPLOYEES = "/employees";
export const PATH_QUESTIONNAIRES = "/questionnaires";
export const PATH_EGEN_QUESTIONNAIRES = "/egen-questionnaires";
export const PATH_UN_BOOKABLE_DATE = "/un-bookable-date";
export const PATH_AUTH = "/auth";
export const PATH_SIGN_IN = "/auth/sign-in";
export const PATH_SIGN_UP = "/auth/sign-up";
export const PATH_RESET_PASSWORD = "/auth/reset-password";
export const PATH_404 = "/auth/404";
export const PATH_500 = "/auth/500";
export const PATH_EGEN_CONTROL_APPROVE_REJECT =
  "/egenkontroll/action/:id/:egenId";

// Name for all path
export const DASHBOARD = "dashboard";
export const CHARTS = "Charts";
export const CALENDER = "Calendar";
export const SCHEDULER = "scheduler";
export const REPORTS = "Reports";
export const SIGN_IN = "Sign In";
export const SIGN_UP = "Sign Up";
export const SETTINGS = "Settings";
export const CUSTOMERS = "customers";
export const AGREEMENTS = "agreements";
export const ADDRESSES = "addresses";
export const QUALITY_CHECKS = "quality_checks";
export const EGEN_QUALITY_CHECKS = "egen_control";
export const CUSTOMER_CREATE = "Create Customers";
export const CUSTOMER_VIEW = "View Customers";
export const EMPLOYEES = "employees";
export const EMPLOYEE_VIEW = "View Employees";
export const QUESTIONNAIRES = "questionnaires";
export const EGEN_QUESTIONNAIRES = "egen_questionnaires";
export const UN_BOOKABLE_DATE = "un_bookable_date";
export const AUTH = "Auth";
export const RESET_PASSWORD = "Reset Password";
export const PAGE_404 = "404 Page";
export const PAGE_500 = "500 Page";
export const ROLE_EMPLOYEE = "ROLE_EMPLOYEE";
export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_CUSTOMER = "ROLE_CUSTOMER";
export const EGEN_CONTROL_ACTION = "Egen Control Action";
