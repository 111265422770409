import { URL_CHANGE_PASSWORD, URL_RESET_PASSWORD, URL_SIGN_IN } from "../config/AppConstant";
import http from "../utils/http-common";

class AuthService {
  resetPassword(email) {
    return http.post(URL_RESET_PASSWORD, { email });
  }

  changePassword(params) {
    return http.post(URL_CHANGE_PASSWORD, params);
  }

  login(data) {
    return http.post(URL_SIGN_IN, data).then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem(
          "token",
          JSON.stringify(response.data.accessToken)
        );
      }
      if (response.data.roles) {
        localStorage.setItem("roles", JSON.stringify(response.data.roles));
      }

      return response.data;
    });
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
  }
}

export default new AuthService();
