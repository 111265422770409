import {
  CREATE_AGREEMENT,
  DELETE_ALL_AGREEMENTS,
  DELETE_AGREEMENT,
  RETRIEVE_AGREEMENTS,
  RETRIEVE_AGREEMENT,
  UPDATE_AGREEMENT,
  RETRIEVE_AGREEMENT_FOR_QUALITY_RATING_REPORT,
} from "../constants";

const initialState = {
  data: [],
  qualityRatingData: [],
  page: 0,
  sizePerPage: 0,
  totalSize: 0,
  isLoading: true,
};

export default function reducer(state = initialState, actions) {
  const { type, payload } = actions;
  switch (type) {
    case CREATE_AGREEMENT:
      return {
        ...state,
        data: [...state.data, payload],
        isLoading: false,
      };
    case RETRIEVE_AGREEMENTS:
      return {
        ...state,
        data: payload.content,
        page: payload.pageNo,
        sizePerPage: payload.pageSize,
        totalSize: payload.totalElements,
        isLoading: false,
      };
    case RETRIEVE_AGREEMENT:
      return {
        ...state,
        data: [payload],
        isLoading: false,
      };
    case RETRIEVE_AGREEMENT_FOR_QUALITY_RATING_REPORT:
      return {
        ...state,
        qualityRatingData: payload.content,
        page: payload.pageNo,
        sizePerPage: payload.pageSize,
        totalSize: payload.totalElements,
        isLoading: false,
      };
    case UPDATE_AGREEMENT:
      return {
        ...state,
        data: state.data.map((agreement) => {
          if (agreement.publicId === payload.publicId) {
            return {
              ...agreement,
              ...payload,
            };
          } else {
            return agreement;
          }
        }),
        isLoading: false,
      };

    case DELETE_AGREEMENT:
      return {
        ...state,
        data: state.data.filter(
          ({ publicId }) => publicId !== payload.publicId
        ),
        isLoading: false,
      };

    case DELETE_ALL_AGREEMENTS:
      return [];
    default:
      return state;
  }
}
