import {
  ADDRESS_IN_PROCESSING,
  ASSIGN_CLEANERS_TO_ADDRESS,
  CREATE_ADDRESS,
  DELETE_ADDRESS,
  DELETE_ALL_ADDRESSES,
  RETRIEVE_ADDRESS,
  RETRIEVE_ADDRESSES,
  RETRIEVE_ADDRESSES_FOR_QUALITY_RATING_REPORT,
  RETRIEVE_ADDRESS_CLEANERS,
  UPDATE_ADDRESS,
  UPDATE_ADDRESS_STATUS,
  UPLOAD_ADDRESS_DOCUMENT,
} from "../constants";

const initialState = {
  data: [],
  qualityRatingData: [],
  assignedCleanersData: null,
  cleanersData: null,
  documentData: null,
  page: 0,
  sizePerPage: 0,
  totalSize: 0,
  isLoading: true,
  isProcessing: false,
};

export default function reducer(state = initialState, actions) {
  const { type, payload } = actions;
  switch (type) {
    case ADDRESS_IN_PROCESSING:
      return {
        ...state,
        isProcessing: payload,
      };
    case CREATE_ADDRESS:
      return {
        ...state,
        data: [...state.data, payload],
        isProcessing: false,
      };
    case RETRIEVE_ADDRESSES:
      return {
        ...state,
        data: payload.content,
        page: payload.pageNo,
        sizePerPage: payload.pageSize,
        totalSize: payload.totalElements,
        isLoading: false,
      };
    case RETRIEVE_ADDRESSES_FOR_QUALITY_RATING_REPORT:
      return {
        ...state,
        qualityRatingData: payload.content,
        page: payload.pageNo,
        sizePerPage: payload.pageSize,
        totalSize: payload.totalElements,
        isLoading: false,
      };
    case RETRIEVE_ADDRESS:
      return {
        ...state,
        data: [payload],
        isLoading: false,
      };

    case RETRIEVE_ADDRESS_CLEANERS:
      return {
        ...state,
        assignedCleanersData: payload,
        isLoading: false,
      };
    case ASSIGN_CLEANERS_TO_ADDRESS:
      return {
        ...state,
        cleanersData: payload,
        isLoading: false,
      };
    case UPDATE_ADDRESS:
      return {
        ...state,
        data: state.data.map((address) => {
          if (address.publicId === payload.publicId) {
            return {
              ...address,
              ...payload,
            };
          } else {
            return address;
          }
        }),
        isProcessing: false,
      };
    case UPLOAD_ADDRESS_DOCUMENT:
      return {
        ...state,
        documentData: payload,
      };
    case UPDATE_ADDRESS_STATUS:
      return {
        ...state,
        data: state.data.filter(
          ({ publicId }) => publicId !== payload.publicId
        ),
        isProcessing: false,
      };
    case DELETE_ADDRESS:
      return {
        ...state,
        data: state.data.filter(
          ({ publicId }) => publicId !== payload.publicId
        ),
        isLoading: false,
      };

    case DELETE_ALL_ADDRESSES:
      return [];
    default:
      return state;
  }
}
