// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_RIGHT_ENABLE = "SIDEBAR_RIGHT_ENABLE";
export const SIDEBAR_RIGHT_DISABLE = "SIDEBAR_RIGHT_DISABLE";
export const NAVBAR_LANG_CHANGE = "NAVBAR_LANG_CHANGE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

// Customer
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const CREATE_CUSTOMER_ACCOUNT = "CREATE_CUSTOMER_ACCOUNT";
export const RETRIEVE_CUSTOMERS = "RETRIEVE_CUSTOMERS";
export const RETRIEVE_CUSTOMER_ACCOUNTS = "RETRIEVE_CUSTOMER_ACCOUNTS";
export const RETRIEVE_CUSTOMER_ACCOUNT = "RETRIEVE_CUSTOMER_ACCOUNT";
export const RETRIEVE_CUSTOMER_TEAM_LEADS = "RETRIEVE_CUSTOMER_TEAM_LEADS";
export const RETRIEVE_TEAM_LEAD_CUSTOMERS = "RETRIEVE_TEAM_LEAD_CUSTOMERS";
export const RETRIEVE_CUSTOMER = "RETRIEVE_CUSTOMER";
export const RETRIEVE_CUSTOMER_LOGO = "RETRIEVE_CUSTOMER_LOGO";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_ACCOUNT = "UPDATE_CUSTOMER_ACCOUNT";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const DELETE_ALL_CUSTOMERS = "DELETE_ALL_CUSTOMERS";

// Agreement
export const CREATE_AGREEMENT = "CREATE_AGREEMENT";
export const RETRIEVE_AGREEMENTS = "RETRIEVE_AGREEMENTS";
export const RETRIEVE_AGREEMENT = "RETRIEVE_AGREEMENT";
export const RETRIEVE_AGREEMENT_FOR_QUALITY_RATING_REPORT =
  "RETRIEVE_AGREEMENT_FOR_QUALITY_RATING_REPORT";
export const UPDATE_AGREEMENT = "UPDATE_AGREEMENT";
export const DELETE_AGREEMENT = "DELETE_AGREEMENT";
export const DELETE_ALL_AGREEMENTS = "DELETE_ALL_AGREEMENTS";

// Address
export const ADDRESS_IN_PROCESSING = "ADDRESS_IN_PROGRESS";
export const CREATE_ADDRESS = "CREATE_ADDRESS";
export const RETRIEVE_ADDRESSES = "RETRIEVE_ADDRESSES";
export const RETRIEVE_ADDRESSES_FOR_QUALITY_RATING_REPORT =
  "RETRIEVE_ADDRESSES_FOR_QUALITY_RATING_REPORT";
export const RETRIEVE_ADDRESS = "RETRIEVE_ADDRESS";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const UPDATE_ADDRESS_STATUS = "UPDATE_ADDRESS_STATUS";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const DELETE_ALL_ADDRESSES = "DELETE_ALL_ADDRESSES";
export const UPLOAD_ADDRESS_DOCUMENT = "UPLOAD_ADDRESS_DOCUMENT";
export const ASSIGN_CLEANERS_TO_ADDRESS = "ASSIGN_CLEANERS_TO_ADDRESS";
export const RETRIEVE_ADDRESS_CLEANERS = "RETRIEVE_ADDRESS_CLEANERS";

// Schedule
export const CREATE_SCHEDULE = "CREATE_SCHEDULE";
export const RETRIEVE_SCHEDULES = "RETRIEVE_SCHEDULES";
export const RETRIEVE_SCHEDULE = "RETRIEVE_SCHEDULE";
export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";
export const DELETE_SCHEDULE = "DELETE_SCHEDULE";
export const DELETE_ALL_SCHEDULES = "DELETE_ALL_SCHEDULES";
export const DATA_PROCESSING = "DATA_PROCESSING";

// Quality Check
export const CREATE_QUALITY_CHECK = "CREATE_QUALITY_CHECK";
export const CREATE_FOLLOW_UP_QUALITY_CHECK = "CREATE_FOLLOW_UP_QUALITY_CHECK";
export const RETRIEVE_QUALITY_CHECKS = "RETRIEVE_QUALITY_CHECKS";
export const RETRIEVE_FOLLOW_UP_QUALITY_CHECKS =
  "RETRIEVE_FOLLOW_UP_QUALITY_CHECKS";
export const RETRIEVE_QUALITY_CHECK = "RETRIEVE_QUALITY_CHECK";
export const UPDATE_QUALITY_CHECK = "UPDATE_QUALITY_CHECK";
export const DELETE_QUALITY_CHECK = "DELETE_QUALITY_CHECK";
export const DELETE_ALL_QUALITY_CHECKS = "DELETE_ALL_QUALITY_CHECKS";
export const RETRIEVE_QUALITY_CHECK_REPORT = "RETRIEVE_QUALITY_CHECK_REPORT";

// Egen Control Quality Check
export const CREATE_EGEN_CONTROL_QUALITY_CHECK =
  "CREATE_EGEN_CONTROL_QUALITY_CHECK";
export const RETRIEVE_TEAM_LEAD_EGEN_CONTROL_QUALITY_CHECKS =
  "RETRIEVE_TEAM_LEAD_EGEN_CONTROL_QUALITY_CHECKS";
export const RETRIEVE_CLEANER_EGEN_CONTROL_QUALITY_CHECKS =
  "RETRIEVE_CLEANER_EGEN_CONTROL_QUALITY_CHECKS";
export const RETRIEVE_EGEN_CONTROL_QUALITY_CHECK =
  "RETRIEVE_EGEN_CONTROL_QUALITY_CHECK";
export const UPDATE_EGEN_CONTROL_QUALITY_CHECK =
  "UPDATE_EGEN_CONTROL_QUALITY_CHECK";
export const DELETE_EGEN_CONTROL_QUALITY_CHECK =
  "DELETE_EGEN_CONTROL_QUALITY_CHECK";
export const DELETE_ALL_EGEN_CONTROL_QUALITY_CHECKS =
  "DELETE_ALL_EGEN_CONTROL_QUALITY_CHECKS";
export const RETRIEVE_EGEN_CONTROL_QUALITY_CHECK_REPORT =
  "RETRIEVE_EGEN_CONTROL_QUALITY_CHECK_REPORT";
export const VALIDATE_EGEN_CONTROL_ACTION_URL =
  "VALIDATE_EGEN_CONTROL_ACTION_URL";
export const APPROVE_OR_REJECT_EGEN_CONTROL_ACTION_URL =
  "APPROVE_OR_REJECT_EGEN_CONTROL_ACTION_URL";
export const CLEAR_CLEANER_EGEN_CONTROL_QUALITY_CHECKS =
  "CLEAR_CLEANER_EGEN_CONTROL_QUALITY_CHECKS";
export const CLEAR_TEAM_LEAD_EGEN_CONTROL_QUALITY_CHECKS =
  "CLEAR_TEAM_LEAD_EGEN_CONTROL_QUALITY_CHECKS";

// Employee
export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const RETRIEVE_EMPLOYEES = "RETRIEVE_EMPLOYEES";
export const RETRIEVE_CLEANERS = "RETRIEVE_CLEANERS";
export const RETRIEVE_EMPLOYEE = "RETRIEVE_EMPLOYEE";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const DELETE_ALL_EMPLOYEES = "DELETE_ALL_EMPLOYEES";
export const ASSIGN_ADDRESSES_TO_EMPLOYEE = "ASSIGN_ADDRESSES_TO_EMPLOYEE";
export const UPDATE_EMPLOYEE_PENDING_FLAG = "UPDATE_EMPLOYEE_PENDING_FLAG";
export const UPDATE_EMPLOYEE_CONNECTED_FLAG = "UPDATE_EMPLOYEE_CONNECTED_FLAG";

// Employee
export const CREATE_REPRESENTATIVE = "CREATE_REPRESENTATIVE";
export const RETRIEVE_REPRESENTATIVES = "RETRIEVE_REPRESENTATIVES";
export const RETRIEVE_REPRESENTATIVE = "RETRIEVE_REPRESENTATIVE";
export const UPDATE_REPRESENTATIVE = "UPDATE_REPRESENTATIVE";
export const DELETE_REPRESENTATIVE = "DELETE_REPRESENTATIVE";
export const DELETE_ALL_REPRESENTATIVES = "DELETE_ALL_REPRESENTATIVES";

//Questionnaires
export const CREATE_QUESTIONNAIRE = "CREATE_QUESTIONNAIRE";
export const RETRIEVE_QUESTIONNAIRES = "RETRIEVE_QUESTIONNAIRES";
export const RETRIEVE_QUESTIONNAIRE = "RETRIEVE_QUESTIONNAIRE";
export const UPDATE_QUESTIONNAIRE = "UPDATE_QUESTIONNAIRE";
export const DELETE_QUESTIONNAIRE = "DELETE_QUESTIONNAIRE";
export const DELETE_ALL_QUESTIONNAIRES = "DELETE_ALL_QUESTIONNAIRES";

//Egen Questionnaires
export const CREATE_TEAM_LEAD_EGEN_QUESTIONNAIRE =
  "CREATE_TEAM_LEAD_EGEN_QUESTIONNAIRE";
export const CREATE_CLEANER_EGEN_QUESTIONNAIRE =
  "CREATE_CLEANER_EGEN_QUESTIONNAIRE";
export const RETRIEVE_TEAM_LEAD_EGEN_QUESTIONNAIRES =
  "RETRIEVE_TEAM_LEAD_EGEN_QUESTIONNAIRES";
export const RETRIEVE_CLEANER_EGEN_QUESTIONNAIRES =
  "RETRIEVE_CLEANER_EGEN_QUESTIONNAIRES";
export const RETRIEVE_EGEN_QUESTIONNAIRE = "RETRIEVE_EGEN_QUESTIONNAIRE";
export const UPDATE_TEAM_LEAD_EGEN_QUESTIONNAIRE =
  "UPDATE_TEAM_LEAD_EGEN_QUESTIONNAIRE";
export const UPDATE_CLEANER_EGEN_QUESTIONNAIRE =
  "UPDATE_CLEANER_EGEN_QUESTIONNAIRE";
export const DELETE_EGEN_QUESTIONNAIRE = "DELETE_EGEN_QUESTIONNAIRE";
export const DELETE_ALL_EGEN_QUESTIONNAIRES = "DELETE_ALL_EGEN_QUESTIONNAIRES";
export const CREATE_TEAM_LEAD_EGEN_QUESTIONNAIRE_CATEGORY =
  "CREATE_TEAM_LEAD_EGEN_QUESTIONNAIRE_CATEGORY";
export const RETRIEVE_TEAM_LEAD_EGEN_QUESTIONNAIRES_CATEGORIES =
  "RETRIEVE_TEAM_LEAD_EGEN_QUESTIONNAIRES_CATEGORIES";
export const CREATE_CLEANER_EGEN_QUESTIONNAIRE_CATEGORY =
  "CREATE_CLEANER_EGEN_QUESTIONNAIRE_CATEGORY";
export const RETRIEVE_CLEANER_EGEN_QUESTIONNAIRES_CATEGORIES =
  "RETRIEVE_CLEANER_EGEN_QUESTIONNAIRES_CATEGORIES";

//Un Bookable Date
export const CREATE_UN_BOOKABLE_DATE = "CREATE_UN_BOOKABLE_DATE";
export const RETRIEVE_UN_BOOKABLE_DATES = "RETRIEVE_UN_BOOKABLE_DATES";
export const RETRIEVE_UN_BOOKABLE_DATE = "RETRIEVE_UN_BOOKABLE_DATE";
export const UPDATE_UN_BOOKABLE_DATE = "UPDATE_UN_BOOKABLE_DATE";
export const DELETE_UN_BOOKABLE_DATE = "DELETE_UN_BOOKABLE_DATE";
export const DELETE_ALL_UN_BOOKABLE_DATES = "DELETE_ALL_UN_BOOKABLE_DATES";

// Auth
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const SIGN_IN_IN_PROGRESS = "SIGN_IN_IN_PROGRESS";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAIL = "SIGN_IN_FAIL";
export const LOGOUT = "LOGOUT";

// Dashboard
export const RETRIEVE_DASHBOARD = "RETRIEVE_DASHBOARD";
export const RETRIEVE_DASHBOARD_REPORT1 = "RETRIEVE_DASHBOARD_REPORT1";
export const RETRIEVE_DASHBOARD_REPORT2 = "RETRIEVE_DASHBOARD_REPORT2";
export const RETRIEVE_QUALITY_CHECK_RATING_REPORT =
  "RETRIEVE_QUALITY_CHECK_RATING_REPORT";
export const RETRIEVE_OBJECT_QUALITY_CHECK_RATING_REPORT =
  "RETRIEVE_OBJECT_QUALITY_CHECK_RATING_REPORT";
export const RETRIEVE_QUESTION_QUALITY_CHECK_RATING_REPORT =
  "RETRIEVE_QUESTION_QUALITY_CHECK_RATING_REPORT";
export const RETRIEVE_TEAM_LEAD_QUALITY_CHECK_RATING_REPORT =
  "RETRIEVE_TEAM_LEAD_QUALITY_CHECK_RATING_REPORT";
export const RETRIEVE_TEAM_LEAD_QUESTION_QUALITY_CHECK_RATING_REPORT =
  "RETRIEVE_TEAM_LEAD_QUESTION_QUALITY_CHECK_RATING_REPORT";
export const REPORT_IN_PROCESSING = "REPORT_IN_PROCESSING";
export const CUSTOMER_QUALITY_CHECK_REPORT_IN_PROCESSING =
  "CUSTOMER_QUALITY_CHECK_REPORT_IN_PROCESSING";
export const CUSTOMER_QUESTION_REPORT_IN_PROCESSING =
  "CUSTOMER_QUESTION_REPORT_IN_PROCESSING";
export const TEAM_LEAD_QUALITY_CHECK_REPORT_IN_PROCESSING =
  "TEAM_LEAD_QUALITY_CHECK_REPORT_IN_PROCESSING";
export const TEAM_LEAD_QUESTION_REPORT_IN_PROCESSING =
  "TEAM_LEAD_QUESTION_REPORT_IN_PROCESSING";
export const EGEN_QUESTIONNAIRE_MESSAGE_SUCCESS =
  "EGEN_QUESTIONNAIRE_MESSAGE_SUCCESS";
export const DASHBOARD_REPORT1_IN_PROCESSING =
  "DASHBOARD_REPORT1_IN_PROCESSING";
export const DASHBOARD_REPORT2_IN_PROCESSING =
  "DASHBOARD_REPORT2_IN_PROCESSING";

// Message
export const MESSAGE_SUCCESS = "MESSAGE_SUCCESS";
export const MESSAGE_FAILURE = "MESSAGE_FAILURE";
export const MESSAGE_RESET = "MESSAGE_RESET";

// Location change
export const LOCATION_CHANGE = "LOCATION_CHANGE";
