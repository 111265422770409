import axios from "axios";
import { t } from "i18next";
import { APP_URL, MESSAGE_ERROR } from "../config/AppConstant";
import AuthService from "../services/authService";
import { showMessage } from "./app-utils";
import authHeader from "./auth-header";

const request = axios.create({
  baseURL: APP_URL,
  headers: {
    "Content-type": "application/json",
  },
});

request.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    ...authHeader(),
  };
  return config;
});
request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      AuthService.logout();
      // window.location.reload();
    } else {
      if (typeof error.response.data === "string") {
        showMessage({
          type: MESSAGE_ERROR,
          message: t(error.response.data),
        });
      } else if (typeof error.response.data === "object" && error.response.data?.errors[0]?.defaultMessage) {
        if (error.response.data?.errors[0]?.defaultMessage) {
          showMessage({
            type: MESSAGE_ERROR,
            message: t(error.response.data?.errors[0]?.defaultMessage),
          });
        }
      } else {
        showMessage({
          type: MESSAGE_ERROR,
          message: t("something_goes_wrong"),
        });
      }
    }
    return Promise.reject(error);
  }
);
export default request;
