import { t } from "i18next";
import AgreementDataService from "../../services/agreementService";
import {
  CREATE_AGREEMENT,
  DELETE_AGREEMENT,
  MESSAGE_SUCCESS,
  RETRIEVE_AGREEMENT,
  RETRIEVE_AGREEMENTS,
  RETRIEVE_AGREEMENT_FOR_QUALITY_RATING_REPORT,
  UPDATE_AGREEMENT,
} from "../constants";

export const retrieveAgreements = (params) => async (dispatch) => {
  try {
    const res = await AgreementDataService.getAll(params);
    dispatch({
      type: RETRIEVE_AGREEMENTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const createAgreement = (agreement) => async (dispatch) => {
  try {
    const res = await AgreementDataService.create(agreement);
    dispatch({
      type: CREATE_AGREEMENT,
      payload: res.data,
    });
    dispatch({
      type: MESSAGE_SUCCESS,
      message: t("agreement_created"),
    });
  } catch (err) {
    console.log(err);
  }
};

export const getAgreement = (id) => async (dispatch) => {
  try {
    const res = await AgreementDataService.get(id);
    dispatch({
      type: RETRIEVE_AGREEMENT,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAgreement = (publicId) => async (dispatch) => {
  try {
    await AgreementDataService.delete(publicId);

    dispatch({
      type: DELETE_AGREEMENT,
      payload: { publicId },
    });
  } catch (err) {
    console.log(err);
  }
};

export const editAgreement = (agreement) => async (dispatch) => {
  try {
    const res = await AgreementDataService.update(agreement);

    dispatch({
      type: UPDATE_AGREEMENT,
      payload: res.data,
    });
    dispatch({
      type: MESSAGE_SUCCESS,
      message: t("agreement_updated"),
    });
  } catch (err) {
    console.log(err);
  }
};

export const retrieveCustomerAgreements =
  (publicId, params) => async (dispatch) => {
    try {
      const res = await AgreementDataService.getCustomerAgreements(
        publicId,
        params
      );
      dispatch({
        type: RETRIEVE_AGREEMENTS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const retrieveCustomerAgreementsForQualityCheckReport =
  (publicId, params) => async (dispatch) => {
    try {
      const res = await AgreementDataService.getCustomerAgreements(
        publicId,
        params
      );
      dispatch({
        type: RETRIEVE_AGREEMENT_FOR_QUALITY_RATING_REPORT,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
