import {
  EGEN_QUESTIONNAIRE_MESSAGE_SUCCESS,
  MESSAGE_FAILURE,
  MESSAGE_RESET,
  MESSAGE_SUCCESS,
} from "../constants";

const initialState = {
  message: {},
  update: true,
};

export default function reducer(state = initialState, actions) {
  const { type, message } = actions;
  switch (type) {
    case MESSAGE_SUCCESS:
      return {
        ...state,
        message: { type: "success", detail: message },
        update: !state.update,
      };
    case EGEN_QUESTIONNAIRE_MESSAGE_SUCCESS:
      return {
        ...state,
        message: { type: message?.type, detail: message?.content },
        update: !state.update,
      };
    case MESSAGE_FAILURE:
      return {
        ...state,
        message: message,
        update: !state.update,
      };
    case MESSAGE_RESET:
      return {
        ...state,
        message: "",
      };
    default:
      return state;
  }
}
