import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import { faKey, faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import EmptyData from "../../components/EmptyData";
import {
  MESSAGE_ERROR,
  MESSAGE_SUCCESS,
  SIZE_PER_PAGE,
  STATUS,
  STATUS_COLORS,
} from "../../config/AppConstant";
import {
  changeEmployeePassword,
  createEmployee,
  // deleteEmployee,
  editEmployee,
  retrieveEmployees,
} from "../../redux/actions/employeeActions";
import { showMessage } from "../../utils/app-utils";
import UpdateEmployee from "./UpdateEmployee";

import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import ChangePassword from "../../components/ChangePassword";
import { NavbarDropdowns, NavbarToggle } from "../../components/Navbar";

const EmployeesList = (props) => {
  const { employees } = useSelector((state) => state);
  const { isLoading } = employees;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: SIZE_PER_PAGE,
      status: "ALL",
    };
    dispatch(retrieveEmployees(params));
  }, [dispatch]);

  // const removeEmployee = (publicId) => {
  //   dispatch(deleteEmployee(publicId));
  // };

  const onEmployeeSelectHandler = (publicId) => {
    let employee = {
      id: "",
      username: "",
      email: "",
      name: "",
      password: "",
      phoneNumber: "",
      type: "TEAM_LEAD",
      active: true,
    };
    if (publicId) {
      employee = employees.data.find((obj) => obj.publicId === publicId);
    }
    props.setChangePassword(false);
    props.setEmployee(employee);
  };

  const onChangePasswordSelectHandler = (publicId) => {
    let employee = {
      id: "",
      username: "",
      email: "",
      name: "",
      password: "",
      phoneNumber: "",
    };
    if (publicId) {
      employee = employees.data.find((obj) => obj.publicId === publicId);
    }
    props.setChangePassword(true);
    props.setEmployee(employee);
  };

  const actionFormatter = (cell, row, rowIndex) => {
    return (
      <div className="table-action text-center">
        <Link to={"#"} onClick={() => onEmployeeSelectHandler(row?.publicId)}>
          <FontAwesomeIcon
            icon={faPen}
            fixedWidth
            className="align-middle mr-1"
          />
        </Link>
        <Link
          to={"#"}
          onClick={() => onChangePasswordSelectHandler(row?.publicId)}
        >
          <FontAwesomeIcon
            icon={faKey}
            fixedWidth
            className="align-middle mr-1"
          />
        </Link>
      </div>
    );
  };

  const handleTableChange = (type, newState) => {
    const { page, sizePerPage, filters, sortField, sortOrder, status } =
      newState;
    const params = {
      pageNo: page ? page - 1 : "",
      pageSize: sizePerPage ? sizePerPage : SIZE_PER_PAGE,
      sortBy: sortField ? sortField : "",
      sortDir: sortOrder ? sortOrder : "",
      status: status ? status : "ALL",
    };
    for (const dataField in filters) {
      const { filterVal } = filters[dataField];
      params[dataField] = filterVal;
    }
    dispatch(retrieveEmployees(params));
  };

  const headerFormatter = (
    column,
    colIndex,
    { sortElement, filterElement }
  ) => {
    return (
      <div>
        <div>
          {column.text}
          {sortElement}
        </div>
        <div style={{ display: "grid", marginTop: "10px" }}>
          {filterElement}
        </div>
      </div>
    );
  };

  const tableColumns = [
    {
      dataField: "publicId",
      text: "",
      hidden: true,
    },
    {
      dataField: "employeeNumber",
      text: t("employee_number"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      formatter: (cell, row, rowIndex) => {
        return <div>{row?.employeeNumber ?? "N/A"}</div>;
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "name",
      text: t("name"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerFormatter: headerFormatter,
    },
    {
      dataField: "email",
      text: t("email"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerFormatter: headerFormatter,
    },
    {
      dataField: "phoneNumber",
      text: t("phone_number"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerFormatter: headerFormatter,
    },
    {
      dataField: "type",
      text: t("type"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      filter: selectFilter({
        key: t("team_lead"),
        placeholder: t("select"),
        options: [
          {
            value: "TEAM_LEAD",
            label: t("team_lead"),
          },
          {
            value: "CLEANER",
            label: t("cleaner"),
          },
        ],
      }),
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div>{row?.type === "TEAM_LEAD" ? t("team_lead") : t("cleaner")}</div>
        );
      },
      formatExtraData: t,
      headerFormatter: headerFormatter,
    },
    {
      dataField: "status",
      text: t("status"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      filter: selectFilter({
        key: t("active"),
        placeholder: t("select"),
        options: [
          {
            value: true,
            label: t("active"),
          },
          {
            value: false,
            label: t("in_active"),
          },
        ],
      }),
      formatter: (cell, row, rowIndex) => {
        return (
          <Badge style={{ backgroundColor: STATUS_COLORS[row?.active] }}>
            {STATUS[row?.active]}
          </Badge>
        );
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "actions",
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      formatter: actionFormatter,
      headerFormatter: headerFormatter,
    },
  ];

  return (
    <Card>
      <CardHeader>
        <div className="card-actions float-right">
          <Button
            color="primary"
            className="mr-1 mb-1"
            onClick={() => onEmployeeSelectHandler(null)}
          >
            <FontAwesomeIcon icon={faPlus} /> {t("add")}
          </Button>
        </div>
        <CardTitle tag="h5" className="mb-0">
          {t("employees")}
        </CardTitle>
      </CardHeader>
      <CardBody className={isLoading ? "text-center" : ""}>
        <BootstrapTable
          condensed
          keyField="publicId"
          data={employees.data}
          remote={true}
          columns={tableColumns}
          bootstrap4
          striped
          hover
          bordered={false}
          filter={filterFactory()}
          pagination={paginationFactory({
            sizePerPage: employees.sizePerPage,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true,
            totalSize: employees.totalSize,
          })}
          onTableChange={handleTableChange}
          loading={employees.isLoading}
          noDataIndication={() => {
            return <EmptyData isLoading={employees.isLoading} />;
          }}
        ></BootstrapTable>
      </CardBody>
    </Card>
  );
};

const Employees = () => {
  const { messages } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [employee, setEmployee] = useState(null);
  const [changePassword, setChangePassword] = useState(false);
  const { message } = messages;
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      if (message.type === "success") {
        showMessage({
          type: MESSAGE_SUCCESS,
          message: message.detail,
        });
        setEmployee(null);
        setChangePassword(false);
      }
    } else {
      isMounted.current = true;
    }
  }, [messages, message]);

  const handleOnSubmit = (employee) => {
    if (employee.publicId) {
      dispatch(editEmployee(employee));
    } else {
      dispatch(createEmployee(employee));
    }
  };

  const handlePasswordChange = (customer) => {
    dispatch(changeEmployeePassword(customer));
  };
  return (
    <Container fluid>
      <Header>
        <div className="effektToggle">
          <NavbarToggle />
          <HeaderTitle> {t("employees")}</HeaderTitle>
        </div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">{t("dashboard")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active> {t("employees")}</BreadcrumbItem>
        </Breadcrumb>
        <NavbarDropdowns />
      </Header>
      <Row>
        <Col>
          <ErrorBoundary
            fallbackRender={() => {}}
            onError={() => {
              showMessage({
                type: MESSAGE_ERROR,
                message: t("employees_fallback_msg"),
              });
            }}
          >
            <EmployeesList
              setEmployee={setEmployee}
              setChangePassword={setChangePassword}
            />
          </ErrorBoundary>
        </Col>
        {!changePassword && employee && (
          <Col className="col-md-3 col-xxl-3">
            <ErrorBoundary
              fallbackRender={() => {}}
              onError={() => {
                showMessage({
                  type: MESSAGE_ERROR,
                  message: t("employee_fallback_msg"),
                });
              }}
            >
              <UpdateEmployee
                employee={employee}
                setEmployee={setEmployee}
                handleOnSubmit={handleOnSubmit}
                setChangePassword={setChangePassword}
              />
            </ErrorBoundary>
          </Col>
        )}
        {changePassword && (
          <Col className="col-md-3 col-xxl-3">
            <ErrorBoundary
              fallbackRender={() => {}}
              onError={() => {
                showMessage({
                  type: MESSAGE_ERROR,
                  message: t("customer_fallback_msg"),
                });
              }}
            >
              <ChangePassword
                user={employee}
                setUser={setEmployee}
                setChangePassword={setChangePassword}
                handlePasswordChange={handlePasswordChange}
              />
            </ErrorBoundary>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Employees;
