import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import EmptyData from "../../components/EmptyData";
import { NavbarDropdowns, NavbarToggle } from "../../components/Navbar";
import {
  MESSAGE_ERROR,
  SIZE_PER_PAGE,
  STATUS,
  STATUS_COLORS,
} from "../../config/AppConstant";
import {
  createQuestionnaire,
  editQuestionnaire,
  retrieveQuestionnaires,
} from "../../redux/actions/questionnairesActions";
import { MESSAGE_SUCCESS } from "../../redux/constants";
import { showMessage } from "../../utils/app-utils";
import UpdateQuestionnaire from "./UpdateQuestionnaire";

export const QuestionnairesList = (props) => {
  const { questionnaires } = useSelector((state) => state);
  const { isLoading } = questionnaires;
  const dispatch = useDispatch();

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: SIZE_PER_PAGE,
    };
    dispatch(retrieveQuestionnaires(params));
  }, [dispatch]);

  // const removeQuestionnaire = (publicId) => {
  //   dispatch(deleteQuestionnaire(publicId));
  //   props.setQuestionnaire(null);
  // };

  const onQuestionnaireClickHandler = (publicId) => {
    let questionnaire = {
      publicId: "",
      name: "",
      active: false,
    };
    if (publicId) {
      questionnaire = questionnaires.data.find(
        (obj) => obj.publicId === publicId
      );
    }
    props.setQuestionnaire(questionnaire);
  };

  const actionFormatter = (cell, row, rowIndex) => {
    return (
      <div className="table-action text-center">
        <Link
          to={"#"}
          onClick={() => onQuestionnaireClickHandler(row?.publicId)}
        >
          <FontAwesomeIcon
            icon={faPen}
            fixedWidth
            className="align-middle mr-1"
          />
        </Link>
      </div>
    );
  };

  const handleTableChange = (type, newState) => {
    const { page, sizePerPage, filters, sortField, sortOrder, status } =
      newState;
    const params = {
      pageNo: page ? page - 1 : "",
      pageSize: sizePerPage ? sizePerPage : SIZE_PER_PAGE,
      sortBy: sortField ? sortField : "",
      sortDir: sortOrder ? sortOrder : "",
      status: status ? status : "",
    };
    for (const dataField in filters) {
      const { filterVal } = filters[dataField];
      params[dataField] = filterVal;
    }
    dispatch(retrieveQuestionnaires(params));
  };

  const headerFormatter = (
    column,
    colIndex,
    { sortElement, filterElement }
  ) => {
    return (
      <div>
        <div>
          {column.text}
          {sortElement}
        </div>
        <div style={{ display: "grid", marginTop: "10px" }}>
          {filterElement}
        </div>
      </div>
    );
  };

  const tableColumns = [
    {
      dataField: "publicId",
      text: "",
      hidden: true,
    },
    {
      dataField: "name",
      text: t("name"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "active",
      text: t("status"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
      filter: selectFilter({
        key: t("active"),
        placeholder: t("select"),
        options: [
          {
            value: true,
            label: t("active"),
          },
          {
            value: false,
            label: t("in_active"),
          },
        ],
      }),
      formatter: (cell, row, rowIndex) => {
        return (
          <Badge style={{ backgroundColor: STATUS_COLORS[row?.active] }}>
            {STATUS[row?.active]}
          </Badge>
        );
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "actions",
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      formatter: actionFormatter,
      headerFormatter: headerFormatter,
    },
  ];

  return (
    <Card>
      <CardHeader>
        <div className="card-actions float-right">
          <Button
            color="primary"
            className="mr-1 mb-1"
            onClick={() => onQuestionnaireClickHandler(null)}
          >
            <FontAwesomeIcon icon={faPlus} /> {t("add")}
          </Button>
        </div>
        <CardTitle tag="h5" className="mb-0">
          {t("questionnaires")}
        </CardTitle>
      </CardHeader>
      <CardBody className={isLoading ? "text-center" : ""}>
        <BootstrapTable
          condensed
          keyField="publicId"
          data={questionnaires.data}
          remote={true}
          columns={tableColumns}
          bootstrap4
          striped
          hover
          bordered={false}
          filter={filterFactory()}
          pagination={paginationFactory({
            sizePerPage: questionnaires.sizePerPage,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true,
            totalSize: questionnaires.totalSize,
          })}
          onTableChange={handleTableChange}
          loading={questionnaires.isLoading}
          noDataIndication={() => {
            return <EmptyData isLoading={questionnaires.isLoading} />;
          }}
        ></BootstrapTable>
      </CardBody>
    </Card>
  );
};

const Questionnaires = () => {
  const { messages } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [questionnaire, setQuestionnaire] = useState(null);
  const { message } = messages;
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      if (message.type === "success") {
        showMessage({
          type: MESSAGE_SUCCESS,
          message: message.detail,
        });
        setQuestionnaire(null);
      }
    } else {
      isMounted.current = true;
    }
  }, [messages, message]);

  const handleOnSubmit = (questionnaire) => {
    if (questionnaire.publicId) {
      dispatch(editQuestionnaire(questionnaire));
    } else {
      dispatch(createQuestionnaire(questionnaire));
    }
  };

  return (
    <Container fluid>
      <Header>
        <div className="effektToggle">
          <NavbarToggle />
          <HeaderTitle>{t("questionnaires")}</HeaderTitle>
        </div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">{t("dashboard")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("questionnaires")}</BreadcrumbItem>
        </Breadcrumb>
        <NavbarDropdowns />
      </Header>
      <Row>
        <Col>
          <ErrorBoundary
            fallbackRender={() => {}}
            onError={() => {
              showMessage({
                type: MESSAGE_ERROR,
                message: t("questionnaires_fallback_msg"),
              });
            }}
          >
            <QuestionnairesList setQuestionnaire={setQuestionnaire} />
          </ErrorBoundary>
        </Col>
        {questionnaire && (
          <Col className="col-md-3 col-xxl-3">
            <ErrorBoundary
              fallbackRender={() => {}}
              onError={() => {
                showMessage({
                  type: MESSAGE_ERROR,
                  message: t("questionnaire_fallback_msg"),
                });
              }}
            >
              <UpdateQuestionnaire
                questionnaire={questionnaire}
                setQuestionnaire={setQuestionnaire}
                handleOnSubmit={handleOnSubmit}
              />
            </ErrorBoundary>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Questionnaires;
