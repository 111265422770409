import { combineReducers } from "redux";

import sidebar from "./sidebarReducers";
import theme from "./themeReducer";
import customers from "./customerReducers";
import auth from "./authReducers";
import employees from "./employeeReducers";
import questionnaires from "./questionnaireReducers";
import teamLeadEgenQuestionnaires from "./teamLeadEgenQuestionnaireReducers";
import cleanerEgenQuestionnaires from "./cleanerEgenQuestionnaireReducers";
import unBookableDates from "./unBookableDateReducers";
import messages from "./messageReducers";
import addresses from "./addressReducers";
import schedules from "./scheduleReducers";
import representatives from "./representativeReducers";
import agreements from "./agreementReducers";
import qualityChecks from "./qualityCheckReducers";
import egenControlQualityChecks from "./egenControlReducers";
import teamLeadEgenControlQualityChecks from "./teamLeadEgenControlReducers";
import cleanerEgenControlQualityChecks from "./cleanerEgenControlReducers";
import followUpQualityChecks from "./followUpQualityCheckReducers";
import customerAccounts from "./customerAccountReducers";
import dashboard from "./dashboardReducers";

import { reducer as toastr } from "react-redux-toastr";

export default combineReducers({
  sidebar,
  theme,
  toastr,
  customers,
  auth,
  employees,
  questionnaires,
  teamLeadEgenQuestionnaires,
  cleanerEgenQuestionnaires,
  unBookableDates,
  messages,
  addresses,
  schedules,
  representatives,
  agreements,
  qualityChecks,
  egenControlQualityChecks,
  teamLeadEgenControlQualityChecks,
  cleanerEgenControlQualityChecks,
  followUpQualityChecks,
  customerAccounts,
  dashboard,
});
