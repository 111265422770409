import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import { Button, Card, CardBody, Label, Spinner } from "reactstrap";
import { resetPassword } from "../../redux/actions/authActions";
import {
  PATH_DASHBOARD,
  PATH_LANDING,
  PATH_QUALITY_CHECK,
  ROLE_ADMIN,
} from "../../routes/constants";
import { showMessage, validateInput } from "../../utils/app-utils";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { auth } = useSelector((state) => state);

  const [email, setEmail] = useState(null);

  if (auth.isLoggedIn) {
    return (
      <Redirect
        to={
          auth.roles?.includes(ROLE_ADMIN) ? PATH_DASHBOARD : PATH_QUALITY_CHECK
        }
      />
    );
  }

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const values = [email];

    if (validateInput(values)) {
      dispatch(resetPassword(email));
    } else {
      showMessage({
        type: "error",
        message: t("missing_information"),
      });
    }
  };

  console.log(email);
  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">{t("reset_password")}</h1>
        <p className="lead">{t("reset_password_text")}</p>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <AvForm onValidSubmit={handleOnSubmit}>
              <AvGroup>
                <Label for="email">{t("email")}</Label>
                <AvInput
                  required
                  bsSize="lg"
                  type="email"
                  name="email"
                  id="email"
                  placeholder={t("email")}
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                <AvFeedback>{t("missing_email")}</AvFeedback>
              </AvGroup>
              <small>
                <Link to={PATH_LANDING}>{t("sign_in")}?</Link>
              </small>
              <div className="text-center mt-3">
                <Button
                  color="primary"
                  size="lg"
                  type="submit"
                  disabled={auth.isLoading}
                >
                  {auth.isLoading ? <Spinner /> : t("reset_password")}
                </Button>
              </div>
            </AvForm>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ResetPassword;
