import { URL_CUSTOMER } from "../config/AppConstant";
import http from "../utils/http-common";

class CustomerDataService {
  getAll(params) {
    return http.get(URL_CUSTOMER + "/list", { params });
  }

  get(id) {
    return http.get(`${URL_CUSTOMER}/${id}`);
  }

  getDetails() {
    return http.get(`${URL_CUSTOMER}/details`);
  }

  getLogo(id) {
    return http.get(`${URL_CUSTOMER}/get-logo/${id}`);
  }

  create(formData) {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return http.post(URL_CUSTOMER, formData, config);
  }

  update(formData) {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return http.put(URL_CUSTOMER, formData, config);
  }

  delete(id) {
    return http.delete(`${URL_CUSTOMER}/${id}`);
  }

  changePassword(params) {
    return http.put(`${URL_CUSTOMER}/change-password`, params);
  }

  getRepresentatives(id) {
    return http.get(`${URL_CUSTOMER}/representative/${id}`);
  }

  getTeamLeads(id) {
    return http.get(`${URL_CUSTOMER}/team-lead/${id}`);
  }

  createCustomerAccount(customerId, params) {
    return http.post(`${URL_CUSTOMER}/${customerId}/sub-account`, params);
  }

  updateCustomerAccount(params) {
    return http.put(`${URL_CUSTOMER}/sub-account`, params);
  }

  getAllCustomerAccounts(customerId, params) {
    return http.get(`${URL_CUSTOMER}/${customerId}/sub-account`, { params });
  }

  getCustomerAccount(id) {
    return http.get(`${URL_CUSTOMER}/sub-account/${id}`);
  }
  changeCustomerAccountPassword(publicId, params) {
    return http.put(
      `${URL_CUSTOMER}/sub-account/${publicId}/change-password`,
      params
    );
  }
  retrieveTeamLeadCustomers(id) {
    return http.get(`${URL_CUSTOMER}/team-lead/customers/${id}`);
  }
}

export default new CustomerDataService();
