import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import ReactInputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import ConfirmationModal from "../../components/ConfirmationModal";
import { MESSAGE_ERROR, PATTERN_PHONE_NUMBER } from "../../config/AppConstant";
import {
  isEmployeeConnected,
  retrieveEmployees,
  sendAppUrlToEmployee,
} from "../../redux/actions/employeeActions";
import {
  getEmployeeOption,
  showMessage,
  validateInput,
} from "../../utils/app-utils";

const UpdateEmployee = (props) => {
  const { employees } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [employee, setEmployee] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    active: true,
    employeePublicId: "",
    employeeNumber: "",
    type: "TEAM_LEAD",
  });
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  useEffect(() => {
    props.employee && setEmployee(props.employee);
  }, [props.employee]);

  useEffect(() => {
    const params = {
      status: true,
      type: props.employee?.type ? props.employee?.type : "TEAM_LEAD",
    };
    dispatch(retrieveEmployees(params, true));
  }, [dispatch, props.employee?.type]);

  useEffect(() => {
    dispatch(isEmployeeConnected(props.employee?.publicId));
  }, [dispatch, props.employee?.publicId]);

  const {
    publicId,
    name,
    email,
    phoneNumber,
    active,
    employeePublicId,
    employeeNumber,
    type,
  } = employee;

  const readOnly = props.readOnly;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEmployee((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const values = [name, email, phoneNumber, employeeNumber];
    if (employees.isConnected) {
      !active && values.push(employeePublicId);
    }

    if (validateInput(values)) {
      if (employees.isConnected && !active && !openConfirmationModal) {
        setOpenConfirmationModal(true);
      } else {
        openConfirmationModal && setOpenConfirmationModal(false);
        const employee = {
          publicId,
          name,
          phoneNumber,
          username: email,
          password: email,
          email,
          active,
          employeePublicId,
          employeeNumber,
          type,
        };
        props.handleOnSubmit(employee);
      }
    } else {
      showMessage({
        type: MESSAGE_ERROR,
        message: t("missing_information"),
      });
    }
  };

  const handleCheckChange = (event) => {
    const { name, checked } = event.target;
    setEmployee((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const employeesList = employees?.allData?.filter(
    (employee) => employee.publicId !== publicId
  );

  const options = employeesList?.map((employee) => getEmployeeOption(employee));

  const replacedEmployee = employeePublicId
    ? employees?.allData?.filter(
        (employee) => employee.publicId === employeePublicId
      )
    : "";

  const handleEmployeeDropDownChange = (employee) => {
    if (employee) {
      const value = employee?.value?.split("|");
      setEmployee((prevState) => ({
        ...prevState,
        employeePublicId: value[0],
      }));
    } else {
      setEmployee((prevState) => ({
        ...prevState,
        employeePublicId: "",
      }));
    }
  };

  const handleEmployeeTypeDropDownChange = (type) => {
    let { value } = type;
    setEmployee((prevState) => ({
      ...prevState,
      type: value,
    }));
  };

  const empTypeOptions = [
    { label: t("team_lead"), value: "TEAM_LEAD" },
    { label: t("cleaner"), value: "CLEANER" },
  ];

  const onResendAppUrlClick = (event) => {
    event.preventDefault();
    dispatch(sendAppUrlToEmployee(publicId));
  };

  return (
    <Card>
      <CardHeader>
        <div className="card-actions float-right">
          <span className="cursor-pointer mr-1">
            <X onClick={() => props.setEmployee(null)} />
          </span>{" "}
        </div>
        <CardTitle tag="h5" className="mb-0">
          {t("employee")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <ConfirmationModal
          openConfirmationModal={openConfirmationModal}
          setOpenConfirmationModal={setOpenConfirmationModal}
          header={t("deactivate_confirmation_header")}
          message={
            type === "CLEANER"
              ? t("deactivate_cleaner_confirmation_message")
              : t("deactivate_confirmation_message")
          }
          handleOnSubmit={handleOnSubmit}
        />

        <AvForm onValidSubmit={handleOnSubmit}>
          <Row>
            <Col>
              <AvGroup>
                <Label for="employeeNumber">{t("employee_number")}</Label>
                <AvInput
                  required
                  type="text"
                  name="employeeNumber"
                  id="employeeNumber"
                  placeholder={t("employee_number")}
                  value={employeeNumber || ""}
                  mask="99999"
                  maskChar=""
                  onChange={handleInputChange}
                  tag={[Input, ReactInputMask]}
                />
                <AvFeedback>{t("employee_number_format")}</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="name">{t("name")}</Label>
                <AvInput
                  required
                  type="text"
                  name="name"
                  id="name"
                  placeholder={t("name")}
                  value={name}
                  readOnly={readOnly}
                  onChange={handleInputChange}
                />
                <AvFeedback>{t("missing_name")}</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="email">{t("email")}</Label>
                <AvInput
                  required
                  type="email"
                  name="email"
                  id="email"
                  placeholder={t("email")}
                  value={email}
                  readOnly={readOnly}
                  onChange={handleInputChange}
                />
                <AvFeedback>{t("missing_email")}</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="phoneNumber">{t("phone_number")}</Label>
                <AvInput
                  required
                  type="tel"
                  name="phoneNumber"
                  id="phoneNumber"
                  value={phoneNumber}
                  readOnly={readOnly}
                  placeholder={t("phone_number")}
                  onChange={handleInputChange}
                  validate={{ tel: { pattern: PATTERN_PHONE_NUMBER } }}
                />
                <AvFeedback>{t("missing_phone_number")}</AvFeedback>
              </AvGroup>
              <FormGroup>
                <Label for="employeeType">{t("type")}</Label>
                <ReactSelect
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={empTypeOptions}
                  isDisabled={publicId}
                  value={{
                    label: type === "TEAM_LEAD" ? t("team_lead") : t("cleaner"),
                    value: type || "",
                  }}
                  isSearchable
                  id="employeeType"
                  onChange={handleEmployeeTypeDropDownChange}
                />
              </FormGroup>
              {publicId && (
                <FormGroup>
                  <CustomInput
                    type="checkbox"
                    name="active"
                    id="checkbox"
                    value={active}
                    checked={active}
                    label={t("active")}
                    onChange={handleCheckChange}
                  />
                </FormGroup>
              )}
              {publicId && !active && employees.isConnected && (
                <FormGroup>
                  <Label for="phoneNumber">{t("replace_with")}</Label>
                  <ReactSelect
                    className="calenderSelect react-select-container"
                    classNamePrefix="react-select"
                    options={options}
                    onChange={handleEmployeeDropDownChange}
                    isDisabled={props.employee?.employeePublicId}
                    value={
                      replacedEmployee && replacedEmployee[0]
                        ? {
                            label: replacedEmployee[0].name,
                            value: replacedEmployee[0].publicId,
                          }
                        : ""
                    }
                    placeholder={t("select")}
                    isClearable
                  />
                </FormGroup>
              )}
            </Col>
          </Row>
          {employees.isPending && (
            <div className="text-center mb-2">
              <Spinner />
            </div>
          )}
          <Row>
            <Col>
              <Button
                className="mt-2 mr-3"
                color="primary"
                disabled={employees.isPending}
              >
                {publicId ? t("update") : t("create")}
              </Button>
              {publicId && (
                <Button
                  className="mt-2"
                  onClick={onResendAppUrlClick}
                  color="primary"
                  disabled={employees.isPending}
                >
                  {t("resend_app_url")}
                </Button>
              )}
            </Col>
          </Row>
        </AvForm>
      </CardBody>
    </Card>
  );
};

export default UpdateEmployee;
