import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { Badge, Card, CardBody, CardHeader, CardTitle } from "reactstrap";

import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import EmptyData from "../../components/EmptyData";
import {
  DATE_TIME_FORMAT,
  QUALITY_CHECK_STATUS_CANCELLED,
  QUALITY_CHECK_STATUS_COLORS,
  QUALITY_CHECK_STATUS_COMPLETED,
  QUALITY_CHECK_STATUS_IN_PROGRESS,
  SIZE_PER_PAGE,
} from "../../config/AppConstant";
import { retrieveFollowUpQualityChecks } from "../../redux/actions/followUpQualityCheckActions";
import { PATH_QUALITY_CHECK } from "../../routes/constants";

export const FollowUpQualityCheckList = (props) => {
  const { t } = useTranslation();
  const { followUpQualityChecks, auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: SIZE_PER_PAGE,
    };
    dispatch(retrieveFollowUpQualityChecks(props.qualityCheckId, params));
  }, [dispatch, props.qualityCheckId]);

  const onQualityCheckEditHandler = (publicId, parentId) => {
    let qualityCheck = {
      employeePublicId: "",
      addressId: "",
      title: "",
      start: "",
      end: "",
      publicId: publicId,
      parentId: parentId,
      isCreate: false,
    };
    props.setQualityCheck(qualityCheck);
  };

  const viewReportFormatter = (cell, row, rowIndex) => {
    return (
      <div className="table-action ml-4">
        <Link to={PATH_QUALITY_CHECK + "/" + row?.publicId}>
          <FontAwesomeIcon
            icon={faEye}
            fixedWidth
            className="align-middle mr-1"
          />
        </Link>
      </div>
    );
  };

  const actionFormatter = (cell, row, rowIndex) => {
    return (
      <div className="table-action text-center">
        <Link
          to={"#"}
          onClick={() =>
            onQualityCheckEditHandler(row?.publicId, row?.parentId)
          }
        >
          <FontAwesomeIcon
            icon={faPen}
            fixedWidth
            className="align-middle mr-1"
          />
        </Link>
      </div>
    );
  };

  const handleTableChange = (type, newState) => {
    const { page, sizePerPage, filters, sortField, sortOrder } = newState;
    const params = {
      pageNo: page ? page - 1 : "",
      pageSize: sizePerPage ? sizePerPage : "",
      sortBy: sortField ? sortField : "",
      sortDir: sortOrder ? sortOrder : "",
    };

    for (const dataField in filters) {
      const { filterVal } = filters[dataField];
      params[dataField] = filterVal;
    }
    dispatch(retrieveFollowUpQualityChecks(props.qualityCheckId, params));
  };

  const headerFormatter = (
    column,
    colIndex,
    { sortElement, filterElement }
  ) => {
    return (
      <div>
        <div className={column.dataField === "actions" && "text-center"}>
          {column.text}
          {sortElement}
        </div>
        <div style={{ display: "grid", marginTop: "10px" }}>
          {filterElement}
        </div>
      </div>
    );
  };

  const tableColumns = [
    {
      dataField: "publicId",
      text: "",
      hidden: true,
    },
    // {
    //   dataField: "customerName",
    //   text: t("customer_name"),
    //   sort: auth.isAdmin,
    //   hidden: auth.isCustomer,
    //   filter: textFilter({
    //     placeholder: t("search"),
    //   }),
    //   headerFormatter: headerFormatter,
    // },
    // {
    //   dataField: "objectNumber",
    //   text: t("object_number"),
    //   sort: true,
    //   filter: textFilter({
    //     placeholder: t("search"),
    //   }),
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "10%" };
    //   },
    //   headerFormatter: headerFormatter,
    // },
    // {
    //   dataField: "objectName",
    //   text: t("object_name"),
    //   sort: true,
    //   // filter: textFilter({
    //   //   placeholder: t("search"),
    //   // }),
    //   headerFormatter: headerFormatter,
    // },
    // {
    //   dataField: "addressInfo",
    //   text: t("address"),
    //   sort: auth.isAdmin,
    //   filter:
    //     auth.isAdmin &&
    //     textFilter({
    //       placeholder: t("search"),
    //     }),
    //   headerFormatter: headerFormatter,
    // },
    {
      dataField: "dateTime",
      text: t("performed_date"),
      sort: auth.isAdmin,
      // filter:
      //   auth.isAdmin &&
      //   textFilter({
      //     placeholder: t("search"),
      //   }),

      headerStyle: (colum, colIndex) => {
        return { width: "13%", verticalAlign: "top" };
      },
      formatter: (cell, row, rowIndex) => {
        return <div>{moment(row?.dateTime).format(DATE_TIME_FORMAT)}</div>;
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "status",
      text: t("status"),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      sort: auth.isAdmin,
      filter:
        auth.isAdmin &&
        selectFilter({
          key: t("completed"),
          placeholder: t("select"),
          options: [
            {
              value: QUALITY_CHECK_STATUS_COMPLETED,
              label: t("completed"),
            },
            {
              value: QUALITY_CHECK_STATUS_IN_PROGRESS,
              label: t("in_progress"),
            },
            {
              value: QUALITY_CHECK_STATUS_CANCELLED,
              label: t("cancelled"),
            },
          ],
        }),
      formatter: (cell, row, rowIndex) => {
        return (
          <Badge
            style={{
              backgroundColor: QUALITY_CHECK_STATUS_COLORS[row?.status],
            }}
          >
            {row?.status}
          </Badge>
        );
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "viewReport",
      text: t("view_report"),
      formatter: viewReportFormatter,
      headerFormatter: headerFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "10%", verticalAlign: "top" };
      },
    },
  ];

  auth.isAdmin &&
    tableColumns.push({
      dataField: "actions",
      text: "",
      formatter: actionFormatter,
      headerFormatter: headerFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "10%", verticalAlign: "top" };
      },
    });

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          {t("follow_up_quality_checks")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <BootstrapTable
          condensed
          keyField="publicId"
          data={followUpQualityChecks.data}
          remote={true}
          columns={tableColumns}
          striped
          hover
          bootstrap4
          bordered={false}
          filter={filterFactory()}
          pagination={paginationFactory({
            sizePerPage: followUpQualityChecks.sizePerPage,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true,
            totalSize: followUpQualityChecks.totalSize,
          })}
          onTableChange={handleTableChange}
          loading={followUpQualityChecks.isLoading}
          noDataIndication={() => {
            return <EmptyData isLoading={followUpQualityChecks.isLoading} />;
          }}
        ></BootstrapTable>
      </CardBody>
    </Card>
  );
};
