import {
  CREATE_SCHEDULE,
  DATA_PROCESSING,
  DELETE_ALL_SCHEDULES,
  DELETE_SCHEDULE,
  RETRIEVE_SCHEDULE,
  RETRIEVE_SCHEDULES,
  UPDATE_SCHEDULE,
} from "../constants";

const initialState = {
  data: [],
  isLoading: true,
  isProcessing: false,
};

export default function reducer(state = initialState, actions) {
  const { type, payload } = actions;
  switch (type) {
    case CREATE_SCHEDULE:
      return {
        ...state,
        data: [...state.data, { ...payload }],
        isLoading: false,
      };
    case RETRIEVE_SCHEDULES:
      return {
        ...state,
        data: payload.content.map((schedule, index) => {
          return {
            ...schedule,
          };
        }),
        isLoading: false,
      };
    case RETRIEVE_SCHEDULE:
      return {
        ...state,
        data: [{ ...payload }],
        isLoading: false,
      };
    case UPDATE_SCHEDULE:
      return {
        ...state,
        data: state.data.map((schedule) => {
          if (schedule.publicId === payload.publicId) {
            return {
              ...schedule,
              ...payload,
            };
          } else {
            return schedule;
          }
        }),
        isLoading: false,
      };

    case DELETE_SCHEDULE:
      return {
        ...state,
        data: state.data.filter(
          ({ publicId }) => publicId !== payload.publicId
        ),
        isLoading: false,
      };

    case DELETE_ALL_SCHEDULES:
      return [];

    case DATA_PROCESSING:
      return {
        ...state,
        isProcessing: payload,
      };
    default:
      return state;
  }
}
