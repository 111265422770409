import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Label,
} from "reactstrap";
import { MESSAGE_ERROR } from "../config/AppConstant";
import { showMessage, validateInput } from "../utils/app-utils";

const ChangePassword = (props) => {
  const [user, setUser] = useState({
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    props.user && setUser(props.user);
  }, [props.user]);

  const { password, confirmPassword, publicId } = user;

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const values = [password, confirmPassword];

    if (validateInput(values)) {
      if (password !== confirmPassword) {
        showMessage({
          type: MESSAGE_ERROR,
          message: t("password_not_match"),
        });
      } else {
        const user = {
          publicId,
          password,
          isCustomerAccount: props.isCustomerAccount,
        };
        props.handlePasswordChange(user);
      }
    } else {
      showMessage({
        type: MESSAGE_ERROR,
        message: t("missing_information"),
      });
    }
  };

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Card>
      <CardHeader>
        <div className="card-actions float-right">
          <span className="cursor-pointer mr-1">
            <X
              onClick={() => {
                props.setChangePassword(false);
                props.setUser(null);
              }}
            />
          </span>{" "}
        </div>
        <CardTitle tag="h5" className="mb-0">
          {t("change_password")} ({user.name})
        </CardTitle>
      </CardHeader>
      <CardBody>
        <AvForm onValidSubmit={handleOnSubmit}>
          <AvGroup>
            <Label for="password">{t("password")}</Label>
            <AvInput
              required
              type="password"
              name="password"
              id="password"
              placeholder={t("password")}
              value={password || ""}
              onChange={handleInputChange}
            />
            <AvFeedback>{t("missing_password")}</AvFeedback>
          </AvGroup>
          <AvGroup>
            <Label for="confirmPassword">{t("confirm_password")}</Label>
            <AvInput
              required
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              placeholder={t("confirm_password")}
              value={confirmPassword || ""}
              onChange={handleInputChange}
            />
            <AvFeedback>{t("missing_password")}</AvFeedback>
          </AvGroup>
          <Button color="primary">{t("update")}</Button>
        </AvForm>
      </CardBody>
    </Card>
  );
};

export default ChangePassword;
