import { t } from "i18next";
import EgenQuestionnairesDataService from "../../services/egenQuestionnaireService";
import {
  CREATE_CLEANER_EGEN_QUESTIONNAIRE,
  CREATE_CLEANER_EGEN_QUESTIONNAIRE_CATEGORY,
  CREATE_TEAM_LEAD_EGEN_QUESTIONNAIRE,
  CREATE_TEAM_LEAD_EGEN_QUESTIONNAIRE_CATEGORY,
  DELETE_EGEN_QUESTIONNAIRE,
  EGEN_QUESTIONNAIRE_MESSAGE_SUCCESS,
  MESSAGE_SUCCESS,
  RETRIEVE_CLEANER_EGEN_QUESTIONNAIRES,
  RETRIEVE_CLEANER_EGEN_QUESTIONNAIRES_CATEGORIES,
  RETRIEVE_EGEN_QUESTIONNAIRE,
  RETRIEVE_TEAM_LEAD_EGEN_QUESTIONNAIRES,
  RETRIEVE_TEAM_LEAD_EGEN_QUESTIONNAIRES_CATEGORIES,
  UPDATE_CLEANER_EGEN_QUESTIONNAIRE,
  UPDATE_TEAM_LEAD_EGEN_QUESTIONNAIRE,
} from "../constants";

export const retrieveTeamLeadQuestionnairesCategories =
  (params) => async (dispatch) => {
    try {
      const res = await EgenQuestionnairesDataService.getCategories(params);
      dispatch({
        type: RETRIEVE_TEAM_LEAD_EGEN_QUESTIONNAIRES_CATEGORIES,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const createTeamLeadQuestionnaireCategory =
  (category) => async (dispatch) => {
    try {
      const res = await EgenQuestionnairesDataService.createCategory(category);
      dispatch({
        type: CREATE_TEAM_LEAD_EGEN_QUESTIONNAIRE_CATEGORY,
        payload: res.data,
      });
      dispatch({
        type: MESSAGE_SUCCESS,
        message: t("category_created"),
      });
    } catch (err) {
      console.log(err);
    }
  };

export const retrieveCleanerQuestionnairesCategories =
  (params) => async (dispatch) => {
    try {
      const res = await EgenQuestionnairesDataService.getCategories(params);

      dispatch({
        type: RETRIEVE_CLEANER_EGEN_QUESTIONNAIRES_CATEGORIES,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const createCleanerQuestionnaireCategory =
  (category) => async (dispatch) => {
    try {
      const res = await EgenQuestionnairesDataService.createCategory(category);
      dispatch({
        type: CREATE_CLEANER_EGEN_QUESTIONNAIRE_CATEGORY,
        payload: res.data,
      });
      dispatch({
        type: MESSAGE_SUCCESS,
        message: t("category_created"),
      });
    } catch (err) {
      console.log(err);
    }
  };

export const retrieveCleanerQuestionnaires =
  (categoryId, params) => async (dispatch) => {
    try {
      const res = await EgenQuestionnairesDataService.getAll(
        categoryId,
        params
      );
      dispatch({
        type: RETRIEVE_CLEANER_EGEN_QUESTIONNAIRES,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const retrieveTeamLeadQuestionnaires =
  (categoryId, params) => async (dispatch) => {
    try {
      const res = await EgenQuestionnairesDataService.getAll(
        categoryId,
        params
      );
      dispatch({
        type: RETRIEVE_TEAM_LEAD_EGEN_QUESTIONNAIRES,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const createTeamLeadQuestionnaire =
  (questionnaire) => async (dispatch) => {
    try {
      const res = await EgenQuestionnairesDataService.create(questionnaire);
      dispatch({
        type: CREATE_TEAM_LEAD_EGEN_QUESTIONNAIRE,
        payload: res.data,
      });
      dispatch({
        type: EGEN_QUESTIONNAIRE_MESSAGE_SUCCESS,
        message: { content: t("questionnaire_created"), type: "TEAM_LEAD" },
      });
    } catch (err) {
      console.log(err);
    }
  };

export const createCleanerQuestionnaire =
  (questionnaire) => async (dispatch) => {
    try {
      const res = await EgenQuestionnairesDataService.create(questionnaire);
      dispatch({
        type: CREATE_CLEANER_EGEN_QUESTIONNAIRE,
        payload: res.data,
      });
      dispatch({
        type: EGEN_QUESTIONNAIRE_MESSAGE_SUCCESS,
        message: { content: t("questionnaire_created"), type: "CLEANER" },
      });
    } catch (err) {
      console.log(err);
    }
  };

export const editTeamLeadQuestionnaire =
  (publicId, questionnaire) => async (dispatch) => {
    try {
      delete questionnaire.publicId;
      const res = await EgenQuestionnairesDataService.update(
        publicId,
        questionnaire
      );

      dispatch({
        type: UPDATE_TEAM_LEAD_EGEN_QUESTIONNAIRE,
        payload: res.data,
      });
      dispatch({
        type: EGEN_QUESTIONNAIRE_MESSAGE_SUCCESS,
        message: { content: t("questionnaire_updated"), type: "TEAM_LEAD" },
      });
    } catch (err) {
      console.log(err);
    }
  };

export const editCleanerQuestionnaire =
  (publicId, questionnaire) => async (dispatch) => {
    try {
      delete questionnaire.publicId;
      const res = await EgenQuestionnairesDataService.update(
        publicId,
        questionnaire
      );

      dispatch({
        type: UPDATE_CLEANER_EGEN_QUESTIONNAIRE,
        payload: res.data,
      });
      dispatch({
        type: EGEN_QUESTIONNAIRE_MESSAGE_SUCCESS,
        message: { content: t("questionnaire_updated"), type: "CLEANER" },
      });
    } catch (err) {
      console.log(err);
    }
  };

export const getQuestionnaire = (publicId) => async (dispatch) => {
  try {
    const res = await EgenQuestionnairesDataService.get(publicId);
    dispatch({
      type: RETRIEVE_EGEN_QUESTIONNAIRE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteQuestionnaire = (publicId) => async (dispatch) => {
  try {
    await EgenQuestionnairesDataService.delete(publicId);

    dispatch({
      type: DELETE_EGEN_QUESTIONNAIRE,
      payload: { publicId },
    });
  } catch (err) {
    console.log(err);
  }
};
