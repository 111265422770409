import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

import Statistics from "./Statistics";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import { NavbarDropdowns, NavbarToggle } from "../../../components/Navbar";
import {
  getDashboardReport1,
  getDashboardReport2,
  getQualityCheckRatingReport,
} from "../../../redux/actions/dashboardActions";
import Column from "./Column";
import ColumnWithLabel from "./ColumnWithLabel";
import moment from "moment";
import { capitalizeFirstLetter, showMessage } from "../../../utils/app-utils";
import { ErrorBoundary } from "react-error-boundary";
import { LANGUAGE_SE, MESSAGE_ERROR } from "../../../config/AppConstant";

const Default = () => {
  const dispatch = useDispatch();
  const { dashboard, sidebar } = useSelector((state) => state);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getDashboardReport1());
    dispatch(getDashboardReport2());
    dispatch(getQualityCheckRatingReport());
  }, [dispatch]);

  let thisYearCompleted = 0;
  let thisYearPending = 0;
  //let thisYearInProgress = 0;
  let thisMonthCompleted = 0;
  let thisMonthPending = 0;
  if (dashboard?.report1) {
    thisMonthCompleted = dashboard?.report1?.currentMonthCompleted;
    thisMonthPending = dashboard?.report1?.currentMonthPending;
    thisYearCompleted = dashboard?.report1?.yearTotalCompleted;
    thisYearPending = dashboard?.report1?.yearTotalPending;
    //thisYearInProgress = dashboard?.report1?.yearTotalInProgress;
  }

  let columnChartData = [];
  let categories = [
    "Jan",
    "Feb",
    t("mar"),
    "Apr",
    t("may"),
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    t("oct"),
    "Nov",
    "Dec",
  ];
  if (dashboard?.report2) {
    for (const empDetail of dashboard?.report2) {
      let columnChartDataObj = { name: "", data: [] };
      columnChartDataObj.name = empDetail.employeeName;
      for (const monthlyDetail of empDetail?.monthlyResponses) {
        columnChartDataObj.data.push(monthlyDetail.count);
      }
      columnChartData.push(columnChartDataObj);
    }
  }

  let columnLabelChartSeries = [];
  if (dashboard?.qualityCheckRating) {
    let columnLabelChartData = {
      name: t("quality_checks_rating"),
      data: dashboard?.qualityCheckRating?.map((rat) => {
        return {
          x: capitalizeFirstLetter(t(rat["x"].toLowerCase())),
          y: t(rat["y"]),
        };
      }),
    };
    columnLabelChartSeries.push(columnLabelChartData);
  }

  let currentMonth = moment().format("MMM");
  if (sidebar.language === LANGUAGE_SE && currentMonth === "Oct") currentMonth = "Okt";
  else if (sidebar.language === LANGUAGE_SE && currentMonth === "Mar")
    currentMonth = "Mars";
  else if (sidebar.language === LANGUAGE_SE && currentMonth === "May")
    currentMonth = "Maj";

  const currentYear = moment().year();
  return (
    <Container fluid>
      <Header>
        <div className="effektToggle">
          <NavbarToggle />
          <HeaderTitle>{t("welcome")}</HeaderTitle>
        </div>
        <div style={{ color: "#0b3e66" }} className="mt-3">
          {t("current_year") +
            " " +
            currentYear +
            " " +
            t("quality_checks_statistic")}
        </div>
        <NavbarDropdowns isDashboard={true} />
      </Header>
      <Row>
        <Col md={6} lg={6} xl={6}>
          <ErrorBoundary
            fallbackRender={() => {}}
            onError={() => {
              showMessage({
                type: MESSAGE_ERROR,
                message: t("quality_rating_fallback_msg"),
              });
            }}
          >
            <ColumnWithLabel
              data={columnLabelChartSeries}
              title={t("current_year_quality_check_heading")}
              height={220}
            />
          </ErrorBoundary>
        </Col>
        <Col md={6} lg={6} xl={6}>
          <ErrorBoundary
            fallbackRender={() => {}}
            onError={() => {
              showMessage({
                type: MESSAGE_ERROR,
                message: t("statistic_fallback_msg"),
              });
            }}
          >
            <Statistics
              thisYearCompleted={thisYearCompleted}
              thisYearPending={thisYearPending}
              thisMonthCompleted={thisMonthCompleted}
              thisMonthPending={thisMonthPending}
              currentYear={currentYear}
              currentMonth={currentMonth}
            />
          </ErrorBoundary>
        </Col>
      </Row>
      <ErrorBoundary
        fallbackRender={() => {}}
        onError={() => {
          showMessage({
            type: MESSAGE_ERROR,
            message: t("employee_qcr_fallback_msg"),
          });
        }}
      >
        <Column
          data={columnChartData}
          title={t("employee_statistics_heading")}
          categories={Array.from(categories)}
        />
      </ErrorBoundary>
    </Container>
  );
};

export default Default;
