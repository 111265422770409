import React from "react";
import { useDispatch } from "react-redux";
import se from "../assets/img/brands/se.png";
import en from "../assets/img/brands/en.png";
import { changeLanguage } from "../redux/actions/sidebarActions";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LANGUAGE_SE, LANGUAGE_US } from "../config/AppConstant";

const FlagIcon = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const handleLangChange = (lng) => {
    i18n.changeLanguage(lng);
    dispatch(changeLanguage(lng));
  };
  return (
    <div className="flagIcon">
      <div className="seFlagIcon mr-1">
        <Link to={"#"} onClick={() => handleLangChange(LANGUAGE_SE)}>
          <img className="flag" src={se} alt="" />
        </Link>
      </div>
      <div className="usFlagIcon">
        <Link to={"#"} onClick={() => handleLangChange(LANGUAGE_US)}>
          <img className="flag" src={en} alt="" />
        </Link>
      </div>
    </div>
  );
};

export default FlagIcon;
