import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import EmptyData from "../../components/EmptyData";
import {
  DATE_TIME_FORMAT,
  MESSAGE_ERROR,
  QUALITY_CHECK_STATUS_COLORS,
  QUALITY_CHECK_STATUS_NOT_STARTED,
  SIZE_PER_PAGE,
} from "../../config/AppConstant";
import {
  exportCompletedEgenControl,
  exportEgenControl,
  retrieveCleanerEgenControlQualityChecks,
} from "../../redux/actions/egenControlActions";
import { retrieveCleaners } from "../../redux/actions/employeeActions";
import { PATH_EGEN_QUALITY_CHECK } from "../../routes/constants";
import {
  getEmployeeOption,
  getStatusMapping,
  showMessage,
} from "../../utils/app-utils";

const CleanerEgenControlList = (props) => {
  const { t } = useTranslation();
  const {
    employees,
    cleanerEgenControlQualityChecks,
    egenControlQualityChecks,
    auth,
  } = useSelector((state) => state);
  const [status, setStatus] = useState("all");
  const [tableFilters, setTableFilters] = useState([]);
  const [selectedCleaner, setSelectedCleaner] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const params = {
      status: true,
      type: "CLEANER",
    };
    auth.isAdmin && dispatch(retrieveCleaners(params, true));
  }, [auth.isAdmin, dispatch]);

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: SIZE_PER_PAGE,
      status: status,
      month: props?.selectedMonth,
      employeeId: selectedCleaner?.value,
      type: "CLEANER",
    };
    for (const dataField in tableFilters) {
      const { filterVal } = tableFilters[dataField];
      params[dataField] = filterVal;
    }
    dispatch(retrieveCleanerEgenControlQualityChecks(params));
  }, [
    dispatch,
    props?.selectedMonth,
    selectedCleaner?.value,
    status,
    tableFilters,
  ]);

  const statusOptions = [
    { value: "all", label: t("all") },
    { value: "completed", label: t("completed") },
    { value: "inprogress", label: t("in_progress") },
    { value: "NOTSTARTED", label: t("not_started") },
    { value: "pendingapproval", label: t("pending_approval") },
    { value: "rejected", label: t("rejected") },
  ];

  const employeeOptions = employees?.allCleaners?.map((employee) =>
    getEmployeeOption(employee)
  );

  const handleTypeDropDownChange = (type) => {
    if (type) {
      setStatus(type?.value);
    }
  };

  const handleCleanerDropDownChange = (cleaner) => {
    if (cleaner) {
      const value = cleaner?.value?.split("|");
      setSelectedCleaner({
        label: cleaner?.label,
        value: value[0],
      });
    } else {
      setSelectedCleaner(null);
    }
  };

  const onExportBtnClick = (event) => {
    const params = {
      status: status,
      month: props?.selectedMonth,
      employeeId: selectedCleaner?.value,
      type: "CLEANER",
    };
    for (const dataField in tableFilters) {
      const { filterVal } = tableFilters[dataField];
      params[dataField] = filterVal;
    }
    dispatch(exportEgenControl(params, selectedCleaner?.label));
  };

  const onExportCompletedClick = (event) => {
    const params = {
      status: "COMPLETED",
      month: props?.selectedMonth,
      type: "CLEANER",
    };
    for (const dataField in tableFilters) {
      const { filterVal } = tableFilters[dataField];
      params[dataField] = filterVal;
    }
    dispatch(exportCompletedEgenControl(params, selectedCleaner?.label));
  };

  const viewReportFormatter = (cell, row, rowIndex) => {
    return row?.status === QUALITY_CHECK_STATUS_NOT_STARTED ? (
      ""
    ) : (
      <div className="table-action ml-4">
        <Link to={PATH_EGEN_QUALITY_CHECK + "/" + row?.publicId}>
          <FontAwesomeIcon
            icon={faEye}
            fixedWidth
            className="align-middle mr-1"
          />
        </Link>
      </div>
    );
  };

  const handleTableChange = (type, newState) => {
    const { page, sizePerPage, filters, sortField, sortOrder } = newState;
    const params = {
      pageNo: page ? page - 1 : "",
      pageSize: sizePerPage ? sizePerPage : "",
      sortBy: sortField ? sortField : "",
      sortDir: sortOrder ? sortOrder : "",
      status: status,
      month: props?.selectedMonth,
      employeeId: selectedCleaner?.value,
      type: "CLEANER",
    };

    for (const dataField in filters) {
      const { filterVal } = filters[dataField];
      params[dataField] = filterVal;
    }
    setTableFilters(filters);
    dispatch(retrieveCleanerEgenControlQualityChecks(params));
  };

  const headerFormatter = (
    column,
    colIndex,
    { sortElement, filterElement }
  ) => {
    return (
      <div>
        <div className={column.dataField === "actions" && "text-center"}>
          {column.text}
          {sortElement}
        </div>
        <div style={{ display: "grid", marginTop: "10px" }}>
          {filterElement}
        </div>
      </div>
    );
  };

  const tableColumns = [
    {
      dataField: "publicId",
      text: "",
      hidden: true,
    },
    {
      dataField: "parentId",
      text: "",
      hidden: true,
    },
    {
      dataField: "reportingId",
      text: t("number"),
      sort: status !== QUALITY_CHECK_STATUS_NOT_STARTED,
      filter:
        status !== QUALITY_CHECK_STATUS_NOT_STARTED
          ? textFilter({
              placeholder: t("search"),
            })
          : "",
      headerStyle: (colum, colIndex) => {
        return { width: "11%", verticalAlign: "top" };
      },
      formatter: (cell, row, rowIndex) => {
        return <div>{row?.reportingId || "N/A"}</div>;
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "customerName",
      text: t("customer_name"),
      sort: auth.isAdmin,
      hidden: auth.isCustomer,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "16%", verticalAlign: "top" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "objectNumber",
      text: t("object_number"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "11%", verticalAlign: "top" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "objectName",
      text: t("object_name"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "14%", verticalAlign: "top" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "addressInfo",
      text: t("address"),
      sort: auth.isAdmin,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerFormatter: headerFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "13%", verticalAlign: "top" };
      },
    },
    {
      dataField: "performedBy",
      text: t("performed_by"),
      sort: status !== QUALITY_CHECK_STATUS_NOT_STARTED,
      filter:
        status !== QUALITY_CHECK_STATUS_NOT_STARTED
          ? selectFilter({
              key: t("team_lead"),
              placeholder: t("select"),
              options: [
                {
                  value: "TEAM_LEAD",
                  label: t("team_lead"),
                },
                {
                  value: "CLEANER",
                  label: t("cleaner"),
                },
              ],
            })
          : "",

      headerStyle: (colum, colIndex) => {
        return { width: "14%", verticalAlign: "top" };
      },
      formatter: (cell, row, rowIndex, t) => {
        return (
          <div>
            {row?.status === QUALITY_CHECK_STATUS_NOT_STARTED
              ? t("cleaner")
              : row?.performedBy === "TEAM_LEAD"
              ? t("team_lead")
              : t("cleaner")}
          </div>
        );
      },

      formatExtraData: t,
      headerFormatter: headerFormatter,
    },
    {
      dataField: "performerName",
      text: t("performer"),
      sort: false,

      headerStyle: (colum, colIndex) => {
        return { width: "12%", verticalAlign: "top" };
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <div>
            {row?.status === QUALITY_CHECK_STATUS_NOT_STARTED
              ? "N/A"
              : row?.performerName}
          </div>
        );
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "performedDate",
      text: t("performed_date"),
      sort: auth.isAdmin && status !== QUALITY_CHECK_STATUS_NOT_STARTED,
      // filter:
      //   auth.isAdmin &&
      //   textFilter({
      //     placeholder: t("search"),
      //   }),

      headerStyle: (colum, colIndex) => {
        return { width: "10%", verticalAlign: "top" };
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <div>
            {row?.status === QUALITY_CHECK_STATUS_NOT_STARTED
              ? "N/A"
              : moment(row?.performedDate).format(DATE_TIME_FORMAT)}
          </div>
        );
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "status",
      text: t("status"),
      headerStyle: (colum, colIndex) => {
        return { width: "9%", verticalAlign: "top" };
      },
      sort: auth.isAdmin && status !== QUALITY_CHECK_STATUS_NOT_STARTED,
      formatter: (cell, row, rowIndex, t) => {
        return (
          <Badge
            style={{
              backgroundColor: QUALITY_CHECK_STATUS_COLORS[row?.status],
            }}
          >
            {t(getStatusMapping()[row?.status])}
          </Badge>
        );
      },
      formatExtraData: t,
      headerFormatter: headerFormatter,
    },
    {
      dataField: "viewReport",
      text: t("view_report"),
      formatter: viewReportFormatter,
      headerFormatter: headerFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "7%", verticalAlign: "top" };
      },
    },
  ];

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          {t("cleaner_egen_control")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        {auth.isAdmin && (
          <Row>
            <Col md={3} lg={3} xl={3}>
              <FormGroup>
                <Label for="filter_by_type">{t("filter_by_type")}</Label>
                <ReactSelect
                  key={t("all")}
                  className="calenderSelect react-select-container"
                  classNamePrefix="react-select"
                  value={{
                    value: status,
                    label: t(getStatusMapping()[status.toUpperCase()]),
                  }}
                  options={statusOptions}
                  placeholder={t("select")}
                  onChange={handleTypeDropDownChange}
                />
              </FormGroup>
            </Col>
            <Col md={3} lg={3} xl={3}>
              <FormGroup>
                <Label for="filter_by_cleaner">{t("filter_by_cleaner")}</Label>
                <ReactSelect
                  className="calenderSelect react-select-container"
                  classNamePrefix="react-select"
                  placeholder={t("select")}
                  isClearable
                  options={employeeOptions}
                  onChange={handleCleanerDropDownChange}
                  value={selectedCleaner ? selectedCleaner : ""}
                />
              </FormGroup>
            </Col>
            <Col md={6} lg={6} xl={6}>
              <FormGroup>
                <div className="mt-5" style={{ float: "right" }}>
                  <Button
                    className="mr-3"
                    color="primary"
                    disabled={egenControlQualityChecks.isLoadingReport}
                    onClick={onExportCompletedClick}
                  >
                    {egenControlQualityChecks.isLoadingReport ? (
                      <Spinner />
                    ) : (
                      t("export_completed")
                    )}
                  </Button>
                  <Button
                    color="primary"
                    disabled={egenControlQualityChecks.isLoadingReport}
                    onClick={onExportBtnClick}
                  >
                    {egenControlQualityChecks.isLoadingReport ? (
                      <Spinner />
                    ) : (
                      t("export")
                    )}
                  </Button>
                </div>
              </FormGroup>
            </Col>
          </Row>
        )}
        <BootstrapTable
          classes={"mt-2"}
          condensed
          keyField="publicId"
          data={cleanerEgenControlQualityChecks.data}
          remote={true}
          columns={tableColumns}
          striped
          hover
          bootstrap4
          bordered={false}
          filter={filterFactory()}
          pagination={paginationFactory({
            sizePerPage: cleanerEgenControlQualityChecks.sizePerPage,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true,
            totalSize: cleanerEgenControlQualityChecks.totalSize,
          })}
          onTableChange={handleTableChange}
          loading={cleanerEgenControlQualityChecks.isLoading}
          noDataIndication={() => {
            return (
              <EmptyData
                isLoading={cleanerEgenControlQualityChecks.isLoading}
              />
            );
          }}
        ></BootstrapTable>
      </CardBody>
    </Card>
  );
};

const CleanerEgenControl = (props) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col>
        <ErrorBoundary
          fallbackRender={() => {}}
          onError={() => {
            showMessage({
              type: MESSAGE_ERROR,
              message: t("egen_cleaner_fallback_msg"),
            });
          }}
        >
          <CleanerEgenControlList selectedMonth={props?.selectedMonth} />
        </ErrorBoundary>
      </Col>
    </Row>
  );
};

export default CleanerEgenControl;
