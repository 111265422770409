import React from "react";
import classNames from "classnames";
import topRight from "../assets/img/brands/top-right.png";

const Wrapper = ({ className, children }) => (
  <div className={classNames("wrapper", className)}>
    <img src={topRight} alt="" className="absolute-right w-20" />
    {children}
  </div>
);

export default Wrapper;
