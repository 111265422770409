import React from "react";
import { connect } from "react-redux";

import Content from "../components/Content";
import Main from "../components/Main";
import Sidebar from "../components/Sidebar";
import Wrapper from "../components/Wrapper";
import CustomerDetails from "../pages/customer/CustomerDetails";

const Dashboard = ({ sidebar, auth, children }) => (
  <React.Fragment>
    <Wrapper>
      {!sidebar.isOnRight && <Sidebar />}
      <Main>
        {auth.isCustomer && <CustomerDetails />}
        <Content>{children}</Content>
      </Main>
      {sidebar.isOnRight && <Sidebar />}
    </Wrapper>
  </React.Fragment>
);

export default connect((store) => ({
  sidebar: store.sidebar,
  auth: store.auth,
}))(Dashboard);
