import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import {
  MESSAGE_ERROR,
  MESSAGE_SUCCESS,
  SIZE_PER_PAGE,
  STATUS,
  STATUS_COLORS,
} from "../../config/AppConstant";
import {
  createAgreement,
  // deleteAgreement,
  editAgreement,
  retrieveAgreements,
  retrieveCustomerAgreements,
} from "../../redux/actions/agreementActions";

import { PATH_DASHBOARD } from "../../routes/constants";
import { showMessage } from "../../utils/app-utils";
import UpdateAgreement from "./UpdateAgreement";

import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ErrorBoundary } from "react-error-boundary";
import { MinusCircle, PlusCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import EmptyData from "../../components/EmptyData";
import { AddressesList } from "../address/AddressList";
import { NavbarDropdowns, NavbarToggle } from "../../components/Navbar";

export const AgreementList = (props) => {
  const { agreements } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: SIZE_PER_PAGE,
      status: "ALL",
    };
    if (props.customerId) {
      dispatch(retrieveCustomerAgreements(props.customerId, params));
    } else dispatch(retrieveAgreements(params));
  }, [dispatch, props.customerId]);

  // const removeAgreement = (publicId) => {
  //   dispatch(deleteAgreement(publicId));
  // };

  const onAgreementSelectHandler = (publicId) => {
    let agreement = {
      publicId: "",
      customerId: "",
      customerName: "",
      agreementNumber: "",
      description: "",
      duration: 60,
    };
    if (publicId) {
      let ag = agreements?.data.find((obj) => obj.publicId === publicId);
      agreement.publicId = ag.publicId;
      agreement.customerId = ag.customer.publicId;
      agreement.customerName = ag.customer.email;
      agreement.agreementNumber = ag.agreementNumber;
      agreement.description = ag.description;
      agreement.duration = ag.duration;
    }
    props.setAgreement(agreement);
  };

  const actionFormatter = (cell, row, rowIndex) => {
    return props.customerId || !row.active ? (
      ""
    ) : (
      <div className="table-action text-center">
        <Link to={"#"} onClick={() => onAgreementSelectHandler(row?.publicId)}>
          <FontAwesomeIcon
            icon={faPen}
            fixedWidth
            className="align-middle mr-1"
          />
        </Link>
        {/* <Link to={"#"} onClick={() => removeAgreement(agreement.publicId)}>
          <FontAwesomeIcon icon={faTrash} fixedWidth className="align-middle" />
        </Link> */}
      </div>
    );
  };

  const handleTableChange = (type, newState) => {
    const { page, sizePerPage, filters, sortField, sortOrder, status } =
      newState;
    const params = {
      pageNo: page ? page - 1 : "",
      pageSize: sizePerPage ? sizePerPage : "",
      sortBy: sortField ? sortField : "",
      sortDir: sortOrder ? sortOrder : "",
      status: status ? status : "ALL",
    };
    for (const dataField in filters) {
      const { filterVal } = filters[dataField];
      const fieldItems = dataField.split(".");
      params[fieldItems[fieldItems.length - 1]] = filterVal;
    }
    if (props.customerId) {
      dispatch(retrieveCustomerAgreements(props.customerId, params));
    } else dispatch(retrieveAgreements(params));
  };

  const headerFormatter = (
    column,
    colIndex,
    { sortElement, filterElement }
  ) => {
    return (
      <div>
        <div>
          {column.text}
          {sortElement}
        </div>
        <div style={{ display: "grid", marginTop: "10px" }}>
          {filterElement}
        </div>
      </div>
    );
  };

  const tableColumns = [
    {
      dataField: "publicId",
      text: "",
      hidden: true,
    },
    {
      dataField: "agreementNumber",
      text: t("agreement_number"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "description",
      text: t("agreement_description"),
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerFormatter: headerFormatter,
    },
    {
      dataField: "customer.name",
      text: t("customer_name"),
      hidden: props.customerId,
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerFormatter: headerFormatter,
    },
    {
      dataField: "customer.organizationNumber",
      text: t("organization_number"),
      hidden: props.customerId,
      sort: true,
      filter: textFilter({
        placeholder: t("search"),
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "status",
      text: t("status"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      filter: selectFilter({
        key: t("active"),
        placeholder: t("select"),
        options: [
          {
            value: true,
            label: t("active"),
          },
          {
            value: false,
            label: t("in_active"),
          },
        ],
      }),
      formatter: (cell, row, rowIndex) => {
        return (
          <Badge style={{ backgroundColor: STATUS_COLORS[row?.active] }}>
            {STATUS[row?.active]}
          </Badge>
        );
      },
      headerFormatter: headerFormatter,
    },
    {
      dataField: "actions",
      text: "",
      hidden: props.customerId,
      formatter: actionFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      headerFormatter: headerFormatter,
    },
  ];

  const expandRow = {
    renderer: (row) => (
      <div>
        <AddressesList agreementId={row.publicId} />
      </div>
    ),
    showExpandColumn: true,
    onlyOneExpanding: true,
    parentClassName: "table-expand-row",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => null,
    expandColumnRenderer: ({ expanded }) =>
      expanded ? (
        <MinusCircle width={16} height={16} />
      ) : (
        <PlusCircle width={16} height={16} />
      ),
  };

  return (
    <Card>
      <CardHeader>
        {props.customerId ? (
          ""
        ) : (
          <div className="card-actions float-right">
            <Button
              color="primary"
              className="mr-1 mb-1"
              onClick={() => onAgreementSelectHandler(null)}
            >
              <FontAwesomeIcon icon={faPlus} /> {t("add")}
            </Button>
          </div>
        )}
        <CardTitle tag="h5" className="mb-0">
          {t("agreements")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <BootstrapTable
          condensed
          keyField="publicId"
          data={agreements.data}
          remote={true}
          columns={tableColumns}
          bootstrap4
          expandRow={expandRow}
          striped
          hover
          bordered={false}
          filter={filterFactory()}
          pagination={paginationFactory({
            sizePerPage: agreements.sizePerPage,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true,
            totalSize: agreements.totalSize,
          })}
          onTableChange={handleTableChange}
          loading={agreements.isLoading}
          noDataIndication={() => {
            return (
              <EmptyData
                publicId={props.customerId}
                isLoading={agreements.isLoading}
              />
            );
          }}
        ></BootstrapTable>
      </CardBody>
    </Card>
  );
};
const Agreements = (props) => {
  const { messages } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [agreement, setAgreement] = useState(null);
  const { message } = messages;
  const { t } = useTranslation();
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      if (message.type === "success") {
        showMessage({
          type: MESSAGE_SUCCESS,
          message: message.detail,
        });
        setAgreement(null);
      }
    } else {
      isMounted.current = true;
    }
  }, [messages, message]);

  const handleOnSubmit = (agreement) => {
    if (agreement.publicId) {
      dispatch(editAgreement(agreement));
    } else {
      dispatch(createAgreement(agreement));
    }
  };
  return (
    <Container fluid>
      <Header>
        <div className="effektToggle">
          <NavbarToggle />
          <HeaderTitle>{t("agreements")}</HeaderTitle>
        </div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={PATH_DASHBOARD}>{t("dashboard")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("agreements")}</BreadcrumbItem>
        </Breadcrumb>
        <NavbarDropdowns />
      </Header>
      <Row>
        <Col>
          {" "}
          <ErrorBoundary
            fallbackRender={() => {}}
            onError={() => {
              showMessage({
                type: MESSAGE_ERROR,
                message: t("agreements_fallback_msg"),
              });
            }}
          >
            <AgreementList setAgreement={setAgreement} {...props} />
          </ErrorBoundary>
        </Col>
        {agreement && (
          <Col className="col-md-3 col-xxl-3">
            <ErrorBoundary
              fallbackRender={() => {}}
              onError={() => {
                showMessage({
                  type: MESSAGE_ERROR,
                  message: t("agreement_fallback_msg"),
                });
              }}
            >
              <UpdateAgreement
                agreement={agreement}
                setAgreement={setAgreement}
                handleOnSubmit={handleOnSubmit}
              />
            </ErrorBoundary>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Agreements;
