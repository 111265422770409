import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import {
  adminRoutes,
  auth as authRoutes,
  customerRoutes,
  egenControlActionRoutes,
  landing as landingRoute,
} from "./index";

import AuthLayout from "../layouts/Auth";
import DashboardLayout from "../layouts/Dashboard";
import Page404 from "../pages/auth/Page404";

import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "../components/ScrollToTop";
import { unAuthorizedUser, userLogOut } from "../redux/actions/authActions";
import { PATH_LANDING } from "./constants";
import { showMessage } from "../utils/app-utils";
import { MESSAGE_ERROR } from "../config/AppConstant";
import { t } from "i18next";

const childRoutes = (Layout, routes) =>
  routes
    ? // Route item with children
      routes.map(({ path, component: Component }, index) =>
        getRoute(Layout, path, Component, index)
      )
    : [];
const getRoute = (Layout, path, Component, index) => (
  <Route
    key={index}
    path={path}
    exact
    render={(props) => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);

const configureRoutes = (Layout, routes) =>
  routes.map(({ children, path, component }, index) => {
    // Route item with children
    const allRoutes = childRoutes(Layout, children);
    if (component) {
      // Route item without children
      allRoutes.push(getRoute(Layout, path, component, index));
    }
    return allRoutes;
  });

const Routes = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  let routes = [];

  if (auth.isAdmin) {
    routes = adminRoutes;
  } else if (auth.isCustomer) {
    routes = customerRoutes;
  } else if (auth.isEmployee) {
    dispatch(unAuthorizedUser());
    showMessage({
      type: MESSAGE_ERROR,
      message: t("un_authorized"),
    });
  } else {
    if (auth.token && !auth.roles) {
      dispatch(userLogOut());
    }
  }
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          {configureRoutes(AuthLayout, authRoutes)}
          {configureRoutes(AuthLayout, landingRoute)}
          {configureRoutes(AuthLayout, egenControlActionRoutes)}
          {auth.isLoggedIn ? (
            configureRoutes(DashboardLayout, routes)
          ) : (
            <Redirect to={PATH_LANDING} />
          )}
          <Route
            render={() => (
              <AuthLayout>
                <Page404 />
              </AuthLayout>
            )}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};
export default Routes;
