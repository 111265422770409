import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderTitle from "../../components/HeaderTitle";
import { getCustomerDetails } from "../../redux/actions/customerActions";

const CustomerDetails = (props) => {
  const [customerName, setCustomerName] = useState("");

  const dispatch = useDispatch();

  const { customers } = useSelector((state) => state);
  useEffect(() => {
    dispatch(getCustomerDetails());
  }, []);

  useEffect(() => {
    if (customers?.data) {
      setCustomerName(customers?.data[0]?.name);
    } else {
      setCustomerName("");
    }
  }, [customers?.data]);

  return (
    <>
      {customerName && (
        <div
          className="ml-6 mt-3"
          style={{
            display: "flex",
            marginBottom: "-30px",
          }}
        >
          <HeaderTitle style={{ fontSize: "2.2rem", color: "#0b2a62" }}>
            {customerName}
          </HeaderTitle>
        </div>
      )}
    </>
  );
};

export default CustomerDetails;
