import {
  CREATE_QUESTIONNAIRE,
  DELETE_ALL_QUESTIONNAIRES,
  DELETE_QUESTIONNAIRE,
  RETRIEVE_QUESTIONNAIRES,
  RETRIEVE_QUESTIONNAIRE,
  UPDATE_QUESTIONNAIRE,
} from "../constants";

const initialState = {
  data: [],
  page: 0,
  sizePerPage: 0,
  totalSize: 0,
  isLoading: true,
  isError: false,
};

export default function reducer(state = initialState, actions) {
  const { type, payload } = actions;
  switch (type) {
    case CREATE_QUESTIONNAIRE:
      return {
        ...state,
        data: [...state.data, payload],
        isLoading: false,
      };
    case RETRIEVE_QUESTIONNAIRES:
      return {
        ...state,
        data: payload.content,
        page: payload.pageNo,
        sizePerPage: payload.pageSize,
        totalSize: payload.totalElements,
        isLoading: false,
      };
    case RETRIEVE_QUESTIONNAIRE:
      return {
        ...state,
        data: [payload],
        isLoading: false,
      };
    case UPDATE_QUESTIONNAIRE:
      return {
        ...state,
        data: state.data.map((questionnaire) => {
          if (questionnaire.publicId === payload.publicId) {
            return {
              ...questionnaire,
              ...payload,
            };
          } else {
            return questionnaire;
          }
        }),
        isLoading: false,
      };

    case DELETE_QUESTIONNAIRE:
      return {
        ...state,
        data: state.data.filter(
          ({ publicId }) => publicId !== payload.publicId
        ),
        isLoading: false,
      };

    case DELETE_ALL_QUESTIONNAIRES:
      return [];
    default:
      return state;
  }
}
