import { t } from "i18next";
import CustomerDataService from "../../services/customerService";
import {
  CREATE_CUSTOMER,
  DELETE_CUSTOMER,
  MESSAGE_SUCCESS,
  RETRIEVE_CUSTOMER,
  RETRIEVE_CUSTOMERS,
  RETRIEVE_CUSTOMER_LOGO,
  RETRIEVE_CUSTOMER_TEAM_LEADS,
  RETRIEVE_TEAM_LEAD_CUSTOMERS,
  UPDATE_CUSTOMER,
} from "../constants";

export const retrieveCustomers = (params) => async (dispatch) => {
  try {
    const res = await CustomerDataService.getAll(params);
    dispatch({
      type: RETRIEVE_CUSTOMERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const createCustomer = (customer) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("customerNumber", customer.customerNumber);
    formData.append("name", customer.name);
    formData.append("username", customer.username);
    formData.append("password", customer.password);
    formData.append("email", customer.email);
    formData.append("phoneNumber", customer.phoneNumber);
    formData.append("teamLeadId", customer.teamLeadId);
    formData.append("organizationNumber", customer.organizationNumber);
    formData.append("duration", customer.duration);
    formData.append("active", customer.active);
    customer.image_file && formData.append("logo", customer.image_file);
    const res = await CustomerDataService.create(formData);
    dispatch({
      type: CREATE_CUSTOMER,
      payload: res.data,
    });
    dispatch({
      type: MESSAGE_SUCCESS,
      message: t("customer_created"),
    });
  } catch (err) {
    console.log(err);
  }
};

export const getCustomer = (publicId) => async (dispatch) => {
  try {
    const res = await CustomerDataService.get(publicId);
    dispatch({
      type: RETRIEVE_CUSTOMER,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getCustomerLogo = (publicId) => async (dispatch) => {
  try {
    const res = await CustomerDataService.getLogo(publicId);
    dispatch({
      type: RETRIEVE_CUSTOMER_LOGO,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getCustomerDetails = () => async (dispatch) => {
  try {
    const res = await CustomerDataService.getDetails();
    dispatch({
      type: RETRIEVE_CUSTOMER,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteCustomer = (publicId) => async (dispatch) => {
  try {
    await CustomerDataService.delete(publicId);

    dispatch({
      type: DELETE_CUSTOMER,
      payload: { publicId },
    });
  } catch (err) {
    console.log(err);
  }
};

export const changeCustomerPassword = (customer) => async (dispatch) => {
  try {
    await CustomerDataService.changePassword(customer);
    dispatch({
      type: MESSAGE_SUCCESS,
      message: t("customer_updated"),
    });
  } catch (err) {
    console.log(err);
  }
};

export const editCustomer = (customer) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("publicId", customer.publicId);
    formData.append("name", customer.name);
    formData.append("username", customer.username);
    formData.append("password", customer.password);
    formData.append("email", customer.email);
    formData.append("phoneNumber", customer.phoneNumber);
    formData.append("teamLeadId", customer.teamLeadId);
    formData.append("organizationNumber", customer.organizationNumber);
    formData.append("customerNumber", customer.customerNumber);
    formData.append("duration", customer.duration);
    formData.append("active", customer.active);
    customer.image_file && formData.append("logo", customer.image_file);
    const res = await CustomerDataService.update(formData);
    dispatch({
      type: UPDATE_CUSTOMER,
      payload: res.data,
    });
    dispatch({
      type: MESSAGE_SUCCESS,
      message: t("customer_updated"),
    });
  } catch (err) {
    console.log(err);
  }
};

export const retrieveCustomerTeamLeads = (publicId) => async (dispatch) => {
  try {
    const res = await CustomerDataService.getTeamLeads(publicId);
    dispatch({
      type: RETRIEVE_CUSTOMER_TEAM_LEADS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const retrieveTeamLeadCustomers = (publicId) => async (dispatch) => {
  try {
    const res = await CustomerDataService.retrieveTeamLeadCustomers(publicId);
    dispatch({
      type: RETRIEVE_TEAM_LEAD_CUSTOMERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
