import { t } from "i18next";
import EgenControlDataService from "../../services/egenControlService";
import {
  APPROVE_OR_REJECT_EGEN_CONTROL_ACTION_URL,
  CLEAR_CLEANER_EGEN_CONTROL_QUALITY_CHECKS,
  CLEAR_TEAM_LEAD_EGEN_CONTROL_QUALITY_CHECKS,
  CREATE_EGEN_CONTROL_QUALITY_CHECK,
  DELETE_EGEN_CONTROL_QUALITY_CHECK,
  MESSAGE_FAILURE,
  MESSAGE_SUCCESS,
  RETRIEVE_CLEANER_EGEN_CONTROL_QUALITY_CHECKS,
  RETRIEVE_EGEN_CONTROL_QUALITY_CHECK,
  RETRIEVE_EGEN_CONTROL_QUALITY_CHECK_REPORT,
  RETRIEVE_TEAM_LEAD_EGEN_CONTROL_QUALITY_CHECKS,
  UPDATE_EGEN_CONTROL_QUALITY_CHECK,
  VALIDATE_EGEN_CONTROL_ACTION_URL,
} from "../constants";

export const retrieveTeamLeadEgenControlQualityChecks =
  (params) => async (dispatch) => {
    try {
      dispatch({
        type: CLEAR_TEAM_LEAD_EGEN_CONTROL_QUALITY_CHECKS,
        payload: "",
      });
      const res = await EgenControlDataService.getAll(params);
      dispatch({
        type: RETRIEVE_TEAM_LEAD_EGEN_CONTROL_QUALITY_CHECKS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const retrieveCleanerEgenControlQualityChecks =
  (params) => async (dispatch) => {
    try {
      dispatch({
        type: CLEAR_CLEANER_EGEN_CONTROL_QUALITY_CHECKS,
        payload: "",
      });
      const res = await EgenControlDataService.getAll(params);
      dispatch({
        type: RETRIEVE_CLEANER_EGEN_CONTROL_QUALITY_CHECKS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const getEgenControlQualityCheck = (publicId) => async (dispatch) => {
  try {
    const res = await EgenControlDataService.get(publicId);
    dispatch({
      type: RETRIEVE_EGEN_CONTROL_QUALITY_CHECK,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getEgenControlQualityCheckReport =
  (publicId) => async (dispatch) => {
    try {
      dispatch({
        type: RETRIEVE_EGEN_CONTROL_QUALITY_CHECK_REPORT,
        payload: true,
      });
      const res = await EgenControlDataService.getReport(publicId);
      dispatch({
        type: RETRIEVE_EGEN_CONTROL_QUALITY_CHECK_REPORT,
        payload: false,
      });
      const type = res.headers["content-type"];
      const blob = new Blob([res.data], { type: type, encoding: "UTF-8" });
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    } catch (err) {
      console.log(err);
    }
  };

export const deleteEgenControlQualityCheck = (publicId) => async (dispatch) => {
  try {
    await EgenControlDataService.delete(publicId);

    dispatch({
      type: DELETE_EGEN_CONTROL_QUALITY_CHECK,
      payload: { publicId },
    });
  } catch (err) {
    console.log(err);
  }
};

export const editEgenControlQualityCheck =
  (egenControlQualityCheck) => async (dispatch) => {
    try {
      const res = await EgenControlDataService.update(egenControlQualityCheck);

      dispatch({
        type: UPDATE_EGEN_CONTROL_QUALITY_CHECK,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const editEgenControlQualityCheckPerformedDate =
  (egenControlQualityCheck) => async (dispatch) => {
    try {
      const res = await EgenControlDataService.updatePerformedDate(
        egenControlQualityCheck
      );

      dispatch({
        type: UPDATE_EGEN_CONTROL_QUALITY_CHECK,
        payload: res.data,
      });
      dispatch({
        type: MESSAGE_SUCCESS,
        message: t("performed_date_updated"),
      });
    } catch (err) {
      console.log(err);
    }
  };

export const createEgenControlQualityCheck =
  (egenControlQualityCheck) => async (dispatch) => {
    try {
      const res = await EgenControlDataService.create(egenControlQualityCheck);
      dispatch({
        type: CREATE_EGEN_CONTROL_QUALITY_CHECK,
        payload: res.data,
      });
      dispatch({
        type: MESSAGE_SUCCESS,
        message: "success",
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: MESSAGE_FAILURE,
        message: "failure",
      });
    }
  };

export const isValidUrl = (id) => async (dispatch) => {
  try {
    const res = await EgenControlDataService.validateUrl(id);
    dispatch({
      type: VALIDATE_EGEN_CONTROL_ACTION_URL,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const approveOrRejectQualityCheck = (uuid, data) => async (dispatch) => {
  try {
    dispatch({
      type: RETRIEVE_EGEN_CONTROL_QUALITY_CHECK_REPORT,
      payload: true,
    });

    const res = await EgenControlDataService.updateStatus(uuid, data);
    dispatch({
      type: APPROVE_OR_REJECT_EGEN_CONTROL_ACTION_URL,
      payload: res.data,
    });
    let successMsg =
      data.status === "APPROVED"
        ? "Egenkontroll har godkänts."
        : "Egenkontroll avvisades.";
    dispatch({
      type: MESSAGE_SUCCESS,
      message: successMsg,
    });
    dispatch({
      type: RETRIEVE_EGEN_CONTROL_QUALITY_CHECK_REPORT,
      payload: false,
    });
  } catch (err) {
    console.log(err);
  }
};

export const exportCompletedEgenControl =
  (params, employeeName) => async (dispatch) => {
    try {
      dispatch({
        type: RETRIEVE_EGEN_CONTROL_QUALITY_CHECK_REPORT,
        payload: true,
      });
      const res = await EgenControlDataService.exportCompletedEgenControl(
        params
      );
      dispatch({
        type: RETRIEVE_EGEN_CONTROL_QUALITY_CHECK_REPORT,
        payload: false,
      });
      const type = "Lokalvårdare";
      employeeName = employeeName ? employeeName.split("-")[1] : "";
      employeeName = employeeName.trim();

      let outputFilename = employeeName
        ? `${params?.month}-${employeeName}-${type}`
        : `${params?.month}-${type}`;
      const statusValue =
        params?.status?.charAt(0).toUpperCase() + params?.status?.slice(1);
      outputFilename = outputFilename + `-${statusValue}`;
      outputFilename = outputFilename + ".xlsx";

      const url = URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.log(err);
      dispatch({
        type: RETRIEVE_EGEN_CONTROL_QUALITY_CHECK_REPORT,
        payload: false,
      });
    }
  };

export const exportEgenControl = (params, employeeName) => async (dispatch) => {
  try {
    dispatch({
      type: RETRIEVE_EGEN_CONTROL_QUALITY_CHECK_REPORT,
      payload: true,
    });
    const res = await EgenControlDataService.exportEgenControl(params);
    dispatch({
      type: RETRIEVE_EGEN_CONTROL_QUALITY_CHECK_REPORT,
      payload: false,
    });
    const type = params.type === "CLEANER" ? "Lokalvårdare" : "Driftledare";
    employeeName = employeeName ? employeeName.split("-")[1] : "";
    employeeName = employeeName.trim();

    let outputFilename = employeeName
      ? `${params?.month}-${employeeName}-${type}`
      : `${params?.month}-${type}`;
    const statusValue =
      params?.status?.charAt(0).toUpperCase() + params?.status?.slice(1);
    outputFilename = outputFilename + `-${statusValue}`;
    outputFilename = outputFilename + ".xlsx";

    const url = URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", outputFilename);
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    console.log(err);
    dispatch({
      type: RETRIEVE_EGEN_CONTROL_QUALITY_CHECK_REPORT,
      payload: false,
    });
  }
};
