import RepresentativeDataService from "../../services/representativeService";
import {
  CREATE_REPRESENTATIVE,
  DELETE_REPRESENTATIVE,
  MESSAGE_FAILURE,
  MESSAGE_SUCCESS,
  RETRIEVE_REPRESENTATIVE,
  RETRIEVE_REPRESENTATIVES,
  UPDATE_REPRESENTATIVE,
} from "../constants";

export const retrieveRepresentatives = (publicId) => async (dispatch) => {
  try {
    const res = await RepresentativeDataService.getAll(publicId);
    dispatch({
      type: RETRIEVE_REPRESENTATIVES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const createRepresentative = (representative) => async (dispatch) => {
  try {
    const res = await RepresentativeDataService.create(representative);
    dispatch({
      type: CREATE_REPRESENTATIVE,
      payload: res.data,
    });
    dispatch({
      type: MESSAGE_SUCCESS,
      message: "success",
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: MESSAGE_FAILURE,
      message: "failure",
    });
  }
};

export const getRepresentative = (publicId) => async (dispatch) => {
  try {
    const res = await RepresentativeDataService.get(publicId);
    dispatch({
      type: RETRIEVE_REPRESENTATIVE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteRepresentative = (publicId) => async (dispatch) => {
  try {
    await RepresentativeDataService.delete(publicId);

    dispatch({
      type: DELETE_REPRESENTATIVE,
      payload: { publicId },
    });
  } catch (err) {
    console.log(err);
  }
};

export const editRepresentative = (representative) => async (dispatch) => {
  try {
    const res = await RepresentativeDataService.update(representative);

    dispatch({
      type: UPDATE_REPRESENTATIVE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
