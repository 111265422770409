import noResult from "../assets/img/nothing-found.png";
import { useTranslation } from "react-i18next";
import { Spinner } from "reactstrap";
const EmptyData = (props) => {
  const { t } = useTranslation();
  const { isLoading } = props;
  return (
    <div className="text-center">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {" "}
          <img
            src={noResult}
            width="auto"
            height={!props.publicId ? 200 : 100}
            alt={"No Data Found"}
          />
          <p className={!props.publicId ? "h2" : "h5"}>{t("oops")}</p>
          <p>{t("no_data_found")}</p>
        </>
      )}
    </div>
  );
};
export default EmptyData;
