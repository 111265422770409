import "moment/locale/en-gb";
import "moment/locale/sv";
import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col, Label, Row, Table } from "reactstrap";
import DateTime from "react-datetime";
import { DATE_TIME_FORMAT } from "../../config/AppConstant";

const ScheduleList = (props) => {
  const { t } = useTranslation();
  let scheduleCat = props.address?.scheduleCategory;
  // const startDate = scheduleCat?.startDate
  //   ? DateTime.moment.utc(scheduleCat?.startDate)
  //   : "";
  // const endDate = scheduleCat?.endDate
  //   ? DateTime.moment.utc(scheduleCat?.endDate)
  //   : "";
  const style = {
    table: {
      width: "100%",
      display: "table",
      borderSpacing: 0,
      borderCollapse: "separate",
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      position: "sticky",
      backgroundColor: "#fff",
    },
  };
  return (
    <Card>
      <CardBody>
        <h5 className="mb-3">{t("upcoming_schedule_list")}</h5>
        {/* <Row className="m-sm-2 m-md-2">
          <Col>
            <div>
              <h7>{t("start_date")}: </h7>
              {startDate.format(DATE_TIME_FORMAT)}
            </div>
          </Col>
          <Col>
            <div style={{ float: "right" }}>
              <h7>{t("end_date")}: </h7>
              {endDate.format(DATE_TIME_FORMAT)}
            </div>
          </Col>
        </Row> */}
        {scheduleCat?.schedules?.length > 0 && (
          <Row className="mt-3">
            <Col>
              {/* <Label className="font-weight-bold" for="schedule_list">
                {t("schedule_list")}
              </Label> */}
              <div
                style={{
                  maxHeight: "253px",
                  overflowY: "auto",
                }}
              >
                <Table size="sm" striped height="253" style={style.table}>
                  <thead>
                    <tr>
                      <th style={style.th}>{t("start_date")}</th>
                      <th style={style.th}>{t("end_date")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {scheduleCat.schedules.map((schedule) => {
                      const scheduleStartDate = DateTime.moment.utc(
                        schedule.start
                      );
                      const scheduleEndDate = DateTime.moment.utc(schedule.end);
                      return (
                        <tr>
                          <td>{scheduleStartDate.format(DATE_TIME_FORMAT)}</td>
                          <td>{scheduleEndDate.format(DATE_TIME_FORMAT)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default ScheduleList;
