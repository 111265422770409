import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
} from "reactstrap";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fullcalendar/react/dist/vdom";
import DateTime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";

import { useTranslation } from "react-i18next";
import "tippy.js/dist/tippy.css";
import {
  getTeamLeadQualityCheckRatingReport,
  getTeamLeadQuestionQualityCheckRatingReport,
} from "../../redux/actions/dashboardActions";
import YearlyTable from "./YearlyTable";

const TeamleadQualityAndQuestionRating = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dashboard } = useSelector((state) => state);
  const [reportType, setReportType] = useState("all");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    dispatch(getTeamLeadQualityCheckRatingReport());
    dispatch(getTeamLeadQuestionQualityCheckRatingReport());
  }, [dispatch]);

  useEffect(() => {
    const params = {
      startDate: startDate,
      endDate: endDate,
    };
    if (reportType === "all") {
      dispatch(getTeamLeadQualityCheckRatingReport(params));
      dispatch(getTeamLeadQuestionQualityCheckRatingReport(params));
    } else if (reportType === "quality")
      dispatch(getTeamLeadQualityCheckRatingReport(params));
    else dispatch(getTeamLeadQuestionQualityCheckRatingReport(params));
  }, [dispatch, endDate, reportType, startDate]);

  const reportOptions = [
    { value: "all", label: t("all") },
    { value: "quality", label: t("quality_control_rating") },
    { value: "question", label: t("question_rating") },
  ];

  const handleReportTypeDropDownChange = (reportType) => {
    if (reportType) {
      setReportType(reportType?.value);
    }
  };

  let qualityCheckRatingReportData = null;
  let questionRatingReportData = null;
  let qualityCheckRatingReportTitle = "";
  let questionRatingReportTitle = "";
  let columnSpan = 6;
  if (reportType === "all") {
    qualityCheckRatingReportData = dashboard?.teamLeadQualityCheckRating;
    questionRatingReportData = dashboard?.teamLeadQuestionQualityCheckRating;
    qualityCheckRatingReportTitle = t("team_lead_quality_checks_rating");
    questionRatingReportTitle = t("team_lead_question_rating");
  } else if (reportType === "quality") {
    qualityCheckRatingReportData = dashboard?.teamLeadQualityCheckRating;
    qualityCheckRatingReportTitle = t("team_lead_quality_checks_rating");
    columnSpan = 12;
  } else {
    questionRatingReportData = dashboard?.teamLeadQuestionQualityCheckRating;
    questionRatingReportTitle = t("team_lead_question_rating");
    columnSpan = 12;
  }
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          {t("team_lead_quality_and_question_rating_report")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={3} lg={3} xl={3}>
            <FormGroup>
              <Label for="select_report_type">{t("select_report_type")}</Label>
              <ReactSelect
                key={t("quality_control_rating")}
                className="calenderSelect react-select-container"
                classNamePrefix="react-select"
                options={reportOptions}
                defaultValue={{
                  value: "all",
                  label: t("all"),
                }}
                onChange={handleReportTypeDropDownChange}
                placeholder={t("select")}
              />
            </FormGroup>
          </Col>
          <Col md={3} lg={3} xl={3}>
            <FormGroup>
              <Label for="year_picker">{t("year_picker")}</Label>
              <DateTime
                inputProps={{
                  readOnly: true,
                  placeholder: t("select"),
                }}
                className="effectDatePicker"
                name="yearPicker"
                dateFormat={"YYYY"}
                timeFormat={false}
                closeOnSelect
                onChange={(date) => {
                  const selectedYear = date.year();
                  const startDate = selectedYear + "-01-01";
                  const endDate = selectedYear + "-12-31";
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12} xl={12} className="mt-3">
            <Row>
              {qualityCheckRatingReportData && (
                <Col md={columnSpan}>
                  <YearlyTable
                    data={qualityCheckRatingReportData}
                    title={qualityCheckRatingReportTitle}
                    handleTableChange={""}
                    isLoading={dashboard?.isTeamLeadQualityCheckProcessing}
                    isTeamLeadData={true}
                  />
                </Col>
              )}
              {questionRatingReportData && (
                <Col md={columnSpan}>
                  <YearlyTable
                    data={questionRatingReportData}
                    title={questionRatingReportTitle}
                    handleTableChange={""}
                    isLoading={dashboard?.isTeamLeadQuestionCheckProcessing}
                    isTeamLeadData={true}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default TeamleadQualityAndQuestionRating;
