import { AvForm } from "availity-reactstrap-validation";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DateTime from "react-datetime";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import {
  DATA_TIME_ZONE,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DISPLAY_TIME_ZONE,
  MESSAGE_ERROR,
  SCHEDULE_DURATION,
  TIME_FORMAT,
} from "../../config/AppConstant";
import { retrieveEmployees } from "../../redux/actions/employeeActions";
import { getQualityCheck } from "../../redux/actions/qualityCheckActions";
import {
  checkStartDateValidation,
  getEmployeeOption,
  showMessage,
  valid,
  validateInput,
} from "../../utils/app-utils";

const CreateQualityCheck = (props) => {
  const dispatch = useDispatch();
  const { employees, qualityChecks } = useSelector((state) => state);
  const [qualityCheck, setQualityCheck] = useState({
    publicId: "",
    employeePublicId: "",
    addressId: "",
    title: "",
    start: "",
    end: "",
    parentId: "",
    selectedCustomer: "",
    selectedAgreement: "",
    selectedAddress: "",
    selectedEmployee: "",
    scheduleId: "",
    isCreate: true,
  });

  useEffect(() => {
    props.qualityCheck &&
      setQualityCheck((prevState) => ({
        ...prevState,
        employeePublicId: props.qualityCheck.employeePublicId,
        addressId: props.qualityCheck.addressId,
        title: props.qualityCheck.title,
        start: props.qualityCheck.start,
        end: props.qualityCheck.end,
        publicId: props.qualityCheck.publicId,
        parentId: props.qualityCheck.parentId,
        isCreate: props.qualityCheck.isCreate,
      }));
  }, [props.qualityCheck]);

  useEffect(() => {
    const params = {
      status: true,
      type: "TEAM_LEAD",
    };
    dispatch(retrieveEmployees(params, true));
  }, [dispatch]);

  useEffect(() => {
    if (qualityChecks.detail.length > 0) {
      let qualityCheckData = qualityChecks.detail[0];

      if (qualityCheckData?.schedule) {
        let address = qualityCheckData?.schedule?.address;
        let selectedCustomer = address?.agreement?.customer?.name;
        let selectedAgreement = address?.agreement?.agreementNumber;
        let selectedAddress =
          address?.objectName + " (" + address?.objectNumber + ")";

        setQualityCheck((prevState) => ({
          ...prevState,
          addressId: address?.publicId,
          selectedCustomer: selectedCustomer,
          selectedAgreement: selectedAgreement,
          selectedAddress: selectedAddress,
          title: qualityCheck.isCreate ? "" : qualityCheckData?.schedule?.title,
          start: qualityCheck.isCreate ? "" : qualityCheckData?.schedule?.start,
          selectedEmployee: qualityCheck.isCreate
            ? ""
            : qualityCheckData?.schedule?.employee?.name,
          employeePublicId: qualityCheck.isCreate
            ? ""
            : qualityCheckData?.schedule?.employee?.publicId,
          scheduleId: qualityCheckData?.schedule?.publicId,
        }));
      }
    }
  }, [qualityCheck.isCreate, qualityChecks]);

  useEffect(() => {
    if (qualityCheck.publicId) {
      dispatch(getQualityCheck(qualityCheck.publicId));
    } else if (qualityCheck.parentId) {
      dispatch(getQualityCheck(qualityCheck.parentId));
    }
  }, [dispatch, qualityCheck.parentId, qualityCheck.publicId]);

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    setQualityCheck((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const start = moment.utc(qualityCheck.start);
    let payload = {
      publicId: qualityCheck.publicId ? qualityCheck.scheduleId : "",
      employeePublicId: qualityCheck.employeePublicId,
      addressId: qualityCheck.addressId,
      title: qualityCheck.title,
      start: start.toISOString(),
      end: start.add(SCHEDULE_DURATION, "minutes").toISOString(),
    };
    const values = [
      payload.title,
      payload.start,
      payload.end,
      payload.employeePublicId,
      payload.addressId,
    ];

    if (validateInput(values)) {
      props.handleOnSubmit(qualityCheck.parentId, payload);
    } else {
      showMessage({
        type: MESSAGE_ERROR,
        message: t("missing_information"),
      });
    }
  };

  const employeeOptions = employees?.allData?.map((employee) =>
    getEmployeeOption(employee)
  );

  const handleDropDownChange = (event) => {
    let { value } = event;
    value = value?.split("|");
    event.label = value[1];
    setQualityCheck((prevState) => ({
      ...prevState,
      employeePublicId: value[0],
      selectedEmployee: value[1],
    }));
  };

  const dateTimeLocale =
    localStorage.getItem("i18nextLng") === "SE" ? "sv" : "en-gb";

  const lastScheduleStartDate = DateTime.moment.utc(
    qualityChecks?.detail[0]?.schedule?.start
  );

  return (
    <Card>
      <CardHeader>
        <div className="card-actions float-right">
          <span className="cursor-pointer mr-1">
            <X onClick={() => props.setQualityCheck(null)} />
          </span>{" "}
        </div>
        <CardTitle tag="h5" className="mb-0">
          {t("follow_up")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <AvForm onValidSubmit={handleOnSubmit}>
          <FormGroup>
            <Label for="title">{t("title")}</Label>
            <Input
              type="text"
              name="title"
              id="title"
              placeholder={t("title")}
              value={qualityCheck.title}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="customerId">{t("customer")}</Label>
            <ReactSelect
              className="react-select-container"
              classNamePrefix="react-select"
              isDisabled={true}
              value={
                qualityCheck.selectedCustomer
                  ? { label: qualityCheck.selectedCustomer, value: "" }
                  : ""
              }
              id="customerId"
            />
          </FormGroup>
          <FormGroup>
            <Label for="agreementId">{t("agreement_number")}</Label>
            <ReactSelect
              className="react-select-container"
              classNamePrefix="react-select"
              isDisabled={true}
              value={
                qualityCheck.selectedAgreement
                  ? { label: qualityCheck.selectedAgreement, value: "" }
                  : ""
              }
              id="agreementId"
            />
          </FormGroup>
          <FormGroup>
            <Label for="addressId">{t("address")}</Label>
            <ReactSelect
              className="react-select-container"
              classNamePrefix="react-select"
              isDisabled={true}
              value={
                qualityCheck.selectedAddress
                  ? { label: qualityCheck.selectedAddress, value: "" }
                  : ""
              }
              id="addressId"
            />
          </FormGroup>
          <FormGroup>
            <Label for="employeePublicId">{t("employee")}</Label>
            <ReactSelect
              className="react-select-container"
              classNamePrefix="react-select"
              isSearchable
              options={employeeOptions}
              value={
                qualityCheck.selectedEmployee
                  ? {
                      label: qualityCheck.selectedEmployee,
                      value: qualityCheck.employeePublicId,
                    }
                  : ""
              }
              onChange={handleDropDownChange}
              id="employeePublicId"
            />
          </FormGroup>
          <FormGroup>
            <Label for="start">{t("start_date")}</Label>
            <div className="align-self-center w-100">
              <DateTime
                inputProps={{
                  readOnly: false,
                  placeholder: t("start_date"),
                }}
                className="effectDatePicker qualityCheckDatePicker"
                name="start"
                timeConstraints={{
                  hours: { min: 6, max: 20 },
                  minutes: { min: 0, max: 59 },
                  seconds: { min: 0, max: 59 },
                }}
                initialViewDate={moment.utc().set({ hour: 6, minute: 0 })}
                dateFormat={DATE_FORMAT}
                timeFormat={TIME_FORMAT}
                dataTimezone={DATA_TIME_ZONE}
                displayTimezone={DISPLAY_TIME_ZONE}
                //value={qualityCheck.start}
                locale={dateTimeLocale}
                isValidDate={valid}
                utc={true}
                closeOnSelect
                onClose={(date) => {
                  if (typeof date != "string") {
                    checkStartDateValidation(date);
                    setQualityCheck((prevState) => ({
                      ...prevState,
                      start: date.toISOString(),
                    }));
                  }
                }}
                renderInput={(props) => {
                  return (
                    <input
                      {...props}
                      value={qualityCheck?.start ? props.value : ""}
                    />
                  );
                }}
              />
            </div>
          </FormGroup>
          <FormGroup>
            {!qualityCheck.isCreate && (
              <Label for="lastScheduleStartDate">
                {t("last_schedule_start_date") +
                  " : " +
                  lastScheduleStartDate.format(DATE_TIME_FORMAT)}
              </Label>
            )}
          </FormGroup>
          <Button color="primary">
            {qualityCheck.isCreate ? t("create") : t("update")}
          </Button>
        </AvForm>
      </CardBody>
    </Card>
  );
};

export default CreateQualityCheck;
