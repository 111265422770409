import React from "react";
import Chart from "react-apexcharts";
import { connect } from "react-redux";

import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { t } from "i18next";

const ColumnChart = (props) => {
  const { theme, data } = props;
  const options = {
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "75%",
      },
    },
    chart: {
      type: "bar",
      height: props.height,
    },
    xaxis: {
      type: "category",
    },
    yaxis: {
      title: {
        text: t("avg_quality_checks_rating"),
      },
      min: 1,
      max: 6,
      tickAmount: 5,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    colors: [
      theme.primary,
      theme.success,
      theme.warning,
      theme.danger,
      theme.info,
    ],
  };

  return (
    <Card className={props.border ? "border" : ""}>
      <CardHeader>
        <CardTitle tag="h5"> {props.title}</CardTitle>
      </CardHeader>
      <CardBody>
        <Chart
          options={options}
          series={data}
          type="bar"
          height={props.height}
        />
      </CardBody>
    </Card>
  );
};

export default connect((store) => ({
  theme: store.theme.currentTheme,
}))(ColumnChart);
