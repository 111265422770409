import {
  CREATE_CLEANER_EGEN_QUESTIONNAIRE,
  CREATE_CLEANER_EGEN_QUESTIONNAIRE_CATEGORY,
  RETRIEVE_CLEANER_EGEN_QUESTIONNAIRES,
  RETRIEVE_CLEANER_EGEN_QUESTIONNAIRES_CATEGORIES,
  UPDATE_CLEANER_EGEN_QUESTIONNAIRE,
} from "../constants";

const initialState = {
  data: [],
  category: [],
  page: 0,
  sizePerPage: 0,
  totalSize: 0,
  isLoading: true,
  isError: false,
  isCleaner: false,
};

export default function reducer(state = initialState, actions) {
  const { type, payload } = actions;
  switch (type) {
    case CREATE_CLEANER_EGEN_QUESTIONNAIRE_CATEGORY:
      return {
        ...state,
        category: [...state.data, payload],
        isLoading: false,
      };
    case RETRIEVE_CLEANER_EGEN_QUESTIONNAIRES_CATEGORIES:
      return {
        ...state,
        category: payload.content,
        page: payload.pageNo,
        sizePerPage: payload.pageSize,
        totalSize: payload.totalElements,
        isLoading: false,
      };
    case RETRIEVE_CLEANER_EGEN_QUESTIONNAIRES:
      return {
        ...state,
        data: payload.content,
        page: payload.pageNo,
        sizePerPage: payload.pageSize,
        totalSize: payload.totalElements,
        isLoading: false,
      };
    case CREATE_CLEANER_EGEN_QUESTIONNAIRE:
      return {
        ...state,
        data: [...state.data, payload],
        isLoading: false,
        isCleaner: payload.type === "CLEANER",
      };
    case UPDATE_CLEANER_EGEN_QUESTIONNAIRE:
      return {
        ...state,
        data: state.data.map((EGEN_QUESTIONNAIRE) => {
          if (EGEN_QUESTIONNAIRE.publicId === payload.publicId) {
            return {
              ...EGEN_QUESTIONNAIRE,
              ...payload,
            };
          } else {
            return EGEN_QUESTIONNAIRE;
          }
        }),
        isLoading: false,
        isCleaner: payload.type === "CLEANER",
      };
    default:
      return state;
  }
}
