import { URL_EGEN_CONTROL } from "../config/AppConstant";
import http from "../utils/http-common";

class EgenControlDataService {
  getAll(params) {
    return http.get(URL_EGEN_CONTROL + "/list", { params });
  }

  get(id) {
    return http.get(`${URL_EGEN_CONTROL}/${id}`);
  }

  getReport(id) {
    return http.get(`${URL_EGEN_CONTROL}/${id}/report`, {
      responseType: "blob",
    });
  }

  create(data) {
    return http.post(URL_EGEN_CONTROL, data);
  }

  update(data) {
    return http.put(`${URL_EGEN_CONTROL}`, data);
  }

  updatePerformedDate(data) {
    return http.put(`${URL_EGEN_CONTROL}/update-date`, data);
  }

  delete(id) {
    return http.delete(`${URL_EGEN_CONTROL}/${id}`);
  }
  validateUrl(id) {
    return http.get(`/verification/verify/${id}/EGENKONTROLL_APPROVAL`);
  }
  updateStatus(uuid, data) {
    return http.put(
      `${URL_EGEN_CONTROL}/update-status/${uuid}/EGENKONTROLL_APPROVAL`,
      data
    );
  }
  exportEgenControl(params) {
    return http.get(`${URL_EGEN_CONTROL}/export`, {
      params,
      responseType: "arraybuffer",
      "Content-Type": "blob",
    });
  }
  exportCompletedEgenControl(params) {
    return http.get(`${URL_EGEN_CONTROL}/export/cleaner/questionnaire`, {
      params,
      responseType: "arraybuffer",
      "Content-Type": "blob",
    });
  }
}

export default new EgenControlDataService();
