import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
import "moment/locale/en-gb";
import "moment/locale/sv";
import React, { useEffect, useState } from "react";
import DateTime from "react-datetime";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ReactSelect from "react-select";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Spinner,
} from "reactstrap";
import Row from "reactstrap/lib/Row";
import {
  DATA_TIME_ZONE,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DISPLAY_TIME_ZONE,
  MESSAGE_ERROR,
  TIME_FORMAT,
} from "../../config/AppConstant";
import { updateAddressSchedule } from "../../redux/actions/addressActions";
import {
  checkDateValidation,
  checkStartDateValidation,
  convertToISOString,
  showMessage,
  valid,
  validateInput,
} from "../../utils/app-utils";
import moment from "moment";

const initialScheduleCategory = {
  publicId: "",
  type: "",
  frequency: 1,
  title: "",
  duration: "",
  startDate: "",
  endDate: "",
};

const ScheduleCategory = (props) => {
  const [scheduleCategory, setScheduleCategory] = useState(
    initialScheduleCategory
  );

  const { duration, type, frequency, title, startDate, endDate } =
    scheduleCategory;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (props.address?.scheduleCategory) {
      let scheduleCat = props.address?.scheduleCategory;
      setScheduleCategory({
        publicId: scheduleCat.publicId,
        type: scheduleCat.type,
        frequency: scheduleCat.frequency,
        title: scheduleCat.title,
        startDate: "",
        duration: scheduleCat?.duration,
        endDate: "",
      });
    } else {
      setScheduleCategory((prevState) => ({
        ...prevState,
        duration: props.address?.agreement?.customer?.duration,
      }));
    }
  }, [
    props.address?.agreement?.customer?.duration,
    props.address?.scheduleCategory,
  ]);

  const typeOptions = [
    {
      label: t("daily"),
      value: "daily",
    },
    {
      label: t("weekly"),
      value: "weekly",
    },
    {
      label: t("monthly"),
      value: "monthly",
    },
    {
      label: t("yearly"),
      value: "yearly",
    },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setScheduleCategory((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDurationDropDownChange = (event) => {
    let { value } = event;
    setScheduleCategory((prevState) => ({
      ...prevState,
      duration: value,
    }));
  };

  const readOnly = props.address?.publicId ? false : true;

  const dateTimeLocale =
    localStorage.getItem("i18nextLng") === "SE" ? "sv" : "en-gb";

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const values = [title, type, endDate, startDate, duration, frequency];
    if (validateInput(values)) {
      const addressSchedule = {
        type,
        frequency,
        title,
        duration,
        startDate,
        endDate,
      };
      if (
        checkStartDateValidation(startDate) &&
        checkDateValidation(startDate, endDate)
      )
        dispatch(
          updateAddressSchedule(props.address?.publicId, addressSchedule)
        );
    } else {
      showMessage({
        type: MESSAGE_ERROR,
        message: t("missing_information"),
      });
    }
  };

  const addressDuration = duration
    ? duration
    : props.address?.agreement?.customer?.duration;

  const lastUpdated = props.address?.scheduleCategory?.lastUpdated
    ? convertToISOString(
        DateTime.moment.utc(props.address?.scheduleCategory?.lastUpdated)
      )
    : "";

  const lastUpdatedSch = DateTime.moment.utc(lastUpdated);

  const durationOptions = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 15, value: 15 },
    { label: 20, value: 20 },
    { label: 25, value: 25 },
    { label: 30, value: 30 },
    { label: 35, value: 35 },
    { label: 40, value: 40 },
    { label: 45, value: 45 },
    { label: 50, value: 50 },
    { label: 55, value: 55 },
    { label: "60 ( 1 " + t("hrs") + " )", value: 60 },
    { label: "90 ( 1.5 " + t("hrs") + " )", value: 90 },
    { label: "120 ( 2 " + t("hrs") + " )", value: 120 },
    { label: "150 ( 2.5 " + t("hrs") + " )", value: 150 },
    { label: "180 ( 3 " + t("hrs") + " )", value: 180 },
    { label: "210 ( 3.5 " + t("hrs") + " )", value: 210 },
    { label: "240 ( 4 " + t("hrs") + " )", value: 240 },
    { label: "270 ( 4.5 " + t("hrs") + " )", value: 270 },
    { label: "300 ( 5 " + t("hrs") + " )", value: 300 },
    { label: "330 ( 5.5 " + t("hrs") + " )", value: 330 },
    { label: "360 ( 6 " + t("hrs") + " )", value: 360 },
    { label: "390 ( 6.5 " + t("hrs") + " )", value: 390 },
    { label: "420 ( 7 " + t("hrs") + " )", value: 420 },
    { label: "450 ( 7.5 " + t("hrs") + " )", value: 450 },
    { label: "480 ( 8 " + t("hrs") + " )", value: 480 },
  ];

  return (
    <Card>
      <CardBody>
        <h5>{t("schedule_information")}</h5>
        <AvForm id="schedule-form" onValidSubmit={handleOnSubmit}>
          <Row>
            <Col>
              <AvGroup>
                <Label for="title">{t("title")}</Label>
                <AvInput
                  readOnly={readOnly}
                  required={!readOnly}
                  type="text"
                  name="title"
                  id="title"
                  value={scheduleCategory?.title || ""}
                  onChange={handleInputChange}
                  placeholder={t("title")}
                />
              </AvGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="type">{t("type")}</Label>
                <ReactSelect
                  isDisabled={readOnly}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={typeOptions}
                  isSearchable
                  id="type"
                  value={{
                    label:
                      typeOptions.find(
                        (t) => t.value === scheduleCategory?.type
                      )?.label || t("select"),
                    value: scheduleCategory?.type || "",
                  }}
                  onChange={(event) => {
                    let { value, label } = event;
                    setScheduleCategory((prevState) => ({
                      ...prevState,
                      type: value,
                      typeName: label,
                    }));
                  }}
                />
              </FormGroup>
            </Col>
            <Col>
              <AvGroup>
                <Label for="frequency">{t("repeat_every")}</Label>
                <AvInput
                  readOnly={readOnly}
                  required={!readOnly}
                  type="number"
                  name="frequency"
                  id="frequency"
                  min={1}
                  value={scheduleCategory?.frequency || ""}
                  onChange={handleInputChange}
                />
              </AvGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              {/* <AvGroup>
                <Label for="duration">{t("duration")}</Label>
                <AvInput
                  readOnly={readOnly}
                  required={!readOnly}
                  type="number"
                  name="duration"
                  id="duration"
                  value={addressDuration || 1}
                  step={0.01}
                  min={0.17}
                  max={8}
                  placeholder={t("duration")}
                  onChange={handleInputChange}
                />
                <AvFeedback>{t("missing_duration")}</AvFeedback>
              </AvGroup> */}
              <FormGroup>
                <Label for="duration">{t("duration")}</Label>
                <ReactSelect
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={durationOptions}
                  value={{
                    label: addressDuration || t("select"),
                    value: addressDuration || "",
                  }}
                  isSearchable
                  id="duration"
                  onChange={handleDurationDropDownChange}
                  menuPlacement="top"
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="startDate">{t("start_date")}</Label>
                <div className="align-self-center w-100">
                  <DateTime
                    inputProps={{
                      readOnly: readOnly,
                      placeholder: t("start_date"),
                    }}
                    timeConstraints={{
                      hours: { min: 6, max: 20 },
                      minutes: { min: 0, max: 59 },
                      seconds: { min: 0, max: 59 },
                    }}
                    initialViewDate={moment.utc().set({ hour: 6, minute: 0 })}
                    name="startDate"
                    className="scheduleCategoryDatePicker"
                    dateFormat={DATE_FORMAT}
                    timeFormat={TIME_FORMAT}
                    dataTimezone={DATA_TIME_ZONE}
                    displayTimezone={DISPLAY_TIME_ZONE}
                    renderInput={(props) => {
                      return (
                        <input
                          {...props}
                          value={scheduleCategory?.startDate ? props.value : ""}
                        />
                      );
                    }}
                    // initialValue={DateTime.moment(scheduleCategory?.startDate)}
                    // defaultValue={DateTime.moment(scheduleCategory?.startDate)}
                    //value={scheduleCategory?.startDate}
                    // initialValue={DateTime.moment(scheduleCategory?.startDate)}
                    locale={dateTimeLocale}
                    isValidDate={valid}
                    onClose={(date) => {
                      if (typeof date != "string") {
                        checkStartDateValidation(date);
                        checkDateValidation(date, endDate);
                        setScheduleCategory((prevState) => ({
                          ...prevState,
                          startDate: date.toISOString(),
                        }));
                      }
                    }}
                    utc={true}
                    closeOnSelect={true}
                    // onChange={(date) => {
                    //   if (typeof date != "string") {
                    //     setScheduleCategory((prevState) => ({
                    //       ...prevState,
                    //       startDate: date.toISOString(),
                    //     }));
                    //   }
                    // }}
                  />
                </div>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="endDate">{t("end_date")}</Label>
                <div className="align-self-center w-100">
                  <DateTime
                    name="endDate"
                    className="scheduleCategoryDatePicker"
                    inputProps={{
                      readOnly: readOnly,
                      placeholder: t("end_date"),
                    }}
                    // inputProps={{ readOnly: readOnly }}
                    // initialValue={DateTime.moment(scheduleCategory?.endDate)}
                    // defaultValue={DateTime.moment(scheduleCategory?.endDate)}
                    // value={scheduleCategory?.endDate}
                    renderInput={(props) => {
                      return (
                        <input
                          {...props}
                          value={scheduleCategory?.endDate ? props.value : ""}
                        />
                      );
                    }}
                    dateFormat={DATE_FORMAT}
                    timeFormat={false}
                    dataTimezone={DATA_TIME_ZONE}
                    displayTimezone={DISPLAY_TIME_ZONE}
                    locale={dateTimeLocale}
                    isValidDate={valid}
                    utc={true}
                    onClose={(date) => {
                      if (typeof date != "string") {
                        checkDateValidation(startDate, date);
                        date = date.add(23, "hours");
                        date = date.add(59, "minutes");
                        setScheduleCategory((prevState) => ({
                          ...prevState,
                          endDate: date.toISOString(),
                        }));
                      }
                    }}
                    closeOnSelect={true}
                    // onChange={(date) => {
                    // if (typeof date != "string") {
                    // setScheduleCategory((prevState) => ({
                    //   ...prevState,
                    //   endDate: typeof date != "string" ? date.toISOString() : date,
                    // }));
                    // }
                    // }}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              {props.address?.scheduleCategory?.publicId ? (
                <Label for="lastUpdated">
                  {t("last_updated_schedule") +
                    " : " +
                    lastUpdatedSch.format(DATE_TIME_FORMAT)}
                </Label>
              ) : (
                <Label for="noSchedule">{t("no_schedule")}</Label>
              )}
              {props.address?.publicId && (
                <Button
                  style={{ float: "right" }}
                  color="primary"
                  type="submit"
                  disabled={props.isProcessing}
                >
                  {props.isProcessing ? <Spinner /> : t("schedule_now")}
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {props.address?.agreement?.duration && (
                <Label for="gapBtwSchedule">
                  {t("gap_between_schedule") +
                    " : " +
                    props.address?.agreement?.duration}
                </Label>
              )}
            </Col>
          </Row>
        </AvForm>
      </CardBody>
    </Card>
  );
};

export default ScheduleCategory;
